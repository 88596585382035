// import { createSession } from 'api/routes/session';
import { getEvent, getEventById } from 'api/routes/event';
import { getSessionbyId, getSessionForEvent } from 'api/routes/session';
import { getSessionQuestions } from 'api/routes/sessionQuestion';
import { getSessionResource } from 'api/routes/sessionResources';
import { authUser, initializeUserData } from 'api/routes/user';
import { getMeetingAnalytics } from 'api/routes/userSessionData';
import { getAvailableVods, getVods } from 'api/routes/vod';
import get from 'lodash/get';
import { call, put, retry, select, takeEvery } from 'redux-saga/effects';
import * as aa from 'store/analytics/analyticsActions';
import { setAppTypeAction, setChooseServicesAction } from 'store/app/appActions';
import * as ca from 'store/chime/chimeActions';
import { joinMeetingFlowSaga } from 'store/chime/chimeFlowSagas';
import * as ea from 'store/event/eventActions';
import * as sqa from 'store/session-question/sessionQuestionActions';
import * as sra from 'store/session-resource/sessionResourceActions';
import * as sa from 'store/session/sessionActions';
import * as ua from 'store/user/userActions';
import { setRefreshTokenCookie, setTokenCookie } from 'utils/token';

export function* initializeTokenFlowSaga(action) {
  const key = 'initializeTokenFlowSaga';
  try {
    yield put(ua.initAction({ key }));
    const { externalToken, accountId } = action.payload;
    const {
      token,
      refreshToken,
      user,
      error,
      type,
      role,
      eventId,
      choose,
      servicesOnly,
    } = yield call(authUser, externalToken, accountId);
    localStorage.setItem('eventId', eventId);
    if (user?.isRecordingUser) {
      yield put(
        ua.setReloadUrlAction({ reloadUrl: `${window.location.pathname}${window.location.search}` })
      );
    }
    yield put(ea.setEventAction({ eventId }));
    if (error) {
      throw new Error(
        get(error, 'message', 'Unknown Error Occurred While Trying To Authroize The User.')
      );
    } else {
      localStorage.setItem('user', JSON.stringify({ ...user, role, choose, servicesOnly }));
      yield put(ua.setUserAction({ ...user, role }));
      setTokenCookie(token);
      setRefreshTokenCookie(refreshToken);
      yield put(setChooseServicesAction({ choose, servicesOnly }));
      yield put(ea.setTypeAction(type));
      yield put(setAppTypeAction({ appType: type }));
      yield put(ua.setIsLoggedInAction({ isLoggedIn: true }));
    }

    yield put(ua.successAction({ key }));
  } catch (error) {
    yield put(ua.setIsLoggedInAction({ isLoggedIn: false }));
    yield put(ua.errorAction({ key, error }));
  }
}

export function* initializeRecordingRouteFlowSaga(action) {
  const tries = 2;
  const retryDelay = 500;
  const key = 'initializeRecordingRouteFlowSaga';
  try {
    yield put(ua.initAction({ key }));
    const { audioRef } = action.payload;
    const { userId } = yield select(({ user }) => ({
      userId: user.userId,
    }));

    const { event } = yield call(getEvent);
    yield put(ea.setEventAction({ ...event }));
    if (!event.currentSessionId) {
      throw new Error('No Session for Event');
    }

    const { session, updatedToken: token, updatedRefreshToken: refreshToken } = yield call(
      getSessionForEvent,
      event.currentSessionId
    );
    yield put(sa.setSessionAction({ ...session }));
    setTokenCookie(token);
    setRefreshTokenCookie(refreshToken);

    if (session.currentResourceId) {
      const sessionResource = yield call(getSessionResource, session.currentResourceId);
      yield put(
        sra.setSessionResource({
          ...sessionResource.sessionresource,
        })
      );
    }
    yield retry(tries, retryDelay, joinMeetingFlowSaga, {
      payload: {
        sessionId: session.sessionId,
        userId,
        audioRef,
      },
    });
    const { audioVideo } = yield select(({ chime }) => ({
      audioVideo: chime.audioVideo,
    }));
    audioVideo.start();
    yield put(ca.setHasJoinedAction({ hasJoined: true }));
    yield put(ua.successAction({ key }));
  } catch (error) {
    console.log('ERROR', error);
    yield put(ua.errorAction({ key, error }));
  }
}

export function* initializeSettingsRouteFlowSaga() {
  const key = 'initializeSettingsRouteFlowSaga';
  try {
    yield put(ua.initAction({ key }));
    const { role } = yield select(({ user }) => ({
      role: user.role,
    }));

    if (role !== 'admin') throw Error('Unauthorized');
    const { event, error: eventError } = yield call(getEvent);
    if (eventError) throw new Error(eventError.message);
    yield put(ea.setEventAction({ ...event }));
    const response = yield call(getVods);
    yield put(
      ea.setVodsAction({
        vods: response.vods,
      })
    );

    yield put(ua.successAction({ key }));
  } catch (error) {
    yield put(ua.errorAction({ key, error }));
  }
}

export function* initializeVodRouteFlowSaga() {
  const key = 'initializeVodRouteFlowSaga';
  try {
    yield put(ua.initAction({ key }));
    const { event, error: eventError } = yield call(getEvent);
    if (eventError) throw new Error(eventError.message);
    yield put(ea.setEventAction({ ...event }));
    const response = yield call(getAvailableVods);
    yield put(
      ea.setVodsAction({
        vods: response.vods,
      })
    );

    yield put(ua.successAction({ key }));
  } catch (error) {
    yield put(ua.errorAction({ key, error }));
  }
}

export function* initializeAnalyticsRouteFlowSaga(action) {
  const key = 'initializeAnalyticsRouteFlowSaga';
  try {
    yield put(ua.initAction({ key }));
    const { sessionId, eventId } = action.payload;
    const { event, error: eventError } = yield call(getEventById, eventId);
    if (eventError) throw new Error(eventError.message);
    yield put(ea.setEventAction({ ...event }));
    const { session, error: sessionError } = yield call(getSessionbyId, sessionId, eventId);
    if (sessionError) throw new Error(sessionError.message);
    yield put(sa.setSessionAction({ ...session }));
    const { sessionData } = yield call(getMeetingAnalytics, sessionId, eventId);
    yield put(aa.addSessionDataAction({ sessionData }));

    yield put(ua.successAction({ key }));
  } catch (error) {
    yield put(ua.errorAction({ key, error }));
  }
}

export function* initializeMeetingRouteFlowSaga(action) {
  const tries = 2;
  const retryDelay = 500;
  const key = 'initializeMeetingRouteFlowSaga';
  try {
    yield put(ua.initAction({ key }));
    yield put(ca.setWaitingPayloadAction({ waitingPayload: action.payload }));
    const { servicesOnly = false, isBroadcast = false } = action.payload;
    const { role, userId, sessionId } = yield select(({ user, app }) => ({
      role: user.role,
      appType: app.appType,
      userId: user.userId,
    }));

    yield put(setAppTypeAction({ appType: isBroadcast ? 'broadcast' : 'meeting' }));

    const { event, error: eventError } = yield call(getEvent);
    if (eventError) throw new Error(eventError.message);
    yield put(ea.setEventAction({ ...event }));
    const {
      session,
      updatedToken: token,
      updatedRefreshToken: refreshToken,
      error: sessionError,
    } = yield call(getSessionForEvent, event.currentSessionId);
    if (sessionError) throw new Error(sessionError.message);
    yield put(sa.setSessionAction({ ...session }));
    setTokenCookie(token);
    setRefreshTokenCookie(refreshToken);

    const questions = yield retry(tries, retryDelay, getSessionQuestions);
    yield put(sqa.setAllQuestionsAction({ questions }));

    if ((isBroadcast && role === 'attendee') || servicesOnly) {
      yield put(ca.startMessagingAction());
      yield put(ca.broadcastAttendeeAction());
      yield call(initializeUserData);
    } else {
      const { audioRef } = action.payload;
      if (session.currentResourceId) {
        const sessionResource = yield call(getSessionResource, session.currentResourceId);
        yield put(
          sra.setSessionResource({
            ...sessionResource.sessionresource,
          })
        );
      }
      yield retry(tries, retryDelay, joinMeetingFlowSaga, {
        payload: {
          sessionId: sessionId,
          userId: userId,
          audioRef,
        },
      });
    }
    yield put(ua.successAction({ key }));
  } catch (error) {
    yield put(ua.errorAction({ key, error }));
  }
}

export default function* userWatcher() {
  yield takeEvery(ua.initializeTokenAction().type, initializeTokenFlowSaga);
  yield takeEvery(ua.initializeRecordingRouteAction().type, initializeRecordingRouteFlowSaga);
  yield takeEvery(ua.initializeSettingsRouteAction().type, initializeSettingsRouteFlowSaga);
  yield takeEvery(ua.initializeMeetingRouteAction().type, initializeMeetingRouteFlowSaga);
  yield takeEvery(ua.initializeVodRouteAction().type, initializeVodRouteFlowSaga);
  yield takeEvery(ua.initializeAnalyticsRouteAction().type, initializeAnalyticsRouteFlowSaga);
}
