import IconButton from '@material-ui/core/IconButton';
import ExpandLessIcon from '@material-ui/icons/ExpandLess';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { updateLocalVideoStatus } from 'api/routes/userSession';
import useStyles from 'components/GridView.style';
import VideoStatus from 'enums/VideoStatus';
import PropTypes from 'prop-types';
import { useEffect, useRef, useState } from 'react';
import Draggable from 'react-draggable';
import { useDispatch, useSelector } from 'react-redux';
import { toggleVideoStatusAction } from 'store/chime/chimeActions';
import { showNotificationAction } from 'store/utils/utilActions';

const constraints = {
  video: {
    deviceId: {
      exact: '',
    },
    width: {
      max: 1280,
    },
    height: {
      max: 720,
    },
  },
};

export default function VideoPreviewBox({ hidden }) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const nodeRef = useRef(null);
  const currentStream = useRef(null);
  const currentVideoInputDevice = useSelector((store) => store.chime.currentVideoInputDevice);
  const firstName = useSelector((store) => store.user.firstName);
  const lastName = useSelector((store) => store.user.lastName);

  const videoElement = useRef(null);
  const [previewOpen, setPreviewOpen] = useState(true);

  const startLocalWebcam = async () => {
    try {
      constraints.video.deviceId.exact = currentVideoInputDevice;
      const stream = await navigator.mediaDevices.getUserMedia(constraints);
      currentStream.current = stream;
      stream.oninactive = () => void toggleVideoStatusAction(VideoStatus.Disabled);
      if (videoElement.current) videoElement.current.srcObject = stream;
    } catch (e) {
      dispatch(
        showNotificationAction({
          message: "Cannot connect to camera. Make sure it isn't in use by another application.",
          type: 'error',
        })
      );

      dispatch(toggleVideoStatusAction(VideoStatus.Disabled));
      await updateLocalVideoStatus(VideoStatus.Disabled);
    }
  };

  const stopLocalWebcam = async () => {
    currentStream?.current?.getVideoTracks()?.forEach((track) => {
      track.enabled = false;
      track.stop();
    });
    if (videoElement.current) videoElement.current.srcObject = null;
  };

  useEffect(() => {
    if (hidden) {
      stopLocalWebcam();
    } else {
      startLocalWebcam();
    }
    return () => {
      try {
        stopLocalWebcam();
      } catch (e) {
        console.log('UNABLE TO STOP LOCAL WEBCAM', e);
      }
    };
  }, [hidden, currentVideoInputDevice]);

  return (
    <Draggable nodeRef={nodeRef} bounds="parent" handle=".drag">
      <div
        ref={nodeRef}
        className={classes.videoPreview}
        data-open={previewOpen ? 1 : 0}
        data-hidden={hidden ? 1 : 0}
      >
        <div className="drag">
          <div className={classes.videoPreviewName}>
            {firstName} {lastName}
          </div>
          <IconButton onClick={() => void setPreviewOpen(!previewOpen)}>
            {previewOpen ? <ExpandLessIcon /> : <ExpandMoreIcon />}
          </IconButton>
        </div>
        {!hidden && <video data-testid="video-tile" ref={videoElement} autoPlay />}
      </div>
    </Draggable>
  );
}

VideoPreviewBox.propTypes = {
  hidden: PropTypes.bool,
};
