import { makeStyles, emphasize } from '@material-ui/core/styles';
import { Typography } from '@material-ui/core';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import PropTypes from 'prop-types';

const useStyles = makeStyles((theme) => ({
  option: {
    '&:not(:last-child)': {
      marginBottom: '3rem',
    },
  },
  yourSelection: {
    display: 'flex',
    alignItems: 'center',

    '& > *:first-child': {
      color: theme.palette.primary.main,
    },

    '& > *:last-child': {
      fontWeight: 'bold',
      fontSize: '0.9rem',
      marginLeft: '0.5ch',
    },
  },
  optionResults: {
    display: 'flex',
    alignItems: 'center',

    // Count/percent
    '& > *:first-child': {
      minWidth: '4rem',
      // textAlign: 'right',
    },

    // Check mark for correct answer
    '& > *:nth-child(2)': {
      opacity: 0,
      color: theme.palette.success.main,
    },

    // Check mark visible
    '&[data-mark-as-correct="1"] > *:nth-child(2)': {
      opacity: 1,
    },

    // Bar wrapper
    '& > *:nth-child(3)': {
      width: '100%',
      height: '1.5rem',
      position: 'relative',
      flexGrow: 1,

      // Bar
      '& > div': {
        position: 'absolute',
        left: 0,
        top: 0,
        height: '100%',
        transition: 'width 0.5s ease',
        borderRadius: '0.75rem',
        backgroundColor: emphasize(theme.palette.background.paper, 0.5),
      },
    },

    // My answer bar
    '&[data-mark-as-mine="1"] > *:nth-child(3) > div': {
      backgroundColor: theme.palette.primary.main,
    },

    '&[data-mark-as-correct="1"]': {
      // Correct votes
      '& > *:first-child': {
        fontWeight: 'bold',
      },

      // Correct bar
      '& > *:nth-child(3) > div': {
        backgroundColor: theme.palette.success.main,
      },
    },

    // Check mark visible
    '&[data-mark-as-correct="1"] > *:last-child(2)': {
      display: 'inline-block',
    },
  },
}));

PollOptionResult.propTypes = {
  optionIdx: PropTypes.number.isRequired,
  option: PropTypes.string,
  showResults: PropTypes.bool,
  isCorrect: PropTypes.bool,
  answerCount: PropTypes.number.isRequired,
  totalAnswers: PropTypes.number.isRequired,
  isMyVote: PropTypes.bool,
  asNumeric: PropTypes.bool,
};

export default function PollOptionResult(props) {
  const {
    optionIdx,
    option,
    showResults,
    isCorrect,
    answerCount,
    totalAnswers,
    isMyVote,
    asNumeric,
  } = props;

  const classes = useStyles();

  const barWidth = Math.max(totalAnswers, 1) / 100;

  return (
    <div className={classes.option}>
      {isMyVote && (
        <div className={classes.yourSelection}>
          <CheckBoxIcon />
          <Typography>YOUR SELECTION</Typography>
        </div>
      )}

      <Typography variant="body1">
        <b>{optionIdx + 1}.</b> {option}
      </Typography>

      {showResults && (
        <div
          className={classes.optionResults}
          data-mark-as-mine={isMyVote ? 1 : 0}
          data-mark-as-correct={isCorrect ? 1 : 0}
        >
          {asNumeric ? (
            <div>({answerCount})</div>
          ) : (
            <div>({totalAnswers === 0 ? 0 : Math.round((answerCount / totalAnswers) * 100)}%)</div>
          )}
          <CheckCircleIcon />
          <div>
            <div style={{ width: `${Math.max(answerCount / barWidth, 5)}%` }}></div>
          </div>
        </div>
      )}
    </div>
  );
}
