import { Typography } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import { makeStyles } from '@material-ui/core/styles';
import ExitMeetingDialog from 'components/ExitMeetingDialog';
import { useState } from 'react';

export const useStyles = makeStyles((theme) => ({
  meetingControls: {
    backgroundColor: theme.palette.background.light,
    margin: theme.sidePanel.gutter,
    padding: '1rem',
    ...theme.mixins.borderRadius(),
    ...theme.mixins.shadow(),
    ...theme.mixins.flexVerticalList(),
    '& > div': {
      width: '100%',
      alignItems: 'center',
      ...theme.mixins.flexHorizontalList('0.33rem'),
      '& > *:last-child': {
        flexGrow: 1,
      },
    },
  },
  button: {
    color: 'red',
  },
}));

export default function MeetingControls() {
  const classes = useStyles();
  const [showEndMeetingDialog, setShowEndMeetingDialog] = useState(false);

  return (
    <div className={classes.meetingControls}>
      <Typography variant="h6">Meeting Controls</Typography>
      <Button onClick={() => setShowEndMeetingDialog((c) => !c)} className={classes.button}>
        End Meeting For All
      </Button>
      <ExitMeetingDialog
        open={showEndMeetingDialog}
        setOpen={setShowEndMeetingDialog}
        showEndMeeting={true}
      />
    </div>
  );
}
