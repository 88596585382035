import { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import { Button, Typography } from '@material-ui/core';
import { ToggleButton, ToggleButtonGroup } from '@material-ui/lab';
import PropTypes from 'prop-types';
import { startPoll, endPoll, setPollResultsShared } from 'api/routes/sessionPolls';
import {
  startPollAction,
  endPollAction,
  updatePollAction,
} from 'store/session-polling/sessionPollingActions';
import {
  pollIsEnded,
  pollIsRunning,
  pollGetTimeDisplay,
  pollGetTotalAnswers,
  useRunningPoll,
  PropTypePoll,
} from 'utils/polling';
import ButtonStrip from 'components/ButtonStrip';
import useInterval from 'hooks/useInterval';
import useCommonClasses from 'components/polling/PollCommon.style';
import PollOptionResult from 'components/polling/PollOptionResult';
import PollHeader from 'components/polling/PollHeader';
import { setFocusedPollIdAction } from 'store/session-polling/sessionPollingActions';
import PollInProgressModal from 'components/polling/PollInProgressModal';

const useStyles = makeStyles((theme) => ({
  timer: {
    textTransform: 'uppercase',
    fontSize: '0.9rem',
    padding: '0.5rem',

    '&[data-is-running="1"]': {
      backgroundColor: theme.palette.primary.main,
      color: 'white',
    },

    // Time header
    '& > *:first-child': {
      fontWeight: 'bold',
    },

    // Actual time in minutes
    '& > *:last-child': {},
  },
  startButton: {
    backgroundColor: theme.palette.danger.main,
  },
}));

PollPresenter.propTypes = {
  poll: PropTypePoll,
  expanded: PropTypes.bool,
  inSettings: PropTypes.bool,
};

export default function PollPresenter({ poll, expanded, inSettings }) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const commonClasses = useCommonClasses();
  const [resultDisplayType, setResultDisplayType] = useState('percent');

  const { pollId } = poll;

  const [confirmEndPoll, setConfirmEndPoll] = useState(false);

  const focused = useSelector((state) => state.sessionPolling.focusedPollId === pollId);
  if (focused) expanded = true;

  const runningPoll = useRunningPoll();

  const [forceUpdateTick, setForceUpdateTick] = useState(0);
  useInterval(() => setForceUpdateTick(forceUpdateTick + 1), 1000);

  const totalAnswers = pollGetTotalAnswers(poll);
  const options = Object.keys(poll.answerOptions).sort((a, b) =>
    poll.answerOptions[a].order > poll.answerOptions[b].order ? 1 : -1
  );

  const isEnded = pollIsEnded(poll);
  const isRunning = pollIsRunning(poll);
  const runningPollDisplayName = runningPoll?.name || null;
  const runningPollDisplayQuestion = runningPoll?.questionText;

  const onClickStartPoll = async (event, skipConfirm) => {
    if (runningPoll && !skipConfirm) {
      setConfirmEndPoll(true);
    } else {
      const response = await startPoll(pollId);
      if (response?.success) {
        dispatch(startPollAction(response));
      }
    }
  };

  const onClickEndPoll = async () => {
    const response = await endPoll(pollId);
    if (response?.success) {
      dispatch(endPollAction(response));
    }
  };

  const onClickShareResults = async () => {
    const response = await setPollResultsShared(pollId, true);
    if (response?.success) {
      dispatch(updatePollAction({ pollId, data: { resultsShared: true } }));
    }
  };

  const onClickDetails = () => {
    dispatch(setFocusedPollIdAction({ focusedPollId: pollId }));
  };

  const onConfirmStart = async (event) => {
    setConfirmEndPoll(false);
    await onClickStartPoll(event, true);
  };

  const onCancelStart = () => void setConfirmEndPoll(false);

  const onChangeResultDisplayType = (event, value) =>
    void setResultDisplayType(value || resultDisplayType);

  return (
    <div className={commonClasses.poll}>
      <PollInProgressModal
        isOpen={confirmEndPoll}
        onClose={onCancelStart}
        onStart={onConfirmStart}
        pollName={runningPollDisplayName}
        pollQuestion={runningPollDisplayQuestion}
      />

      <PollHeader poll={poll} canManage={true} />

      {expanded ? (
        <>
          <ButtonStrip>
            {poll.duration && (
              <div className={classes.timer} data-is-running={isRunning ? 1 : 0}>
                <div>{isRunning || isEnded ? 'TIME REMAINING' : 'TIMED POLL'}</div>
                <div>{pollGetTimeDisplay(poll)} minutes</div>
              </div>
            )}

            {isRunning && !inSettings && (
              <Button onClick={onClickEndPoll} variant="text" color="secondary">
                Stop
              </Button>
            )}
            {isEnded && (
              <Button variant="text" disabled>
                Poll Complete
              </Button>
            )}
          </ButtonStrip>

          <div className={commonClasses.optionsHeader}>
            <div>{isEnded || isRunning ? 'Results' : 'Options'}</div>

            <ToggleButtonGroup
              exclusive
              className={commonClasses.switcher}
              size="small"
              value={resultDisplayType}
              onChange={onChangeResultDisplayType}
            >
              <ToggleButton value="numeric">#</ToggleButton>
              <ToggleButton value="percent">%</ToggleButton>
            </ToggleButtonGroup>
          </div>

          <div className={commonClasses.options}>
            {options.map((option, i) => (
              <PollOptionResult
                key={i}
                optionIdx={i}
                option={option}
                showResults={isRunning || isEnded}
                isCorrect={poll.correctAnswer === option}
                answerCount={poll.answerOptions[option].count}
                totalAnswers={totalAnswers}
                asNumeric={resultDisplayType === 'numeric'}
              />
            ))}
          </div>

          {(isEnded || isRunning) && (
            <div className={commonClasses.shareResultsArea}>
              <Button
                onClick={onClickShareResults}
                disabled={poll.resultsShared}
                variant="outlined"
              >
                {poll.resultsShared ? 'Results Shared' : 'Share Results'}
              </Button>

              <Typography variant="body2">
                {poll.resultsShared
                  ? 'Results now available for all participants.'
                  : 'Sharing results will make them available to all presenters and attendees.'}
              </Typography>
            </div>
          )}
        </>
      ) : (
        <ButtonStrip extraStyles={isRunning && !isEnded ? commonClasses.live : ''}>
          <Button onClick={onClickDetails} variant="text" color="secondary">
            {isEnded ? 'Results' : 'Live Results'}
          </Button>

          {!isRunning && !inSettings && (
            <Button onClick={onClickStartPoll} variant="text" color="secondary">
              {isEnded ? 'Resume' : 'Start'}
            </Button>
          )}
        </ButtonStrip>
      )}
    </div>
  );
}
