import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import Switch from '@material-ui/core/Switch';
import { Typography } from '@material-ui/core';
import { muteAllUsers } from 'api/routes/session';
import { useDispatch, useSelector } from 'react-redux';
import { showGenericErrorNotification } from 'utils/utilMethods';
import * as sa from 'store/session/sessionActions';
import { useLatestPendingRequest } from 'hooks/usePendingRequests';

export const useStyles = makeStyles((theme) => ({
  masterMuteSwitcher: {
    backgroundColor: theme.palette.background.light,
    margin: theme.sidePanel.gutter,
    padding: '1rem',
    ...theme.mixins.borderRadius(),
    ...theme.mixins.shadow(),
    '& > *': {
      width: '100%',
      '& .MuiSelect-selectMenu': {
        fontSize: '90%',
      },
    },
    '& > *:not(:last-child)': {
      marginBottom: '2rem',
    },
    '& .MuiFormControl-root': {
      paddingTop: '1.5rem',
    },
    '& .MuiSwitch-root': {
      transform: 'rotate(90deg)',
    },
    '& .MuiTypography-root': {
      fontSize: '0.875rem',
    },
  },
  muteRow: {
    ...theme.mixins.flexHorizontalList('0.33rem'),
  },
}));

export default function DeviceSwitcher() {
  const classes = useStyles();
  const dispatch = useDispatch();
  const mutedAllStore = useSelector((store) => store.session.mutedAll);
  const mutedAllPending = useLatestPendingRequest('masterMute');
  const mutedAll = mutedAllPending ? mutedAllPending.data.isMuted : mutedAllStore;

  const toggleMute = () => {
    const newMutedAll = !mutedAll;

    muteAllUsers(newMutedAll, {
      retries: 2,
      onSuccess() {
        dispatch(sa.setAllMutedAction({ isMuted: newMutedAll }));
      },
      onError(error, reason, retriesLeft) {
        if (retriesLeft === 0) {
          showGenericErrorNotification('setting master mute');
        }
      },
    });
  };

  return (
    <div className={classes.masterMuteSwitcher}>
      <FormControl>
        <InputLabel shrink={true} id="master-mute-label">
          Quiet Mode
        </InputLabel>
        <div className={classes.muteRow}>
          <Switch
            disabled={!!mutedAllPending}
            checked={mutedAll}
            onChange={toggleMute}
            color="primary"
          />
          <div>
            <Typography color={!mutedAll ? 'primary' : 'textPrimary'}>Off</Typography>
            <Typography color={mutedAll ? 'primary' : 'textPrimary'}>On</Typography>
          </div>
          <Typography style={{ paddingTop: '0.0rem' }}>
            Mutes all attendees. Only presenters can be heard.
          </Typography>
        </div>
      </FormControl>
    </div>
  );
}
