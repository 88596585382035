import { Typography } from '@material-ui/core';
import CircularProgress from '@material-ui/core/CircularProgress';
import { makeStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import { Autocomplete } from '@material-ui/lab';
import { getAccounts } from 'api/routes/account';
import { getAccountEvents } from 'api/routes/event';
import { managementAnalaytics } from 'api/routes/reporting';
import get from 'lodash/get';
import { useEffect, useState } from 'react';
import deepEqual from 'react-fast-compare';
import { useDispatch, useSelector } from 'react-redux';
import Base from 'routes/management/components/Base';
import {
  addAccountEventNamesAction,
  initializeReportingAccountsAction,
  setReportingAccountDataAction,
} from 'store/management/managementActions';
import { columns, options } from './tableOptions';
const useStyles = makeStyles((theme) => ({
  select: {
    marginTop: '3rem',
    margin: `1rem ${theme.sidePanel.gutter}`,
  },
  process: {
    display: 'flex',
    flexDirection: 'row',
    flexGrow: 1,
    justifyContent: 'center',
    marginTop: '3rem',
    margin: `1rem ${theme.sidePanel.gutter}`,
  },
}));
UserReporting.propTypes = {};
export default function UserReporting() {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const accounts = useSelector((store) => store.management.reportingAccounts, deepEqual);

  const [selectedEvents, setSelectedEvents] = useState([]);
  const [selectedAccount, setSelectedAccount] = useState(null);

  useEffect(() => {
    getAccounts().then((data) => {
      if (data && data.accounts) {
        dispatch(initializeReportingAccountsAction({ accounts: data.accounts }));
      }
    });
  }, []);

  const handleChangeAccount = async (e, value) => {
    console.log('handleChange', value);
    setSelectedAccount(value);
    if (!get(accounts, `${value.accountId}.hasQueriedEventNames`, false)) {
      const { events } = await getAccountEvents(value.accountId);
      dispatch(addAccountEventNamesAction({ events, accountId: value.accountId }));
    }
  };
  const handleEventChange = async (e, value) => {
    console.log('EVENT CHANGE', { e, value });
    setSelectedEvents(value);
    const currentQueried = get(accounts, `${selectedAccountId}.queriedEvents`, []);
    const eventIdsToQuery = value
      .map(({ eventId }) => eventId)
      .filter((eventId) => !currentQueried.includes(eventId));
    if (eventIdsToQuery.length) {
      setLoading(true);
      const { accountId, data } = await managementAnalaytics(selectedAccountId, eventIdsToQuery);
      dispatch(
        setReportingAccountDataAction({
          eventIds: eventIdsToQuery,
          accountId,
          data,
        })
      );
      setLoading(false);
    }
  };

  const selectedAccountId = get(selectedAccount, 'accountId', '');
  const selectedEventIds = selectedEvents.map(({ eventId }) => eventId);
  const tableData = get(accounts, `${selectedAccountId}.data`, []).filter(({ eventId }) =>
    selectedEventIds.includes(eventId)
  );

  const accountOptions = Object.values(accounts).map(({ accountId, accountName }) => ({
    accountId,
    accountName,
  }));
  return (
    <>
      {!loading ? (
        <>
          <Autocomplete
            className={classes.select}
            defaultValue={'Select Account'}
            disableClearable
            options={accountOptions}
            getOptionLabel={(option) => `${option.accountName}`}
            getOptionSelected={(option, value) => option.accountId === value.accountId}
            value={selectedAccount}
            renderInput={(params) => (
              <TextField {...params} color="primary" label="Select Account" variant="outlined" />
            )}
            onChange={handleChangeAccount}
          />

          <Autocomplete
            multiple
            className={classes.select}
            defaultValue={'Select Event(s)'}
            options={get(accounts, `${selectedAccountId}.eventNames`, [])}
            getOptionLabel={(option) => `${option.title}   -   ${option.eventId.slice(0, 6)}...`}
            getOptionSelected={(option, value) => value.eventId === option.eventId}
            value={selectedEvents}
            renderInput={(params) => (
              <TextField {...params} color="primary" label="Select Account" variant="outlined" />
            )}
            onChange={handleEventChange}
          />
          <Base name="Reporting" columns={columns} data={tableData} options={options} />
        </>
      ) : (
        <div className={classes.process}>
          <CircularProgress />
          <Typography variant="h3">Processing...</Typography>
        </div>
      )}
    </>
  );
}
