import { styled } from '@material-ui/styles';
import { Button } from '@material-ui/core';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';

export const PrimaryButton = styled(Button)(({ theme }) => ({
  backgroundColor: theme.palette.primary.main,
  borderRadius: '5px',
  color: 'white',
  '&:hover': {
    backgroundColor: theme.palette.primary.light,
  },
  '&:disabled': {
    backgroundColor: theme.palette.background.light,
    color: theme.palette.text.disabled,
  },
}));

export const WhiteOutlinedButton = styled(Button)(({ theme }) => ({
  backgroundColor: theme.palette.background.dark,
  fontWeight: '400',
  '&:hover': {
    backgroundColor: theme.palette.background.light,
    border: '1px solid black',
  },
}));

export const LinkButton = styled(Button)({
  backgroundColor: 'inherit',
  '&:hover': {
    backgroundColor: 'inherit',
    boxShadow: 'none',
    textDecoration: 'underline',
  },
  '&:active': {
    boxShadow: 'none',
    backgroundColor: 'inherit',
  },
  '&:focus': {
    boxShadow: 'none',
  },
});

export const StyledTabs = styled(Tabs)(({ theme }) => ({
  // backgroundColor: '#E6E6E6',
  backgroundColor: theme.palette.background.main,
  borderRadius: '5px',
  padding: 0,
  '& .MuiTabs-indicator': {
    display: 'none',
  },
}));

export const StyledTab = styled(Tab)(({ theme }) => ({
  textTransform: 'none',
  color: theme.palette.text.secondary,
  fontSize: '1rem',
  padding: '0',
  margin: '.5rem',
  borderRadius: '5px',
  // backgroundColor: '#E6E6E6',
  backgroundColor: theme.palette.background.main,
  '&.Mui-selected': {
    color: '#fff',
    backgroundColor: theme.palette.primary.main,
  },
}));
