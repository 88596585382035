import AppWrapper from 'components/AppWrapper';
import WebSocketInitialization from 'components/initialization/WebSocketInitialization';
import LoadingPage from 'components/LoadingPage';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { initializeMeetingRouteAction } from 'store/user/userActions';
export default function ServicesOnlyUser() {
  const dispatch = useDispatch();
  const initLoading = useSelector(
    (store) => store.user.actionStatus.initializeMeetingRouteFlowSaga?.loading
  );

  useEffect(() => {
    dispatch(
      initializeMeetingRouteAction({
        servicesOnly: true,
      })
    );
  }, []); // eslint-disable-line
  return initLoading ? (
    <LoadingPage>Loading app...</LoadingPage>
  ) : (
    <AppWrapper>
      <WebSocketInitialization />
    </AppWrapper>
  );
}
