import { Typography } from '@material-ui/core';
import Box from '@material-ui/core/Box';
import IconButton from '@material-ui/core/IconButton';
import { makeStyles } from '@material-ui/core/styles';
import RefreshIcon from '@material-ui/icons/Refresh';
import MUIDataTable from 'mui-datatables';
import PropTypes from 'prop-types';
const useStyles = makeStyles(() => ({
  root: {
    // backgroundColor: 'white'
    dispay: 'flex',
    flexDirection: 'column',
    margin: '2rem',
  },
  header: {
    flexGrow: 1,
    display: 'flex',
    justifyContent: 'flex-start',
  },
  actions: {
    flexGrow: 1,
    display: 'flex',
    justifyContent: 'flex-end',
    padding: '1rem 0 1rem 0',
  },
  refreshButton: {
    marginLeft: '2rem',
  },
}));

Base.propTypes = {
  name: PropTypes.string.isRequired,
  Actions: PropTypes.node,
  columns: PropTypes.array.isRequired,
  data: PropTypes.array.isRequired,
  options: PropTypes.object,
  refresh: PropTypes.func.isRequired,
};

Base.defaultProps = {
  columns: [],
  options: {},
  data: [],
  Actions: () => <></>,
};

export default function Base(props) {
  const { name, Actions, columns, data, options, refresh } = props;
  const classes = useStyles();
  const defaultOptions = {
    draggableColumns: {
      enabled: true,
      transitionTime: 300,
    },
    pagination: true,
    print: false,
    download: false,
    filterType: 'multiselect',
    ...options,
  };
  return (
    <div className={classes.root}>
      <Box className={classes.header}>
        <Typography variant="h3">{name}</Typography>
      </Box>
      <Box display="flex" flexDirection="row" justifyContent="space-between">
        <div className={classes.actions}>
          <Actions />
          <IconButton color="secondary" className={classes.refreshButton} onClick={refresh}>
            <RefreshIcon />
          </IconButton>
        </div>
      </Box>
      <div style={{ minHeight: 400 }}>
        <MUIDataTable columns={columns} data={data} options={defaultOptions} />
      </div>
    </div>
  );
}
