import { createReducer } from '@reduxjs/toolkit';
import { buildActionStatus, standardActions } from 'store/utils';
import * as ma from './webSocketActions';

const initialState = {
  socket: null,
  actionStatus: buildActionStatus(ma),
  hasInitialized: false,
};

const sessionMessageReducer = createReducer(initialState, {
  ...standardActions(ma),
  [ma.resetStateAction]: () => ({
    ...initialState,
  }),
  [ma.setSocketAction]: (state, { payload: { socket } }) => {
    state.socket = socket;
  },
  [ma.initializeWebSocketAction]: (state) => {
    state.hasInitialized = true;
  },
});

export default sessionMessageReducer;
