import Box from '@material-ui/core/Box';
import makeStyles from '@material-ui/styles/makeStyles';
import SideMenu from 'components/side-menu';
import SidePanelContent from 'components/side-panel';
import PropTypes from 'prop-types';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    width: '100vw',
    overflow: 'hidden',
    justifyContent: 'flex-end',
    ...theme.mixins.vhHeight(100),
  },
  showCaptions: {
    color: theme.palette.primary.main,
  },
  videoSection: {
    flexGrow: 1,
    height: '100%',
    overflow: 'hidden',
    position: 'relative',
  },
}));

const AppWrapper = ({ children }) => {
  const classes = useStyles();
  return (
    <Box className={classes.root}>
      <div className={classes.videoSection}>{children}</div>
      <SidePanelContent />
      <SideMenu />
    </Box>
  );
};

AppWrapper.propTypes = {
  children: PropTypes.node,
};
export default AppWrapper;
