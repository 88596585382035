import { basePost, promisedPost } from 'api/apiDetour';

const baseEndpoint = 'event';

const post = async ({ endpoint, data = {}, isSigned = false }) => {
  let resp = await basePost(endpoint, data, baseEndpoint, isSigned);
  return resp;
};

export const createEvent = async (title, description) => {
  let resp = await post({ endpoint: 'create', data: { title, description } });
  return resp;
};

export const createBetaEvent = async (title, description) => {
  let resp = await post({ endpoint: 'createbeta', data: { title, description } });
  return resp;
};

export const getEvent = async () => {
  let resp = await post({ endpoint: 'get' });
  return resp;
};

export const getEventById = async (eventId) => {
  let resp = await post({ endpoint: 'get-by-id', data: { eventId } });
  return resp;
};

export const getBetaEvent = async (eventId) => {
  let resp = await post({ endpoint: 'getbeta', data: { eventId } });
  return resp;
};

export const updateEvent = async (update, remove) => {
  let resp = await post({ endpoint: 'update', data: { update, remove } });
  return resp;
};

export const deleteEvent = async () => {
  let resp = await post({ endpoint: 'delete' });
  return resp;
};

export const disableFeature = (feature, disabled, options) => {
  options = options ?? {};
  options.data = { feature, disabled };
  return promisedPost('disable-feature', baseEndpoint, 'disabledFeatures', options);
};

export const addVideo = async (video) => {
  let resp = await post({ endpoint: 'add-video', data: { video } });
  return resp;
};

export const removeVideo = async (video) => {
  let resp = await post({ endpoint: 'delete-video', data: { video } });
  return resp;
};

export const getFileInfo = async (key, bucket) => {
  let resp = await post({ endpoint: 'file-info', data: { key, bucket } });
  return resp;
};

export const getEvents = async (getActiveSessions = false) => {
  const resp = await post({ endpoint: 'list', data: { getActiveSessions } });
  return resp;
};

export const updateVideoName = async (name, key) => {
  const resp = await post({ endpoint: 'update-video-name', data: { name, key } });
  return resp;
};

export const sendMeetingEvents = async (meetingEvents) => {
  const resp = await post({ endpoint: 'collect-meeting-events', data: { meetingEvents } });
  return resp;
};

export const deleteEvents = async (events) => {
  const resp = await post({ endpoint: 'delete-events', data: { events } });
  return resp;
};

export const getAccountEvents = async (accountId) => {
  const resp = await post({ endpoint: 'get-account-events', data: { accountId } });
  return resp;
};
