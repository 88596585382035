import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';

const styles = (theme) => ({
  notification: {
    position: 'sticky',
    width: '100%',
    bottom: 0,
    left: 0,
    right: 0,
    padding: '0.5rem',
    backgroundColor: theme.palette.background.dark,
    color: theme.palette.secondary.main,
    borderTop: '1px solid',
    borderTopColor: theme.palette.primary.main,
    zIndex: 1000,
    cursor: 'pointer',
    textAlign: 'center',
    fontWeight: 'bold',
  },
});

const NewQuestionsNotification = ({ classes, onClick }) => (
  <div className={classes.notification} onClick={onClick} id="new-questions-refresh">
    There are new questions and answers.
    <br />
    Click here to update.
  </div>
);

NewQuestionsNotification.propTypes = {
  classes: PropTypes.object.isRequired,
  onClick: PropTypes.func,
};

export default withStyles(styles)(NewQuestionsNotification);
