// import { makeStyles } from '@material-ui/core/styles';
import LoadingPage from 'components/LoadingPage';
import routes from 'constants/routes';
import has from 'lodash/has';
import { useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Redirect, useLocation } from 'react-router-dom';
import { initializeTokenAction } from 'store/user/userActions';
import ChooseServices from './ChooseServices';

const loggedInCases = {
  broadcast: { path: routes.BROADCAST.path, params: '' },
  broadcastNoIVS: { path: routes.BROADCAST.path, params: '/true' },
  meeting: { path: routes.MEETING.path, params: '' },
  recording: { path: routes.RECORDING.path, params: '/false' },
  streaming: { path: routes.RECORDING.path, params: '/true' },
  settings: { path: routes.SETTINGS.path, params: '/broadcast' }, // remove
  settingsBroadcast: { path: routes.SETTINGS.path, params: '/broadcast' },
  settingsMeeting: { path: routes.SETTINGS.path, params: '/meeting' },
  settingsVod: { path: routes.SETTINGS.path, params: '/vod' },
  settingsPolls: { path: routes.SETTINGS.path, params: '/polls' },
  vod: { path: routes.VOD.path, params: '' },
};

export default function SSO() {
  const location = useLocation();
  const dispatch = useDispatch();
  const queryRef = new URLSearchParams(location.search);
  const query = useRef(queryRef);
  const externalToken = query.current.get('token');
  const accountId = query.current.get('accountid') || query.current.get('accountId');
  const isLoggedIn = useSelector((store) => store.user.isLoggedIn);
  const type = useSelector((store) => store.event.type);
  const choose = useSelector((store) => store.app.choose);
  const didUserChoose = useSelector((store) => store.app.didUserChoose);
  const servicesOnly = useSelector((store) => store.app.servicesOnly);

  const error = useSelector((store) => store.actionStatus?.initializeTokenFlowSaga?.error);

  useEffect(() => {
    localStorage.clear();
    dispatch(initializeTokenAction({ externalToken, accountId }));
    // eslint-disable-next-line
  }, []);

  if (isLoggedIn) {
    console.log({ choose, didUserChoose, servicesOnly });
    if (choose && !didUserChoose) return <ChooseServices />;
    let redirect;
    if (choose && didUserChoose && servicesOnly) {
      redirect = routes.SERVICES;
    } else if (!choose && servicesOnly) {
      redirect = routes.SERVICES;
    } else {
      redirect = has(loggedInCases, type) ? loggedInCases[type] : routes.MEETING;
    }
    const { path, params } = redirect;
    return <Redirect to={`${path}${params}`} />;
  } else if (isLoggedIn === null) {
    return (
      <div>
        <LoadingPage />
      </div>
    );
  } else {
    if (error) {
      console.log('Login Error:', error);
    }
    return (
      <Redirect
        to={{
          pathname: `${routes.ERROR.path}/failedLogin`,
        }}
      />
    );
  }
}
