import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import PropTypes from 'prop-types';
import React from 'react';

NotificationDialog.propTypes = {
  state: PropTypes.shape({
    open: PropTypes.bool.isRequired,
    msg: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
  }),
  setState: PropTypes.func.isRequired,
};

export default function NotificationDialog(props) {
  const handleClose = () => {
    props.setState({ ...props.state, open: false });
  };
  return (
    <Box>
      <Dialog
        open={props.state.open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle>{props.state.title}</DialogTitle>
        <DialogContent>
          <DialogContentText>{props.state.msg}</DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary" id="dialog-ok" autoFocus>
            Ok
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
}
