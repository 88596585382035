import { createAction as ca } from '@reduxjs/toolkit';

export const initAction = ca('sessionQuestion/initAction');
export const successAction = ca('sessionQuestion/successAction');
export const errorAction = ca('sessionQuestion/errorAction');
export const resetStateAction = ca('sessionQuestion/resetStateAction');

export const setAllQuestionsAction = ca('sessionQuestion/setAllQuestionsAction');
export const updateQuestionAction = ca('sessionQuestion/updateQuestionAction');
export const putQuestionAction = ca('sessionQuestion/putQuestionAction');
export const deleteQuestionAction = ca('sessionQuestion/deleteQuestionAction');
export const askQuestionAction = ca('sessionQuestion/askQuestionAction');
export const setQuestionAnswerAction = ca('sessionQuestion/setQuestionAnswerAction');
export const setQuestionHiddenAction = ca('sessionQuestion/setQuestionHiddenAction');
export const setUnreadQuestionsAction = ca('sessionQuestion/setUnreadQuestionsAction');
export const addUnreadQuestionAction = ca('sessionQuestion/addUnreadQuestionAction');
export const removeUnreadQuestionAction = ca('sessionQuestion/removeUnreadQuestionAction');
