import {
  createGroupMessage,
  createPresentersMessage,
  createPrivateMessage,
  createQueueMessage,
} from 'api/routes/sessionMessage';
import InputContainer from 'components/InputContainer';
import ScrollContainer from 'components/ScrollContainer';
import RecipientSelect from 'components/side-panel/components/chat/RecipientSelect';
import { get } from 'lodash';
import PropTypes from 'prop-types';
import { useEffect } from 'react';
import deepEqual from 'react-fast-compare';
import { useDispatch, useSelector } from 'react-redux';
import {
  putMessageAction,
  setUnreadMessagesAction,
  updateMessageAction,
} from 'store/session-message/sessionMessageActions';
import { showGenericErrorNotification } from 'utils/utilMethods';
import ChatInput from './ChatInput';
import ChatMessage from './ChatMessage';

let requestNumber = 0;

Chat.propTypes = {
  presentersMessage: PropTypes.bool,
  queueMessage: PropTypes.bool,
};

export default function Chat({ presentersMessage, queueMessage }) {
  const dispatch = useDispatch();
  const userId = useSelector((store) => store.user.userId);
  const isBroadcast = useSelector((store) => store.app.appType === 'broadcast');
  const conversationId = useSelector((store) =>
    presentersMessage || queueMessage ? 'all' : store.sessionMessage.conversationId
  );
  const userName = useSelector((store) => `${store.user.firstName} ${store.user.lastName}`);
  const recipientId = useSelector((store) =>
    presentersMessage || queueMessage ? '' : get(store.chime.roster, `${conversationId}.userId`, '')
  );
  const recipientName = useSelector((store) =>
    presentersMessage || queueMessage ? '' : get(store.chime.roster, `${conversationId}.name`, '')
  );
  const messages = useSelector(
    (store) =>
      store.sessionMessage[
        queueMessage ? 'queueMessages' : presentersMessage ? 'presenterMessages' : 'messages'
      ],
    deepEqual
  );

  useEffect(() => {
    dispatch(setUnreadMessagesAction({ count: 0, presentersMessage, queueMessage }));
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const send = async (message) => {
    // Holding on to these by putting in new variables. Otherwise other requests will mess with them.

    const msgNum = requestNumber++;
    let messageId, timestamp, isGlobalMessage;

    if (typeof message === 'object') {
      // From a replay
      message.presentersMessage = presentersMessage;
      message.queueMessage = queueMessage;
      messageId = message.messageId;
      timestamp = message.timestamp;
      isGlobalMessage = !message.recipientId;
    } else {
      // A new message
      messageId = `pending#${msgNum}#${timestamp}`;
      timestamp = Date.now();
      isGlobalMessage = conversationId === 'all';

      message = {
        status: 'pending',
        message,
        messageId,
        senderId: userId,
        senderName: userName,
        presentersMessage,
        queueMessage,
      };

      if (!isGlobalMessage) {
        message.recipientId = recipientId;
        message.recipientName = recipientName;
      }

      dispatch(putMessageAction({ message, presentersMessage, queueMessage }));
    }

    const options = {
      retries: 2,
      onSuccess: (response) => {
        const updatedMessage = response.data?.message;
        console.log('In respones', updatedMessage);
        if (updatedMessage) {
          updatedMessage.status = 'sent';

          dispatch(
            updateMessageAction({
              messageId,
              message: updatedMessage,
              presentersMessage,
              queueMessage,
            })
          );
        }
      },
      onError: (error, reason, retriesLeft) => {
        if (retriesLeft <= 0) {
          dispatch(
            updateMessageAction({
              messageId,
              message: {
                status: 'error',
              },
              presentersMessage,
              queueMessage,
            })
          );

          showGenericErrorNotification('sending chat message');
        }
      },
    };
    if (presentersMessage) {
      createPresentersMessage(message.message, options);
    } else if (queueMessage) {
      createQueueMessage(message.message, options);
    } else if (isGlobalMessage) {
      createGroupMessage(message.message, options);
    } else {
      createPrivateMessage(message.message, message.recipientId, message.recipientName, options);
    }
  };

  const retry = async (message) => {
    dispatch(
      updateMessageAction({
        messageId: message.messageId,
        message: {
          status: 'pending',
        },
        presentersMessage,
        queueMessage,
      })
    );

    await send(message);
  };

  let displayMessages = messages;
  if (conversationId !== 'all') {
    displayMessages = displayMessages.filter(
      (msg) =>
        msg.recipientId && (msg.senderId === conversationId || msg.recipientId === conversationId)
    );
  }

  return (
    <>
      {!isBroadcast && !presentersMessage && <RecipientSelect />}

      <ScrollContainer scrollOnUpdate>
        {displayMessages.map((message) => (
          <ChatMessage
            key={message.messageId}
            isMe={userId === message.senderId}
            status={message.status || 'sent'}
            message={message}
            retry={retry}
          />
        ))}
      </ScrollContainer>

      <InputContainer>
        <ChatInput send={send} />
      </InputContainer>
    </>
  );
}
