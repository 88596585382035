export const MAX_COL = 48;
export const MAX_ROW = 36;
export const TOTAL_CELLS = MAX_COL * MAX_ROW;
export const CONTENT_SIZE_COLS = MAX_COL;
export const CONTENT_SIZE_ROWS = Math.round(MAX_ROW * 0.75);

// TODO: 10 users in grid could be a 4x3 instead of 4x4

// Number of users : how many cells to have in the axis. Use next number down if undefined
export const itemSpanRows = {
  [0]: 1,
  [2]: 1,
  [3]: 1 / 2,
  [5]: 1 / 2,
  [7]: 1 / 3,
  [10]: 1 / 4,
};
export const itemSpanColumns = {
  [0]: 1,
  [2]: 1 / 2,
  [3]: 1 / 2,
  [5]: 1 / 3,
  [7]: 1 / 3,
  [13]: 1 / 4,
};

// Number of users : how many cells to have in the axis when there's a selected speaker, INCLUDING the selected speaker
export const itemSpanRowsSelectSpeaker = {
  [0]: 1,
  [2]: 1 / 3,
  [7]: 1 / 4,
  [10]: 1 / 4,
};
export const itemSpanColumnsSelectSpeaker = {
  [0]: 1,
  [2]: 1 / 3,
  // [7]: 1 / 4,
  [10]: 1 / 4,
};

// Size of select speaker box at various user numbers, INCLUDING the selected speaker
export const selectSpeakerRows = {
  [0]: 1,
  [2]: 2 / 3,
  [7]: 3 / 4,
  [8]: 1 / 2,
  [10]: 1 / 2,
};
export const selectSpeakerCols = {
  [0]: 1,
  [2]: 2 / 3,
  [7]: 2 / 3,
  [10]: 1 / 2,
};

export function getBaseCellSize(numItems, hasSelectSpeaker, hasContent, isSelectSpeaker) {
  let spanRows, spanCols, r, c;
  if (hasContent) {
    numItems = 32;
    spanRows = itemSpanRows;
    spanCols = itemSpanColumns;
  } else if (isSelectSpeaker) {
    spanRows = selectSpeakerRows;
    spanCols = selectSpeakerCols;
  } else {
    spanRows = hasSelectSpeaker ? itemSpanRowsSelectSpeaker : itemSpanRows;
    spanCols = hasSelectSpeaker ? itemSpanColumnsSelectSpeaker : itemSpanColumns;
  }

  r = c = numItems;
  while (!spanRows[r]) {
    if (--r <= 0) break;
  }
  while (!spanCols[c]) {
    if (--c <= 0) break;
  }

  return [Math.round(spanRows[r] * MAX_ROW), Math.round(spanCols[c] * MAX_COL)];
}
