function quality(width, height, maxBandwidthKbps, frameRate, name) {
  name = name ?? `${width}x${height} @ ${frameRate} FPS`;
  frameRate = frameRate ?? 15;

  return {
    name,
    width,
    height,
    maxBandwidthKbps,
    frameRate,
  };
}

export default [
  quality(1280, 720, 2000, 15, '720'),
  quality(960, 540, 1400, 15, '540'),
  quality(854, 480, 1000, 15, '480'),
  quality(640, 360, 600, 15, '360'),
  quality(320, 180, 300, 15, 'Low Bandwidth'),
];
