import { makeStyles } from '@material-ui/core/styles';
import * as animationData from 'audio-wave.json';
import PropTypes from 'prop-types';
import Lottie from 'react-lottie';
import useShowVolumeIndicator from 'hooks/useShowVolumeIndicator';
import StarIcon from '@material-ui/icons/Star';
import RaiseHandIcon from '@material-ui/icons/PanTool';
import Avatar from '@material-ui/core/Avatar';
import { MicOffIcon, VideoOffIcon, MicOnIcon, VideoOnIcon } from 'svg';
import { useSelector, useDispatch } from 'react-redux';
import { stopUserVideo } from 'api/routes/session';
import { toggleRosterUserMutedAction, toggleVideoAction } from 'store/chime/chimeActions';
import useWindowDimensions from 'hooks/useWindowDimensions';

const useStyles = makeStyles((theme) => ({
  chip: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    position: 'absolute',
    bottom: '0.5rem',
    width: '100%',
    padding: '0.5rem 0rem',
    overflow: 'hidden',
    wordBreak: 'break-all',
    zIndex: '2',
    cursor: 'default',
    ...theme.mixins.borderRadius(),

    [theme.breakpoints.down('lg')]: {
      bottom: '0.25rem',
    },
    [theme.breakpoints.down('md')]: {
      padding: 0,
    },

    '&:hover': {
      opacity: 1,
    },
  },
  nameWrapper: {
    display: 'flex',
    alignItems: 'center',
    maxWidth: '60%',
    [theme.breakpoints.down(800)]: {
      maxWidth: '100%',
    },
  },
  nameTag: {
    display: 'flex',
    marginLeft: '1rem',
    alignItems: 'center',
    padding: '0.5rem 0.75rem',
    backgroundColor: theme.palette.icon.nameTag,
    borderRadius: '100px',
    color: 'white',
    width: '80%',
    [theme.breakpoints.down('md')]: {
      padding: '3px',
      marginLeft: '5px',
    },

    '&[data-local="1"]': {
      border: '2px solid',
      borderColor: 'white',
    },
  },
  volume: {
    marginLeft: '.5rem',
    opacity: 0,
    width: '20%',
    '&[data-volume-indicator="1"]': {
      opacity: 1,
    },
  },
  iconWrapper: {
    marginRight: '1rem',
    display: 'flex',
    [theme.breakpoints.down('md')]: {
      marginRight: '0',
    },
  },
  icon: {
    // color: 'white',
    margin: '0px 5px',
    '-webkit-filter': 'drop-shadow( 3px 3px 2px rgba(0, 0, 0, .7))',
    filter: 'drop-shadow( 3px 3px 2px rgba(0, 0, 0, .7))',
    opacity: 1,
    [theme.breakpoints.down('lg')]: {
      fontSize: '1.25rem',
    },
    [theme.breakpoints.down('sm')]: {
      fontSize: '1rem',
    },

    '& > .cls-1': {
      fill: theme.palette.icon.secondary.fill,
    },
    '& > .cls-2': {
      stroke: theme.palette.secondary.stroke,
    },
  },
  iconButton: {
    margin: '0px 5px',
    borderRadius: '50%',
    padding: '6px 3px 5px 3px',
    backgroundColor: theme.palette.icon.secondary.background,

    '&:hover': {
      opacity: 0.51,
    },
    '&[disabled]': {
      opacity: 0.5,
      color: theme.palette.error.main,
      cursor: 'not-allowed',
      pointerEvents: 'none',
      ...theme.mixins.grayscale(),
    },
  },
  starAvatar: {
    backgroundColor: 'white',
    height: theme.spacing(2),
    width: theme.spacing(2),
    color: 'gold',
    marginRight: '.5rem',
  },
  star: {
    fontSize: '1rem',
  },
  name: {
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
  },
}));

Chip.propTypes = {
  children: PropTypes.node,
  isLocal: PropTypes.bool,
  attendeeId: PropTypes.string,
  userId: PropTypes.string,
  isMuted: PropTypes.bool,
  name: PropTypes.string,
  handRaised: PropTypes.bool,
  isPresenter: PropTypes.bool,
  hasVideo: PropTypes.bool,
};

let defaultOptions = {
  loop: true,
  autoplay: true,
  animationData: animationData.default,
  rendererSettings: {
    preserveAspectRatio: 'none',
  },
};

export default function Chip({
  isLocal,
  isMuted,
  attendeeId,
  userId,
  name,
  hasVideo,
  handRaised,
  isPresenter,
}) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { width } = useWindowDimensions();
  const showVolumeIndicator = useShowVolumeIndicator({ attendeeId });
  const isBroadcast = useSelector((store) =>
    ['broadcast', 'streaming'].includes(store.app.appType)
  );
  const currentUserId = useSelector((store) => store.user.userId);
  const role = useSelector((store) => store.user.role);
  const isCurrentUser = userId === currentUserId;
  const mutedAll = useSelector((store) => store.session.mutedAll);
  const currentUsersAudioDevice = useSelector((store) => store.chime.currentAudioInputDevice);
  const canManage = role === 'presenter' || role === 'admin';
  const canToggleVideo = (canManage && !hasVideo) || isCurrentUser;
  let canToggleMute = (canManage && !isMuted) || (isCurrentUser && (canManage || !mutedAll));
  if (canToggleMute && isCurrentUser && !currentUsersAudioDevice) {
    canToggleMute = false;
  }

  const handleToggleVideo = async () => {
    if (isCurrentUser) {
      dispatch(toggleVideoAction());
    } else {
      await stopUserVideo(userId);
    }
  };

  const handleToggleMuteUser = async () => {
    dispatch(toggleRosterUserMutedAction({ userId }));
  };

  return (
    <div className={classes.chip}>
      <div className={classes.nameWrapper}>
        <div data-local={isLocal ? 1 : 0} className={classes.nameTag}>
          {isPresenter && !isBroadcast && (
            <Avatar className={classes.starAvatar}>
              <StarIcon className={classes.star} />
            </Avatar>
          )}
          <div className={classes.name}>{name}</div>
          {handRaised && <RaiseHandIcon style={{ marginLeft: '1rem', color: 'gold' }} />}
        </div>

        <div
          className={classes.volume}
          data-volume-indicator={!isMuted && showVolumeIndicator ? 1 : 0}
        >
          <Lottie options={defaultOptions} height={20} width={30} />
        </div>
      </div>

      {width > 800 && (
        <div className={classes.iconWrapper}>
          <div
            className={classes.iconButton}
            disabled={!canToggleVideo}
            onClick={handleToggleVideo}
          >
            {hasVideo ? (
              <VideoOffIcon className={classes.icon} />
            ) : (
              <VideoOnIcon className={classes.icon} />
            )}
          </div>
          <div
            className={classes.iconButton}
            disabled={!canToggleMute}
            onClick={handleToggleMuteUser}
          >
            {isMuted ? (
              <MicOffIcon className={classes.icon} />
            ) : (
              <MicOnIcon className={classes.icon} />
            )}
          </div>
        </div>
      )}
    </div>
  );
}
