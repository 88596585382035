import { createAction as ca } from '@reduxjs/toolkit';
export const addUserToGridAction = ca('session/addUserToGridAction');
export const askForHelpAction = ca('session/askForHelpAction');
export const blockUserAction = ca('session/blockUserAction');
export const errorAction = ca('session/errorAction');
export const handleUpdateStageAttendeeAction = ca('session/handleUpdateStageAttendeeAction');
export const initAction = ca('session/initAction');
export const inviteUserToStageAction = ca('session/inviteUserToStageAction');
export const notifyRaiseHandAction = ca('session/notifyRaiseHandAction');
export const putBlockedUserAction = ca('session/putBlockedUserAction');
export const updateStageAttendeeAction = ca('session/updateStageAttendeeAction');
export const updateStageAttendeesAction = ca('session/updateStageAttendeesAction');
export const updateStageAttendeeAttrAction = ca('session/updateStageAttendeeAttrAction');
export const receiveLoadTestRequestAction = ca('session/receiveLoadTestRequestAction');
export const removeAllUsersFromGridAction = ca('session/removeAllUsersFromGridAction');
export const removeBlockedUserAction = ca('session/removeBlockedUserAction');
export const removeUserFromGridAction = ca('session/removeUserFromGridAction');
export const removeStageAttendeeAction = ca('session/removeStageAttendeeAction');
export const removeAttendeeSpeaker = ca('session/removeAttendeeSpeaker');
export const resetStateAction = ca('session/resetStateAction');
export const sendLoadTestRequestAction = ca('session/sendLoadTestRequestAction');
export const setAllMutedAction = ca('session/setAllMutedAction');
export const setBlockedUsersAction = ca('session/setBlockedUsersAction');
export const sethasRequestedToJoinStageAction = ca('session/sethasRequestedToJoinStageAction');
export const setIsRecordingAction = ca('session/setIsRecordingAction');
export const setIsWebsocketConnectedAction = ca('session/setIsWebsocketConnectedAction');
export const setRaisedHandAction = ca('session/setRaisedHandAction');
export const setResourceStatusAction = ca('session/setResourceStatusAction');
export const setCurrentQueueSpeaker = ca('session/setCurrentQueueSpeaker');
export const setSessionAction = ca('session/setSessionAction');
export const setSpeakerAction = ca('session/setSpeakerAction');
export const setStageAttendeesAction = ca('session/setStageAttendeeAction');
export const setStreamStatusAction = ca('session/setStreamStatusAction');
export const setQueueQuestionTextAction = ca('session/setQueueQuestionTextAction');
export const setUsersInSessionAction = ca('session/setUsersInSessionAction');
export const setTestUrlAction = ca('session/setTestUrlAction');
export const startStreamAction = ca('session/startStreamAction');
export const stopStreamAction = ca('session/stopStreamAction');
export const successAction = ca('session/successAction');
export const toggleRecordingAction = ca('session/toggleRecordingAction');
export const updateLocalVideoStatusAction = ca('session/updateLocalVideoStatusAction');
export const eventBridgeMeetingEndedAction = ca('session/eventBridgeMeetingEndedAction');
