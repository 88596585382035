import config from 'config';
import get from 'lodash/get';
import { all, takeEvery } from 'redux-saga/effects';
import { serializeError } from 'serialize-error';
import appWatcher from 'store/app/appFlowSagas';
import chimeWatcher from 'store/chime/chimeFlowSagas';
import eventWatcher from 'store/event/eventFlowSagas';
import managementWatcher from 'store/management/managementFlowSagas';
import sessionMessageWatcher from 'store/session-message/sessionMessageFlowSagas';
import sessionPollingWatcher from 'store/session-polling/sessionPollingFlowSagas';
import sessionQuestionWatcher from 'store/session-question/sessionQuestionFlowSagas';
import sessionWatcher from 'store/session/sessionFlowSagas';
import userWatcher from 'store/user/userFlowSagas';
import utilWatcher from 'store/utils/utilFlowSagas';
import webSocketWatcher from 'store/web-socket/webSocketFlowSagas';

function* logActions(data) {
  if (!config.IS_PRODUCTION) {
    let methodName = get(data, 'payload.key', '');
    let [storeGroup, actionName] = get(data, 'type', '/').split('/'); //eslint-disable-line
    if (actionName === 'initAction') {
      console.log(`${storeGroup}/${methodName}: Started`);
    } else if (actionName === 'successAction') {
      console.log(`${storeGroup}/${methodName}: Successfull`);
    } else if (actionName === 'errorAction') {
      let error;
      try {
        error = serializeError(get(data, 'payload.error', {}));
      } catch (e) {
        error = {};
      }
      console.error(`${storeGroup}/${methodName}: Error`, error);
    } else {
      console.log(`${storeGroup}/${actionName}`, { payload: get(data, 'payload', {}) });
    }
  }
  yield;
}

export default function* rootSaga() {
  yield all([
    takeEvery('*', logActions),
    webSocketWatcher(),
    userWatcher(),
    appWatcher(),
    utilWatcher(),
    chimeWatcher(),
    sessionMessageWatcher(),
    sessionPollingWatcher(),
    sessionWatcher(),
    sessionQuestionWatcher(),
    eventWatcher(),
    managementWatcher(),
  ]);
}
