import { parse, stringify } from 'flatted';
import { get } from 'lodash';
import logger from 'logger';
import { call as reduxCall, put as reduxPut } from 'redux-saga/effects';
import { serializeError } from 'serialize-error';
import * as Sentry from '@sentry/browser';

const getMethodName = (data) => get(data, 'payload.key', '');

const getError = (data) => {
  try {
    return serializeError(get(data, 'payload.error', {}));
  } catch (e) {
    //
    return {};
  }
};

export const logData = (data) => {
  data = parse(stringify(get(data, '[0]', {})));
  let [storeGroup, actionName] = get(data, 'type', '/').split('/');
  if (actionName === 'initAction') {
    logger.debug(`${storeGroup}/${getMethodName(data)}: Started`);
  } else if (actionName === 'successAction') {
    logger.debug(`${storeGroup}/${getMethodName(data)}: Successfull`);
  } else if (actionName === 'errorAction') {
    logger.error(`${storeGroup}/${getMethodName(data)}: Error`, getError(data));
  } else {
    logger.debug(`${storeGroup}/${actionName}`, { payload: get(data, 'payload', {}) });
  }
};

export function put() {
  // logData(arguments);
  return reduxPut.apply(this, arguments);
}

export function call() {
  // logData(arguments);
  return reduxCall.apply(this, arguments);
}

export const actionManager = (state, key, values) => {
  if (values && values.error) {
    Sentry.captureException(values.error);
    logger.error('Saga Error', { error: values.error, key: key });
  }
  return {
    ...state,
    actionStatus: {
      ...state.actionStatus,
      [key]: { ...state.actionStatus[key], ...values },
    },
  };
};

export const buildActionStatus = (allActions) =>
  Object.keys(allActions)
    .filter((key) => !['initAction', 'successAction', 'errorAction'].includes(key))
    .reduce((actions, key) => ({ ...actions, [key]: { loading: false, error: '' } }), {});

export const standardActions = (actions) => ({
  [actions.initAction]: (state, { payload: { key } }) =>
    actionManager(state, key, { loading: true, error: '' }),
  [actions.successAction]: (state, { payload: { key } }) =>
    actionManager(state, key, { loading: false, error: '' }),
  [actions.errorAction]: (state, { payload: { key, error } }) =>
    actionManager(state, key, { loading: false, error }),
});
