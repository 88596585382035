import { Button, TextField, Typography } from '@material-ui/core';
import LinearProgress from '@material-ui/core/LinearProgress';
import Select from '@material-ui/core/Select';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import { createBetaEvent } from 'api/routes/event';
import useKeyPress from 'hooks/useKeyPress';
import PropTypes from 'prop-types';
import { useState } from 'react';
import { useHistory } from 'react-router-dom';
import routes from 'constants/routes';

const EVENT_NAME_MAX = 48;

CreateEvent.propTypes = {
  eventName: PropTypes.string.isRequired,
  setEventName: PropTypes.func.isRequired,
};

export default function CreateEvent({ eventName, setEventName }) {
  const history = useHistory();
  const [creating, setCreating] = useState(false);
  const [type, setType] = useState('meeting');

  const createEvent = async () => {
    setCreating(true);
    let createdEvent;
    try {
      const response = await createBetaEvent(eventName);
      createdEvent = response.event;
    } catch (e) {} // eslint-disable-line

    if (createdEvent) {
      history.push(`${routes.BETA.path}/${createdEvent.eventId}/${type}`);
    }
    setCreating(false);
  };

  const onChangeEventName = (event) => {
    let name = event.currentTarget.value;
    name = name.substr(0, EVENT_NAME_MAX);
    setEventName(name);
  };
  useKeyPress(() => {
    if (eventName) {
      createEvent();
    }
  }, 13);

  return (
    <>
      <Typography variant="h5" color="primary">
        Create a meeting
      </Typography>

      <TextField
        fullWidth
        autoFocus={true}
        label="Event name"
        value={eventName}
        placeholder="My Awesome Event"
        disabled={creating}
        onChange={onChangeEventName}
      />

      <FormControl>
        <InputLabel id="event-type-select-label">Type</InputLabel>
        <Select
          labelId="event-type-select-label"
          id="event-type-select"
          value={type}
          onChange={(e) => setType(e.target.value)}
        >
          <MenuItem value={'broadcast'}>Broadcast</MenuItem>
          <MenuItem value={'meeting'}>Meeting</MenuItem>
        </Select>
      </FormControl>

      <Button
        variant="contained"
        color="primary"
        onClick={createEvent}
        disabled={creating || !eventName}
      >
        {creating ? 'Creating' : 'Create'}
      </Button>
      <LinearProgress style={{ opacity: creating ? 1 : 0 }} />
    </>
  );
}
