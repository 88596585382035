import { getS3VideoUrl } from 'api/routes/sessionResources';
import ErrorPage from 'components/ErrorPage';
import GridView from 'components/GridView';
import AudioVideoInitialization from 'components/initialization/AudioVideoInitialization';
import WebSocketInitialization from 'components/initialization/WebSocketInitialization';
import MeetingStatus from 'enums/MeetingStatus';
import { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { initializeRecordingRouteAction } from 'store/user/userActions';
import StreamVideo from './components/StreamVideo';
import { useParams } from 'react-router';

export default function Recording() {
  const meetingStatus = useSelector((store) => store.chime.meetingStatus);
  const errorMessage = useSelector((store) => store.actionStatus?.joinMeeting?.error);
  const audioVideoHasInitialized = useSelector((store) => store.chime.hasInitialized);
  const videoKey = useSelector((store) => store.sessionResource.videoKey);
  const shouldLoop = useSelector((store) => store.sessionResource.shouldLoop);
  const showVideo = useSelector((store) => store.sessionResource.showVideo);
  const { isStream } = useParams();
  const audioElement = useRef(null);
  const dispatch = useDispatch();
  const [videoUrl, setVideoUrl] = useState('');

  const getUrl = async () => {
    const urlInfo = await getS3VideoUrl(videoKey);
    if (urlInfo?.url) setVideoUrl(urlInfo.url);
  };

  useEffect(() => {
    dispatch(
      initializeRecordingRouteAction({
        audioRef: audioElement.current,
      })
    );
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (videoKey) getUrl();
  }, [videoKey]);

  useEffect(() => {
    if (isStream === 'true') {
      audioElement.current.muted = showVideo;
    } else {
      audioElement.current.muted = false;
    }
  }, [showVideo, isStream]);

  return (
    <div>
      <audio ref={audioElement} style={{ display: 'none' }} />
      {/* {meetingStatus === MeetingStatus.Loading && <LoadingPage />} */}
      {meetingStatus === MeetingStatus.Failed && <ErrorPage>{errorMessage}</ErrorPage>}
      {meetingStatus === MeetingStatus.Succeeded && (
        <div style={{ height: '100vh', overflow: 'hidden' }}>
          <AudioVideoInitialization />
          <WebSocketInitialization />
          {audioVideoHasInitialized && isStream == 'true' ? (
            <>
              {showVideo ? (
                <StreamVideo loop={shouldLoop} videoUrl={videoUrl} />
              ) : (
                <GridView isRecordingUser={true} />
              )}
            </>
          ) : (
            <GridView isRecordingUser={true} />
          )}
        </div>
      )}
    </div>
  );
}
