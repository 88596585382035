import { Typography } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import { makeStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import { createAccount } from 'api/routes/account';
import PropTypes from 'prop-types';
import { useState } from 'react';
import Base from '../components/Base';
import AdminModal from '../components/Modal';
const useStyles = makeStyles(() => ({
  root: {
    // backgroundColor: 'white'
    margin: '2rem',
  },
  actionButton: {
    marginRight: '1rem',
    height: '25px',
  },
}));

Accounts.propTypes = {
  data: PropTypes.array.isRequired,
  refresh: PropTypes.func.isRequired,
};
export default function Accounts({ data, refresh }) {
  const classes = useStyles();
  const [open, setOpen] = useState(false);
  const [accountName, setAccountName] = useState('');

  const columns = [
    {
      name: 'accountName',
      label: 'Account Name',
      options: {
        filter: true,
        sort: true,
        searchable: true,
      },
    },
    {
      name: 'accountId',
      label: 'Account Id',
      options: {
        filter: true,
        sort: true,
        searchable: true,
      },
    },
    {
      name: 'tokenSecret',
      label: 'Secret',
      options: {
        filter: false,
        sort: false,
        searchable: false,
      },
    },
  ];

  const handleOpen = () => {
    setOpen(true);
  };

  const handleCreateAccount = async () => {
    await createAccount(accountName);
  };

  const options = {
    print: false,
    download: false,
    filterType: 'multiselect',
    onRowsDelete: async (value) => {
      //@TODO: WHen Deleting an Account There should be a confirm, also do we delete all the data in the account???
      await Promise.all(
        value.data.map(({ dataIndex }) => Promise.resolve(data[dataIndex].accountId))
      );
    },
    pagination: true,
  };

  return (
    <>
      <Base
        name="Accounts"
        refresh={refresh}
        Actions={() => (
          <Button
            className={classes.actionButton}
            type="button"
            variant="contained"
            onClick={handleOpen}
          >
            Create Account
          </Button>
        )}
        columns={columns}
        data={data}
        options={options}
      />
      <AdminModal
        ModalContent={() => (
          <>
            <Typography variant="h5" component="h2">
              Create Account
            </Typography>
            <TextField
              autoFocus
              onChange={({ target }) => {
                setAccountName(target.value);
                //setOpen(false);
              }}
              label="Account Name"
              value={accountName}
            />
          </>
        )}
        ModalActions={() => (
          <Button type="button" variant="contained" onClick={handleCreateAccount}>
            Create
          </Button>
        )}
        open={open}
        setOpen={setOpen}
      />
    </>
  );
}
