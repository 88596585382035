import { useSelector } from 'react-redux';

/*
State prediction works like this:
- State should use useLatestPendingRequest (request.data is the predicted state if the request succeeds) if it exists
  - Otherwise regular redux store (current known server value) is used to render current state.
- That state we decided on rendering from above should also be used for button clicks, input, etc.
- Since we basically ignore all but the newest pending request, we never have to worry about failures; it all happens automagically.

Example:

// Both hooks have to be on their own line, do not use tertiary or ||. Hooks need to always be ran in the same order each render.
// Current known authorative server value.
const stateMuted = useSelector((state) => state.roster[userId].muted);
// Newest predicted value is in pendingMuted.data if the pending request were to succeed.
const pendingMuted = useLatestPendingRequest('userMute', 'userId', userId);
// Value to use for rendering.
const muted = pendingMuted ? pendingMuted.data.mute : stateMuted;

const onClickMute = () => {
  // API call. Makes a promise, runs it, and then inserts it in to
  // state.pendingRequests.userMute with a timestamp and data: { userId, muted }.
  // When the request succeeds (or fails) it is removed from pendingRequests.
  // You can define retries and it will automatically try to resend failed requests.
  // You can attach your own success, error, and finalize functions in the promisedRequest options argument.
  // The entire options object and all functions are optional.
  const newMuted = !muted;
  setUserMuted(userId, newMuted, {
    retries: 2,
    onSuccess(response) {
      // If the response is a success, we can change the state to the authorative version from the response
      // OR if it's simple we can assume our request IS the authorative version.
      // Either way, the redux state needs to be changed in this case.
      dispatch(setUserMutedAction({userId, muted: newMuted});
    },
    onError(error, reason, retriesLeft) {
      // No need to do any state changes here, rollback is handled automatically.
      if (retriesLeft <= 0) {
        showGenericErrorNotification('muting that user');
      }
    },
    onFinalize(successResponse) {
      // This callback is called no matter what, after onSuccess/onError.
      // successResponse = response from onSuccess, otherwise null.
    }
  });
};

return <Switch value={muted} onClick={onClickMute} />;
*/

const empty = [];

export const requestIsEqual = (req1, req2) => {
  if (req1 === req2) return true;
  if (!req1 || !req2) return false;

  return req1.id === req2.id;
};

export const requestsAreEqual = (reqs1, reqs2) => {
  if (reqs1 === reqs2) return true;
  if (!reqs1 || !reqs2) return false;
  if (reqs1.length !== reqs2.length) return false;

  for (let i = 0; i < reqs1.length; ++i) {
    if (!requestIsEqual(reqs1[i], reqs2[i])) return false;
  }

  return true;
};

export const getPendingRequests = (state, requestGroup, filter, filterByValue) => {
  let requests = state.pendingRequests[requestGroup];
  if (!requests) return empty;

  if (requests.length > 0) {
    if (typeof filter === 'function') {
      requests = requests.filter(filter);
    } else if (filter) {
      requests = requests.filter((req) => req.data[filter] === filterByValue);
    }
  }

  return requests;
};

export const usePendingRequests = (requestGroup, filter, filterByValue) =>
  useSelector(
    (state) => getPendingRequests(state, requestGroup, filter, filterByValue),
    requestsAreEqual
  );

export const getLatestPendingRequest = (state, requestGroup, filter, filterByValue) => {
  const requests = getPendingRequests(state, requestGroup, filter, filterByValue);
  return requests[requests.length - 1];
};

export const useLatestPendingRequest = (requestGroup, filter, filterByValue) =>
  useSelector(
    (state) => getLatestPendingRequest(state, requestGroup, filter, filterByValue),
    requestIsEqual
  );

export const getOldestPendingRequest = (state, requestGroup, filter, filterByValue) => {
  const requests = getPendingRequests(state, requestGroup, filter, filterByValue);
  return requests[0];
};

export const useOldestPendingRequest = (requestGroup, filter, filterByValue) =>
  useSelector(
    (state) => getOldestPendingRequest(state, requestGroup, filter, filterByValue),
    requestIsEqual
  );

export default usePendingRequests;
