import { createMuiTheme, fade, emphasize } from '@material-ui/core/styles';
import { defaultsDeep } from 'lodash';
import {
  baseThemeSettings,
  basePalette,
  baseProps,
  typography,
  mixins,
  overrides,
} from 'themes/base';

/*The names of some of these colors are a bit misleading.
Since everything was originally made for a dark theme, light type themes have reversed 'light' and 'dark' palette definitions.
For example, the dark theme had background.dark being black, but background.main being gray.
However, the light theme has background.main being off-white but background.dark being pure white which is the opposite of what is expected.*/

export const palette = defaultsDeep(
  {
    type: 'light',
    logo: '/Poweredby-light.png',
    endLogo: '/byCombobulate-light.svg',
    shadow: {
      distanceScale: 0,
      opacity: 0.2,
      onlyImportant: true,
    },
    background2: {
      main: '#fff',
      border: '#ededed'
    },
    background: {
      main: emphasize('#fff', 0.08),
      light: emphasize('#fff', 0.16),
      dark: '#ffffff',
      border: '#ccc',
    },
    secondary: {
      main: '#222222',
      light: '#000000',
      dark: '#3a3a3a',
    },
    sidePanelGradient: {
      main: '#ffffff',
      light: '#ffffff',
      dark: '#ffffff',
    },
    text: {
      primary: '#222222',
      secondary: '#000000',
      disabled: '#3a3a3a',
      hint: fade('#000', 0.38),
      signifier: '#004E9D',
    },
    stage: {
      main: '#F4F4F4',
      dark: '#C7C7C7',
      light: '#FFFFFF',
    },
    input: {
      main: '#F7F7F7',
    },
    icon: {
      nameTag: 'rgba(0,0,0,.6)',
      devicesBackground: '#F2F2F2',
      primary: {
        fill: '#000000',
        stroke: '#888',
        background: '#FFFFFF',
        border: '#000000',
      },
      secondary: {
        fill: '#FFFFFF',
        stroke: '#707070',
        background: fade('#FFFFFF', 0.61),
      },
      tertiary: {
        fill: '#E5E5E5',
        stroke: '#E5E5E5',
        background: '#FFFFFF',
      },
    },
  },
  basePalette
);

palette.background.default = palette.background.main;
palette.background.modal = palette.background.dark;
palette.background.sideMenu = palette.background.dark;
palette.background.sidePanelTitle = palette.background.dark;
palette.background.paper = emphasize(palette.background.dark, 0.05);
palette.background.seeThrough = fade(palette.background.dark, 0.5);

export const props = defaultsDeep({}, baseProps);

export const themeSettings = {
  ...baseThemeSettings,
  palette,
  props,
  typography: typography(palette),
  overrides: overrides(palette),
  mixins: mixins(palette),
};

export default createMuiTheme(themeSettings);
