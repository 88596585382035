import { makeStyles } from '@material-ui/core/styles';
import Switch from '@material-ui/core/Switch';
import usePermissions from 'hooks/usePermissions';
import PropTypes from 'prop-types';

const useStyles = makeStyles(() => ({
  root: {
    display: 'flex',
    alignItems: 'center',
    flexGrow: 1,

    '& > div': {
      textAlign: 'right',
      flexGrow: 1,
    },
  },
}));

PermissionSwitch.propTypes = {
  name: PropTypes.string.isRequired,
  perm: PropTypes.string.isRequired,
};

export default function PermissionSwitch({ name, perm }) {
  const { getPermission, setPermission } = usePermissions();
  const classes = useStyles();

  const onChange = (event) => void setPermission('attendee', perm, event.target.checked);

  return (
    <div className={classes.root}>
      <div>{name}</div>
      <Switch checked={getPermission('attendee', perm)} onChange={onChange} color="primary" />
    </div>
  );
}
