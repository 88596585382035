import Button from '@material-ui/core/Button';
import Select from '@material-ui/core/Select';
import { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

TimeLogin.propTypes = {
  fakeLogin: PropTypes.func.isRequired,
};

export default function TimeLogin({ fakeLogin }) {
  const [willRun, setWillRun] = useState(false);
  const [runTime, setRunTime] = useState('');
  const [currentTime, setCurrentTime] = useState('');
  const [intervalId, setIntervalId] = useState(null);
  const [hours, setHours] = useState(0);
  const [minutes, setMinutes] = useState(0);
  const [seconds, setSeconds] = useState(0);

  useEffect(() => {
    const getTime = () => {
      let d = new Date();
      setCurrentTime(`${d.getHours()}:${d.getMinutes()}:${d.getSeconds()}`);
    };

    let date = new Date();
    setCurrentTime(`${date.getHours()}:${date.getMinutes()}:${date.getSeconds()}`);
    setHours(date.getHours());
    setMinutes(date.getMinutes());
    setSeconds(date.getSeconds());

    let id = setInterval(getTime, 1000);
    setIntervalId(id);

    return () => {
      if (intervalId !== null) {
        clearInterval(intervalId);
        setIntervalId(null);
      }
    };
  }, []);

  const loginAtTime = () => {
    const now = new Date();
    const wantedTime = new Date(
      now.getFullYear(),
      now.getMonth(),
      now.getDate(),
      hours,
      minutes,
      seconds,
      0
    );
    const milliSecondsToTime = wantedTime - now;
    setWillRun(true);
    setTimeout(() => {
      fakeLogin('presenter', 'meeting');
      console.log('This ran at ', new Date());
      setWillRun(false);
    }, milliSecondsToTime);
    setRunTime(`The Meeting will launch at ${wantedTime.toLocaleTimeString()}`);
  };

  return (
    <div>
      <p>Current Time: {currentTime}</p>
      <p>{runTime && runTime}</p>
      {!willRun && (
        <div>
          <Select value={hours} onChange={(e) => setHours(e.target.value)}>
            {[...Array(24).keys()].map((value) => (
              <option key={value} value={value}>
                {value}
              </option>
            ))}
          </Select>
          <Select value={minutes} onChange={(e) => setMinutes(e.target.value)}>
            {[...Array(59).keys()].map((value) => (
              <option key={value} value={value}>
                {value}
              </option>
            ))}
          </Select>
          <Select value={seconds} onChange={(e) => setSeconds(e.target.value)}>
            {[...Array(59).keys()].map((value) => (
              <option key={value} value={value}>
                {value}
              </option>
            ))}
          </Select>
          <Button style={{ marginLeft: '1rem' }} onClick={loginAtTime}>
            Login At Time
          </Button>
        </div>
      )}
    </div>
  );
}
