import { makeStyles } from '@material-ui/core/styles';
import LiveHelpIcon from '@material-ui/icons/LiveHelp';
import ChatIcon from '@material-ui/icons/Chat';
import VideocamIcon from '@material-ui/icons/Videocam';
import ScreenShareIcon from '@material-ui/icons/ScreenShare';
import PermissionSwitch from './PermissionSwitch';

const permissionGroups = [
  {
    name: 'Questions',
    icon: LiveHelpIcon,
    perms: [
      ['askQuestion', 'Can Ask Question'],
      ['answerQuestion', 'Can Answer Question'],
    ],
  },
  {
    name: 'Chat',
    icon: ChatIcon,
    perms: [
      ['chatToPresenters', 'Can Chat With Hosts'],
      ['chatToAll', 'Can Chat To All'],
      ['chatToAttendees', 'Can Chat to Individual Attendees'],
    ],
  },
  {
    name: 'Video',
    icon: VideocamIcon,
    perms: [['shareVideo', 'Can Share Video']],
  },
  {
    name: 'Screen',
    icon: ScreenShareIcon,
    perms: [['shareScreen', 'Can Share Screen']],
  },
];

const useStyles = makeStyles((theme) => ({
  root: {
    margin: `0.75rem ${theme.sidePanel.gutter}`,
  },
  group: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    borderTop: '2px solid gray',
    padding: '0.75rem 0',
    '& > .MuiSvgIcon-root': {
      fontSize: '2rem',
    },
    '& > div': {
      paddingLeft: '1.5rem',
    },
  },
  roleName: {
    marginBottom: '0.33rem',
    fontWeight: 'bold',
    fontSize: '125%',
    letterSpacing: '1px',
  },
}));

export default function Permissions() {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <div className={classes.role}>
        <div className={classes.roleName}>Attendees</div>

        {permissionGroups.map((group) => {
          const Icon = group.icon;
          const perms = group.perms;

          return (
            <div className={classes.group} key={group.name}>
              <Icon />
              <div>
                {perms.map((perm) => (
                  <PermissionSwitch key={perm[0]} perm={perm[0]} name={perm[1]} />
                ))}
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
}
