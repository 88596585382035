import { makeStyles } from '@material-ui/styles';
import ErrorPage from 'components/ErrorPage';
import config from 'config';
import routes from 'constants/routes';
import { windowTitle } from 'constants/strings';
import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router';
import { Redirect, Route, Switch, useHistory } from 'react-router-dom';
import AdminLogin from 'routes/admin-login';
import App from 'routes/app';
import Beta from 'routes/beta';
import Home from 'routes/home';
import HttpError from 'routes/http-error';
import Management from 'routes/management';
import MeetingEnded from 'routes/meeting-ended';
import MeetingLoggedOut from 'routes/meeting-logged-out';
import Recording from 'routes/recording';
import ServicesOnly from 'routes/services-only';
import Settings from 'routes/settings';
import ExternalSignOn from 'routes/sso';
import Vod from 'routes/Vod';
import Waiting from 'routes/waiting';
import * as appActions from 'store/app/appActions';
import { setUpstreamVideoQualityAction } from 'store/chime/chimeActions';
import * as userActions from 'store/user/userActions';
import * as utilsActions from 'store/utils/utilActions';
import { hasLessThanThreeCPU, isMobileOrTablet } from 'utils/systemCheck';
import { useToken } from 'utils/token';

const useStyles = makeStyles((theme) => ({
  appWrapper: {
    overflow: 'hidden auto',
    ...theme.mixins.vhHeight(100),
  },
}));

const standardRoutes = [
  [routes.SSO, ExternalSignOn],
  [routes.BETA, Beta],
  [routes.LOGIN, AdminLogin],
  [routes.ERROR, ErrorFromList],
  [routes.MANAGEMENT, Management],
  [routes.LOGOUT, MeetingLoggedOut],
];

const privateRoutes = [
  [routes.RECORDING, Recording],
  [routes.VOD, Vod],
  [routes.MEETING, App],
  [routes.BROADCAST, App],
  [routes.SETTINGS, Settings],
  [routes.END, MeetingEnded],
  [routes.WAITING, Waiting],
  [routes.SERVICES, ServicesOnly],
];

const { IS_PRODUCTION, STAGE } = config;

export default function Routes() {
  const classes = useStyles();
  const history = useHistory();
  const dispatch = useDispatch();
  const title = useSelector((state) => state.event.title);

  useEffect(() => {
    dispatch(utilsActions.setPushAction({ push: history.push }));
    const shouldForceLowerResolution = isMobileOrTablet() || hasLessThanThreeCPU();
    dispatch(appActions.setShouldForceLowerResolutionAction({ shouldForceLowerResolution }));
    if (shouldForceLowerResolution) dispatch(setUpstreamVideoQualityAction({ id: 4 }));
    // eslint-disable-next-line
  }, []);
  // useEffect(() => {
  //   window.addEventListener('beforeunload', (event) => {
  //     event.returnValue = `Are you sure you want to leave?`;
  //   });
  //   return () => {
  //     window.removeEventListener('beforeunload');
  //   };
  // }, []);

  useEffect(() => {
    document.title = windowTitle;
    if (title) {
      document.title = `${title} - ${document.title}`;
    }
  }, [title]);

  if (window.navigator.userAgent.match('CriOS')) {
    return (
      <ErrorPage>
        Sorry, Chrome is not supported on iOS. Please set your default browser to Safari in your
        device settings.
      </ErrorPage>
    );
  }

  return (
    <div className={classes.appWrapper}>
      <Switch>
        <Redirect from="/classroom" to={routes.MEETING.path} />
        <Redirect from="/conference-settings" to={routes.SETTINGS.path} />
        <Redirect from="/conference" to={routes.BROADCAST.path} />
        {standardRoutes.map(([{ path, params }, RouteComp, exact]) => {
          const p = `${path}${params}`;
          return (
            <Route path={p} exact={exact} key={p}>
              <RouteComp />
            </Route>
          );
        })}
        {privateRoutes.map(([{ path, params }, component, exact]) => {
          return (
            <PrivateRoute
              component={component}
              path={`${path}${params}`}
              exact={exact}
              key={path}
            />
          );
        })}
        {!IS_PRODUCTION && <Route path="/">{STAGE === 'beta' ? <Beta /> : <Home />}</Route>}
        <Route path="*">
          <HttpError code={404} />
        </Route>
      </Switch>
    </div>
  );
}

const PrivateRoute = ({ component: Component, ...options }) => {
  const { token } = useToken();
  const dispatch = useDispatch();
  const [user, setUser] = useState(null);
  if (!user) {
    const user = JSON.parse(localStorage.getItem('user'));
    setUser(user);
    dispatch(userActions.setUserAction({ ...user }));
  }

  const routeRoles = {
    allRoutes: ['admin', 'presenter', 'attendee'],
    // TODO We can lock down routes if needed here
  };

  return (
    <Route
      {...options}
      render={(props) =>
        token && routeRoles['allRoutes'].includes(user?.role) ? (
          <Component {...props} />
        ) : (
          <Redirect to={`${routes.ERROR.path}/failedLogin`} />
        )
      }
    />
  );
};

PrivateRoute.propTypes = {
  component: PropTypes.any,
};

const errors = {
  failedinit: [
    502,
    "We couldn't initialize the broadcast for you. Please try again or contact an admin if the problem persists.",
  ],
  eventnotfound: [500, 'No Event has been found. Please try logging in again!'],
  meetingnotstarted: [500, 'The meeting has not started.'],
  failedLogin: [500, 'Authorization Failed. Please try logging in again.'],
  loginElsewhere: [401, 'You have Logged into another meeting in a separate tab.'],
  '': [500, 'Unknown Error has occured.'],
};

function ErrorFromList() {
  let { error } = useParams();
  let [code, message] = errors[error] || [500, 'Unknown Error'];

  return <HttpError code={code || 502} help={message || 'Unknown error has occured'} />;
}

ErrorFromList.propTypes = {
  match: PropTypes.object,
};
