import { createReducer } from '@reduxjs/toolkit';
import InfoIcon from '@material-ui/icons/Info';
import * as ua from './utilActions';
import { buildActionStatus, standardActions } from 'store/utils';

const initialState = {
  actionStatus: buildActionStatus(ua),
  push: null,
  open: false,
  message: '',
  type: 'info',
  icon: '',
  notiPopoutName: '',
  notiPopoutData: undefined,
  duration: 5000,
};

const utilsReducer = createReducer(initialState, {
  ...standardActions(ua),
  [ua.setPushAction]: (state, { payload: { push } }) => {
    state.push = push;
  },
  [ua.showNotificationAction]: (
    state,
    { payload: { message, type, icon, notiPopoutName, notiPopoutData, duration } }
  ) => {
    state.open = true;
    state.message = message;
    state.type = type || 'info';
    state.notiPopoutName = notiPopoutName || '';
    state.notiPopoutData = notiPopoutData;
    state.duration = duration || 7000;
    state.icon = icon || InfoIcon;
  },
  [ua.closeNotificationAction]: (state) => {
    state.open = false;
  },
});

export default utilsReducer;
