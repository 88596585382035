import { withStyles } from '@material-ui/core/styles';
import React from 'react';
import PropTypes from 'prop-types';

const styles = () => ({
  inputContainer: {},
});

const InputContainer = ({ classes, children }) => (
  <div className={classes.inputContainer}>{children}</div>
);
InputContainer.propTypes = {
  classes: PropTypes.object,
  children: PropTypes.node,
};

export default withStyles(styles)(InputContainer);
