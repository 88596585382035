import { createReducer } from '@reduxjs/toolkit';
import * as sa from 'store/session/sessionActions';
import { buildActionStatus, standardActions } from 'store/utils';
import { merge } from 'lodash';
import { removeOneBy } from 'utils/utilMethods';

const initialState = {
  actionStatus: buildActionStatus(sa),
  blockedUsers: [],
  currentResourceId: null,
  endDate: '',
  gridUsers: {},
  helpRequest: [],
  isRecording: 'OFFLINE',
  isWebsocketConnected: false,
  mutedAll: false,
  playbackUrl: '',
  raisedHand: false,
  recordingUserId: '',
  resourceStatus: '',
  selectSpeaker: '',
  sessionId: null,
  startDate: '',
  status: '',
  queueQuestionText: '',
  stageAttendees: {},
  testUrl: '',
  usersInSession: 0,
};
const sessionReducer = createReducer(initialState, {
  ...standardActions(sa),
  [sa.resetStateAction]: () => ({
    ...initialState,
  }),
  [sa.setSessionAction]: (state, { payload }) => {
    merge(state, payload);

    if (Array.isArray(payload.usersInSession)) {
      state.usersInSession = payload.usersInSession.length;
    } else {
      state.usersInSession = payload.usersInSession || 0;
    }
  },
  [sa.putBlockedUserAction]: (state, { payload: { userId } }) => {
    if (state.blockedUsers.indexOf(userId) === -1) {
      state.blockedUsers.push(userId);
    }
  },
  [sa.removeBlockedUserAction]: (state, { payload: { userId } }) => {
    removeOneBy(state.blockedUsers, 'userId', userId);
  },
  [sa.setBlockedUsersAction]: (state, { payload: { blockedUsers } }) => {
    state.blockedUsers = blockedUsers;
  },
  [sa.setStreamStatusAction]: (state, { payload: { status } }) => {
    state.status = status;
  },
  [sa.setTestUrlAction]: (state, { payload: { url } }) => {
    state.testUrl = url;
  },
  [sa.setQueueQuestionTextAction]: (state, { payload: { text } }) => {
    state.queueQuestionText = text;
  },
  [sa.setResourceStatusAction]: (state, { payload: { status } }) => {
    state.resourceStatus = status;
  },
  [sa.addUserToGridAction]: (state, { payload: { userId, timestamp } }) => {
    state.gridUsers[userId] = timestamp;
  },
  [sa.removeUserFromGridAction]: (state, { payload: { userId } }) => {
    delete state.gridUsers[userId];
  },
  [sa.removeAllUsersFromGridAction]: (state) => {
    state.gridUsers = {};
  },
  [sa.setAllMutedAction]: (state, { payload: { isMuted } }) => {
    state.mutedAll = isMuted;
  },
  [sa.setRaisedHandAction]: (state, { payload: { raisedHand } }) => {
    state.raisedHand = raisedHand;
  },
  [sa.setSpeakerAction]: (state, { payload: { userId } }) => {
    state.selectSpeaker = userId;
  },
  [sa.setUsersInSessionAction]: (state, { payload: { count } }) => {
    state.usersInSession = count;
  },
  [sa.setIsRecordingAction]: (state, { payload: { isRecording } }) => {
    state.isRecording = isRecording;
  },
  [sa.setIsWebsocketConnectedAction]: (state, { payload: { connected } }) => {
    state.isWebsocketConnected = connected;
  },
  [sa.updateStageAttendeeAction]: (state, { payload: { userId, value } }) => {
    state.stageAttendees[userId] = value;
  },
  [sa.updateStageAttendeesAction]: (state, { payload: { value } }) => {
    state.stageAttendees = value;
  },
  [sa.updateStageAttendeeAttrAction]: (state, { payload: { userId, key, value } }) => {
    if (state.stageAttendees[userId]) state.stageAttendees[userId][key] = value;
  },
  [sa.removeStageAttendeeAction]: (state, { payload: { userId } }) => {
    delete state.stageAttendees[userId];
  },
  [sa.setStageAttendeesAction]: (state, { payload: { stageAttendees } }) => {
    state.stageAttendees = stageAttendees;
  },
});
export default sessionReducer;
