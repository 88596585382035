import { makeStyles } from '@material-ui/core/styles';
import ErrorPage from 'components/ErrorPage';
import LoadingPage from 'components/LoadingPage';
import AudioVideoInitialization from 'components/initialization/AudioVideoInitialization';
import MeetingStatus from 'enums/MeetingStatus';
import { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import GridView from 'components/GridView';
import { setMutedAction } from 'store/chime/chimeActions';
import deepEqual from 'react-fast-compare';
import { showNotificationAction } from 'store/utils/utilActions';
import {
  startGuestChimeAction,
  removeChimeAction,
  toggleVideoAction,
} from 'store/chime/chimeActions';
import GuestJoinMeetingSetup from './GuestJoinMeetingSetup';
import { Button, Modal, Box, Typography, Switch, FormControlLabel } from '@material-ui/core';
import { updateStageAttendee } from 'api/routes/session';

const useStyles = makeStyles((theme) => ({
  modal: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    backgroundColor: theme.palette.background.dark,
    borderRadius: '5px',
    boxShadow: 24,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    padding: '1rem 2rem 2rem 2rem',
  },
}));

export default function QuestionChimeWrapper() {
  const classes = useStyles();
  const { Loading, Failed, Succeeded } = MeetingStatus;
  const dispatch = useDispatch();
  const audioElement = useRef(null);
  const status = useSelector((store) => store.chime.meetingStatus);
  const errorMessage = useSelector((store) => store.chime.actionStatus.joinMeetingFlowSaga?.error);
  const audioVideoHasInitialized = useSelector((store) => store.chime.hasInitialized);
  const audioVideo = useSelector((store) => store.chime.audioVideo, deepEqual);
  const stageAttendees = useSelector((store) => store.session.stageAttendees || {}, deepEqual);
  const userId = useSelector((store) => store.user.userId);
  const currentSpeaker = stageAttendees[userId]?.currentSpeaker;
  const nextSpeaker = stageAttendees[userId]?.nextSpeaker;
  const [joined, setJoined] = useState(false);
  const [onstageWarning, setOnStageWarning] = useState(false);
  const [startAudio, setStartAudio] = useState(false);
  const [startVideo, setStartVideo] = useState(false);

  useEffect(() => {
    if (currentSpeaker) {
      setOnStageWarning(true);
    }
    return () => {
      setOnStageWarning(false);
    };
  }, [currentSpeaker]);

  useEffect(() => {
    if (nextSpeaker) {
      dispatch(
        showNotificationAction({
          message: `You have been made the next speaker. You will be brought onto the Stage next.`,
          type: 'info',
        })
      );
    }
  }, [nextSpeaker]);

  const handleOnStageWarning = () => {
    if (startAudio) {
      audioVideo.realtimeUnmuteLocalAudio();
      dispatch(setMutedAction(false));
    }
    if (startVideo) dispatch(toggleVideoAction());
    setOnStageWarning(false);
  };

  const handleJoined = () => {
    setJoined(!joined);

    audioVideo.realtimeMuteLocalAudio();
    dispatch(setMutedAction(true));
    updateStageAttendee(userId, 'hasJoined', true);
    audioVideo.start();
  };

  useEffect(() => {
    dispatch(startGuestChimeAction({ audioRef: audioElement.current }));
    return () => {
      dispatch(removeChimeAction({ shouldEndSession: false }));
    };
  }, []);

  useEffect(() => {
    const callback = (localMuted) => {
      dispatch(setMutedAction(localMuted));
    };
    if (audioVideo) {
      audioVideo.realtimeSubscribeToMuteAndUnmuteLocalAudio(callback);
    }
    return () => {
      if (audioVideo) {
        audioVideo.stopLocalVideoTile();
        audioVideo.realtimeUnsubscribeToMuteAndUnmuteLocalAudio(callback);
      }
    };
    // eslint-disable-next-line
  }, [audioVideoHasInitialized]);

  return (
    <div style={{ height: '100vh' }}>
      <audio ref={audioElement} style={{ display: 'none' }} />
      {status === Loading && <LoadingPage>Loading meeting...</LoadingPage>}
      {status === Failed && <ErrorPage>{errorMessage}</ErrorPage>}
      {status === Succeeded && (
        <>
          {status === Succeeded && (
            <>
              <AudioVideoInitialization questionAsker={true} />
              {audioVideoHasInitialized && joined ? (
                <>
                  <GridView />
                  <Modal open={onstageWarning} onClose={handleOnStageWarning}>
                    <Box className={classes.modal} style={{}}>
                      <Typography variant="h5">You are Live!</Typography>
                      <Typography style={{ marginTop: '1rem' }} variant="body">
                        Please enable audio and video below.
                      </Typography>

                      <div style={{ marginTop: '1rem' }}>
                        <FormControlLabel
                          control={
                            <Switch
                              checked={startAudio}
                              name="Audio"
                              color="primary"
                              onChange={(e) => setStartAudio(e.target.checked)}
                            />
                          }
                          label="Audio"
                        />
                        <FormControlLabel
                          control={
                            <Switch
                              checked={startVideo}
                              name="Video"
                              color="primary"
                              onChange={(e) => setStartVideo(e.target.checked)}
                            />
                          }
                          label="Video"
                        />
                      </div>
                      <Button
                        style={{ marginTop: '1rem' }}
                        variant="contained"
                        color="primary"
                        onClick={handleOnStageWarning}
                      >
                        Enable
                      </Button>
                    </Box>
                  </Modal>
                </>
              ) : (
                <GuestJoinMeetingSetup joinMeeting={handleJoined} />
              )}
            </>
          )}
        </>
      )}
    </div>
  );
}
