import { createReducer } from '@reduxjs/toolkit';
import * as act from './pendingRequestsActions';
import { buildActionStatus, standardActions } from 'store/utils';
import { removeOneBy, replaceOneBy } from 'utils/utilMethods';

const initialState = {
  actionStatus: buildActionStatus(act),
};

const pendingRequestsReducer = createReducer(initialState, {
  ...standardActions(act),
  [act.resetStateAction]: () => ({
    ...initialState,
  }),
  [act.putRequestAction]: (
    state,
    {
      payload: {
        id,
        endpoint,
        baseEndpoint,
        requestType,
        requestGroup,
        request,
        data,
        options,
        retryId,
      },
    }
  ) => {
    if (!state[requestGroup]) state[requestGroup] = [];

    const req = {
      id,
      endpoint,
      baseEndpoint,
      request,
      requestType,
      requestGroup,
      data,
      options,
      timestamp: Date.now(),
    };

    if (!retryId || replaceOneBy(state[requestGroup], req, 'id', retryId) === -1) {
      state[requestGroup].push(req);
    }
  },
  [act.removeRequestAction]: (state, { payload: { id, requestGroup } }) => {
    const requests = state[requestGroup];
    if (requests) {
      removeOneBy(requests, 'id', id);
    }
  },
});

export default pendingRequestsReducer;
