import AppWrapper from 'components/AppWrapper';
import VideoWrapper from 'components/video';
import WebSocketInitialization from 'components/initialization/WebSocketInitialization';
import QuestionChimeWrapper from 'components/QuestionChimeWrapper';
import { useSelector } from 'react-redux';
import deepEqual from 'react-fast-compare';

export default function Index() {
  const stageAttendees = useSelector((store) => store.session.stageAttendees || {}, deepEqual);
  const userId = useSelector((store) => store.user.userId);
  const acceptedRequest = stageAttendees[userId]?.accepted === true;

  return (
    <AppWrapper>
      <WebSocketInitialization />
      {acceptedRequest ? <QuestionChimeWrapper /> : <VideoWrapper isAttendee={true} />}
    </AppWrapper>
  );
}
