import PropTypes from 'prop-types';
import ScreenPage from 'components/ScreenPage';

ErrorPage.propTypes = {
  children: PropTypes.node,
};

export default function ErrorPage({ children }) {
  return (
    <ScreenPage type="error" header="Error">
      {children}
    </ScreenPage>
  );
}
