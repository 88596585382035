import PropTypes from 'prop-types';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import * as ma from 'store/management/managementActions';
import deepEqual from 'react-fast-compare';

ManagementWrapper.propTypes = {
  dataKey: PropTypes.string.isRequired,
  Component: PropTypes.node.isRequired,
  disableAutoSearch: PropTypes.bool.isRequired,
};
export default function ManagementWrapper({ dataKey, Component, disableAutoSearch }) {
  const dispatch = useDispatch();
  const data = useSelector((store) => store.management[dataKey], deepEqual);
  const getData = () => dispatch(ma.getDataAction({ dataKey }));
  useEffect(() => {
    if (disableAutoSearch && (!data || !data.length)) getData();
  }, []);

  return <Component data={data} refresh={getData} />;
}
