import Button from '@material-ui/core/Button';
import { makeStyles } from '@material-ui/core/styles';
import ClearAllIcon from '@material-ui/icons/ClearAll';
import VolumeOffIcon from '@material-ui/icons/VolumeOff';
import { muteUser, removeAllUsersFromGrid } from 'api/routes/session';
import { removeAllUsersFromGridAction } from 'store/session/sessionActions';
import { removeReconnectingUserAction } from 'store/chime/chimeActions';
import { useDispatch } from 'react-redux';
import RosterToolBar from 'components/side-panel/components/roster/RosterToolbar';
import RosterUser from 'components/side-panel/components/roster/RosterUser';
import { useState } from 'react';
import deepEqual from 'react-fast-compare';
import { useSelector } from 'react-redux';
// import { useGridUsers } from 'hooks/useGridUsers';
import { showGenericErrorNotification } from 'utils/utilMethods';
import CircularProgress from '@material-ui/core/CircularProgress';

const useStyles = makeStyles((theme) => ({
  root: {
    margin: `0.75rem ${theme.sidePanel.gutter}`,
  },
  user: {
    display: 'flex',
    alignItems: 'center',
    padding: '0.5rem 0',
    borderBottom: '2px solid #fff2',
  },
  removeFromStage: {
    marginBottom: '1.5rem',
  },
  muteAllButton: {
    marginBottom: '1rem',
  },
  userName: {
    maxWidth: '20ch',
    textOverflow: 'ellipsis',
    overflowX: 'hidden',
    fontWeight: 'bold',
    //fontSize: '0.8rem',
    paddingLeft: '0.25rem',
  },
}));

const filtersObject = {
  inStream: {
    text: 'Users On Stage',
    value: null,
    labels: {
      null: 'All',
      true: 'On Stage',
      false: 'Off Stage',
    },
  },
  isPresenter: {
    text: 'User Role',
    value: null,
    labels: {
      null: 'All',
      true: 'Presenters',
      false: 'Attendees',
    },
  },
  localVideoIsEnabled: {
    text: 'User Video',
    value: null,
    labels: {
      null: 'All',
      true: 'On',
      false: 'Off',
    },
  },
  micEnabled: {
    text: 'User Microphone',
    value: null,
    labels: {
      null: 'All',
      true: 'On',
      false: 'Off',
    },
  },
};

export default function Roster() {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [searchFilter, setSearchFilter] = useState('');
  const [filters, setFilters] = useState(filtersObject);
  const roster = useSelector((store) => store.chime.roster, deepEqual);
  const stageAttendees = useSelector((store) => store.session.stageAttendees, deepEqual);
  const reconnectingList = useSelector((store) => store.chime.reconnectingList, deepEqual);
  const role = useSelector((store) => store.user.role);
  const mutedAll = useSelector((store) => store.session.mutedAll);
  // const gridUsers = useGridUsers();
  const gridUsers = useSelector((store) => store.session.gridUsers, deepEqual);

  const muteAll = async () => {
    muteUser('all');
  };

  const removeAllFromStage = () => {
    removeAllUsersFromGrid({
      retries: 2,
      onSuccess() {
        dispatch(removeAllUsersFromGridAction({}));
      },
      onError(error, reason, retriesLeft) {
        if (retriesLeft <= 0) {
          showGenericErrorNotification('removing all users from the stage');
        }
      },
    });
  };

  const canManage = role === 'presenter' || role === 'admin';
  const rosterList = Object.values(roster)
    .filter((user) => {
      if (user.isRecordingUser || !user.name || Object.keys(stageAttendees).includes(user.userId))
        return false;
      const sf = searchFilter.toLowerCase().trim();
      if (sf && !user.name.toLowerCase().trim().includes(sf)) return false;
      if (filters.inStream.value && !gridUsers[user.userId]) return false;
      if (filters.inStream.value === false && !!gridUsers[user.userId]) return false;
      if (filters.localVideoIsEnabled.value && user.videoStatus === 'disabled') return false;
      if (filters.localVideoIsEnabled.value === false && user === 'enabled') return false;
      if (filters.micEnabled.value && user.muted) return false;
      if (filters.micEnabled.value === false && !user.muted) return false;
      if (filters.isPresenter.value && user.role !== 'presenter') return false;
      if (filters.isPresenter.value === false && user.role === 'presenter') return false;

      return true;
    })
    .sort((userA, userB) => {
      const aName = userA.name || '';
      const bName = userB.name || '';
      return aName.localeCompare(bName, 'en', { ignorePunctuation: true });
    });

  const usersReconnecting = Object.values(reconnectingList).filter((user) => {
    if (Object.keys(roster).includes(user.userId) || Date.now() - user.timestamp < 7000) {
      dispatch(removeReconnectingUserAction({ userId: user.userId }));
      return false;
    }
    return true;
  });

  return (
    <div className={classes.root}>
      {canManage && (
        <div>
          <Button
            fullWidth
            color="secondary"
            variant="outlined"
            size="small"
            startIcon={<VolumeOffIcon />}
            className={classes.muteAllButton}
            onClick={muteAll}
          >
            Mute All
          </Button>
          <Button
            fullWidth
            color="secondary"
            variant="outlined"
            startIcon={<ClearAllIcon />}
            size="small"
            className={classes.removeFromStage}
            onClick={removeAllFromStage}
          >
            Remove All From Stage
          </Button>
        </div>
      )}
      <RosterToolBar
        searchFilter={searchFilter}
        setSearchFilter={setSearchFilter}
        filters={filters}
        setFilters={setFilters}
      />
      {roster &&
        rosterList.map((user) => (
          <RosterUser key={user.userId} gridUsers={gridUsers} user={user} mutedAll={mutedAll} />
        ))}
      {reconnectingList &&
        usersReconnecting.map((user) => (
          <div className={classes.user} key={user.userId}>
            <CircularProgress size="1.5rem" style={{ marginLeft: '1rem' }} />
            <div style={{ marginLeft: '3.25rem' }} className={classes.userName}>
              {user.userName}
            </div>
          </div>
        ))}
    </div>
  );
}
