import { useSelector } from 'react-redux';
import ViewContainerBroadcast from 'components/view-container/ViewContainerBroadcast';
import ViewContainerMeeting from 'components/view-container/ViewContainerMeeting';
export default function ViewContainer() {
  const isBroadcastApp = useSelector((store) => store.app.appType === 'broadcast');

  return isBroadcastApp ? (
    <ViewContainerBroadcast id="presenter-view" />
  ) : (
    <ViewContainerMeeting id="presenter-view-meeting" />
  );
}
