import { makeStyles, emphasize } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  createPoll: {
    width: '100%',
    borderWidth: 1,
    borderStyle: 'solid',
    borderColor: theme.palette.primary.main,
    borderRadius: '0.5rem',
  },
  titleInput: {
    padding: '0 0.5rem',
    '& > .MuiInput-root': {
      padding: '1rem',
      backgroundColor: theme.palette.input.main,
      borderRadius: '10px 10px 0px 0px',
    },
    '& > .MuiInput-underline:before': {
      borderBottom: 'solid 1px lightgray',
    },
    '& > .MuiInput-underline:hover:not(.Mui-disabled):before': {
      borderBottom: 'solid 1px lightgray',
    },
    '& > .MuiInput-underline:after': {
      borderBottom: 'solid 1px lightgray',
    },
  },
  descriptionInput: {
    padding: '0 0.5rem',
    marginBottom: '1rem',
    '& > .MuiInput-root': {
      padding: '1rem',
      backgroundColor: theme.palette.input.main,
      borderRadius: '10px 10px 10px 10px',
    },
    '& > .MuiInput-underline:before': {
      borderBottom: 'none',
    },
    '& > .MuiInput-underline:hover:not(.Mui-disabled):before': {
      borderBottom: 'none',
    },
    '& > .MuiInput-underline:after': {
      borderBottom: 'none',
    },
  },
  optionField: {
    marginTop: '.25rem',
    '& > .MuiInput-root': {
      padding: '.5rem',
      borderRadius: '5px',
      backgroundColor: theme.palette.input.main,
    },
    '& > .MuiInput-underline:before': {
      borderBottom: 'none',
    },
    '& > .MuiInput-underline:hover:not(.Mui-disabled):before': {
      borderBottom: 'none',
    },
    '& > .MuiInput-underline:after': {
      borderBottom: 'none',
    },
  },
  templateLabel: {
    fontWeight: '600',
    textAlign: 'center',
    marginBottom: '.25rem',
  },
  templateSelectContainer: {
    padding: '0 0.5rem',
    marginBottom: '1rem',
  },
  divider: {
    display: 'flex',
    alignItems: 'center',
    margin: '2rem 0rem',
  },
  dividerBar: {
    borderBottom: '2px solid lightgray',
    margin: '0rem 2rem',
    flex: 1,
  },
  duration: {
    border: 0,
    backgroundColor: emphasize(theme.palette.background.dark, 0.05),
    width: '5ch',
    '& > input': {
      textAlign: 'center',
    },
  },
  header: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'flex-start',

    // Close button
    ' & > button:last-child': {
      marginRight: '0.5rem',
      marginTop: '0.5rem',
    },
  },
  body: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'stretch',
    marginTop: '2rem',
    marginBottom: '2rem',

    '& > button': {
      alignSelf: 'center',
    },
  },
  footer: {
    display: 'flex',
    justifyContent: 'space-between',
    padding: '0.5rem',
    borderTopWidth: 1,
    borderTopStyle: 'solid',
    borderTopColor: theme.palette.primary.main,
    borderRadius: '0.5rem',

    '& > *': {
      flexGrow: 1,
    },
    '& > *:nth-child(2)': {
      marginLeft: '0.5rem',
    },
  },
  topTag: {
    backgroundColor: theme.palette.primary.main,
    color: 'white',
    fontWeight: 'bold',
    borderTopLeftRadius: '0.5rem',
    borderBottomRightRadius: '0.5rem',
    padding: '0.5rem 1rem',
    display: 'inline-block',
  },
  instruction: {
    marginBottom: '1rem',
    padding: '0 0.5rem',

    // Number
    '& > *:first-child': {
      float: 'left',
      width: '2rem',
      height: '2rem',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      borderRadius: '50%',
      fontWeight: 'bold',
      backgroundColor: emphasize(theme.palette.background.dark, 0.05),
      color: theme.palette.primary.main,

      '&[data-done="1"]': {
        color: 'white',
        backgroundColor: theme.palette.success.main,

        '& > *:first-child': {
          display: 'none',
        },
      },

      '&[data-done="0"] > *:last-child': {
        display: 'none',
      },
    },

    // Text
    '& > *:last-child': {
      marginLeft: '3rem',
      marginTop: '0.3rem',
    },
  },
  setting: {
    marginBottom: '1rem',
    marginLeft: '0.33rem',
    marginRight: '0.33rem',
    padding: '0.33rem',
    border: 1,
    borderStyle: 'solid',
    borderColor: emphasize(theme.palette.background.paper),
    borderRadius: '0.5rem',

    // Switch
    '& > *:first-child': {
      float: 'left',
    },

    // Text
    '& > *:last-child': {
      marginLeft: '4rem',
    },
  },
  options: {
    marginBottom: '1rem',
    ...theme.mixins.flexVerticalList(0),

    // Option
    '& > div': {
      display: 'flex',
      flexDirection: 'column',
      padding: '0.5rem',
      borderTop: 1,
      borderTopStyle: 'solid',
      borderTopColor: emphasize(theme.palette.background.paper),

      '&:last-child': {
        borderBottom: 1,
        borderBottomStyle: 'solid',
        borderBottomColor: emphasize(theme.palette.background.paper),
      },

      // Option number
      '& > *:first-child': {
        alignSelf: 'flex-start',
        fontWeight: 'bold',
        textTransform: 'uppercase',
      },

      // Text entry
      '& > *:nth-child(2)': {
        alignSelf: 'stretch',
      },

      // Mark as correct
      '& > *:nth-child(3)': {
        alignSelf: 'flex-end',
      },
    },
  },
  afterOptions: {
    display: 'flex',
    justifyContent: 'center',
    marginTop: '1rem',
  },
}));

export default useStyles;
export { useStyles };
