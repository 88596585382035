import { MenuItem, Select } from '@material-ui/core';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import { /*createMuiTheme, MuiThemeProvider,*/ makeStyles } from '@material-ui/core/styles';
import Switch from '@material-ui/core/Switch';
import FiberManualRecordIcon from '@material-ui/icons/FiberManualRecord';
import { countKeys, historyKeys } from 'constants/analytics';
import useInterval from 'hooks/useInterval';
import get from 'lodash/get';
import has from 'lodash/has';
import isString from 'lodash/isString';
import last from 'lodash/last';
import moment from 'moment';
import MUIDataTable from 'mui-datatables';
import { useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { setSelectedAnalyticAction, setShowUsersInMeetingAction } from 'store/app/appActions';
import deepEqual from 'react-fast-compare';

const mapKeys = {
  attendence: 'Attendance',
  cameraActive: 'Active Camera',
  contentShare: 'Content Share',
  videoInStream: 'Video In Stream',
  messages: 'Messages Sent',
  questionsAnswered: 'Questions Answered',
  questionsAsked: 'Questions Asked',
  questionsVoted: 'Questions Upvoted',
  raisedHands: 'Hands Raised',
};

const requiredAppType = {
  raisedHands: 'meeting',
};

const useStyles = makeStyles((theme) => ({
  leaderboard: {
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
  },
  header: {
    margin: `1rem ${theme.sidePanel.gutter}`,
    '& > *': {
      width: '100%',
    },
  },
  tableContainer: {
    overflowY: 'auto',
    flexGrow: 1,
    display: 'flex',
    flexDirection: 'column',
    '& > *': {
      width: '100%',
      flexGrow: 1,
    },
    '& th': {
      fontWeight: 'bold',
    },
    '& .MuiTableCell-root:not(:first-child)': {
      textAlign: 'center',
    },
  },
}));

const options = {
  print: false,
  download: false,
  filterType: 'multiselect',
  selectableRows: 'none',
  pagination: false,
};

export default function LeaderBoard() {
  const classes = useStyles();
  const dispatch = useDispatch();
  const roster = useSelector((store) => store.chime.roster, deepEqual);
  const analytics = useSelector((store) => store.analytics.analytics, deepEqual);
  const selectedAnalytic = useSelector((store) => store.app.selectedAnalytic);
  const showUsersInMeeting = useSelector((store) => store.app.showUsersInMeeting);
  const appType = useSelector((store) => store.app.appType);
  const [forceUpdateTick, setForceUpdateTick] = useState(0);

  useInterval(() => setForceUpdateTick(forceUpdateTick + 1), 1000);

  const columns = useMemo(() => {
    const isHistory = historyKeys.includes(selectedAnalytic);

    const cols = [
      {
        name: 'user',
        label: 'User',
        options: {
          filter: true,
          sort: true,
          searchable: true,
        },
      },
    ];

    if (isHistory) {
      cols.push({
        name: 'totalTime',
        label: 'Total Time',
        options: {
          filter: false,
          sort: true,
          searchable: false,
          customBodyRender: (data) => moment(data).format('mm:ss'),
        },
      });
    } else {
      cols.push({
        name: 'count',
        label: 'Count',
        options: {
          filter: false,
          sort: true,
          searchable: false,
        },
      });
    }

    /*cols.push({
      name: 'percentActive',
      label: 'Percent Active',
      options: {
        filter: false,
        sort: true,
        searchable: false,
      },
    });*/

    cols.push({
      name: 'timeSinceLast',
      label: 'Time Since Last',
      options: {
        filter: false,
        sort: true,
        searchable: false,
        customBodyRender: (data) => {
          if (data === -1) return '-';

          return moment(data).format('mm:ss');
        },
      },
    });

    if (isHistory) {
      cols.push({
        name: 'status',
        label: 'Status',
        options: {
          filter: true,
          sort: true,
          searchable: false,
          customBodyRender: (data) => (
            <FiberManualRecordIcon style={{ color: data ? 'lime' : 'red' }} />
          ),
        },
      });
    }

    return cols;
  }, [selectedAnalytic]);

  const data = useMemo(() => {
    const isHistory = historyKeys.includes(selectedAnalytic);
    const newData = [];

    for (const userAnalytic of Object.values(analytics)) {
      if (
        get(userAnalytic, 'user.firstName') === 'recording' &&
        get(userAnalytic, 'user.lastName') === 'user'
      )
        continue;
      if (!has(userAnalytic, 'user.userId')) continue;
      if (showUsersInMeeting && !has(roster, `${userAnalytic.user.userId}`)) continue;

      const analytic = userAnalytic[selectedAnalytic];
      if (!analytic) continue;

      const row = {
        user: `${get(userAnalytic, `user.firstName`, 'Missing First Name')} ${get(
          userAnalytic,
          `user.lastName`,
          'Missting Last Name'
        )}`,
      };

      const lastItem = last(analytic.history);
      let lastTime;
      if (lastItem) {
        if (isString(lastItem) && lastItem.includes('#')) {
          const [historyType, timestamp] = lastItem.split('#');
          lastTime = parseInt(timestamp);
          row.timeSinceLast = historyType === 'START' ? -1 : Date.now() - lastTime;
        } else {
          lastTime = lastItem;
          row.timeSinceLast = Date.now() - lastTime;
        }
      } else {
        lastTime = row.timeSinceLast = -1;
      }

      if (isHistory) {
        row.totalTime = analytic.totalTime;
        row.status = analytic.current;
        if (lastItem && row.status) {
          row.totalTime += Date.now() - lastTime;
        }
      } else {
        row.count = analytic.count;
      }

      newData.push(row);
    }

    return newData;
  }, [forceUpdateTick, selectedAnalytic, roster, showUsersInMeeting]);

  const selectedAnalyticDropdownMap = (key) =>
    !requiredAppType[key] || appType === requiredAppType[key] ? (
      <MenuItem key={key} value={key}>
        {mapKeys[key]}
      </MenuItem>
    ) : null;

  const onChangeAnalytic = (event) => {
    dispatch(setSelectedAnalyticAction({ selected: event.target.value }));
  };

  const onChangeShowUsersInMeeting = () => {
    dispatch(setShowUsersInMeetingAction({ show: !showUsersInMeeting }));
  };

  return (
    <div className={classes.leaderboard}>
      <div className={classes.header}>
        <Select value={selectedAnalytic} onChange={onChangeAnalytic}>
          {historyKeys.map(selectedAnalyticDropdownMap)}
          {countKeys.map(selectedAnalyticDropdownMap)}
        </Select>
        <FormControlLabel
          control={
            <Switch
              checked={showUsersInMeeting}
              onChange={onChangeShowUsersInMeeting}
              color="primary"
              name="Show Users In Meeting"
              inputProps={{ 'aria-label': 'primary checkbox' }}
            />
          }
          label="Show Users Currently In Meeting"
          labelPlacement="end"
        />
      </div>
      <div className={classes.tableContainer}>
        <MUIDataTable data={data} columns={columns} options={options} />
      </div>
    </div>
  );
}
