import { withStyles } from '@material-ui/core/styles';
import { IconButton } from '@material-ui/core';
import VerticalAlignTopIcon from '@material-ui/icons/VerticalAlignTop';
import PropTypes from 'prop-types';
import React, { useEffect, useState, useRef } from 'react';

const styles = () => ({
  scrollContainer: {
    display: 'flex',
    flexDirection: 'column',
    flexGrow: 1,
    alignSelf: 'stretch',
    overflowX: 'hidden',
    overflowY: 'auto',
    position: 'relative',
  },
  spacer: {
    flexGrow: 1,
    alignSelf: 'stretch',
  },
  scrollToTopButton: {
    position: 'absolute',
    zIndex: 10,
    right: '0.25rem',
    transition: '0.2s ease right',
    '&[data-scrolled-to-top="1"]': {
      right: '-3rem!important',
    },
  },
});

const ScrollContainer = ({
  classes,
  children,
  scrollOnUpdate,
  showScrollToTop,
  topToBottom,
  onScrolled,
}) => {
  const box = useRef(null);
  const [scrolled, setScrolled] = useState(0);

  useEffect(() => {
    if (!scrollOnUpdate) return;

    const timer = setTimeout(() => {
      const el = box.current;
      if (el /*&& el.scrollTop + el.offsetHeight >= el.scrollHeight*/)
        el.scrollTop = el.scrollHeight;
    }, 10);
    return () => clearTimeout(timer);
  }, [children, scrollOnUpdate]);

  const onScroll = (evt) => {
    const el = evt.currentTarget;
    if (el) setScrolled(el.scrollTop);

    if (onScrolled) onScrolled(el.scrollTop, el.scrollHeight);
  };

  const scrollToTop = () => {
    const el = box.current;
    if (el) el.scrollTop = 0;
  };

  return (
    <div className={classes.scrollContainer} onScroll={onScroll} ref={box}>
      {!topToBottom && <div className={classes.spacer} />}
      {children}
      {topToBottom && <div className={classes.spacer} />}
      {showScrollToTop && (
        <IconButton
          className={classes.scrollToTopButton}
          style={{ top: scrolled + 'px' }}
          data-scrolled-to-top={scrolled === 0 ? 1 : 0}
          onClick={scrollToTop}
        >
          <VerticalAlignTopIcon size="large" />
        </IconButton>
      )}
    </div>
  );
};

ScrollContainer.propTypes = {
  classes: PropTypes.object,
  children: PropTypes.node,
  scrollOnUpdate: PropTypes.bool,
  showScrollToTop: PropTypes.bool,
  topToBottom: PropTypes.bool,
  onScrolled: PropTypes.func,
};

export default withStyles(styles)(ScrollContainer);
