import { makeStyles } from '@material-ui/core/styles';

export default makeStyles((theme) => ({
  sideMenu: {
    display: 'flex',
    position: 'relative',
    flexDirection: 'column',
    justifyContent: 'space-between',
    overflowX: 'hidden',
    overflowY: 'auto',
    boxShadow: '-5px 3px 13px #00000014',
    ...theme.mixins.vhMinHeight(100),
    backgroundColor: theme.palette.background.sideMenu,
    ...theme.mixins.shadow(false, 1, '-2px', '0px'),
    [theme.breakpoints.down('mobile')]: {
      maxWidth: '3rem',
      minWidth: '3rem',
    },
    [theme.breakpoints.up('mobile')]: {
      maxWidth: '5rem',
      minWidth: '5rem',
    },
  },
  buttons: {
    padding: 0,
    fontSize: '.6rem',
    color: theme.palette.icon.primary.fill,

    '& > *': {
      padding: '1.5rem 0',
      [theme.breakpoints.downHeight('lg')]: {
        padding: '.75rem 0',
      },
      [theme.breakpoints.down('mobile')]: {
        padding: '0.5rem',
        fontSize: '.70rem',
      },
    },
  },
  attendeeStatusBox: {
    [theme.breakpoints.up('mobile')]: {
      flexGrow: 1,
      marginTop: '3rem',
    },
  },
  userCount: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'row',
  },
  warningSignal: {
    color: theme.palette.warning.main,
    margin: '0.25rem auto',
    display: 'flex',
    fontSize: '1.75rem',
    alignItems: 'center',
    borderRadius: '4px',
    flexDirection: 'column',
  },
  deviceContainer: {
    [theme.breakpoints.down('mobile')]: {
      backgroundColor: 'inherit',
      margin: '0',
    },
    [theme.breakpoints.up('mobile')]: {
      borderRadius: '100px',
      backgroundColor: theme.palette.icon.devicesBackground,
      margin: '0rem .75rem 1rem .75rem',
    },
  },
}));
