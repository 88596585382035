import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import WithPowerdBy from 'components/WithPoweredBy';

const useStyles = makeStyles(() => ({
  textDiv: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    flexGrow: 1,
  },
  padd: { paddingTop: '2rem' },
}));

export default function MeetingEnded() {
  const classes = useStyles();
  return (
    <WithPowerdBy>
      <div className={classes.textDiv}>
        <Typography variant="h4">Cannot Join Multiple Sessions</Typography>
        <Typography className={classes.padd}>
          Two or more sessions cannot run simultaneously on the same browser.
        </Typography>
        <Typography variant="body1">
          Multiple browsers and/or private windows must be used.
        </Typography>
        <Typography variant="body1" className={classes.padd}>
          If you would like to reopen this page, please open it up
        </Typography>
        <Typography variant="body1">
          the same way you entered without clicking the back button.
        </Typography>
      </div>
    </WithPowerdBy>
  );
}
