import CheckPermissions from 'components/CheckPermissions';
import ErrorPage from 'components/ErrorPage';
import JoinMeetingPopup from 'components/JoinMeetingPopup';
import LoadingPage from 'components/LoadingPage';
import AudioVideoInitialization from 'components/initialization/AudioVideoInitialization';
import WebSocketInitialization from 'components/initialization/WebSocketInitialization';
import MeetingStatus from 'enums/MeetingStatus';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { initializeMeetingUserAction } from 'store/chime/chimeActions';
import ScreenPage from 'components/ScreenPage';

export default function MeetingUserInit() {
  const { Loading, Failed, Waiting, Succeeded } = MeetingStatus;
  const [hasCheckedPermissions, setHasCheckedPermissions] = useState(false);
  const dispatch = useDispatch();
  const hasJoined = useSelector((store) => store.chime.hasJoined);
  const status = useSelector((store) => store.chime.meetingStatus);
  const errorMessage = useSelector((store) => store.chime.actionStatus.joinMeetingFlowSaga?.error);
  const audioVideoHasInitialized = useSelector((store) => store.chime.hasInitialized);
  const meetingUserHasInitialized = useSelector((store) => store.chime.meetingUserHasInitialized);
  const webSocketHasInitialized = useSelector((store) => store.webSocket.hasInitialized);

  const showMeetingPopup = Boolean(
    status === Succeeded &&
      audioVideoHasInitialized &&
      webSocketHasInitialized &&
      hasCheckedPermissions
  );
  useEffect(() => {
    if (showMeetingPopup && hasJoined && !meetingUserHasInitialized) {
      dispatch(initializeMeetingUserAction());
    }
  }, [showMeetingPopup, hasJoined, meetingUserHasInitialized]);

  return (
    <div>
      {status === Loading && <LoadingPage>Loading meeting...</LoadingPage>}
      {status === Failed && <ErrorPage>{errorMessage}</ErrorPage>}
      {(status === Waiting || status === Succeeded) && (
        <>
          <WebSocketInitialization />
          {status === Waiting && (
            <ScreenPage type="message" header="Waiting for host">
              The meeting is currently offline.
            </ScreenPage>
          )}
          {status === Succeeded && (
            <>
              <AudioVideoInitialization />
              <CheckPermissions setHasCheckedPermissions={setHasCheckedPermissions} />
              {showMeetingPopup && !hasJoined && (
                <JoinMeetingPopup id="presenter-join-meeting-popup" />
              )}
            </>
          )}
        </>
      )}
    </div>
  );
}
