import { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import deepEqual from 'react-fast-compare';
import PropTypes from 'prop-types';

useShowVolumeIndicator.propTypes = {
  attendeeId: PropTypes.string.isRequired,
};

export default function useShowVolumeIndicator({ attendeeId }) {
  const [showVolumenIndicator, setShowVolumeIndicator] = useState(false);
  const audioVideo = useSelector((store) => store.chime.audioVideo, deepEqual);
  const updateVolumeIndicator = (attendeeId, volume) => {
    setShowVolumeIndicator(volume);
  };

  useEffect(() => {
    audioVideo.realtimeSubscribeToVolumeIndicator(attendeeId, updateVolumeIndicator);

    return () => {
      if (attendeeId) {
        audioVideo.realtimeUnsubscribeFromVolumeIndicator(attendeeId, updateVolumeIndicator);
      }
    };
  }, [attendeeId]);

  return showVolumenIndicator;
}
