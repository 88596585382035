import AppTheme from 'AppTheme';
import NotificationWrapper from 'components/notifications/NotificationWrapper';
import configureStore from 'configureStore';
import React from 'react';
import { Provider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';
import Routes from 'Routes';
import rootState from 'store/rootReducer';

export const store = configureStore(rootState);
export default function App() {
  return (
    <Provider store={store}>
      <AppTheme>
        <BrowserRouter>
          <NotificationWrapper>
            <Routes />
          </NotificationWrapper>
        </BrowserRouter>
      </AppTheme>
    </Provider>
  );
}
