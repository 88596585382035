import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import DefaultIcon from './DefaultIcon';
import { ParticipantsIcon as rosterIcon } from 'svg';

ParticipantsIcon.propTypes = {
  hideIcon: PropTypes.bool.isRequired,
};

export default function ParticipantsIcon({ hideIcon }) {
  const isBroadcastApp = useSelector((store) => store.app.appType === 'broadcast');

  return (
    <>
      <DefaultIcon
        name={isBroadcastApp ? 'Panelists' : 'Participants'}
        buttonId="roster-icon"
        hideIcon={hideIcon}
        IconComp={rosterIcon}
      />
    </>
  );
}
