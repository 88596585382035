import SvgIcon from '@material-ui/core/SvgIcon';
import { ReactComponent as MicOffWhite } from './icons/mic-off.svg';
import { ReactComponent as MicOnWhite } from './icons/mic-on.svg';
import { ReactComponent as VideoOffWhite } from './icons/video-camera-off.svg';
import { ReactComponent as VideoOnWhite } from './icons/video-camera-on.svg';
import { ReactComponent as QAIcon } from './icons/qa.svg';
import { ReactComponent as Chat } from './icons/chat.svg';
import { ReactComponent as leaderboard } from './icons/leaderboard.svg';
import { ReactComponent as Participants } from './icons/participants.svg';
import { ReactComponent as Settings } from './icons/settings.svg';
import { ReactComponent as ScreenShare } from './icons/screen-share.svg';
import { ReactComponent as PhoneSVG } from './icons/phone.svg';
import EmojiPeopleIcon from '@material-ui/icons/EmojiPeople';

export const StageIcon = (props) => {
  return <EmojiPeopleIcon {...props} />;
};

export const MicOffIcon = (props) => {
  return <SvgIcon {...props} component={MicOffWhite} viewBox="0 0 24 25.55"></SvgIcon>;
};
export const MicOnIcon = (props) => {
  return <SvgIcon {...props} component={MicOnWhite} viewBox="0 0 16.616 24"></SvgIcon>;
};

export const VideoOffIcon = (props) => {
  return <SvgIcon {...props} component={VideoOffWhite} viewBox="0 0 24.37 24"></SvgIcon>;
};

export const VideoOnIcon = (props) => {
  return <SvgIcon {...props} component={VideoOnWhite} viewBox="0 0 22 12.926"></SvgIcon>;
};

export const QandAIcon = (props) => {
  return <SvgIcon {...props} component={QAIcon} viewBox="0 0 23.567 24"></SvgIcon>;
};

export const ChatIcon = (props) => {
  return <SvgIcon {...props} component={Chat} viewBox="0 0 27.385 24.002"></SvgIcon>;
};

export const LeaderboardIcon = (props) => {
  return <SvgIcon {...props} component={leaderboard} viewBox="0 0 26.182 24"></SvgIcon>;
};

export const ParticipantsIcon = (props) => {
  return <SvgIcon {...props} component={Participants} viewBox="0 0 28.295 24.002"></SvgIcon>;
};

export const SettingsIcon = (props) => {
  return <SvgIcon {...props} component={Settings} viewBox="0 0 25.043 24"></SvgIcon>;
};

export const ScreenShareIcon = (props) => {
  return <SvgIcon {...props} component={ScreenShare} viewBox="0 0 22 14.667"></SvgIcon>;
};

export const PhoneIcon = (props) => {
  return <SvgIcon {...props} component={PhoneSVG} viewBox="0 0 16.356 5.841"></SvgIcon>;
};
