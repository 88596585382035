import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { askForHelpAction } from 'store/session/sessionActions';

const maxChars = 500;

const useStyles = makeStyles((theme) => ({
  helpPanel: {
    margin: `0.75rem ${theme.sidePanel.gutter}`,
    overflowY: 'auto',
  },
  troubleshootText: {
    '& li': {
      marginBottom: '1rem',
    },
  },
  helpHint: {
    whiteSpace: 'pre-line',
    textAlign: 'center',
    marginBottom: '3rem',
    fontWeight: 'bold',
    textShadow: '2px 1px 0px black',
  },
  helpForm: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    '& > *:not(:last-child)': {
      marginBottom: '1rem',
    },
    '& > button': {
      alignSelf: 'center',
    },
  },
}));

export default function Help() {
  const [message, setMessage] = useState('');
  const classes = useStyles();
  const dispatch = useDispatch();

  const askForHelp = () => {
    dispatch(askForHelpAction({ Message: message }));

    setMessage('');
  };

  const onSubmit = (event) => {
    event.preventDefault();
    askForHelp();
  };

  return (
    <div className={classes.helpPanel}>
      <Typography variant="h5" style={{ textAlign: 'center' }}>
        Troubleshooting
      </Typography>
      <div className={classes.troubleshootText}>
        <ul>
          <li>
            If Webcam is not showing ensure video input is correct in the ... panel on the right and
            toggle the video button icon on the right hand menu
          </li>
          <li>If missing presenters or issues with chat. Try refreshing the browser.</li>
          <li>
            If stream has audio sync issues please send us a message below and we will restore it.
          </li>
          <li>
            If you hear an echo ensure your own computer speakers are muted and use headphones as
            they will pick up the audio from your mic{' '}
          </li>
          <li>
            The mobile version is not supported for presenters and Chrome is the suggested browser{' '}
          </li>
          <li>If you dont not see a play button in the bottom right please contact us below</li>
          <li>
            If your video is significantly stuttering please refresh the page and on the join screen
            select ... and set quality to low bandwidth.
          </li>
        </ul>
      </div>
      <div className={classes.helpHint}>
        {`Submitting a help request will alert a Mosaic Learning employee.

        They will join your chat shortly after to help with your issue.`}
      </div>

      <form className={classes.helpForm} onSubmit={onSubmit}>
        <TextField
          required
          inputProps={{
            maxLength: maxChars,
          }}
          label="Message"
          value={message}
          onChange={(e) => setMessage(e.target.value)}
        />
        <Button type="submit" variant="contained" color="primary" disabled={!message}>
          Call for Help
        </Button>
      </form>
    </div>
  );
}
