import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import useStyles from './CreateEditPoll.style.js';
import {
  Button,
  IconButton,
  Input,
  TextField,
  Typography,
  FormControlLabel,
  FormControl,
  // InputLabel,
  Select,
  Checkbox,
  MenuItem,
} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import DoneIcon from '@material-ui/icons/Done';
import PropTypes from 'prop-types';
import { createPoll, editPoll } from 'api/routes/sessionPolls';
import { addPollAction, updatePollAction } from 'store/session-polling/sessionPollingActions';
import { showNotification } from 'utils/utilMethods';
import StyledSwitch from 'components/StyledSwitch';
import { useRunningPoll } from 'utils/polling';
import PollInProgressModal from 'components/polling/PollInProgressModal';
import PollSaveModal from 'components/polling/PollSaveModal';

const pollOptionMin = 2;
const pollOptionMax = 20;
const pollOptionCharactersMin = 1;
const pollOptionCharactersMax = 100;
const pollQuestionTextCharactersMax = 300;
const pollNameCharactersMax = 100;

const defaultOptions = [];
for (let i = 0; i++ < pollOptionMin; ) defaultOptions.push('');

const inputProps = { maxLength: pollOptionCharactersMax };

CreateEditPoll.propTypes = {
  onSubmitted: PropTypes.func,
  onClickBack: PropTypes.func,
  isSettingsPage: PropTypes.bool,
  editPollId: PropTypes.string,
};

export default function CreateEditPoll({ isSettingsPage, onSubmitted, onClickBack, editPollId }) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [options, setOptions] = useState(defaultOptions);
  const [template, setTemplate] = useState('');
  const [questionText, setQuestionText] = useState('');
  const [name, setName] = useState('');
  const [sending, setSending] = useState(false);
  const [correctAnswerIdx, setCorrectAnswerIdx] = useState(-1);
  const [resultsShared, setResultsShared] = useState(false);
  const [hasDuration, setHasDuration] = useState(false);
  const [durationMinutes, setDurationMinutes] = useState(15);
  const [durationSeconds, setDurationSeconds] = useState(0);
  const [displayDuration, setDisplayDuration] = useState(true);
  const [confirmEndPoll, setConfirmEndPoll] = useState(false);
  const [showSaveModal, setShowSaveModal] = useState(false);
  const templates = useSelector((state) => state.sessionPolling.templates);
  const runningPoll = useRunningPoll();

  useEffect(() => {
    //Always keep a blank option at the end of the list
    if (
      options.length < pollOptionMax &&
      options.findIndex((option) => option.length === 0) === -1
    ) {
      const newOptions = [...options];
      newOptions.push('');
      setOptions(newOptions);
    }
  }, [options]);

  useEffect(() => {
    if (![null, undefined, ''].includes(editPollId)) {
      setTemplate(editPollId);
      const dupe = templates.find((poll) => poll.pollId === editPollId);
      duplicatePoll(dupe);
    } else {
      resetPoll();
      setTemplate('');
    }
  }, [editPollId]);

  const setDuration = (duration) => {
    let mins = Math.floor(duration / 60);
    setDurationMinutes(mins);
    duration -= mins * 60;
    setDurationSeconds(duration);
  };

  const duplicatePoll = (otherPoll) => {
    const newOptions = Object.keys(otherPoll.answerOptions).sort((a, b) =>
      otherPoll.answerOptions[a].order > otherPoll.answerOptions[b].order ? 1 : -1
    );

    setQuestionText(otherPoll.questionText);
    setName(otherPoll.name);
    setOptions(newOptions);
    setCorrectAnswerIdx(newOptions.findIndex((option) => option === otherPoll.correctAnswer));
    setResultsShared(otherPoll.resultsShared ?? false);
    setHasDuration(!!otherPoll.duration);
    setDisplayDuration(otherPoll.displayDuration ?? true);

    if (otherPoll.duration) {
      setDuration(otherPoll.duration / 1000);
    } else {
      setDurationMinutes(15);
      setDurationSeconds(0);
    }
  };

  const resetPoll = () => {
    setQuestionText('');
    setName('');
    setOptions(defaultOptions);
    setCorrectAnswerIdx(-1);
    setResultsShared(false);
    setHasDuration(false);
    setDisplayDuration(true);
    setDurationMinutes(15);
    setDurationSeconds(0);
  };

  const changeOption = (index, newValue) => {
    const newOptions = [...options];

    if (newValue.length === 0 && newOptions.length > pollOptionMin) {
      // Remove this option if we blank it out
      newOptions.splice(index, 1);
      if (correctAnswerIdx === index) {
        setCorrectAnswerIdx(-1);
      }
    } else {
      // Set this value to text entered.
      newOptions[index] = newValue;

      // If no empty options and we have room, make one.
      if (
        newOptions.length < pollOptionMax &&
        newOptions.findIndex((option) => option.length === 0) === -1
      ) {
        newOptions.push('');
      }
    }

    setOptions(newOptions);
  };

  const onSubmit = async (event, isTemplate = false, saveNew = false, skipConfirm = false) => {
    if (event?.preventDefault) event.preventDefault();
    const editing =
      !saveNew && isTemplate && Boolean(templates.find((poll) => poll.pollId === template));

    if (!editing && runningPoll && !skipConfirm) {
      setConfirmEndPoll(true);
      return;
    }
    const processedOptions = [...options];
    for (let optionIdx in processedOptions) {
      processedOptions[optionIdx] = processedOptions[optionIdx].trim();
    }

    // Last element can be blank but still submitted due to how the form works
    if (processedOptions[processedOptions.length - 1] === '') {
      processedOptions.pop();
    }

    setSending(true);

    let dur = 0;
    if (hasDuration) {
      dur += durationMinutes * 60;
      dur += durationSeconds;
      dur *= 1000;
      dur = Math.max(dur, 0);
      dur = Math.round(dur);
    }

    const poll = {
      questionText,
      name,
      possibleAnswers: processedOptions,
      correctAnswerIdx,
      resultsShared,
      duration: dur,
      displayDuration,
      template: isTemplate,
    };

    let response;
    // we want to edit the template
    if (editing) {
      const pollId = template;
      response = await editPoll(pollId, poll);

      if (response?.poll) {
        dispatch(updatePollAction({ pollId, data: response.poll }));
      }
    } else {
      response = await createPoll(poll);

      if (response?.poll) {
        dispatch(addPollAction({ poll: response.poll, serverTime: response.serverTime }));
      }
    }

    if (response?.success) {
      showNotification(`Poll ${editing ? 'edited' : 'created'}.`);
    } else {
      showNotification(`Error ${editing ? 'editing' : 'creating'} poll!`, 'error');
    }

    if (onSubmitted) onSubmitted(!response?.success);

    setSending(false);
  };

  const onConfirmStart = async () => {
    onSubmit({}, false, false, true);
  };

  const onCancelStart = () => {
    setConfirmEndPoll(false);
  };

  const onCancelSaveModal = () => {
    setShowSaveModal(false);
  };

  const onChangeCorrectAnswerIdx = (event) => {
    if (event.target.checked) {
      setCorrectAnswerIdx(Number(event.target.value));
    } else {
      setCorrectAnswerIdx(-1);
    }
  };

  const onClickSave = async (event) => {
    await onSubmit(event, true, false, true);
  };

  const onClickSaveNew = async (event) => {
    if (event?.preventDefault) event.preventDefault();
    if (!name) setName(questionText);
    setShowSaveModal(true);
    // await onSubmit(event, true, true, true);
  };

  const onClickClose = (event) => {
    if (onClickBack) onClickBack(event);
  };

  const handleTemplateSelect = (e) => {
    const id = e.target.value;
    setTemplate(id);
    if (id !== '') {
      const dupe = templates.find((poll) => poll.pollId === id);
      duplicatePoll(dupe);
    } else {
      resetPoll();
    }
  };

  const onChangeResultsShared = (event) => void setResultsShared(event.target.checked);
  const onChangeHasDuration = (event) => void setHasDuration(event.target.checked);
  const onChangeDisplayDuration = (event) => void setDisplayDuration(event.target.checked);
  const onChangeDurationMinutes = (event) => void setDurationMinutes(Number(event.target.value));
  const onChangeDurationSeconds = (event) => void setDurationSeconds(Number(event.target.value));

  // Disabled state of submit buttons
  let badAnswer = false;
  let canSubmit =
    !sending && questionText.length > 0 && questionText.length < pollQuestionTextCharactersMax;
  for (let optionIdx in options) {
    optionIdx = Number(optionIdx);
    let option = options[optionIdx].trim();
    if (optionIdx === options.length - 1 && options.length > pollOptionMin && option.length === 0) {
      // Last option can be blank.
    } else if (option.length < pollOptionCharactersMin || option.length > pollOptionCharactersMax) {
      canSubmit = false;
      badAnswer = true;
      break;
    }
  }

  // Calculate if any conflicting answers
  const existingAnswers = {};
  const conflicts = {};
  for (let optionIdx in options) {
    const option = options[optionIdx];
    if (option) {
      if (existingAnswers[option] !== undefined) {
        canSubmit = false;
        badAnswer = true;
        conflicts[optionIdx] = existingAnswers[option];
        conflicts[existingAnswers[option]] = optionIdx;
      } else {
        existingAnswers[option] = optionIdx;
      }
    }
  }

  if (hasDuration && durationMinutes === 0 && durationSeconds === 0) canSubmit = false;

  let instructionId = 0;
  const instruction = (directions, done) => (
    <div className={classes.instruction}>
      <div data-done={done ? 1 : 0}>
        <div>{++instructionId}</div>
        <DoneIcon />
      </div>
      <div>{directions}</div>
    </div>
  );

  const runningPollDisplayName = runningPoll?.name || null;
  const runningPollDisplayQuestion = runningPoll?.questionText;

  return (
    <form className={classes.createPoll} onSubmit={onSubmit}>
      {runningPoll && (
        <PollInProgressModal
          isOpen={confirmEndPoll}
          onClose={onCancelStart}
          onStart={onConfirmStart}
          pollName={runningPollDisplayName}
          pollQuestion={runningPollDisplayQuestion}
        />
      )}

      <PollSaveModal
        isOpen={showSaveModal}
        onClose={onCancelSaveModal}
        save={() => onSubmit({}, true, true, true)}
        name={name}
        setName={setName}
        pollNameCharactersMax={pollNameCharactersMax}
      />
      <div className={classes.header}>
        <div className={classes.topTag}>
          {isSettingsPage ? 'Create Poll Template' : 'Create Poll'}
        </div>
        <IconButton onClick={onClickClose}>
          <CloseIcon />
        </IconButton>
      </div>

      <div className={classes.body}>
        {!isSettingsPage && (
          <>
            <Typography className={classes.templateLabel}>Choose from template</Typography>
            <FormControl
              size="small"
              variant="outlined"
              className={classes.templateSelectContainer}
            >
              <Select
                id="template-select"
                value={template}
                onChange={handleTemplateSelect}
                displayEmpty
              >
                <MenuItem value="">
                  <em>No Template Selected</em>
                </MenuItem>
                {templates.map((temp) => (
                  <MenuItem key={temp.pollId} value={temp.pollId}>
                    {temp.name ? `${temp.name} (${temp.questionText})` : temp.questionText}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>

            <div className={classes.divider}>
              <div className={classes.dividerBar}></div>
              <Typography component={'span'} style={{ fontWeight: '600' }}>
                or
              </Typography>
              <div className={classes.dividerBar}></div>
            </div>
          </>
        )}
        <Typography className={classes.templateLabel} style={{ marginBottom: '1rem' }}>
          {isSettingsPage
            ? template !== ''
              ? 'Edit Template'
              : 'Create a new poll'
            : 'Create a new poll'}
        </Typography>
        {instruction(
          'Fill in your poll question. The question will be displayed to all attendees when deployed.',
          !!questionText
        )}

        <TextField
          fullWidth
          autoFocus
          multiline
          rows={4}
          // variant="outlined"
          size="small"
          placeholder="Type your poll question here..."
          inputProps={{ maxLength: pollQuestionTextCharactersMax }}
          value={questionText}
          onChange={(evt) => setQuestionText(evt.target.value)}
          className={classes.descriptionInput}
        />

        {instruction(
          <>
            <div>
              Your options will display as choices for attendees. At least two options are required
              to deploy a poll.
            </div>
            <br />
            <Typography variant="subtitle2">
              Optional: Mark an option as the correct answer.
            </Typography>
          </>,
          !badAnswer
        )}

        <div className={classes.options}>
          {options.map((option, i) => (
            <div key={i}>
              <div>Option {i + 1}</div>

              <TextField
                fullWidth
                error={!!conflicts[i]}
                helperText={conflicts[i] && 'This option is a duplicate'}
                placeholder="Start typing option here..."
                // variant="outlined"
                className={classes.optionField}
                size="small"
                inputProps={inputProps}
                value={option}
                onChange={(evt) => changeOption(i, evt.target.value)}
              />

              <FormControlLabel
                control={
                  <Checkbox
                    checked={correctAnswerIdx === i}
                    disabled={!option}
                    value={i}
                    onChange={onChangeCorrectAnswerIdx}
                    size="small"
                  />
                }
                label="Mark as correct answer."
              />
            </div>
          ))}
        </div>

        {instruction('Settings')}

        <div className={classes.setting}>
          <StyledSwitch checked={hasDuration} value={hasDuration} onChange={onChangeHasDuration} />
          <div>
            <Typography variant="body1">Poll Timer</Typography>
            <Typography variant="subtitle2">
              Set how much time attendees have to complete poll.
            </Typography>
            <Input
              disabled={!hasDuration}
              disableUnderline={true}
              type="number"
              size="small"
              inputProps={{ min: 0, max: 1440 }}
              value={durationMinutes}
              onChange={onChangeDurationMinutes}
              className={classes.duration}
            />{' '}
            <Typography variant="caption">mins</Typography>
            <Input
              disabled={!hasDuration}
              disableUnderline={true}
              type="number"
              size="small"
              inputProps={{ min: 0, max: 59 }}
              value={durationSeconds}
              onChange={onChangeDurationSeconds}
              className={classes.duration}
              style={{ marginLeft: '0.5rem' }}
            />{' '}
            <Typography variant="caption">secs</Typography>
            <hr />
            <FormControlLabel
              control={
                <Checkbox
                  disabled={!hasDuration}
                  checked={displayDuration}
                  value={displayDuration}
                  onChange={onChangeDisplayDuration}
                  size="small"
                />
              }
              label="Display remaining time to attendees"
            />
          </div>
        </div>

        <div className={classes.setting}>
          <StyledSwitch
            checked={resultsShared}
            value={resultsShared}
            onChange={onChangeResultsShared}
          />
          <div>
            <Typography variant="body1">Auto-Share Results</Typography>
            <Typography variant="subtitle2">
              As options are selected results will be shared live with attendees.
            </Typography>
          </div>
        </div>
      </div>

      <div className={classes.footer}>
        <Button
          onClick={template === '' ? onClickSaveNew : onClickSave}
          variant="outlined"
          disabled={!canSubmit}
        >
          Save
        </Button>
        {template !== '' && (
          <Button onClick={onClickSaveNew} variant="outlined" disabled={!canSubmit}>
            Save as New Template
          </Button>
        )}
        {!isSettingsPage && (
          <Button type="submit" variant="contained" color="primary" disabled={!canSubmit}>
            Start
          </Button>
        )}
      </div>
    </form>
  );
}
