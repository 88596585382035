import PropTypes from 'prop-types';
import Base from '../components/Base';
// import moment from 'moment';
// import startCase from 'lodash/startCase';
Ecs.propTypes = {
  data: PropTypes.array.isRequired,
  refresh: PropTypes.func.isRequired,
};
export default function Ecs({ data, refresh }) {
  const columns = [
    {
      name: 'clusterName',
      label: 'Name',
      options: {
        filter: true,
        sort: true,
        searchable: true,
      },
    },
    {
      name: 'status',
      label: 'Status',
      options: {
        filter: true,
        sort: true,
        searchable: true,
      },
    },
    {
      name: 'runningTasksCount',
      label: 'Running Tasks',
      options: {
        filter: true,
        sort: true,
        searchable: true,
      },
    },
    {
      name: 'pendingTasksCount',
      label: 'Pending Tasks',
      options: {
        filter: true,
        sort: true,
        searchable: true,
      },
    },
    {
      name: 'registeredContainerInstancesCount',
      label: 'Instances',
      options: {
        filter: true,
        sort: true,
        searchable: true,
      },
    },
  ];
  const containercolumns = [
    {
      name: 'ec2InstanceId',
      label: 'Id',
      options: {
        filter: true,
        sort: true,
        searchable: true,
      },
    },
    {
      name: 'status',
      label: 'Status',
      options: {
        filter: true,
        sort: true,
        searchable: true,
      },
    },
    {
      name: 'runningTasksCount',
      label: 'Running Tasks',
      options: {
        filter: true,
        sort: true,
        searchable: true,
      },
    },
    {
      name: 'pendingTasksCount',
      label: 'Pending Tasks',
      options: {
        filter: true,
        sort: true,
        searchable: true,
      },
    },
  ];

  const options = {};
  return (
    <>
      <Base
        name="Clusters"
        columns={columns}
        data={data?.[0]?.describeClusters?.clusters}
        options={options}
        refresh={refresh}
      />
      <Base
        name="Instances"
        columns={containercolumns}
        data={data?.[0]?.containerInstances?.containerInstances}
        options={options}
        refresh={refresh}
      />
    </>
  );
}
