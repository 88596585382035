import Button from '@material-ui/core/Button';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import Typography from '@material-ui/core/Typography';
import { updateEvent } from 'api/routes/event';
import logger from 'logger';
import PropTypes from 'prop-types';
import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { setEventAction } from 'store/event/eventActions';
import FilesTable from './components/FilesTable';
import { useStyles } from '../../index.styles';
import deepEqual from 'react-fast-compare';

MediaTab.propTypes = {
  tabName: PropTypes.string.isRequired,
  setUploadOpen: PropTypes.func.isRequired,
};

export default function MediaTab({ tabName, setUploadOpen }) {
  const [changingStandby, setChangingStandby] = useState(false);
  const dispatch = useDispatch();
  const classes = useStyles();
  const videos = useSelector((store) => store.event.videos, deepEqual);
  const standby = useSelector((store) => store.event.standby, deepEqual);

  const updateEventKey = async (key, value) => {
    try {
      const updatedEvent = await updateEvent({
        [key]: value,
      });
      dispatch(
        setEventAction({
          eventId: updatedEvent.event.eventId,
          title: updatedEvent.event.title,
          description: updatedEvent.event.description,
          videos: updatedEvent.event.videos,
          standby: updatedEvent.event.standby,
          blockedUsers: updatedEvent.event.blockedUsers,
        })
      );
    } catch (e) {
      logger.error('Error setting new title', { e });
    }
  };

  const changeStandby = async (event) => {
    setChangingStandby(true);
    await updateEventKey('standby', videos[event.target.value]);
    setChangingStandby(false);
  };

  const standbyIndex = videos && videos.findIndex((vid) => vid.key === standby?.key);

  return (
    <div className={classes.page}>
      <Typography variant="h2">{tabName}</Typography>

      <div className={classes.fileTableWrapper}>
        <FormControl className={classes.standbyControl}>
          <InputLabel id="standby-label">Standby Video</InputLabel>
          <Select
            labelId="standby-label"
            value={standbyIndex}
            disabled={changingStandby}
            onChange={changeStandby}
          >
            {videos &&
              videos.map((video, index) => (
                <MenuItem key={video.key} value={index}>
                  {video.name}
                </MenuItem>
              ))}
          </Select>
        </FormControl>
        <Button variant="contained" color="primary" onClick={() => void setUploadOpen(true)}>
          Upload Video
        </Button>
        <FilesTable files={videos} />
      </div>
    </div>
  );
}
