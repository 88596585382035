import { Button, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { Modal, ModalBody, ModalFooter } from 'components/Modal';
import { useDispatch, useSelector } from 'react-redux';
import { setShowRecordingModalAction } from 'store/app/appActions';
import { leaveRoomAction } from 'store/chime/chimeActions';
const useStyles = makeStyles(() => ({
  footer: {
    display: 'flex',
    justifyContent: 'space-between',
    flexGrow: 1,
  },
  leave: {
    backgroundColor: 'red',
  },
}));

RecordingModal.propTypes = {};

export default function RecordingModal() {
  const classes = useStyles();
  const dispatch = useDispatch();
  const showRecordingModal = useSelector((store) => store.app.showRecordingModal);
  const onClose = () => {
    dispatch(setShowRecordingModalAction({ showRecordingModal: false }));
  };
  const onLeaveMeeting = async () => {
    dispatch(leaveRoomAction({ shouldEndSession: false }));
    onClose();
  };
  return (
    <Modal
      frontAndCenter
      frontAndCenterWidth={75}
      open={showRecordingModal}
      onClose={onClose}
      title="This meeting is being recorded"
      variant="large"
    >
      <ModalBody>
        <Typography variant="body">
          This meeting is being recorded. By clicking continue to be in the meeting, you are
          consenting to being recorded.
        </Typography>
      </ModalBody>

      <ModalFooter>
        <div className={classes.footer}>
          <Button variant="contained" className={classes.leave} onClick={onLeaveMeeting}>
            Leave Meeting
          </Button>

          <Button variant="contained" color="primary" onClick={onClose}>
            Continue
          </Button>
        </div>
      </ModalFooter>
    </Modal>
  );
}
