import { createAction as ca } from '@reduxjs/toolkit';

export const errorAction = ca('confapperence/errorAction');
export const initAction = ca('app/initAction');
export const setAppTypeAction = ca('app/setAppTypeAction');
export const setBetaMeetingIdAction = ca('app/setBetaMeetingIdAction');
export const setChooseServicesAction = ca('app/setChooseServicesAction');
export const setPopoutAction = ca('app/setPopoutAction');
export const closePopoutAction = ca('app/closePopoutAction');
export const setSelectedAnalyticAction = ca('app/setSelectedAnalyticAction');
export const setServicesOnlyAction = ca('app/setServicesOnlyAction');
export const setShouldForceLowerResolutionAction = ca('app/setShouldForceLowerResolutionAction');
export const setShowRecordingModalAction = ca('app/setShowRecordingModalAction');
export const setShowUsersInMeetingAction = ca('app/setShowUsersInMeetingAction');
export const setThemeAction = ca('app/setThemeAction');
export const successAction = ca('app/successAction');
export const updateServicesOnlyAction = ca('app/updateServicesOnlyAction');
export const updateTokenAction = ca('app/updateTokenAction');
