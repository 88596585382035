import { makeStyles } from '@material-ui/core/styles';
import { useSelector, useDispatch } from 'react-redux';
import { updateStageAttendee, removeStageAttendee } from 'api/routes/session';
import { leaveGridAction, enterGridAction } from 'store/chime/chimeActions';
import { showNotificationAction } from 'store/utils/utilActions';
import { setSpeakerAction } from 'store/session/sessionActions';
import deepEqual from 'react-fast-compare';
import pickBy from 'lodash/pickBy';
import findKey from 'lodash/findKey';
import { Typography, Button } from '@material-ui/core';
import { WhiteOutlinedButton, LinkButton, PrimaryButton } from './muiComponents';
import PropTypes from 'prop-types';
import { StageIcon } from 'svg';

const MAX_QUESTION_QUEUE = 10;

QuestionQueue.propTypes = {
  isAttendee: PropTypes.bool,
  stageAttendees: PropTypes.object,
  userId: PropTypes.string,
};

const useStyles = makeStyles((theme) => ({
  panel: {
    backgroundColor: theme.palette.icon.devicesBackground,
    marginBottom: '2rem',
    borderRadius: '.5rem',
    minHeight: '25vh',
  },
  speakerName: {
    // color: '#004E9D',
    color: theme.palette.text.signifier,
    fontSize: '.85rem',
    fontWeight: '600',
    paddingBottom: '0',
    marginBottom: '0',
  },
  borderPanel: {
    border: '1px solid black',
    backgroundColor: theme.palette.background.dark,
    borderRadius: '.25rem',
  },
  currentSpeakerPanel: {
    padding: '1rem',
    display: 'flex',
    alignItems: 'center',
    borderBottom: '1px solid black',
  },
  requestItem: {
    padding: '10px',
    borderBottom: '1px solid black',
  },
  secondaryTitle: {
    textTransform: 'uppercase',
    fontSize: '.85rem',
  },
  nextSpeakerPanel: {
    padding: '1rem',
  },
  floorContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    flexGrow: 1,
    marginLeft: '.5rem',
  },
  nextSpeakerText: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  requestToSpeakPanel: {
    backgroundColor: theme.palette.background.dark,
    padding: '1rem .5rem',
    borderRadius: '.5rem',
  },
}));

export default function QuestionQueue({ isAttendee, stageAttendees, userId }) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const roster = useSelector((store) => store.chime.roster, deepEqual);
  const requested = pickBy(stageAttendees, (usr) => !usr.accepted);
  const currentSpeaker = findKey(stageAttendees, 'currentSpeaker');
  const nextSpeaker = findKey(stageAttendees, 'nextSpeaker');
  const upcomingSpeakers = pickBy(
    stageAttendees,
    (usr, i) => usr.accepted && ![currentSpeaker, nextSpeaker].includes(i)
  );

  const approveAttendeeInvite = async (userId) => {
    if (Object.keys(upcomingSpeakers).length >= MAX_QUESTION_QUEUE) {
      dispatch(
        showNotificationAction({
          message: `Cannot accept more than ${MAX_QUESTION_QUEUE} Attendees for Queue`,
          type: 'error',
        })
      );
    } else {
      await updateStageAttendee(userId, 'accepted', true);
    }
  };

  const removeAttendee = async (userId) => {
    await removeStageAttendee(userId);
  };

  const setCurrentSpeaker = async (userId) => {
    if (currentSpeaker) {
      await updateStageAttendee(currentSpeaker, 'currentSpeaker', false);
      dispatch(leaveGridAction({ userId: currentSpeaker }));
    }
    await updateStageAttendee(nextSpeaker, 'nextSpeaker', false);
    await updateStageAttendee(userId, 'currentSpeaker', true);
    dispatch(enterGridAction({ userId, timestamp: Date.now() }));
    dispatch(setSpeakerAction({ userId }));
  };

  const setNextSpeaker = async (userId) => {
    if (nextSpeaker) await updateStageAttendee(nextSpeaker, 'nextSpeaker', false);
    await updateStageAttendee(userId, 'nextSpeaker', true);
  };

  return (
    <div className={classes.root}>
      <div className={classes.panel} style={{ padding: '5px' }}>
        <div className={classes.borderPanel}>
          <div className={classes.currentSpeakerPanel}>
            <StageIcon />
            <div className={classes.floorContainer}>
              {currentSpeaker === undefined ? (
                <Typography>The Floor is open</Typography>
              ) : (
                <>
                  <div
                    style={{
                      display: 'flex',
                      justifyContent: 'space-between',
                      alignItems: 'center',
                      flexGrow: 1,
                    }}
                  >
                    <div style={{ display: 'flex', flexDirection: 'column' }}>
                      <Typography className={classes.speakerName}>
                        {stageAttendees[currentSpeaker].name}
                      </Typography>
                      <Typography variant="subtitle2">is on the Floor</Typography>
                    </div>
                    {!isAttendee && (
                      <WhiteOutlinedButton
                        variant="outlined"
                        size="large"
                        onClick={() => removeAttendee(currentSpeaker)}
                      >
                        Remove Speaker
                      </WhiteOutlinedButton>
                    )}
                  </div>
                </>
              )}
            </div>
          </div>
          <div className={classes.nextSpeakerPanel}>
            <Typography className={classes.secondaryTitle}>Next Speaker</Typography>
            {nextSpeaker !== undefined ? (
              <div className={classes.nextSpeakerText}>
                <Typography style={{ fontWeight: '600' }}>
                  {stageAttendees[nextSpeaker].name}
                </Typography>
                {!isAttendee && (
                  <div>
                    <PrimaryButton onClick={() => setCurrentSpeaker(nextSpeaker)}>
                      Bring to The Floor
                    </PrimaryButton>
                    <LinkButton onClick={() => removeAttendee(nextSpeaker)}>Remove</LinkButton>
                  </div>
                )}
              </div>
            ) : (
              'None'
            )}
          </div>
        </div>
        {isAttendee && (
          <div
            style={{
              padding: '.5rem 0',
              display: 'flex',
              justifyContent: 'right',
              alignItems: 'right',
            }}
          >
            <Button variant="contained" color="secondary" onClick={() => removeAttendee(userId)}>
              Leave Queue
            </Button>
          </div>
        )}
        <div style={{ padding: '2rem 1rem' }}>
          <div style={{ display: 'flex', justifyContent: 'space-between' }}>
            <Typography className={classes.secondaryTitle}>Upcoming Speaker</Typography>
            <Typography>
              {Object.keys(upcomingSpeakers).length}/{MAX_QUESTION_QUEUE}
            </Typography>
          </div>
          <hr />
          {Object.keys(upcomingSpeakers).length === 0 && 'No Speakers Available'}
          {Object.keys(upcomingSpeakers).map((id) => {
            const user = upcomingSpeakers[id];
            const ready = Object.keys(roster).includes(id);
            const status = ready ? 'Next Speaker' : user.hasJoined ? 'Left...' : 'Joining...';
            return (
              <>
                <div
                  style={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                    marginBottom: '10px',
                  }}
                  key={user}
                >
                  <Typography style={{ fontWeight: '600' }}>{user.name}</Typography>
                  <div>
                    {!isAttendee && (
                      <PrimaryButton onClick={() => setNextSpeaker(id)} disabled={!ready}>
                        {status}
                      </PrimaryButton>
                    )}
                    {!isAttendee && (
                      <LinkButton style={{ marginLeft: '5px' }} onClick={() => removeAttendee(id)}>
                        Remove
                      </LinkButton>
                    )}
                  </div>
                </div>
                {!isAttendee && <Typography variant="subtitle1">{user.message}</Typography>}
                <hr />
              </>
            );
          })}
        </div>
      </div>
      {!isAttendee && (
        <div className={classes.panel} style={{ border: '1px solid black' }}>
          <div className={classes.requestToSpeakPanel}>
            <Typography variant="h6" style={{ marginBottom: '0', paddingBottom: '0' }}>
              Requests to speak
            </Typography>
          </div>
          <div>
            {Object.keys(requested).length === 0 && (
              <Typography style={{ paddingLeft: '10px' }}>No Requests.</Typography>
            )}
            {Object.keys(requested).map((id) => {
              const user = requested[id];
              return (
                <div key={user.userId} className={classes.requestItem}>
                  <div
                    style={{
                      display: 'flex',
                      justifyContent: 'space-between',
                      alignItems: 'center',
                    }}
                  >
                    <Typography variant="body1" style={{ fontWeight: '600' }}>
                      {user.name}
                    </Typography>
                    <div>
                      <LinkButton variant="text" onClick={() => removeAttendee(id)}>
                        Decline
                      </LinkButton>
                      <WhiteOutlinedButton
                        size="large"
                        variant="outlined"
                        onClick={() => approveAttendeeInvite(id)}
                      >
                        Approve
                      </WhiteOutlinedButton>
                    </div>
                  </div>
                  <Typography variant="subtitle1">{user.message}</Typography>
                </div>
              );
            })}
          </div>
        </div>
      )}
    </div>
  );
}
