import { updateUserData } from 'api/routes/vodUserData';
import VodControls from 'components/VodControls';
import PropTypes from 'prop-types';
import { useEffect, useRef, useState } from 'react';
import ReactHlsPlayer from 'react-hls-player';
import { supportsVideoType } from 'utils/utilMethods';

const autoFeedTime = 30 * 1000;

VodPlayer.propTypes = {
  maxTime: PropTypes.number,
  minTime: PropTypes.number,
  userDataId: PropTypes.string.isRequired,
  vodId: PropTypes.string.isRequired,
  vodUrl: PropTypes.string.isRequired,
};

// Stop re-renders. React.memo doesn't work for some reason. This is okay for now since this component will only ever exist one at a time.
let lastTotalWatched = 0;

export default function VodPlayer({ userDataId, vodId, vodUrl, minTime, maxTime }) {
  const playerRef = useRef(null);
  const [currentTime, setCurrentTime] = useState(0);
  const [volume, setVolume] = useState(1);
  const [muted, setMuted] = useState(false);
  const [paused, setPaused] = useState(true);
  const [duration, setDuration] = useState(1);

  const feedOutSeconds = () => {
    const player = playerRef?.current;
    if (!vodUrl || !player) return;

    // This only counts time viewed for sections you did not already playback.
    // In the future we may want to have the server hold on to this because right now it's cleared on page load.
    const played = player.played;
    let totalWatched = 0.0;
    for (let i = 0; i < played.length; ++i) {
      totalWatched += played.end(i) - played.start(i);
    }

    if (userDataId !== null && totalWatched - lastTotalWatched > 0) {
      updateUserData(vodId, userDataId, totalWatched - lastTotalWatched, player.currentTime);
    }

    lastTotalWatched = totalWatched;
  };

  const clampCurrentTime = () => {
    const player = playerRef?.current;
    if (!player) return;

    setCurrentTime(player.currentTime);

    if (maxTime && player.currentTime > maxTime) {
      player.currentTime = maxTime;
      player.pause();
    } else if (minTime && player.currentTime < minTime) {
      player.currentTime = minTime;
      player.pause();
    }
  };

  const onVolumeChange = () => void setVolume(playerRef?.current?.volume);
  const onDurationChange = () => void setDuration(playerRef?.current?.duration);
  const onPause = () => void setPaused(true);
  const onPlay = () => void setPaused(false);
  const toggleMute = () => {
    const player = playerRef?.current;
    if (player) {
      player.muted = !player.muted;
      setMuted(player.muted);
    }
  };

  const togglePause = () => {
    const player = playerRef?.current;
    if (player.paused) player.play();
    else player.pause();
  };

  const restart = () => {
    const player = playerRef?.current;
    player.currentTime = minTime || 0;
    player.play();
  };

  const onChangeVolume = (event, volume) => {
    const player = playerRef?.current;
    player.volume = volume;
  };

  const onChangeCurrentTime = (time) => {
    const player = playerRef?.current;
    player.currentTime = time;
  };

  useEffect(() => {
    const player = playerRef?.current;
    let feedTimer;

    feedOutSeconds();
    lastTotalWatched = 0.0;

    if (player) {
      window.addEventListener('beforeunload', feedOutSeconds);
      player.addEventListener('timeupdate', clampCurrentTime);
      player.addEventListener('canplay', clampCurrentTime);
      player.addEventListener('volumechange', onVolumeChange);
      player.addEventListener('pause', onPause);
      player.addEventListener('play', onPlay);
      player.addEventListener('durationchange', onDurationChange);

      feedTimer = setInterval(feedOutSeconds, autoFeedTime);
    }

    return () => {
      if (player) {
        window.removeEventListener('beforeunload', feedOutSeconds);
        player.removeEventListener('timeupdate', clampCurrentTime);
        player.removeEventListener('canplay', clampCurrentTime);
        player.removeEventListener('volumechange', onVolumeChange);
        player.removeEventListener('pause', onPause);
        player.removeEventListener('play', onPlay);
        player.removeEventListener('durationchange', onDurationChange);
        feedOutSeconds();
      }

      if (feedTimer) {
        clearInterval(feedTimer);
      }
    };
  }, []); //eslint-disable-line react-hooks/exhaustive-deps

  return (
    <div>
      <VodControls
        toggleMute={toggleMute}
        togglePause={togglePause}
        restart={restart}
        onChangeVolume={onChangeVolume}
        minTime={minTime}
        maxTime={maxTime}
        currentTime={currentTime}
        setCurrentTime={onChangeCurrentTime}
        volume={volume}
        muted={muted}
        paused={paused}
        duration={duration}
      >
        {/ipad|iphone/i.test(window.navigator.userAgent) ||
        supportsVideoType('hls') === 'probably' ? (
          <video
            ref={playerRef}
            src={vodUrl}
            style={{ width: '100%', height: '100%', maxWidth: '75vw', maxHeight: '80vh' }}
          >
            <source src={vodUrl} type="application/x-mpegURL"></source>
          </video>
        ) : (
          <ReactHlsPlayer
            playerRef={playerRef}
            src={vodUrl}
            autoPlay={true}
            controls={false}
            style={{ width: '100%', height: '100%', maxWidth: '75vw', maxHeight: '80vh' }}
          />
        )}
      </VodControls>
    </div>
  );
}
