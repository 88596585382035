import React from 'react';
import { makeStyles, emphasize } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import PoweredByImage from 'components/PoweredByImage';

const useStyles = makeStyles((theme) => ({
  siteHeader: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: '100%',
    height: '4rem',
    backgroundColor: theme.palette.background.dark,
    borderBottom: '1px solid',
    borderBottomColor: emphasize(theme.palette.background.dark, 0.33),
    marginBottom: '2rem',
  },
  content: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    width: 1920,
    maxWidth: '100vw',
    height: '100%',
    padding: '0.75rem 2.5rem',
    '& > img': {
      height: '100%',
    },
  },
  title: {
    flexGrow: 1,
    margin: '0 2rem',
    fontSize: '1.25rem',
    fontWeight: 'bold',
  },
  children: {
    display: 'flex',
    alignItems: 'center',
    '& .MuiTabs-root': {
      minHeight: 0,
    },
    '& .MuiTab-root': {
      minHeight: 0,
      textTransform: 'none',
      '&.Mui-selected': {
        fontWeight: 'bold',
      },
    },
    '& .MuiTabs-scroller > span': {
      height: 3,
    },
  },
}));

SiteHeader.propTypes = {
  children: PropTypes.node,
  title: PropTypes.string,
};

export default function SiteHeader({ title, children }) {
  const classes = useStyles();

  return (
    <div className={classes.siteHeader}>
      <div className={classes.content}>
        <PoweredByImage />
        <div className={classes.title}>{title}</div>
        <div className={classes.children}>{children}</div>
      </div>
    </div>
  );
}
