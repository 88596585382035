export const basePalette = {
  type: 'dark',
  logo: '/Poweredby.png',
  endLogo: '/byCombobulate.svg',
  shadow: {
    distanceScale: 1,
    sizeScale: 1,
    opacity: 0.87,
  },
  primary: {
    main: '#03adee',
    light: '#4eccfd',
    dark: '#015b7e',
  },
  tertiary: {
    main: '#c43c3c',
    light: '#bf8989',
  },
  hr: {
    main: '#4d636b',
    monochrome: '#555',
  },
  danger: {
    main: '#DC3644',
  },
  imageLabel: {
    main: '#0F85B3',
    dark: '#0D0D0D',
  },
  sidePanelGradient: {
    light: '#166B8B',
    dark: '#2A2A2A',
  },
  success: {
    main: '#1fb447',
  },
};

export const sizes = {
  scrollBar: {
    width: 5,
  },
};

export const mixins = (palette) => ({
  shadow: (override, dist, x, y) => {
    if (!override && palette.shadow.onlyImportant) return {};

    dist = dist ?? 2;
    const radius = dist * 3 * palette.shadow.sizeScale;

    if (!x || !y) {
      dist *= palette.shadow.distanceScale;
      x = `-${dist}px`;
      y = `${dist}px`;
    }

    return { boxShadow: `${x} ${y} ${radius}px rgba(0, 0, 0, ${palette.shadow.opacity})` };
  },
  borderRadius: (radius) => {
    radius = radius ?? 4;
    return {
      borderRadius: `${radius}px`,
    };
  },
  circleHover: (radius, color) => {
    color = color ?? palette.primary.main;
    radius = radius ?? '0.5rem';

    return {
      '&::before': {
        content: '""',
        display: 'block',
        width: `calc(100% + ${radius})`,
        height: `calc(100% + ${radius})`,
        backgroundColor: 'transparent',
        position: 'absolute',
        borderRadius: '10em',
        opacity: '0.25',
        transition: 'background-color 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
      },
      '&:hover::before': {
        backgroundColor: color || palette.primary.main,
      },
    };
  },
  flexHorizontalList: (space) => {
    space = space ?? '0.5rem';
    return {
      display: 'flex',
      '& > *:not(:first-child)': {
        marginLeft: space,
      },
      '& > *:not(:last-child)': {
        marginRight: space,
      },
    };
  },
  flexVerticalList: (space) => {
    space = space ?? '0.5rem';
    return {
      display: 'flex',
      flexDirection: 'column',
      '& > *:not(:first-child)': {
        marginTop: space,
      },
      '& > *:not(:last-child)': {
        marginBottom: space,
      },
    };
  },
  grayscale: (amount) => {
    amount = amount ?? 1;
    return {
      filter: `monochrome(${amount})`,
    };
  },
  invert: (amount) => {
    amount = amount ?? 1;
    return {
      filter: `invert(${amount})`,
    };
  },
  blackAndWhiteImage: () => {
    if (palette.type === 'light') return { filter: `invert(1)` };
    return {};
  },
  logoBackgroundImageUrl: () => {
    return {
      backgroundImage: `url("${palette.logo}")`,
    };
  },
  vhHeight: (height) => ({
    height: `calc(var(--vh, 1vh) * ${height || 100})!important`,
  }),
  vhMinHeight: (height) => ({
    minHeight: `calc(var(--vh, 1vh) * ${height || 100})!important`,
  }),
  vhMaxHeight: (height) => ({
    maxHeight: `calc(var(--vh, 1vh) * ${height || 100})!important`,
  }),
});

export const typography = (palette) => ({
  fontFamily: '"Open Sans", Roboto, tahoma, sans-serif',
  default: {
    color: palette.secondary.main,
  },
  body2: {
    color: palette.secondary.main,
  },
  h6: {
    fontWeight: '600',
    fontSize: '1rem',
    letterSpacing: '0px',
    marginBottom: '.5rem',
  },
});

export const breakpoints = {
  values: {
    xs: 0,
    sm: 600,
    md: 960,
    lg: 1280,
    xl: 1920,
    mobile: 765,
  },
  heightValues: {
    xs: 0,
    sm: 340,
    md: 540,
    lg: 720,
    xl: 970,
    mobile: 575,
  },
  portrait: '@media (orientation: portrait)',
  landscape: '@media (orientation: landscape)',
  get down() {
    return (key) => `@media (max-width:${(this.values[key] || key) - 0.5}px)`;
  },
  get up() {
    return (key) => `@media (min-width:${(this.values[key] || key) + 0.5}px)`;
  },
  get downWithLandscape() {
    return (key) =>
      `@media (max-width:${(this.values[key] || key) - 0.5}px) and (orientation: landscape)`;
  },
  get upWithLandscape() {
    return (key) =>
      `@media (min-width:${(this.values[key] || key) + 0.5}px) and (orientation: landscape)`;
  },
  get downWithPortrait() {
    return (key) =>
      `@media (max-width:${(this.values[key] || key) - 0.5}px) and (orientation: portrait)`;
  },
  get upWithPortrait() {
    return (key) =>
      `@media (min-width:${(this.values[key] || key) + 0.5}px) and (orientation: portrait)`;
  },
  get between() {
    return (keyA, keyB) =>
      `@media (min-width:${this.values[keyA] || keyA}px) and (max-width:${
        (this.values[keyB] || keyB) - 0.5
      }px)`;
  },
  get downHeight() {
    return (key) => `@media (max-height:${(this.heightValues[key] || key) - 0.5}px)`;
  },
  get upHeight() {
    return (key) => `@media (min-height:${(this.heightValues[key] || key) + 0.5}px)`;
  },
  get downHeightWithLandscape() {
    return (key) =>
      `@media (max-height:${(this.heightValues[key] || key) - 0.5}px) and (orientation: landscape)`;
  },
  get upHeightWithLandscape() {
    return (key) =>
      `@media (min-height:${(this.heightValues[key] || key) + 0.5}px) and (orientation: landscape)`;
  },
  get downHeightWithPortrait() {
    return (key) =>
      `@media (max-height:${(this.heightValues[key] || key) - 0.5}px) and (orientation: portrait)`;
  },
  get upHeightWithPortrait() {
    return (key) =>
      `@media (min-height:${(this.heightValues[key] || key) + 0.5}px) and (orientation: portrait)`;
  },
  get betweenHeight() {
    return (keyA, keyB) =>
      `@media (min-height:${this.heightValues[keyA] || keyA}px) and (max-height:${
        (this.heightValues[keyB] || keyB) - 0.5
      }px)`;
  },
};

export const overrides = (palette) => ({
  MuiInputBase: {
    // letterSpacing: '1px',
    // lineHeight: '1.4',
  },
  MuiIconButton: {
    root: {
      // Default MUI icon button backgrounds add a padding, increasing the size of the DOM element and ruining layouts
      padding: 0,
      ...mixins(palette).circleHover(),
      '&:hover': {
        backgroundColor: 'transparent',
      },
    },
    label: {
      zIndex: 2,
    },
  },
  MuiButton: {
    root: {
      textTransform: 'none',
      fontWeight: 'bold',
      padding: '0.75em 1em',
    },
    label: {
      lineHeight: 1,
    },
    contained: {
      boxShadow: '0 0 transparent',
    },
    sizeSmall: {
      fontWeight: 'normal',
      padding: '0.75em 1em',
    },
    containedPrimary: {
      color: 'white',
    },
  },
  MuiBadge: {
    colorPrimary: {
      color: palette.secondary.main,
    },
    colorSecondary: {
      color: 'black',
    },
    badge: {
      color: palette.secondary.main,
      fontWeight: 'bold',
    },
  },
  MuiTooltip: {
    tooltip: {
      fontSize: '0.9rem',
      fontWeight: 'bold',
      backgroundColor: palette.primary.main,
    },
  },
  MuiTableCell: {
    root: {
      padding: '0.25rem',
    },
  },
  MuiDialogContent: {
    root: {
      textAlign: 'center',
      whiteSpace: 'pre-line',
      fontWeight: 'bold',
      padding: '1rem 2rem',
    },
  },
  MuiCssBaseline: {
    '@global': {
      html: {
        fontSize: 14,
        [breakpoints.down('md')]: {
          fontSize: 13,
        },
        [breakpoints.down('sm')]: {
          fontSize: 12,
        },
      },
      body: {
        fontFamily: typography(palette).fontFamily,
        fontSize: '1rem',
        overflow: 'hidden',
        width: '100vw',
        ...mixins(palette).vhHeight(100),
        //color: palette.secondary.main,
      },
      button: {
        fontFamily: typography(palette).fontFamily,
      },
      p: {
        letterSpacing: 0,
      },
      '.fullscreen-enabled': {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        flexDirection: 'column',
      },
      '*::-webkit-scrollbar': {
        width: sizes.scrollBar.width,
      },
      '*::-webkit-scrollbar-track': {
        background: palette.primary.dark,
      },
      '*::-webkit-scrollbar-thumb': {
        background: palette.primary.main,
      },
      '*::-webkit-scrollbar-thumb:hover': {
        background: palette.primary.light,
      },
    },
  },
  MuiTablePagination: {
    root: {
      overflow: 'initial',
    },
  },
});

export const baseProps = {
  MuiButton: {
    varant: 'contained',
  },
};

export const baseThemeSettings = {
  breakpoints,
  sizes,
  sidePanel: {
    titleHeight: 3, // rem units
    gutter: '1.5rem',
  },
};
