import Button from '@material-ui/core/Button';
import makeStyles from '@material-ui/core/styles/makeStyles';
import Switch from '@material-ui/core/Switch';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import { questionsCsv, reportingTesting, userAnalytics, vodAnalytics } from 'api/routes/reporting';
import { createExternalTestToken } from 'api/routes/user';
import axios from 'axios';
import ThemeSwitcher from 'components/themes/ThemeSwitcher';
import config from 'config';
import routes from 'constants/routes';
import { getFakeUser } from 'utils/fakeUsers';
import fileDownload from 'js-file-download';
import has from 'lodash/has';
import moment from 'moment';
import { useState } from 'react';
import { useHistory } from 'react-router-dom';
import TimeLogin from 'components/TimeLogin';

const accountIdToUse = 'mosaic-testing';
const dateToUse = moment().valueOf();

const useStyles = makeStyles((theme) => ({
  main: {
    marginTop: '1rem',
    marginLeft: '1rem',
    ...theme.mixins.flexVerticalList(),
  },
  container: {
    ...theme.mixins.flexHorizontalList(),
  },
}));

const defaultStageIds = {
  confqa: '67890ab3-2603-45e8-bbe8-6b1d98c933fd',
  demo: '62345ab3-2603-45e8-bbe8-6b1d98c933fd',
  beta: '62345ab3-2603-45e8-bbe8-232323232323',
  develop: '96222ab3-2603-45e8-bbe8-6b1d98c933fd',
  proddemo: '87222ab3-2603-45e8-bbe8-6b1d98c933fd',
  conference: '68392ab3-2603-45e8-bbe8-6b1d98c933fd',
};

const fakeAccountId = 'mosaic-testing';
const fakeTitle = 'Meeting Analytics';
const getToken = async (
  role,
  eventId,
  title,
  isForRecordingUser = false,
  type,
  customExternalUserAccountId
) => {
  const { firstName, lastName, email } = getFakeUser();
  const { token } = await createExternalTestToken(
    firstName,
    lastName,
    role,
    email,
    eventId,
    title,
    isForRecordingUser,
    type,
    customExternalUserAccountId
  );
  return token;
};

export default function Meeting() {
  const history = useHistory();
  const classes = useStyles();
  const [customExternalUserAccountId, setCustomExternalUserAccountId] = useState(null);

  let eventId = defaultStageIds[config.STAGE];
  if (config.IS_DEVELOPMENT) eventId = '123458906023908213409';
  if (config.IS_DEVELOPMENT && has(process.env, 'REACT_APP_MEET_HOME_EVENT_ID'))
    eventId = process.env.REACT_APP_MEET_HOME_EVENT_ID;

  const fakeLogin = async (role, type, id) => {
    const token = await getToken(
      role,
      id ? id : eventId,
      fakeTitle,
      false,
      type,
      customExternalUserAccountId || null
    );
    history.push(`${routes.SSO.path}?token=${token}&accountid=${fakeAccountId}`);
  };

  const handleViewManagement = async () => {
    history.push(routes.MANAGEMENT.path);
  };

  const handleQuestionsCsv = async () => {
    const { csv } = await questionsCsv(accountIdToUse, dateToUse);
    fileDownload(csv, 'questions.csv');
  };

  const getUserAnaltyics = async () => {
    const { csv } = await userAnalytics(accountIdToUse, dateToUse);
    fileDownload(csv, 'user-analytics.csv');
  };

  const handleVodCsv = async () => {
    const { csv } = await vodAnalytics(accountIdToUse, dateToUse);
    fileDownload(csv, 'vod-analytics.csv');
  };
  const handleTestDownload = async () => {
    await axios.get('https://pi1op6eq0f.execute-api.us-east-1.amazonaws.com/dev/');
  };
  const handleTest = async () => {
    const accountId = 'mosaic-testing';
    const startTimestamp = moment().subtract(100, 'days').valueOf();
    const endTimestamp = moment().valueOf();
    const dataType = 'questions';
    const resp = await reportingTesting(accountId, dataType, startTimestamp, endTimestamp);
    console.log(resp);
  };

  const meetingButtons = [
    { n: 'Settings', f: () => fakeLogin('admin', 'settingsMeeting') },
    { n: 'Presenter', f: () => fakeLogin('presenter', 'meeting') },
    { n: 'Attendee', f: () => fakeLogin('attendee', 'meeting') },
    { n: 'Presenter Choose', f: () => fakeLogin('presenter', 'meeting:choose') },
    { n: 'Attendee Choose', f: () => fakeLogin('attendee', 'meeting:choose') },
    { n: 'Presenter Services', f: () => fakeLogin('presenter', 'meeting:services') },
    { n: 'Attendee Services', f: () => fakeLogin('attendee', 'meeting:services') },
  ];
  const vodButtons = [
    { n: 'Settings', f: () => fakeLogin('admin', 'settingsVod', '62345ab3-2603-45e8') },
    { n: 'Vod', f: () => fakeLogin('attendee', 'vod', '62345ab3-2603-45e8') },
  ];
  const additionalButtons = [
    { n: 'Admin Management', f: handleViewManagement },
    { n: 'Run Redis Cron', f: handleTestDownload },
  ];
  const downloadButtons = [
    { n: 'Questions', f: handleQuestionsCsv },
    { n: 'User Analytics', f: getUserAnaltyics },
    { n: 'Vod Data', f: handleVodCsv },
    { n: 'Reporting Test', f: handleTest },
  ];

  const renderButtons = (groupName, buttons, size = 'medium') => (
    <div>
      <Typography>{groupName}</Typography>
      <div className={classes.container}>
        {buttons.map(({ n, f }) => (
          <Button
            className={classes.button}
            key={`button-${groupName}-${n}`}
            variant="contained"
            size={size}
            onClick={f}
            id="settings"
          >
            {n}
          </Button>
        ))}
      </div>
    </div>
  );

  return (
    <div className={classes.main}>
      <div>
        <Typography variant="body1">Custom External User Id</Typography>
      </div>

      <div>
        <Switch
          checked={customExternalUserAccountId !== null}
          color="primary"
          onChange={({ target }) => {
            setCustomExternalUserAccountId(target.checked ? '' : null);
          }}
        />
        {customExternalUserAccountId !== null && (
          <TextField
            onChange={(e) => {
              setCustomExternalUserAccountId(e.target.value);
            }}
            value={customExternalUserAccountId || ''}
          />
        )}
      </div>

      <TimeLogin fakeLogin={fakeLogin} />

      {renderButtons('Meeting', meetingButtons, 'small')}
      {renderButtons('Vod', vodButtons, 'small')}
      {renderButtons('Additional', additionalButtons, 'small')}
      {renderButtons('Download CSVs', downloadButtons, 'small')}
      <div style={{ maxWidth: '15rem' }}>
        <ThemeSwitcher />
      </div>
    </div>
  );
}
