import { basePost } from 'api/apiDetour';
const post = async ({ endpoint, data = {}, isSigned = false }) => {
  let resp = await basePost(endpoint, data, 'session-resources', isSigned);
  return resp;
};

export const switchInput = async (resourceId, showVideo, inputName, videoKey, shouldLoop) => {
  let resp = await post({
    endpoint: 'switchinput',
    data: { videoKey, shouldLoop, resourceId, showVideo, inputName },
  });
  return resp;
};

export const getSessionResource = async (resourceId) => {
  let resp = await post({ endpoint: 'get', data: { resourceId } });
  return resp;
};

export const setNextInput = async (nextInput, resourceId) => {
  let resp = await post({ endpoint: 'setnextinput', data: { nextInput, resourceId } });
  return resp;
};

export const getEc2Instances = async () => {
  let resp = await post({ endpoint: 'getec2instances' });
  return resp;
};

export const getIVSChannelsInfo = async () => {
  let resp = await post({ endpoint: 'getivschannels' });
  return resp;
};

export const deleteIVSChannel = async (channelId) => {
  let resp = await post({ endpoint: 'deleteivschannel', data: { channelId } });
  return resp;
};

export const launchrecordingTask = async () => {
  const resp = await post({ endpoint: 'launch-task', data: {} });
  return resp;
};

export const stoprecordingTask = async () => {
  const resp = await post({ endpoint: 'stop-task', data: {} });
  return resp;
};

export const launchStreamingTask = async () => {
  const resp = await post({ endpoint: 'launch-stream', data: {} });
  return resp;
};

export const stopStreamingTask = async () => {
  const resp = await post({ endpoint: 'stop-stream', data: {} });
  return resp;
};

export const addServer = async () => {
  const resp = await post({ endpoint: 'add-server', data: {} });
  return resp;
};

export const getS3VideoUrl = async (key) => {
  const resp = await post({
    endpoint: 'getvideourl',
    data: { key },
  });
  return resp;
};

export const putS3Video = async (key, contentType) => {
  const resp = await post({
    endpoint: 'upload-stream-mp4',
    data: { key, contentType },
  });
  return resp;
};

export const ecsInfo = async () => {
  const resp = await post({ endpoint: 'ecs-info' });
  return resp;
};

export const updateTime = async (currentTime, endTime) => {
  const resp = await post({ endpoint: 'update-time', data: { currentTime, endTime } });
  return resp;
};
