import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import IconButton from '@material-ui/core/IconButton';
import InputBase from '@material-ui/core/InputBase';
import { makeStyles } from '@material-ui/core/styles';
import InsertEmoticonIcon from '@material-ui/icons/InsertEmoticon';
import SendIcon from '@material-ui/icons/Send';
import { Picker } from 'emoji-mart';
import 'emoji-mart/css/emoji-mart.css';
import PropTypes from 'prop-types';
import { useState } from 'react';
import deepEqual from 'react-fast-compare';
import { useSelector } from 'react-redux';

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.light,
    padding: '1rem',
    paddingBottom: '0.5rem',
    margin: `0.5rem ${theme.sidePanel.gutter}`,
    ...theme.mixins.borderRadius(),
  },
  input: {
    color: theme.palette.secondary.main,
  },
  buttons: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  buttonHighlight: {
    padding: '2px',
    color: theme.palette.primary.main,
  },
  button: {
    padding: '2px',
  },
  hr: {
    margin: '0.5rem 0',
    height: '1px',
    backgroundColor: theme.palette.hr.monochrome,
  },
}));

ChatInput.propTypes = {
  send: PropTypes.func,
};

export default function ChatInput({ send }) {
  const classes = useStyles();
  const [inputText, setInputText] = useState('');
  const [showEmotiPicker, setShowEmotiPicker] = useState(false);

  const roster = useSelector((store) => store.chime.roster, deepEqual);
  const conversationId = useSelector((store) => store.sessionMessage.conversationId);

  const isLocked =
    conversationId !== 'all' &&
    !Object.values(roster).some((user) => user.userId === conversationId);

  const onSend = async () => {
    const message = inputText.trim();

    if (message.length > 0) {
      if (send) send(message);
      setInputText('');
    }
  };

  const inputOnChange = (event) => void setInputText(event.target.value);

  const inputOnKeyDown = async (event) => {
    if (event.keyCode === 13) {
      event.preventDefault();

      await onSend();
    }
  };

  return (
    <div className={classes.root}>
      <InputBase
        id="chat-input"
        fullWidth
        className={classes.input}
        value={inputText}
        disabled={isLocked}
        placeholder={isLocked ? 'User is offline.' : 'Enter message...'}
        data-testid="themsg"
        autoComplete="off"
        onChange={inputOnChange}
        onKeyDown={inputOnKeyDown}
      />
      <div className={classes.hr} />
      <div className={classes.buttons}>
        <IconButton onClick={() => setShowEmotiPicker(!showEmotiPicker)}>
          <InsertEmoticonIcon />
        </IconButton>

        <div style={{ flexGrow: 1 }} />

        <IconButton size="small" data-testid="send" onClick={onSend} id="chat-send">
          <SendIcon />
        </IconButton>
      </div>

      {showEmotiPicker && (
        <ClickAwayListener onClickAway={() => setShowEmotiPicker(false)}>
          <Picker
            style={{ position: 'absolute', bottom: 65, right: 60, zIndex: 5 }}
            emoji="point_up"
            onSelect={(emoji) => {
              setInputText(`${inputText}${emoji.native} `);
              setShowEmotiPicker(false);
            }}
          />
        </ClickAwayListener>
      )}
    </div>
  );
}
