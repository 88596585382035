import PropTypes from 'prop-types';
import Button from '@material-ui/core/Button';
import ButtonGroup from '@material-ui/core/ButtonGroup';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import CloseIcon from '@material-ui/icons/Close';
import IconButton from '@material-ui/core/IconButton';
import set from 'lodash/set';
const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
  },
  body: {
    padding: `.5rem 2rem 2rem 2rem`,

    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
  },
  iconButton: {
    '&:hover': {
      color: theme.palette.primary.main,
    },
  },
  topRow: {
    display: 'flex',
    flexGrow: 1,
    justifyContent: 'space-between',
    alignItems: 'flex-start',
    margin: '.5rem .5rem 0 .5rem',
  },
  filterGroup: {
    display: 'flex',
    flexGrow: 1,
    flexDirection: 'column',
    alignItems: 'flex-start',
    justifyContent: 'center',
    margin: '.5rem 0 .5rem 0',
  },
  buttonGroup: {},
}));

FilterBox.propTypes = {
  filters: PropTypes.object.isRequired,
  setFilters: PropTypes.func.isRequired,
  close: PropTypes.func.isRequired,
};
export default function FilterBox({ filters, setFilters, close }) {
  const classes = useStyles();
  const filtersArray = Object.entries(filters)
    .map(([key, { value, labels, text }]) => ({ key, value, labels, text }))
    .sort((a, b) => a.key.localeCompare(b.key, 'en', { ignorePunctuation: true }));

  const handleClick = (key, value) => {
    let newFilters = { ...filters };
    set(newFilters, `${key}.value`, value);
    setFilters(newFilters);
  };

  const reset = () => {
    let newFilters = { ...filters };
    Object.keys(filters).forEach((key) => {
      set(newFilters, `${key}.value`, null);
    });
    setFilters(newFilters);
  };

  return (
    <div className={classes.root}>
      <div className={classes.topRow}>
        <Button color="primary" variant="outlined" onClick={reset}>
          Reset
        </Button>
        <IconButton className={classes.iconButton} onClick={close}>
          <CloseIcon />
        </IconButton>
      </div>
      <div className={classes.body}>
        {filtersArray.map(({ key, value, labels, text }) => (
          <div className={classes.filterGroup} key={key}>
            <Typography>{text}</Typography>
            <ButtonGroup
              className={classes.buttonGroup}
              color="primary"
              aria-label="text primary button group"
            >
              {[false, true, null].map((v) => (
                <Button
                  size="small"
                  key={String(v)}
                  onClick={() => handleClick(key, v)}
                  variant={value === v ? 'contained' : 'outlined'}
                >
                  {labels[String(v)]}
                </Button>
              ))}
            </ButtonGroup>
          </div>
        ))}
      </div>
    </div>
  );
}
