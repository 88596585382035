export const features = ['polling', 'questions', 'chat', 'presenterchat', 'recordings', 'queue'];

export const featureNames = {
  chat: 'Chat',
  presenterchat: 'Presenters Chat',
  questions: 'Question & Answer',
  polling: 'Polling',
  recordings: 'Recordings',
  queue: 'The Queue',
};

export const featureForType = {
  meeting: ['chat', 'presenterchat', 'questions', 'polling', 'recordings'],
  broadcast: ['chat', 'presenterchat', 'questions', 'polling', 'queue'],
  settingsMeeting: ['chat', 'presenterchat', 'questions', 'polling', 'recordings'],
  settings: ['chat', 'presenterchat', 'questions', 'polling', 'queue'],
  settingsBroadcast: ['chat', 'presenterchat', 'questions', 'polling', 'queue'],
  default: ['chat', 'presenterchat', 'questions', 'polling', 'queue', 'recordings'],
};

export const featureDescriptions = {
  chat:
    'Select if you want participants to be able to contribute to a chat. Note that in a LiveBroadcast session, private user-to-user chats are not available to participants.',
  presenterchat: 'Select if you want a private chat channel among your presenters.',
  questions:
    'Select if you want participants to be able to post questions in the session that may be upvoted by others, and responded to by presenters either verbally or in writing. Only use Q&A if presenters are answering questions. Otherwise, enable Chat.',
  polling:
    'Select if you are going to use real-time polls in your session. You can pre-define polls using the Polling tab.',
  recordings:
    'Select if you want to give presenters the option to record a LiveMeeting session. Recordings are automatically created for a LiveBroadcast session.',
  queue:
    'Select if you would like to allow attendees to be able to queue to ask questions on stage during a broadcast.',
};

export const featureTypes = {};
for (const k in featureNames) {
  featureTypes[featureNames[k]] = k;
}

export const getFeatureName = (feature) => {
  if (typeof featureNames[feature] === 'function') {
    return featureNames[feature](Array.prototype.slice.call(arguments, 1)); //eslint-disable-line
  }

  return featureNames[feature] || feature;
};

export const getFeatureType = (featureName) => featureTypes[featureName] || featureName;
export const getFeatureDescription = (featureName) => featureDescriptions[featureName];

export default features;
