import { basePost } from 'api/apiDetour';
const post = async ({ endpoint, data = {}, isSigned = false }) => {
  let resp = await basePost(endpoint, data, 'vod-user-data', isSigned);
  return resp;
};

export const createUserData = async (vodId) => {
  let resp = await post({ endpoint: 'create', data: { vodId } });
  return resp;
};

export const updateUserData = async (vodId, timeStampUserId, seconds, seekPosition) => {
  let resp = await post({
    endpoint: 'update',
    data: { vodId, timeStampUserId, seconds, seekPosition },
  });
  return resp;
};

export const listUserData = async (vodId, filters) => {
  let resp = await post({ endpoint: 'list', data: { vodId, filters } });
  return resp;
};
