import PropTypes from 'prop-types';
import DefaultIcon from './DefaultIcon';
import { SettingsIcon as Icon } from 'svg';

SettingsIcon.propTypes = {
  hideIcon: PropTypes.bool.isRequired,
};

export default function SettingsIcon({ hideIcon }) {
  return (
    <>
      <DefaultIcon name="Settings" hideIcon={hideIcon} IconComp={Icon} />
    </>
  );
}
