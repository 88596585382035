import { click, openAndCloseSidebar, wait } from 'components/side-panel/components/testing/helpers';

export default async function ({ subType }) {
  switch (subType) {
    case 'askQuestion':
      await openAndCloseSidebar('stage-icon', async () => {
        click(`ask-question`);
      });
      while (!document.getElementById('queue-join-button')) {
        await wait(1000);
      }
      click('queue-join-button');
      break;
    default:
      console.log('No Subtype Found For ', subType, ' In Queue');
  }
}
