import { IconButton, ClickAwayListener, Typography, LinearProgress } from '@material-ui/core';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';
import WarningIcon from '@material-ui/icons/Warning';
import SwapVertIcon from '@material-ui/icons/SwapVert';
import Chip from 'components/Chip';
import VideoTile from 'components/VideoTile';
import PropTypes from 'prop-types';
import { useEffect, useMemo, useState, useRef } from 'react';
import { useSelector } from 'react-redux';
import VideoPreviewBox from 'components/VideoPreviewBox';
import VideoStatus from 'enums/VideoStatus';
import { setUserInGrid } from 'api/routes/session';
import Typogrphy from '@material-ui/core/Typography';
import useStyles from './GridView.style.js';
import {
  MAX_COL,
  MAX_ROW,
  TOTAL_CELLS,
  CONTENT_SIZE_COLS,
  CONTENT_SIZE_ROWS,
  getBaseCellSize,
} from './GridView.size.js';
import deepEqual from 'react-fast-compare';
import { useLatestPendingRequest } from 'hooks/usePendingRequests';
import { useGridUsers, getGridUsersHash } from 'hooks/useGridUsers';
import { addUserToGridAction } from 'store/session/sessionActions';
import { ensureVideoAction, reconnectingAction } from 'store/chime/chimeActions';
import { useDispatch } from 'react-redux';
import { showGenericErrorNotification } from 'utils/utilMethods';

export default function GridView({ children, isRecordingUser }) {
  const dispatch = useDispatch();
  const interval = useRef();
  const tileMap = useSelector((store) => store.chime.tileMap, deepEqual);
  const videoStatus = useSelector((store) => store.chime.videoStatus);
  const contentTile = useSelector((store) => store.chime.contentTile);
  const localTileId = useSelector((store) => store.chime.localTileId);
  const localUserIsSharing = useSelector(
    (store) => store.chime.contentSharerId === store.user.userId
  );
  const roster = useSelector((store) => store.chime.roster, deepEqual);
  const userId = useSelector((store) => store.user.userId);
  const role = useSelector((store) => store.user.role);
  const isSupportUser = useSelector((store) => store.user.isSupportUser);
  const active = useSelector((store) => store.activeSpeaker.active);
  const selectSpeakerStore = useSelector((store) => store.session.selectSpeaker);
  const selectSpeakerPending = useLatestPendingRequest('selectSpeaker');
  const selectSpeaker = selectSpeakerPending
    ? selectSpeakerPending.data.userId
    : selectSpeakerStore;
  const gridUsers = useGridUsers();
  const gridUsersHash = getGridUsersHash(gridUsers); // Need this for a useEffect dependency, can't use objects.
  const status = useSelector((store) => store.session.status);
  const reconnecting = useSelector((store) => store.chime.reconnecting);

  const [items, setItems] = useState([]);
  const [stickyItems, setStickyItems] = useState([]);
  const [paginatedCells, setPaginatedCells] = useState(0);
  const [pageOffset, setPageOffset] = useState(0);
  const [maxPageOffset, setMaxPageOffset] = useState(0);
  const [sortMode, setSortMode] = useState(2);
  const [sortModeOpen, setSortModeOpen] = useState(false);

  const hasContent = !!contentTile?.id;
  const hasSelectSpeaker = selectSpeaker && gridUsers[selectSpeaker];
  const showWarning =
    role === 'presenter' && videoStatus === VideoStatus.Enabled && !gridUsers[userId];
  const classes = useStyles({
    showWarning: showWarning,
    hasContent,
  });
  const sortModes = [
    {
      name: 'A-Z',
      sort: (a, b) => {
        const aName = a.name || a.id || '';
        const bName = b.name || b.id || '';
        return aName.localeCompare(bName, 'en', { ignorePunctuation: true });
      },
    },
    {
      name: 'Z-A',
      sort: (a, b) => {
        const aName = a.name || a.id || '';
        const bName = b.name || b.id || '';
        return bName.localeCompare(aName, 'en', { ignorePunctuation: true });
      },
    },
    {
      name: 'Order of Arrival',
      sort: (a, b) => {
        const aTime = parseInt(gridUsers[a.id]) || 0;
        const bTime = parseInt(gridUsers[b.id]) || 0;
        if (aTime === bTime) return sortModes[0].sort(a, b);

        return aTime - bTime;
      },
    },
  ];

  useEffect(() => {
    if (reconnecting) {
      interval.current = setInterval(() => {
        dispatch(reconnectingAction());
      }, 5000);
    }

    return () => {
      clearInterval(interval.current);
    };
  }, [reconnecting]);

  // On first join, automatically add ourselves to the grid if we're a presenter.
  useEffect(() => {
    if (
      role === 'presenter' &&
      !isSupportUser &&
      !gridUsers[userId] &&
      !['CREATING', 'LIVE'].includes(status)
    ) {
      const timestamp = Date.now();
      setUserInGrid(userId, true, {
        retries: 2,
        onSuccess() {
          dispatch(addUserToGridAction({ userId, timestamp }));
          //since the timing of the user being grid was weird this ensuire that if the users video is enabled it will show in chime
          dispatch(ensureVideoAction());
        },
        onError(error, reason, retriesLeft) {
          if (retriesLeft <= 0) {
            showGenericErrorNotification('adding to stage');
          }
        },
      });
    }
  }, [role]);

  // Sets up items and stickyItems.
  useEffect(() => {
    const newItems = [];
    const newStickyItems = [];
    let rows, cols, rosterUserId;

    if (hasContent) {
      newStickyItems.push({
        tileId: contentTile.tileId,
        isContent: true,
        sticky: true,
        localUserIsSharing,
      });
    }

    for (rosterUserId in roster) {
      const isLocal = rosterUserId === userId;
      const rosterUser = roster[rosterUserId];

      // Don't ever add these items.
      if (!rosterUser || rosterUser.isRecordingUser) continue;
      if (!gridUsers[rosterUserId]) continue;

      const item = {
        id: rosterUserId,
        name: rosterUser.name,
        role: rosterUser.role,
        handIsRaised: rosterUser.handIsRaised,
        muted: rosterUser.muted,
        isLocal,
        isUser: true,
        sticky: selectSpeaker === rosterUserId,
        attendeeId: rosterUser.attendeeId,
      };

      if (item.sticky) {
        newStickyItems.push(item);
      } else {
        newItems.push(item);
      }
    }

    // Set size of paginated user items.
    const [baseRows, baseCols] = getBaseCellSize(
      newStickyItems.length + newItems.length,
      hasSelectSpeaker,
      hasContent
    );
    const process = (item) => {
      if (item.isContent) {
        rows = CONTENT_SIZE_ROWS;
        cols = CONTENT_SIZE_COLS;
      } else if (item.id === selectSpeaker) {
        const [ssRows, ssCols] = getBaseCellSize(
          newStickyItems.length + newItems.length,
          hasSelectSpeaker,
          hasContent,
          true
        );
        rows = ssRows;
        cols = ssCols;
      } else {
        rows = baseRows;
        cols = baseCols;
      }

      item.rows = rows;
      item.cols = cols;
      item.cells = rows * cols;
    };
    newStickyItems.forEach(process);
    newItems.forEach(process);

    // Keep cells sorted.
    const itemSort = (a, b) => {
      if (a.isContent != b.isContent) return (b.isContent ? 1 : 0) - (a.isContent ? 1 : 0);
      if (a.cells !== b.cells) return a.cells - b.cells;
      if (a.role !== b.role && a.role && b.role) return a.role - b.role; // [p]resenter before [a]ttendee

      return sortModes[sortMode].sort(a, b);
    };
    newItems.sort(itemSort);
    newStickyItems.sort(itemSort);

    // If content sharing and 0 users then make it full screen
    if (
      hasContent &&
      newStickyItems.length === 1 &&
      newItems.length === 0 &&
      newStickyItems[0].isContent
    ) {
      newStickyItems[0].rows = MAX_ROW;
      newStickyItems[0].cols = MAX_COL;
      newStickyItems[0].cells = MAX_ROW * MAX_COL;
    }

    // Calculate paginated cells available real-estate.
    let stickyCells = 0;
    for (let item of newStickyItems) {
      stickyCells += item.cells;
    }
    setPaginatedCells(TOTAL_CELLS - stickyCells);

    setItems(newItems);
    setStickyItems(newStickyItems);
  }, [
    roster,
    /*tileMap,*/ userId,
    /*videoStatus,*/ isRecordingUser,
    hasContent,
    localUserIsSharing,
    hasSelectSpeaker,
    gridUsersHash,
    selectSpeaker,
    sortMode,
  ]); // eslint-disable-line react-hooks/exhaustive-deps

  // Calculate maximum offset by seeing how many trailing items can fit on one screen.
  useEffect(() => {
    let max = 0,
      renderedPaginatedCells = 0;

    let item;
    for (let i = items.length - 1; i >= 0; --i) {
      item = items[i];
      if (renderedPaginatedCells + item.cells > paginatedCells) break;

      renderedPaginatedCells += item.cells;
      max = i;
    }
    setMaxPageOffset(max);

    // Clamp down page offset.
    if (pageOffset > max) {
      setPageOffset(max);
    }
  }, [items, paginatedCells, selectSpeaker, sortMode]);

  const itemsToRender = useMemo(() => {
    const toRender = [...stickyItems];

    let cellsRemaining = paginatedCells;
    for (let i = pageOffset; i < items.length + pageOffset; ++i) {
      let item = items[i % items.length];

      if (cellsRemaining >= item.cells) {
        cellsRemaining -= item.cells;
        toRender.push(item);
      }
    }

    return toRender;
  }, [items, stickyItems, pageOffset, paginatedCells, sortMode]);

  const renderItem = (item) => {
    return (
      <div
        key={item.id}
        className={classes.cell}
        data-span-row={item.rows}
        data-span-column={item.cols}
      >
        {item.localUserIsSharing ? (
          <div className={classes.currentlyPresenting}>
            <Typogrphy className={classes.currentlyPresentingText} align="center" variant="h4">
              You are currently sharing
            </Typogrphy>
          </div>
        ) : (
          <VideoTile
            videoTileId={item.tileId ?? tileMap[item.id]?.tileId}
            videoUserId={item.id}
            isSelectSpeaker={selectSpeaker === item.id}
            isActive={active[item.id]}
            isPaused={item.tileId ?? tileMap[item.id]?.isPaused}
            isContent={item.isContent}
          >
            {item.name && (
              <Chip
                isLocal={item.isLocal}
                userId={item.id}
                attendeeId={item.attendeeId}
                isMuted={item.muted}
                hasVideo={
                  item.isLocal
                    ? videoStatus === VideoStatus.Disabled ||
                      [null, undefined].includes(localTileId)
                    : item.tileId ?? tileMap[item.id]?.tileId === undefined
                }
                name={item.name}
                handRaised={item.handIsRaised}
                isPresenter={item.role === 'presenter'}
              />
            )}
          </VideoTile>
        )}
      </div>
    );
  };

  const onClickBack = () => void setPageOffset(pageOffset - 1);
  const onClickForward = () => void setPageOffset(pageOffset + 1);

  const onClickToggleSortModeOpen = () => void setSortModeOpen(!sortModeOpen);
  const onChangeSortMode = (event) => {
    setSortMode(parseInt(event.target.getAttribute('value')));
    setSortModeOpen(false);
  };

  return (
    <>
      {reconnecting && (
        <div className={classes.reconnecting}>
          <Typography variant="h4">Reconnecting...</Typography>
          <div style={{ width: '20rem', padding: '2rem' }}>
            <LinearProgress />
          </div>
        </div>
      )}
      {showWarning && (
        <div className={classes.notInStreamWarning}>
          <WarningIcon />
          Notice: Your attendees cannot see your video feed.
          <WarningIcon />
        </div>
      )}
      <div
        className={classes.gridView}
        data-empty={itemsToRender.length > 0 ? 0 : 1}
        data-has-content={hasContent ? 1 : 0}
      >
        {itemsToRender.map((item) => renderItem(item))}

        {children}

        {!isRecordingUser && (
          <div className="sortMode" data-open={sortModeOpen ? 1 : 0}>
            <div onClick={onClickToggleSortModeOpen}>
              Sort <SwapVertIcon />
            </div>
            {sortModeOpen && (
              <ClickAwayListener onClickAway={onClickToggleSortModeOpen}>
                <div className="sortSelection">
                  {sortModes.map((mode, modeIdx) => (
                    <div
                      key={modeIdx}
                      value={modeIdx}
                      data-selected={sortMode == modeIdx ? 1 : 0}
                      onClick={onChangeSortMode}
                    >
                      {mode.name}
                    </div>
                  ))}
                </div>
              </ClickAwayListener>
            )}
          </div>
        )}

        {(pageOffset > 0 || pageOffset < maxPageOffset) && (
          <div className={classes.pageControl}>
            <IconButton onClick={onClickBack} disabled={pageOffset <= 0}>
              <ArrowBackIcon />
            </IconButton>
            <div style={{ flexGrow: 1 }} />
            <IconButton onClick={onClickForward} disabled={pageOffset >= maxPageOffset}>
              <ArrowForwardIcon />
            </IconButton>
          </div>
        )}
        {videoStatus === VideoStatus.Enabled && !gridUsers[userId] && <VideoPreviewBox />}
      </div>
    </>
  );
}

GridView.propTypes = {
  children: PropTypes.node,
  isRecordingUser: PropTypes.bool,
};
