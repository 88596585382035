import { basePost } from 'api/apiDetour';
const post = async ({ endpoint, data = {}, isSigned = false }) => {
  let resp = await basePost(endpoint, data, 'account', isSigned);
  return resp;
};

export const getAccounts = async () => {
  let resp = await post({ endpoint: 'list' });
  return resp;
};

export const createAccount = async (accountName) => {
  let resp = await post({ endpoint: 'create', data: { accountName } });
  return resp;
};

export const deleteAccount = async (accountId) => {
  let resp = await post({ endpoint: 'delete', data: { accountId } });
  return resp;
};
