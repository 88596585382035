import { createReducer } from '@reduxjs/toolkit';
import { countKeys, historyKeys } from 'constants/analytics';
import cloneDeep from 'lodash/cloneDeep';
import get from 'lodash/get';
import last from 'lodash/last';
import set from 'lodash/set';
import updateWith from 'lodash/updateWith';
import { buildActionStatus, standardActions } from 'store/utils';
import * as aa from './analyticsActions';
import {
  defaultAnalyticsObject,
  defaultCountObject,
  defaultHistoryObject,
  defaultTotals,
} from './analyticsHelpers';

const initialState = {
  actionStatus: buildActionStatus(aa),
  analytics: {},
  sessionData: [],
  totals: defaultTotals(),
};

const analyticsReducer = createReducer(initialState, {
  ...standardActions(aa),
  [aa.addSessionDataAction]: (state, { payload: { sessionData } }) => {
    state.sessionData = sessionData;
  },
  [aa.setUserSessionAnalyticsAction]: (state, { payload: { sessionData } }) => {
    let analytics = cloneDeep(state.analytics);
    let totals = cloneDeep(state.totals);
    for (const userData of sessionData) {
      set(analytics, `${userData.user.userId}.user`, userData.user || {});
      for (const historyKey of historyKeys) {
        let totalTime = 0;
        let previousHistoryType = null;
        let previousTimestamp = null;
        for (const historyItem of userData[historyKey]) {
          const [historyType, timestamp] = historyItem.split('#');
          if (previousHistoryType && previousHistoryType === 'START' && historyType === 'STOP') {
            const timeDiff = parseInt(timestamp) - previousTimestamp;
            totalTime += timeDiff;
            updateWith(totals, historyKey, (current) => current + timeDiff);
          }
          previousHistoryType = historyType;
          previousTimestamp = parseInt(timestamp);
        }
        set(analytics, `${userData.userId}.${historyKey}`, {
          totalTime,
          history: userData[historyKey],
          current: userData[historyKey].length
            ? last(userData[historyKey]).startsWith('START')
            : false,
        });
      }
      for (const countKey of countKeys) {
        updateWith(totals, countKey, (current) => current + userData[countKey].length);
        set(analytics, `${userData.userId}.${countKey}`, {
          history: userData[countKey],
          count: userData[countKey].length,
        });
      }
    }
    return { ...state, analytics, totals };
  },
  [aa.addAnalyticUserAction]: (state, { payload: { user } }) => {
    const userAnalytic = get(state.analytics, `${user.userId}`, defaultAnalyticsObject());
    set(state.analytics, `${user.userId}`, { ...userAnalytic, user });
  },
  [aa.updateAnalyticsCountObjectAction]: (
    state,
    { payload: { analyticsKey, userId, timestamp } }
  ) => {
    let { count, history } = get(
      state.analytics,
      `${userId}.${analyticsKey}`,
      defaultCountObject()
    );
    history.push(timestamp);
    count += 1;
    updateWith(state.totals, `${analyticsKey}`, (total) => total + 1);
    set(state.analytics, `${userId}.${analyticsKey}`, { count, history });
  },
  [aa.updateAnalyticsHistoryObjectAction]: (
    state,
    { payload: { analyticsKey, historyType, userId, timestamp } }
  ) => {
    if (!['START', 'STOP'].includes(historyType)) return;

    let analytics = state.analytics;
    let totals = state.totals;

    let { history, current, totalTime } = get(
      analytics,
      `${userId}.${analyticsKey}`,
      defaultHistoryObject()
    );

    if (history.length) {
      let [oldType, oldTime] = last(history).split('#');
      if (historyType === 'STOP' && oldType === 'START') {
        let timeDiff = parseInt(timestamp) - parseInt(oldTime);
        totalTime += timeDiff;
        updateWith(totals, `${analyticsKey}`, (total) => total + timeDiff);
      }
    }
    current = historyType === 'START';
    history.push(`${historyType}#${timestamp}`);
    set(analytics, `${userId}.${analyticsKey}`, { current, history, totalTime });
  },
});

export default analyticsReducer;
