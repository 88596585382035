import { createAction as ca } from '@reduxjs/toolkit';
export const askForHelpAction = ca('event/askForHelpAction');
export const blockUserAction = ca('event/blockUserAction');
export const errorAction = ca('event/errorAction');
export const initAction = ca('event/initAction');
export const resetStateAction = ca('event/resetStateAction');
export const setBlockedUsersAction = ca('event/setBlockedUsersAction');
export const setEventAction = ca('event/setEventAction');
export const setPermissionAction = ca('event/setPermissionAction');
export const updatePermissionsAction = ca('event/updatePermissionsAction');
export const setVodsAction = ca('event/setVodsAction');
export const setVodAction = ca('event/setVodAction');
export const setTypeAction = ca('event/setTypeAction');
export const successAction = ca('event/successAction');
export const updateVodAction = ca('event/updateVodAction');
export const sendMeetingEventsAction = ca('event/sendMeetingEventsAction');
export const collectMeetingEventsAction = ca('event/collectMeetingEventsAction');
export const setMeetingEventsAction = ca('event/setMeetingEventsAction');
export const resetMeetingEventsAction = ca('event/resetMeetingEventsAction');
export const setVideosAction = ca('event/setVideosAction');
export const setFeatureDisabledAction = ca('event/setFeatureDisabledAction');
export const setFeaturesDisabledAction = ca('event/setFeaturesDisabledAction');
