import {
  clickSelector,
  // openAndCloseSidebar,
  // typeInField,
  wait,
} from 'components/side-panel/components/testing/helpers';

export default async function ({ subType, payload }) {
  switch (subType) {
    case 'spotlightUser':
      clickSelector(`#user-spotlight-button-${payload.userId}[data-selected="0"]`);
      await wait(200);
      break;
    case 'removeSpotlight':
      clickSelector(`#user-spotlight-button-${payload.userId}[data-selected="1"]`);
      await wait(200);
      break;
    default:
      console.log('No Subtype Found For ', subType, ' In Chat', { payload });
  }
}
