import LoadingPage from 'components/LoadingPage';
import { useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { initializeMeetingRouteAction } from 'store/user/userActions';
import BroadcastAttendeeUserView from './BroadcastAttendeeUser';
import MeetingUserView from './MeetingUser';

const Index = () => {
  const audioElement = useRef(null);
  const dispatch = useDispatch();
  const role = useSelector((store) => store.user.role);
  const isBroadcast = useSelector((store) => store.app.appType === 'broadcast');
  const initLoading = useSelector(
    (store) => store.user.actionStatus.initializeMeetingRouteFlowSaga?.loading
  );

  const location = useLocation();
  useEffect(() => {
    dispatch(
      initializeMeetingRouteAction({
        audioRef: audioElement.current,
        isBroadcast: location.pathname.includes('broadcast'),
      })
    );
  }, []); // eslint-disable-line

  return (
    <>
      {initLoading ? (
        <LoadingPage>Loading app...</LoadingPage>
      ) : isBroadcast && role === 'attendee' ? (
        <BroadcastAttendeeUserView />
      ) : (
        <MeetingUserView />
      )}
      <audio ref={audioElement} style={{ display: 'none' }} />
    </>
  );
};

export default Index;
