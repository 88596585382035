import PropTypes from 'prop-types';
import Base from '../components/Base';
import moment from 'moment';
import startCase from 'lodash/startCase';
import { deleteSessions } from 'api/routes/session';
Sessions.propTypes = {
  data: PropTypes.array.isRequired,
  refresh: PropTypes.func.isRequired,
};
export default function Sessions({ data, refresh }) {
  const columns = [
    {
      name: 'sessionId',
      label: 'Session Id',
      options: {
        filter: true,
        sort: true,
        searchable: true,
      },
    },
    {
      name: 'eventId',
      label: 'Event Id',
      options: {
        display: true,
        filter: true,
        sort: true,
        searchable: true,
      },
    },
    {
      name: 'eventName',
      label: 'Event',
      options: {
        filter: true,
        sort: true,
        searchable: true,
      },
    },
    {
      name: 'type',
      label: 'Type',
      options: {
        filter: true,
        sort: true,
        searchable: true,
        customBodyRender: (value) => startCase(value),
      },
    },
    {
      name: 'accountId',
      label: 'Account Id',
      options: {
        display: false,
        filter: true,
        sort: true,
        searchable: true,
      },
    },
    {
      name: 'accountName',
      label: 'Account',
      options: {
        display: true,
        filter: true,
        sort: true,
        searchable: true,
      },
    },
    {
      name: 'createdAt',
      label: 'Created',
      options: {
        filter: true,
        sort: true,
        searchable: false,
        customBodyRender: (value) => moment(value).local().format('MM/DD/YY h:mm a'),
      },
    },
    {
      name: 'startDate',
      label: 'Started',
      options: {
        filter: true,
        sort: true,
        searchable: false,
        customBodyRender: (value) =>
          value ? moment.utc(parseInt(value)).local().format('MM/DD/YY h:mm a') : '-',
      },
    },
    {
      name: 'endDate',
      label: 'Ended',
      options: {
        filter: true,
        sort: true,
        searchable: false,
        customBodyRender: (value) =>
          value ? moment.utc(parseInt(value)).local().format('MM/DD/YY h:mm a') : '-',
      },
    },

    {
      name: 'meetingStatus',
      label: 'Status',
      options: {
        filter: true,
        sort: true,
        searchable: true,
        customBodyRender: (value) => startCase(value ? value.toLowerCase() : ''),
      },
    },
    {
      name: 'isRecording',
      label: 'Recording Status',
      options: {
        filter: true,
        sort: true,
        searchable: true,
        customBodyRender: (value) => startCase(value ? value.toLowerCase() : ''),
      },
    },
  ];

  const options = {
    onRowsDelete: async (a) => {
      await deleteSessions(a.data.map(({ dataIndex }) => data[dataIndex]));
    },
    isRowSelectable: (idx, selected) =>
      !selected.data || selected.data.length < 5 || selected.lookup[idx],
  };

  return <Base name="Sessions" columns={columns} data={data} options={options} refresh={refresh} />;
}
