import makeStyles from '@material-ui/styles/makeStyles';
import BanList from 'components/side-panel/components/ban-list';
import StreamController from 'components/side-panel/components/broadcast-stream-controls';
import TheStage from 'components/side-panel/components/the-stage';
import Chat from 'components/side-panel/components/chat';
import Help from 'components/side-panel/components/help';
import LeaderBoard from 'components/side-panel/components/leaderboard';
import Permissions from 'components/side-panel/components/permissions';
import Polling from 'components/side-panel/components/polling';
import Questions from 'components/side-panel/components/questions';
import Roster from 'components/side-panel/components/roster';
import Settings from 'components/side-panel/components/settings';
import UserAnalytics from 'components/side-panel/components/user-analytics';
import config from 'config';
import { getFeatureName } from 'constants/features';
import PropTypes from 'prop-types';
import { lazy, Suspense } from 'react';
import deepEqual from 'react-fast-compare';
import { useSelector } from 'react-redux';

const Testing = lazy(() => {
  if (['develop', 'beta'].includes(config.STAGE) || config.IS_DEVELOPMENT) {
    return import('components/side-panel/components/testing');
  } else {
    return () => <div>This Page Is Not Allowed With The Current Environment Configuration</div>;
  }
});

const useStyles = makeStyles((theme) => ({
  sidePanelContent: (props) => ({
    display: 'flex',
    flexDirection: 'column',
    height: `calc(100% - ${theme.sidePanel.titleHeight}rem)`,
    overflowY: props.disableScroll ? 'initial' : 'auto',
    [theme.breakpoints.down('mobile')]: {
      height: '100%',
    },
  }),
}));

const noScroll = ['Leader Board'];

const SidePanelContent = ({ openStream, setOpenStream }) => {
  const popout = useSelector((store) => store.app.popout, deepEqual);
  const isBroadcastApp = useSelector((store) => store.app.appType === 'broadcast');
  const classes = useStyles({
    disableScroll: noScroll.includes(popout.name),
  });

  const rosterPanelName = isBroadcastApp ? 'Panelists' : 'Participants';

  return (
    <div className={classes.sidePanelContent}>
      {popout.name === rosterPanelName && <Roster />}
      {popout.name === getFeatureName('chat') && <Chat />}
      {popout.name === getFeatureName('presenterchat') && <Chat presentersMessage={true} />}
      {popout.name === getFeatureName('questions') && <Questions />}
      {popout.name === getFeatureName('polling') && <Polling />}
      {popout.name === 'Session Studio' && isBroadcastApp && (
        <StreamController setOpenStream={setOpenStream} openStream={openStream} />
      )}
      {popout.name === 'Blocked Users' && <BanList />}
      {popout.name === 'Settings' && <Settings />}
      {popout.name === 'Testing' && (
        <Suspense fallback={<div>Loading...</div>}>
          <Testing />
        </Suspense>
      )}
      {popout.name === 'Help Request' && <Help />}
      {popout.name === 'Permissions' && <Permissions />}
      {popout.name === 'User Analytics' && <UserAnalytics />}
      {popout.name === 'Leader Board' && <LeaderBoard />}
      {popout.name === 'Request To Join Stage' && <LeaderBoard />}
      {popout.name === 'The Queue' && <TheStage />}
    </div>
  );
};

SidePanelContent.propTypes = {
  setOpenStream: PropTypes.func,
  openStream: PropTypes.bool,
};

export default SidePanelContent;
