import { store } from 'App';
import axios from 'axios';
import appConfig from 'config';
import get from 'lodash/get';
import logger from 'logger';
import routes from 'constants/routes';
import {
  putRequestAction,
  removeRequestAction,
} from 'store/pending-requests/pendingRequestsActions';
import {
  getRefreshTokenCookie,
  getTokenCookie,
  setRefreshTokenCookie,
  setTokenCookie,
} from 'utils/token';

const { MEETING_API, SIGNED_API } = appConfig;
const exemptUrls = ['testing', 'auth'];

const getConfig = () => {
  const configObject = {};
  const token = getTokenCookie();
  const { event = {}, user = {}, session = {} } = store.getState();

  if (token) {
    configObject.headers = {
      Authorization: String(token),
      RefreshAuthorization: String(getRefreshTokenCookie()),
      StoreEventIdSessionIdUserIdRole: JSON.stringify({
        eventId: event.eventId || '',
        userId: user.userId || '',
        sessionId: session.sessionId || '',
        role: user.role || '',
      }),
    };
  }
  return configObject;
};

const defaultOptions = {
  isSigned: false,
  data: {},
};

function processNewTokens({ newToken = null, newRefreshToken = null }) {
  if (newToken) setTokenCookie(newToken);
  if (newRefreshToken) setRefreshTokenCookie(newRefreshToken);
}
export const baseGet = async (endpoint, baseEndpoint, isSigned = false) => {
  let url = isSigned ? `${SIGNED_API}/${endpoint}` : `${MEETING_API}/${baseEndpoint}/${endpoint}`;
  try {
    logger.debug(`REQUEST: /${endpoint}`);
    const res = await axios.get(url, getConfig());
    if (res && res.data && res.data.error) {
      logger.error(`API ERROR: /${url}`, { error: res.data.error });
    } else {
      let response = res.data || {};

      processNewTokens(response);

      logger.debug(`/${url}`, { response });
    }
    return res.data || {};
  } catch (e) {
    logger.error(`API Error: ${url}`, { error: e });
    throw Error(e);
  }
};

const checkForOtherLogin = (endpoint) => {
  if (exemptUrls.includes(endpoint)) return;
  const { event, user } = store.getState();
  const storeEventId = event.eventId;
  const storeRole = user.role;
  const storeUserId = user.userId;
  const tokenEventId = localStorage.getItem('eventId');
  const tokenUser = JSON.parse(localStorage.getItem('user'));

  if (
    (storeEventId && storeEventId !== tokenEventId) ||
    (storeRole && tokenUser.role !== storeRole) ||
    (storeUserId && tokenUser.userId !== storeUserId)
  ) {
    if (user.isRecordingUser) {
      console.log('Failed Token Check', {
        storeEventId,
        storeRole,
        storeUserId,
        tokenEventId,
        tokenUser,
      });
      window.location.href = user.reloadUrl;
      throw Error('Recording user lost storage');
    } else {
      window.location.href = `${routes.ERROR.path}/loginElsewhere`;
      //Dunno if this is necessary browser should be redirecting
      throw Error('Login on multiple tabs');
    }
  }
};

export const basePost = async (endpoint, data = {}, baseEndpoint, isSigned = false) => {
  const url = isSigned ? `${SIGNED_API}/${endpoint}` : `${MEETING_API}/${baseEndpoint}/${endpoint}`;
  try {
    logger.debug(`/${endpoint}`, { payload: data });
    checkForOtherLogin(endpoint);
    const response = get(await axios.post(url, data, getConfig()), 'data', {});
    const error = get(response, 'error', '');
    if (error === 'MultipleLoginError') {
      // store.dispatch(leaveRoomAction({ shouldEndSession: false, forceLogout: true }));
    } else if (error) {
      logger.error(`API ERROR: /${endpoint}`, { error });
    } else {
      processNewTokens(response);
      logger.debug(`/${endpoint}`, { response });
    }
    return response;
  } catch (e) {
    console.log('THE CAUGHT ERROR', e);
    console.log(e.message);
    logger.error(`API Error: ${url}`, { error: e });
    throw Error(e);
  }
};

let requestIdCounter = 1;
const promisedRequest = (requestType, endpoint, baseEndpoint, requestGroup, options) => {
  options = options ?? defaultOptions;
  const { isSigned, onSuccess, onError, onFinalize } = options;
  const data = typeof options.data === 'object' ? options.data : {};

  const url = isSigned ? `${SIGNED_API}/${endpoint}` : `${MEETING_API}/${baseEndpoint}/${endpoint}`;
  const requestPrintName = `${requestType.toUpperCase()} /${baseEndpoint}/${endpoint}`;
  checkForOtherLogin(endpoint);

  logger.debug(`Request started: ${requestPrintName}`, data);

  const config = getConfig();

  let makeRequest;

  makeRequest = (retryId) => {
    const requestId = options.requestId || ++requestIdCounter;
    let succeeded;

    const request = (requestType === 'post'
      ? axios.post(url, data, config)
      : axios.get(url, config)
    )
      .then((response) => {
        if (get(response, 'data.error', null) === 'MultipleLoginError') {
          // store.dispatch(leaveRoomAction({ shouldEndSession: false, forceLogout: true }));
        }
        processNewTokens(response.data);
        succeeded = response;
        if (onSuccess) onSuccess(response);
        logger.debug(`Request succeeded: ${requestPrintName}`, response);
      })
      .catch((error) => {
        succeeded = null;
        let reason;
        if (error.response) {
          // The request was made and the server responded with a status code that falls out of the range of 2xx
          reason = 'error';
          logger.error(`Request ${error.response.status} error from server: ${requestPrintName}`, {
            error: error.response.data?.error || 'No error specified',
          });
        } else if (error.request) {
          // The request was made but no response was received
          // `error.request` is an instance of XMLHttpRequest in the browser and an instance of http.ClientRequest in node.js
          reason = 'timeout';
          logger.error(`Request timed out: ${requestPrintName}`);
        } else {
          // Something happened in setting up the request that triggered an Error
          reason = 'failure';
          logger.error(`Request setup failed: ${requestPrintName}`, { error: error.message });
        }
        const retries = get(options, 'retries', 0);
        if (onError) onError(error, reason, retries);
        if (retries > 0) {
          options = { ...options, retries: retries - 1 };
          makeRequest(requestId);
        }
      })
      .then(() => {
        if (onFinalize) onFinalize(succeeded);
        store.dispatch(
          removeRequestAction({
            id: requestId,
            requestGroup,
          })
        );

        logger.debug(`Request finalized: ${requestPrintName}`, succeeded);
      });

    store.dispatch(
      putRequestAction({
        id: requestId,
        endpoint,
        baseEndpoint,
        requestType,
        requestGroup,
        request,
        data,
        options,
        retryId,
      })
    );
    return request;
  };
  return makeRequest();
};

export const promisedGet = (endpoint, baseEndpoint, requestGroup, options) =>
  promisedRequest('get', endpoint, baseEndpoint, requestGroup, options);
export const promisedPost = (endpoint, baseEndpoint, requestGroup, options) =>
  promisedRequest('post', endpoint, baseEndpoint, requestGroup, options);
