import React from 'react';
import { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import { useDispatch, useSelector } from 'react-redux';
import {
  chooseAudioInputDeviceAction,
  chooseAudioOutputDeviceAction,
  chooseVideoInputDeviceAction,
  chooseUpstreamVideoQualityAction,
} from 'store/chime/chimeActions';
import upstreamVideoQualities from 'constants/upstreamVideoQualities';
import PropTypes from 'prop-types';
import MicMonitor from 'components/devices/MicMonitor';
import { Typography } from '@material-ui/core';
import deepEqual from 'react-fast-compare';

export const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.light,
    margin: theme.sidePanel.gutter,
    padding: '1rem',
    ...theme.mixins.borderRadius(),
    ...theme.mixins.shadow(),
    '& > *': {
      width: '100%',
      '& .MuiSelect-selectMenu': {
        fontSize: '90%',
      },
    },
    '& > *:not(:last-child)': {
      marginBottom: '2rem',
    },

    [theme.breakpoints.down('sm')]: {
      padding: 0,
      margin: 0,
    },
  },
}));

DeviceSwitcher.propTypes = {
  qualityPanel: PropTypes.bool,
};

export default function DeviceSwitcher({ qualityPanel }) {
  const dispatch = useDispatch();
  //const audioVideo = useSelector((store) => store.chime.audioVideo, deepEqual);
  const audioInputDevices = useSelector((store) => store.chime.audioInputDevices, deepEqual);
  const audioOutputDevices = useSelector((store) => store.chime.audioOutputDevices, deepEqual);
  const videoInputDevices = useSelector((store) => store.chime.videoInputDevices, deepEqual);
  const upstreamVideoQuality = useSelector((store) => store.chime.upstreamVideoQuality, deepEqual);
  const currentAudioInputDevice = useSelector((store) => store.chime.currentAudioInputDevice);
  const currentAudioOutputDevice = useSelector((store) => store.chime.currentAudioOutputDevice);
  const currentVideoInputDevice = useSelector((store) => store.chime.currentVideoInputDevice);
  const classes = useStyles();
  const [micMonitor, setMicMonitor] = useState(null);
  const [isMicPreviewEnabled, setMicPreviewEnabled] = useState(false);

  const noVideoInputDevices =
    (videoInputDevices && videoInputDevices.length === 0) ||
    (videoInputDevices && videoInputDevices.length === 1 && videoInputDevices[0].value === '');
  const noAudioInputDevices =
    (audioInputDevices && audioInputDevices.length === 0) ||
    (audioInputDevices && audioInputDevices.length === 1 && audioInputDevices[0].value === '');
  const noAudioOutputDevices =
    (audioOutputDevices && audioOutputDevices.length === 0) ||
    (audioOutputDevices && audioOutputDevices.length === 1 && audioOutputDevices[0].value === '');

  useEffect(() => {
    return () => {
      refreshMicAnalyser(false);
    };
  }, []);

  useEffect(() => {
    refreshMicAnalyser(isMicPreviewEnabled);
  }, [isMicPreviewEnabled]);

  const refreshMicAnalyser = async (isEnabled) => {
    if (isEnabled) {
      const device = await navigator.mediaDevices.getUserMedia({
        audio: { deviceId: currentAudioInputDevice },
      });
      // Some browsers have an implementation-defined maximum number of allowed AudioContext.
      // Try reusing the single AudioContext object in production.
      const audioContext = new (window.AudioContext || window.webkitAudioContext)();
      const node = audioContext.createAnalyser();
      audioContext.createMediaStreamSource(device).connect(node);

      if (node) node.fftSize = 512;

      setMicMonitor(node);
    } else {
      if (micMonitor) micMonitor.context.close();
      //if (micMonitor) micMonitor.removeOriginalInputs();
      setMicMonitor(null);
    }
  };

  const changeAudioInput = (event) => {
    setMicPreviewEnabled(false);
    dispatch(chooseAudioInputDeviceAction({ device: { value: event.target.value } }));
  };
  const changeAudioOutput = (event) => {
    dispatch(chooseAudioOutputDeviceAction({ device: { value: event.target.value } }));
  };
  const changeVideoInput = (event) => {
    dispatch(chooseVideoInputDeviceAction({ device: { value: event.target.value } }));
  };
  const changeUpstreamVideoQuality = (event) => {
    dispatch(chooseUpstreamVideoQualityAction({ id: event.target.value }));
  };

  return (
    <div className={classes.root}>
      {!noAudioInputDevices && (
        <FormControl>
          <InputLabel id="audio-input-label">Audio Input Device</InputLabel>
          <Select
            labelId="audio-input-label"
            id="audio-input-select"
            disabled={noAudioInputDevices}
            value={currentAudioInputDevice || ' '}
            onChange={changeAudioInput}
          >
            {audioInputDevices
              ?.filter((aid) => {
                return aid.value !== 'communications';
              })
              .map((aid) => {
                return (
                  <MenuItem key={aid.value} value={aid.value}>
                    {aid.label}
                  </MenuItem>
                );
              })}
          </Select>
        </FormControl>
      )}
      {noAudioInputDevices && (
        <Typography style={{ textAlign: 'left', color: 'red' }} variant="caption">
          Your microphone permissions are set to block. Please set them to allow by clicking the
          icon to the left of the URL bar and refreshing the page.
        </Typography>
      )}
      {!noAudioInputDevices && (
        <FormControl fullWidth={true}>
          <Typography
            style={{ textAlign: 'left' }}
            variant="caption"
            color={isMicPreviewEnabled ? 'primary' : 'textPrimary'}
          >
            Test Microphone
          </Typography>
          <MicMonitor
            isEnabled={isMicPreviewEnabled}
            setEnabled={setMicPreviewEnabled}
            analyserNode={micMonitor}
          />
        </FormControl>
      )}
      {!noAudioOutputDevices && (
        <FormControl>
          <InputLabel id="audio-output-label">Audio Output Device</InputLabel>
          <Select
            labelId="audio-output-label"
            id="audio-output-select"
            value={currentAudioOutputDevice || 'default'}
            onChange={changeAudioOutput}
          >
            {audioOutputDevices &&
              audioOutputDevices
                .filter((aod) => {
                  return aod.value !== 'communications';
                })
                .map((aod) => {
                  return (
                    <MenuItem key={aod.value} value={aod.value}>
                      {aod.label}
                    </MenuItem>
                  );
                })}
          </Select>
        </FormControl>
      )}

      <FormControl>
        <InputLabel id="video-input-label">Video Input Device</InputLabel>
        <Select
          labelId="video-input-label"
          id="video-input-select"
          disabled={noVideoInputDevices}
          value={currentVideoInputDevice || ' '}
          onChange={changeVideoInput}
        >
          {videoInputDevices &&
            videoInputDevices.map((vid) => {
              return (
                <MenuItem key={vid.value} value={vid.value}>
                  {vid.label}
                </MenuItem>
              );
            })}
        </Select>
      </FormControl>
      {noVideoInputDevices && (
        <Typography style={{ textAlign: 'left', color: 'red' }} variant="caption">
          Your camera permissions are set to block. Please set them to allow by clicking the icon to
          the left of the URL bar and refreshing the page.
        </Typography>
      )}

      {qualityPanel && (
        <FormControl>
          <InputLabel id="video-input-label">Upstream Quality</InputLabel>
          <Select
            labelId="video-input-label"
            id="video-input-select"
            value={upstreamVideoQuality}
            onChange={changeUpstreamVideoQuality}
          >
            {/*<MenuItem key={-1} value={-1}>
            Auto
          </MenuItem>*/}
            {upstreamVideoQualities.map((quality, index) => {
              return (
                <MenuItem key={index} value={index}>
                  {quality.name}
                </MenuItem>
              );
            })}
          </Select>
        </FormControl>
      )}
    </div>
  );
}
