import React, { useEffect, useRef, useState } from 'react';
import { makeStyles, fade } from '@material-ui/core/styles';
import {
  IconButton,
  Slider,
  Menu,
  MenuItem,
  Button,
  ListItem,
  ListItemText,
  ListItemSecondaryAction,
} from '@material-ui/core';
// import ExpandLess from '@material-ui/icons/ExpandLess';
import ExpandMore from '@material-ui/icons/ExpandMore';
import ToggleOnIcon from '@material-ui/icons/ToggleOn';
import ToggleOffIcon from '@material-ui/icons/ToggleOff';
import VolumeOffIcon from '@material-ui/icons/VolumeOff';
import VolumeUpIcon from '@material-ui/icons/VolumeUp';
import SettingsIcon from '@material-ui/icons/Settings';
import FullscreenIcon from '@material-ui/icons/Fullscreen';
import { FullScreen, useFullScreenHandle } from 'react-full-screen';
import PropTypes from 'prop-types';
import CloseIcon from '@material-ui/icons/Close';

const useStyles = makeStyles((theme) => ({
  playerWrapper: {
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'column',
    ...theme.mixins.vhHeight(100),
    width: '100%',
  },
  player: {
    position: 'relative',

    '& .playerUI': {
      position: 'absolute',
      zIndex: '10',
      bottom: 0,
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      padding: '0.5rem 1rem',
      width: '100%',
      background: 'linear-gradient(to bottom, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.9))',
      color: 'white',
      opacity: 0,
      transition: 'opacity 0.33s ease',
      '& > *:not(:last-child)': {
        marginRight: '0.75rem',
      },
    },
    '&:hover .playerUI': {
      opacity: 1,
    },
    '& .MuiSvgIcon-root': {
      // color: 'white',
      fontSize: '2rem',
    },
  },
  qualityIndicator: {
    backgroundColor: fade(theme.palette.background.dark, 0.5),
    fontSize: '0.8rem',
    padding: '0.1rem 0.3rem',
    cursor: 'default',
    ...theme.mixins.borderRadius(),
  },
  volumeSlider: {
    width: '10rem',
  },
  playerVideo: {
    width: '100%',
    height: '100vh',
    ...theme.mixins.vhMaxHeight(100),
  },
  fullScreenButton: {
    [theme.breakpoints.down('mobile')]: {
      display: 'none',
    },
  },
  mutedWarning: {
    position: 'absolute',
    top: 0,
    zIndex: 1,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    verticalAlign: 'middle',
    padding: '0.25rem 0.5rem',
    width: '100%',
    fontWeight: 'bold',
    fontSize: '1rem',
    backgroundColor: theme.palette.primary.main,
    color: 'white',
    '& > *': {
      margin: '0 0.25rem',
    },
    '& .MuiSvgIcon-root': {
      fontSize: '1.5em',
    },
  },
  playOverlay: {
    position: 'absolute',
    top: '50%',
    left: '50%',
  },
  playButton: {
    border: 0,
    background: 'transparent',
    boxSizing: 'border-box',
    width: 0,
    height: '74px',
    borderColor: 'transparent transparent transparent #202020',
    transition: '100ms all ease',
    cursor: 'pointer',

    // play state
    borderStyle: 'solid',
    borderWidth: '37px 0 37px 60px',
  },
}));

VideoPlayer.propTypes = {
  loading: PropTypes.bool,
  player: PropTypes.any,
  streamData: PropTypes.any,
};

export default function VideoPlayer({ loading, player, streamData }) {
  const classes = useStyles();
  const { playbackUrl } = streamData;

  const playerUIRef = useRef(null);
  const [muted, setMuted] = useState(false);
  const [showPlayButton, setShowPlayButton] = useState(true);
  const [volume, setVolume] = useState(1);
  const [isLowLatency, setIsLowLatency] = useState(player ? player.isLiveLowLatency() : true);
  const [settingsAnchorEl, setSettingsAnchorEl] = useState(null);
  const fullScreen = useFullScreenHandle();
  const [closeMuteWarning, setCloseMuteWarning] = useState(false);

  const videoEl = useRef(null);

  // handle case when autoplay with sound is blocked by browser
  useEffect(() => {
    if (loading) return;

    setMuted(player?.isMuted());
  }, [loading, player]);

  useEffect(() => {
    if (player) {
      player.pause();
      player.attachHTMLVideoElement(videoEl.current);
      player.load(playbackUrl);
      try {
        player.play();
        setShowPlayButton(false);
      } catch (error) {
        if (error.name === 'NotAllowedError') {
          //show play button over video
          setShowPlayButton(true);
        } else {
          // Show error message
          console.log('player error', error);
        }
      }
    }
  }, [player, playbackUrl]);

  const playVideo = () => {
    try {
      player.play();
      setShowPlayButton(false);
    } catch (error) {
      if (error.name === 'NotAllowedError') {
        //show play button over video
        setShowPlayButton(true);
      } else {
        // Show error message
        console.log('player error', error);
      }
    }
  };

  const toggleLowLatency = () => {
    player.setLiveLowLatencyEnabled(!isLowLatency);
    setIsLowLatency(!isLowLatency);
  };

  const toggleMute = () => {
    const muteNext = !player.isMuted();

    player.setMuted(muteNext);
    setMuted(muteNext);
  };

  const onChangeVolume = (event, volume) => {
    setVolume(volume);
    player.setVolume(volume);
  };

  const playerQualityDisplay = () => {
    if (player.isAutoQualityMode()) {
      return 'AUTO';
    }

    return player.getQuality().height + 'p';
  };

  const changeQuality = (qual) => {
    if (qual === 'auto') {
      player.setAutoQualityMode(true);
    } else {
      player.setAutoQualityMode(false);
      player.setQuality(qual);
    }

    closeSettings();
  };

  const toggleFullscreen = () => {
    if (fullScreen.active) fullScreen.exit();
    else fullScreen.enter();
  };

  const onClickSettings = (event) => void setSettingsAnchorEl(event.currentTarget); // eslint-disable-line
  const closeSettings = () => void setSettingsAnchorEl(null); // eslint-disable-line

  return (
    <div className={classes.playerWrapper}>
      <FullScreen handle={fullScreen}>
        <div className={classes.player}>
          {muted && !closeMuteWarning && (
            <div className={classes.mutedWarning}>
              <div style={{ flex: 1 }}> </div>
              <span style={{ display: 'flex' }}>
                Your audio is currently muted. Hover over the bottom left corner of the video and
                select <VolumeOffIcon style={{ margin: '0px 5px' }} /> to unmute.
              </span>
              <div style={{ flex: 1, display: 'flex', justifyContent: 'flex-end' }}>
                <IconButton onClick={() => setCloseMuteWarning(true)}>
                  <CloseIcon />
                </IconButton>
              </div>
            </div>
          )}
          <video
            playsInline
            allowFullScreen
            className={classes.playerVideo}
            ref={videoEl}
            id="stream-video"
          />

          {showPlayButton && (
            <div className={classes.playOverlay}>
              <Button onClick={playVideo} className={classes.playButton}></Button>
            </div>
          )}

          {player && !loading && (
            <div className="playerUI" ref={playerUIRef}>
              <IconButton onClick={toggleMute}>
                {muted ? (
                  <VolumeOffIcon style={{ color: '#FFFFFF' }} />
                ) : (
                  <VolumeUpIcon style={{ color: '#FFFFFF' }} />
                )}
              </IconButton>
              <Slider
                min={0}
                max={1}
                step={0.01}
                value={volume}
                className={classes.volumeSlider}
                onChange={onChangeVolume}
              />

              <div style={{ flexGrow: 1 }} />

              <div className={classes.qualityIndicator}>{playerQualityDisplay()}</div>
              <IconButton onClick={onClickSettings}>
                <SettingsIcon style={{ color: '#FFFFFF' }} />
              </IconButton>
              <Menu
                className={classes.menu}
                anchorEl={settingsAnchorEl}
                anchorOrigin={{
                  vertical: 'bottom',
                  horizontal: 'right',
                }}
                transformOrigin={{
                  vertical: 'bottom',
                  horizontal: 'right',
                }}
                container={playerUIRef.current}
                keepMounted
                open={Boolean(settingsAnchorEl)}
                onClose={closeSettings}
              >
                <ListItem>
                  <ListItemText primary="Low Latency" />
                  <ListItemSecondaryAction>
                    <IconButton onClick={toggleLowLatency}>
                      {isLowLatency ? (
                        <ToggleOnIcon style={{ color: '#03adee !important' }} color="primary" />
                      ) : (
                        <ToggleOffIcon color="disabled" />
                      )}
                    </IconButton>
                  </ListItemSecondaryAction>
                </ListItem>
                <ListItem>
                  <ListItemText primary="Choose Quality" />
                  <ExpandMore />
                </ListItem>
                <MenuItem onClick={() => changeQuality('auto')}>Auto</MenuItem>
                {player.getQualities().map((q, i) => (
                  <MenuItem onClick={() => changeQuality(q)} key={i}>
                    {q.height}p
                  </MenuItem>
                ))}
              </Menu>

              <IconButton
                className={classes.fullScreenButton}
                style={fullScreen.active ? { display: 'block!important' } : {}}
                onClick={toggleFullscreen}
              >
                <FullscreenIcon style={{ color: '#FFFFFF' }} />
              </IconButton>
            </div>
          )}
        </div>
      </FullScreen>
    </div>
  );
}
