import { createAction } from '@reduxjs/toolkit';

const act = (a) => createAction(`session-polling/${a}Action`);

export const initAction = act('init');
export const successAction = act('success');
export const errorAction = act('error');
export const resetStateAction = act('resetState');

export const setPollsAction = act('setPolls');
export const setUnseenPollAction = act('setUnseenPoll');
export const setTemplatesAction = act('setTemplates');
export const addPollAction = act('addPoll');
export const removePollAction = act('removePoll');
export const updatePollAction = act('updatePoll');
export const startPollAction = act('startPoll');
export const endPollAction = act('endPoll');
export const processPollUpdatesAction = act('processPollUpdates');
export const setFocusedPollIdAction = act('setFocusedPollId');
export const resetFocusedPollIdAction = act('resetFocusedPollId');
export const setMyAnswerAction = act('setMyAnswer');
export const setMyAnswersAction = act('setMyAnswers');
