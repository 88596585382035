import RaiseHandIcon from '@material-ui/icons/PanTool';
import { makeStyles } from '@material-ui/styles';
import { useDispatch, useSelector } from 'react-redux';
import { setRaisedHandAction } from 'store/session/sessionActions';
import { updateRaisedHand } from 'api/routes/userSession';
import DefaultIcon from './DefaultIcon';
import { useLatestPendingRequest } from 'hooks/usePendingRequests';
import { showGenericErrorNotification } from 'utils/utilMethods';
import PropTypes from 'prop-types';

const useStyles = makeStyles(() => ({
  raisedHand: {
    color: 'gold',
  },
}));

RaisedHand.propTypes = {
  hideIcon: PropTypes.bool.isRequired,
};

export default function RaisedHand({ hideIcon }) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const raisedHandStore = useSelector((store) => store.session.raisedHand);
  const raisedHandPending = useLatestPendingRequest('raiseHand');
  const raisedHand = raisedHandPending ? raisedHandPending.data.handIsRaised : raisedHandStore;

  const raise = () => {
    const newRaisedHand = !raisedHand;

    updateRaisedHand(newRaisedHand, {
      retries: 2,
      onSuccess() {
        dispatch(setRaisedHandAction({ raisedHand: newRaisedHand }));
      },
      onError(error, reason, retriesLeft) {
        if (retriesLeft === 0) {
          showGenericErrorNotification(`${newRaisedHand ? 'raising' : 'putting down'} your hand`);
        }
      },
    });
  };

  return (
    <DefaultIcon
      name="Raise Hand"
      badgeClass={classes.raisedHand}
      badgeClassCondition={raisedHand}
      IconComp={RaiseHandIcon}
      shouldPopout={false}
      hideIcon={hideIcon}
      handleClick={raise}
    />
  );
}
