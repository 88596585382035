import { updateVod, getVods } from 'api/routes/vod';
import { sendMeetingEvents } from 'api/routes/event';
import { select, takeEvery } from 'redux-saga/effects';
import * as ea from 'store/event/eventActions';
import { setPopoutAction } from 'store/app/appActions';
import { call, put } from 'store/utils';
import { getFeatureName } from 'constants/features';

function* updateVodFlowSaga(action) {
  const key = 'updateVodFlowSaga';
  try {
    yield put(ea.initAction({ key }));
    const { endTime, name, published, startTime, trimEnd, trimStart, vodId } = action.payload;
    yield call(updateVod, vodId, {
      endTime,
      name,
      published,
      startTime,
      trimEnd,
      trimStart,
      vodId,
    });
    const response = yield call(getVods);
    yield put(
      ea.setVodsAction({
        vods: response.vods,
      })
    );
    yield put(ea.successAction({ key }));
  } catch (error) {
    yield put(ea.errorAction({ key, error }));
  }
}

function* sendMeetingEventsFlowSaga() {
  const key = 'sendMeetingEventsFlowSaga';
  try {
    yield put(ea.initAction({ key }));
    const { meetingEvents } = yield select(({ event }) => ({
      meetingEvents: event.meetingEvents,
    }));
    if (!meetingEvents.length) return;
    yield put(ea.resetMeetingEventsAction());
    yield call(sendMeetingEvents, meetingEvents);

    yield put(ea.successAction({ key }));
  } catch (error) {
    yield put(ea.errorAction({ key, error }));
  }
}

function* collectMeetingEventsFlowSaga(action) {
  const key = 'collectMeetingEventsFlowSaga';
  try {
    yield put(ea.initAction({ key }));
    const { name, attributes } = action.payload;
    const { meetingHistory, ...otherAttributes } = attributes;

    switch (name) {
      case 'audioInputFailed':
      case 'videoInputFailed':
      case 'meetingStartFailed':
      case 'meetingFailed':
        yield put(
          ea.setMeetingEventsAction({
            name,
            attributes: {
              ...otherAttributes,
              meetingHistory: meetingHistory.filter(({ timestampMs }) => {
                return Date.now() - timestampMs < 5 * 60 * 1000;
              }),
            },
          })
        );
        yield put(ea.sendMeetingEventsAction());
        break;
      case 'meetingEnded':
        yield put(
          ea.setMeetingEventsAction({
            name,
            attributes: otherAttributes,
          })
        );
        yield put(ea.sendMeetingEventsAction());
        break;
      default:
        yield put(
          ea.setMeetingEventsAction({
            name,
            attributes: otherAttributes,
          })
        );
        break;
    }

    yield put(ea.successAction({ key }));
  } catch (error) {
    yield put(ea.errorAction({ key, error }));
  }
}

function* setFeatureDisabledFlowSaga(action) {
  const key = 'setFeatureDisabledFlowSaga';

  yield put(ea.initAction({ key }));

  try {
    const { feature, disabled } = action.payload;

    if (disabled) {
      const popout = yield select((state) => state.app.popout);

      if (popout.open && popout.name === getFeatureName(feature)) {
        yield put(setPopoutAction({ popout: { open: false } }));
      }
    }

    yield put(ea.successAction({ key }));
  } catch (error) {
    yield put(ea.errorAction({ key, error }));
  }
}

function* setFeaturesDisabledFlowSaga(action) {
  const key = 'setFeaturesDisabledFlowSaga';

  yield put(ea.initAction({ key }));

  try {
    const popout = yield select((state) => state.app.popout);
    if (popout.open) {
      const { disabledFeatures } = action.payload;
      if (disabledFeatures[getFeatureName(popout.name)]) {
        yield put(setPopoutAction({ popout: { open: false } }));
      }
    }

    yield put(ea.successAction({ key }));
  } catch (error) {
    yield put(ea.errorAction({ key, error }));
  }
}

export default function* eventWatcher() {
  yield takeEvery(ea.updateVodAction().type, updateVodFlowSaga);
  yield takeEvery(ea.collectMeetingEventsAction().type, collectMeetingEventsFlowSaga);
  yield takeEvery(ea.sendMeetingEventsAction().type, sendMeetingEventsFlowSaga);
  yield takeEvery(ea.setFeatureDisabledAction().type, setFeatureDisabledFlowSaga);
  yield takeEvery(ea.setFeaturesDisabledAction().type, setFeaturesDisabledFlowSaga);
}
