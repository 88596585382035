import { Button, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import FeedbackOutlinedIcon from '@material-ui/icons/FeedbackOutlined';
import useKeyPress from 'hooks/useKeyPress';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router-dom';
import routes from 'constants/routes';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    // flexGrow: 1,
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',

    // backgroundColor: theme.palette.primary.dark,
    padding: '3rem',
    border: '4px solid',
    borderColor: theme.palette.primary.dark,
    borderRadius: '5%',
  },
  icon: {
    color: theme.palette.tertiary.main,
    fontSize: theme.typography.pxToRem(60),
    margin: '1rem 0 1rem 0',
  },
  looksLike: {
    fontWeight: 'bold',
    color: theme.palette.tertiary.main,
  },
  goToMeeting: {
    fontSize: '1.5rem',
  },
}));

AcknowledgeFeedback.propTypes = {
  token: PropTypes.any.isRequired,
  accountId: PropTypes.string.isRequired,
};

export default function AcknowledgeFeedback({ token, accountId }) {
  const history = useHistory();
  const classes = useStyles();
  const handleAcknowledge = () => {
    history.push(`${routes.SSO.path}/?token=${token}&accountid=${accountId}`);
  };
  useKeyPress(handleAcknowledge, 13);

  return (
    <div className={classes.root}>
      <Typography variant="h6" align="center">
        This app is in <strong>beta</strong>.
        <br /> Any feedback is highly appreciated.
        <br /> To give <strong>feedback</strong> or report <strong>bugs</strong>
      </Typography>
      <Typography variant="h5" className={classes.looksLike}>
        The feedback button looks like this:
      </Typography>
      <div className={classes.lastRow}>
        <FeedbackOutlinedIcon className={classes.icon} />
      </div>
      <Button
        size="large"
        color="primary"
        variant="contained"
        className={classes.goToMeeting}
        onClick={handleAcknowledge}
      >
        Go to Meeting
      </Button>
    </div>
  );
}
