import { combineReducers } from 'redux';
import activeSpeaker from 'store/active-speaker/activeSpeakerReducer';
import analytics from 'store/analytics/analyticsReducer';
import app from 'store/app/appReducer';
import chime from 'store/chime/chimeReducer';
import event from 'store/event/eventReducer';
import management from 'store/management/managementReducer';
import pendingRequests from 'store/pending-requests/pendingRequestsReducer';
import sessionMessage from 'store/session-message/sessionMessageReducer';
import sessionPolling from 'store/session-polling/sessionPollingReducer';
import sessionQuestion from 'store/session-question/sessionQuestionReducer';
import sessionResource from 'store/session-resource/sessionResourceReducer';
import session from 'store/session/sessionReducer';
import utils from 'store/utils/utilReducer';
import webSocket from 'store/web-socket/webSocketReducer';
import user from './user/userReducer';

export default combineReducers({
  activeSpeaker,
  analytics,
  app,
  chime,
  event,
  management,
  pendingRequests,
  session,
  sessionMessage,
  sessionPolling,
  sessionQuestion,
  sessionResource,
  user,
  utils,
  webSocket,
});
