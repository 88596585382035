import React from 'react';
import PropTypes from 'prop-types';
import { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import LinearProgress from '@material-ui/core/LinearProgress';
import Switch from '@material-ui/core/Switch';
import useInterval from 'hooks/useInterval';

MicMonitor.propTypes = {
  isEnabled: PropTypes.bool,
  setEnabled: PropTypes.func,
  analyserNode: PropTypes.any,
};

const useStyles = makeStyles(() => ({
  micMonitor: {
    display: 'flex',
    alignItems: 'center',
    '& .MuiLinearProgress-bar1Determinate': {
      transitionDuration: '0.15s !important ',
    },
    '& .MuiLinearProgress-root': {
      height: 8,
    },
    '& >*:last-child': {
      flexGrow: 1,
    },
  },
}));

let buffer = new Uint8Array(512).map(() => 0);

export default function MicMonitor({ isEnabled, setEnabled, analyserNode }) {
  let value = 0;
  const [forceUpdateTick, setForceUpdateTick] = useState(0);
  const classes = useStyles();

  //Updates mic display 5x per second
  useInterval(() => isEnabled & setForceUpdateTick(forceUpdateTick + 1), 100);

  if (isEnabled && analyserNode) {
    analyserNode.getByteTimeDomainData(buffer);

    //Gets the VU of the audio signal by finding the max of the abs of each sample
    //Scales it up by 2 to be more useful
    value = Math.min(Math.max(...buffer.map((x) => Math.abs((x - 128) / 128) * 150)), 100);
  }

  const toggleEnabled = () => {
    setEnabled((prev) => !prev);
  };

  return (
    <div className={classes.micMonitor}>
      <Switch checked={isEnabled} onChange={toggleEnabled} color="primary" />
      <LinearProgress variant="determinate" value={value} />
    </div>
  );
}
