import { useState } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import deepEqual from 'react-fast-compare';
import { makeStyles } from '@material-ui/core/styles';
import { Badge } from '@material-ui/core';
// import Tabs from '@material-ui/core/Tabs';
// import Tab from '@material-ui/core/Tab';
// import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import QuestionQueue from './components/QuestionQueue';
import AttendeeRequest from './components/AttendeeRequest';
import { StyledTabs, StyledTab } from './components/muiComponents';
import Chat from '../chat';

const useStyles = makeStyles((theme) => ({
  root: {
    padding: `0.75rem ${theme.sidePanel.gutter}`,
    height: '100%',
  },
  chatContainer: {
    display: 'flex',
    flexDirection: 'column',
    height: `100%`,
    overflowY: 'auto',
    [theme.breakpoints.down('mobile')]: {
      height: '100%',
    },
  },
}));

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`queue-tabpanel-${index}`}
      aria-labelledby={`queue-tab-${index}`}
      style={{ height: 'calc(100% - 62px' }}
      {...other}
    >
      {value === index && <Box style={{ paddingTop: '10px', height: '100%' }}>{children}</Box>}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `queue-tab-${index}`,
    'aria-controls': `queue-tabpanel-${index}`,
  };
}

export default function TheStage() {
  const classes = useStyles();
  const [value, setValue] = useState(0);
  const role = useSelector((store) => store.user.role);
  const userId = useSelector((store) => store.user.userId);
  const stageAttendees = useSelector((store) => store.session.stageAttendees || {}, deepEqual);
  const unreadMessages = useSelector((store) => store.sessionMessage.unreadQueueMessages);
  const pendingRequest = stageAttendees[userId]?.accepted === false;
  const acceptedRequest = stageAttendees[userId]?.accepted === true;
  const seeQueue = role === 'presenter' || acceptedRequest;

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <div className={classes.root}>
      {seeQueue ? (
        <>
          <StyledTabs
            variant="fullWidth"
            value={value}
            onChange={handleChange}
            aria-label="Queue Tabs"
          >
            <StyledTab label="The Queue" {...a11yProps(0)} />

            <StyledTab
              label={
                <Badge
                  badgeContent={unreadMessages}
                  color="primary"
                  invisible={unreadMessages <= 0}
                >
                  Queue Chat
                </Badge>
              }
              {...a11yProps(1)}
            />
          </StyledTabs>

          <TabPanel value={value} index={0}>
            <QuestionQueue
              isAttendee={role === 'attendee'}
              stageAttendees={stageAttendees}
              userId={userId}
            />
          </TabPanel>

          <TabPanel value={value} index={1}>
            <div className={classes.chatContainer}>
              <Chat queueMessage={true} />
            </div>
          </TabPanel>
        </>
      ) : (
        <AttendeeRequest pendingRequest={pendingRequest} />
      )}
    </div>
  );
}
