import { basePost } from 'api/apiDetour';

const baseEndpoint = 'session-polling-answers';

const post = async ({ endpoint, data = {}, isSigned = false }) => {
  const resp = await basePost(endpoint, data, baseEndpoint, isSigned);
  return resp;
};

export const getUserSessionAnswers = async () => {
  const resp = await post({ endpoint: 'get-user-session-answers' });
  return resp;
};

export const getSessionAnswers = async () => {
  const resp = await post({ endpoint: 'get-session-answers' });
  return resp;
};

export const getPollAnswers = async (pollId) => {
  const resp = await post({ endpoint: 'get-poll-answers', data: { pollId } });
  return resp;
};

export const answerPoll = async (pollId, answer) => {
  const resp = await post({ endpoint: 'answer', data: { pollId, answer } });
  return resp;
};
