import App from 'App';
import React from 'react';
import ReactDOM from 'react-dom';
import * as Sentry from '@sentry/react';
import { Integrations } from '@sentry/tracing';
import config from 'config';

if (!config.IS_DEVELOPMENT) {
  Sentry.init({
    dsn: config.SENTRY_DSN,
    release: process.env.REACT_APP_SENTRY_RELEASE,
    integrations: [new Integrations.BrowserTracing()],
    environment: config.STAGE,
    tracesSampleRate: 0.2,
    beforeSend(event) {
      //May need to clean additional PII here but it is only in the redux state and removed in configure store
      return event;
    },
  });
}

localStorage.removeItem('broadcast-token');
localStorage.removeItem('broadcast-refresh-token');
ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById('root')
);
