import PropTypes from 'prop-types';
import React, { useEffect, useRef } from 'react';
import AutoSizer from 'react-virtualized-auto-sizer';
import { VariableSizeList } from 'react-window';
import Question from './Question';
import {
  questionCharsPerLine,
  questionHeight,
  questionLineHeight,
  questionMargin,
} from './Question.style.js';

const estimatedItemSize = questionHeight + questionMargin * 2 + questionLineHeight * 1.2;

function getHeight(question) {
  const baseHeight = questionHeight + questionMargin * 2;
  try {
    const lines = Math.max(Math.ceil(question.length / questionCharsPerLine), 1);
    return baseHeight + lines * questionLineHeight;
  } catch (e) {
    return baseHeight;
  }
}

export default function QuestionsList({
  questionsArray,
  getUpdates,
  bypassNextIfScrolled,
  onScrolled,
}) {
  const listRef = useRef(null);

  useEffect(() => {
    if (listRef.current) {
      listRef.current.resetAfterIndex(0, true);
    }
  }, [questionsArray]);

  return (
    <AutoSizer disableWidth>
      {({ height }) => (
        <VariableSizeList
          ref={listRef}
          height={height}
          estimatedItemSize={estimatedItemSize}
          width="100%"
          itemSize={(index) => getHeight(questionsArray[index].question)}
          itemCount={questionsArray.length}
          itemData={{
            questions: questionsArray,
            getUpdates: getUpdates,
            bypassNextIfScrolled: bypassNextIfScrolled,
          }}
          itemKey={(index, { questions }) => questions[index].questionId}
          onScroll={({ scrollOffset }) => {
            onScrolled(scrollOffset);
          }}
        >
          {QuestionRenderer}
        </VariableSizeList>
      )}
    </AutoSizer>
  );
}

// The item renderer is declared outside of the list-rendering component.
// So it has no way to directly access the items array.

class QuestionRenderer extends React.PureComponent {
  render() {
    // Access the items array using the "data" prop:
    const question = this.props.data.questions[this.props.index];
    return (
      <Question
        style={this.props.style}
        questionId={question.questionId}
        questionUserId={question.userId}
        userName={question.userName}
        question={question.question}
        answer={question.answer}
        voteCount={question.voteCount}
        hidden={question.hidden}
        didLocalUserVote={question.didLocalUserVote}
        getUpdates={this.props.data.getUpdates}
        bypassNextIfScrolled={this.props.data.bypassNextIfScrolled}
        timeAsked={question.timeAsked}
      />
    );
  }
}

QuestionRenderer.propTypes = {
  data: PropTypes.shape({
    questions: PropTypes.array.isRequired,
    getUpdates: PropTypes.func.isRequired,
    bypassNextIfScrolled: PropTypes.func.isRequired,
  }).isRequired,
  style: PropTypes.object.isRequired,
  index: PropTypes.number.isRequired,
};
QuestionsList.propTypes = {
  questionsArray: PropTypes.array.isRequired,
  getUpdates: PropTypes.func.isRequired,
  bypassNextIfScrolled: PropTypes.func.isRequired,
  onScrolled: PropTypes.func.isRequired,
};
