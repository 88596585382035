import useStyles from './CreateEditPoll.style.js';
import { Button, TextField, InputLabel } from '@material-ui/core';
import { Modal, ModalBody, ModalFooter } from 'components/Modal';
import PropTypes from 'prop-types';

PollSaveModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  save: PropTypes.func.isRequired,
  name: PropTypes.string,
  setName: PropTypes.func.isRequired,
  pollNameCharactersMax: PropTypes.number,
};

export default function PollSaveModal({
  isOpen,
  onClose,
  save,
  name,
  setName,
  pollNameCharactersMax,
}) {
  const classes = useStyles();

  return (
    <Modal open={isOpen} onClose={onClose} title="Save Template" variant="small">
      <ModalBody>
        <InputLabel>Save as:</InputLabel>
        <TextField
          fullWidth
          multiline
          placeholder="Name"
          inputProps={{ maxLength: pollNameCharactersMax }}
          value={name}
          onChange={(evt) => setName(evt.target.value)}
          className={classes.optionField}
        />
      </ModalBody>

      <ModalFooter>
        <Button variant="contained" color="secondary" onClick={onClose}>
          Cancel
        </Button>

        <Button variant="contained" className={classes.startButton} onClick={save}>
          Save
        </Button>
      </ModalFooter>
    </Modal>
  );
}
