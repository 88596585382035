import { FormControl, MenuItem, Select, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { createUserData } from 'api/routes/vodUserData';
import LoadingPage from 'components/LoadingPage';
import VodPlayer from 'components/VodPlayer';
import moment from 'moment';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { initializeVodRouteAction } from 'store/user/userActions';
import deepEqual from 'react-fast-compare';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
    '& > *': {
      marginTop: '1rem',
    },
  },
  vodWrapper: {
    width: '75vw',
    maxWidth: '75vw',
    ...theme.mixins.vhMaxHeight(80),
    overflow: 'hidden',
  },
  vodSelect: {
    minWidth: '20rem',
  },
  header: {
    width: '100%',
    margin: '1rem',
    display: 'flex',
    justifyContent: 'space-between',
  },
  selectContainer: {
    display: 'flex',
    flexDirection: 'column',
    marginRight: '2rem',
  },
}));

export default function Vod() {
  const dispatch = useDispatch();
  const [videoUrl, setVideoUrl] = useState('');
  const [vodId, setVodId] = useState('');
  const [minTime, setMinTime] = useState(0);
  const [maxTime, setMaxTime] = useState(0);
  const [userDataId, setUserDataId] = useState(null);
  const classes = useStyles();
  const title = useSelector((store) => store.event.title);
  const vods = useSelector((store) => store.event.vods, deepEqual);
  const initTokenLoading = useSelector(
    (store) => store.user.actionStatus?.initializeVodRouteFlowSaga?.loading
  );
  const initTokenError = useSelector(
    (store) => store.user.actionStatus?.initializeVodRouteFlowSaga?.error
  );

  const setVod = async (vod) => {
    const { vodId, url, trimStart, trimEnd } = vod;
    setVideoUrl(url);
    setVodId(vodId);
    setMinTime(trimStart);
    setMaxTime(trimEnd);
    const data = await createUserData(vodId);
    setUserDataId(data?.userData?.timeStampUserId);
  };

  useEffect(() => {
    dispatch(initializeVodRouteAction());
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (vods && vods.length > 0) {
      setVod(vods[0]);
    }
  }, [vods]);

  if (initTokenLoading || !vods) return <LoadingPage>Loading VODs...</LoadingPage>;
  if (initTokenError) return null;

  const onChangeVod = async (event) => {
    setVideoUrl(null);
    setVodId(null);
    setUserDataId(null);
    const vod = vods.find((vod) => vod.url === event.target.value);
    await setVod(vod);
  };

  return (
    <div className={classes.root}>
      <div className={classes.header}>
        <div style={{ marginLeft: '1rem' }}>
          <Typography variant="h5">{title}</Typography>
        </div>
        <div className={classes.selectContainer}>
          <FormControl>
            <Select
              id="vod-select"
              labelId="vod-select-label"
              value={videoUrl}
              onChange={onChangeVod}
              className={classes.vodSelect}
            >
              {vods.map((video) => (
                <MenuItem value={video.url} key={video.url}>
                  {video.name || moment(video.start).format('LLL')}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <Typography variant="caption">Select a video clip above to playback.</Typography>
        </div>
      </div>

      <div className={classes.vodWrapper}>
        {videoUrl && userDataId && vodId && (
          <VodPlayer
            vodId={vodId}
            userDataId={userDataId}
            vodUrl={videoUrl}
            minTime={minTime}
            maxTime={maxTime}
          />
        )}
      </div>
    </div>
  );
}
