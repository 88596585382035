import React from 'react';
import ReactDOM from 'react-dom';
import Proptypes from 'prop-types';

// Copied from https://medium.com/hackernoon/using-a-react-16-portal-to-do-something-cool-2a2d627b0202

function copyStyles(sourceDoc, targetDoc) {
  Array.from(sourceDoc.querySelectorAll('link[rel="stylesheet"], style')).forEach((link) => {
    targetDoc.head.appendChild(link.cloneNode(true));
  });
}

class PopOut extends React.PureComponent {
  constructor(props) {
    super(props);
    // STEP 1: create a container <div>
    this.containerEl = document.createElement('div');
    this.externalWindow = null;
  }

  render() {
    // STEP 2: append props.children to the container <div> that isn't mounted anywhere yet
    return ReactDOM.createPortal(this.props.children, this.containerEl);
  }

  componentDidMount() {
    // STEP 3: open a new browser window and store a reference to it
    this.externalWindow = window.open('', '', 'width=608,height=342,left=200,top=200');
    if (this.props.onClose) this.externalWindow.onunload = this.props.onClose;

    copyStyles(document, this.externalWindow.document);
    // STEP 4: append the container <div> (that has props.children appended to it) to the body of the new window
    this.externalWindow.document.body.appendChild(this.containerEl);
  }

  componentWillUnmount() {
    // STEP 5: This will fire when this.state.showWindowPortal in the parent component becomes false
    // So we tidy up by closing the window
    this.externalWindow.close();
  }
}

PopOut.propTypes = {
  children: Proptypes.any,
  onClose: Proptypes.func,
};

export default PopOut;
