import { useState, useEffect } from 'react';
import * as Sentry from '@sentry/browser';

// Currently only compatible with Chrome/Edge/Firefox not IE or Safari
export const checkPermission = async (name, setGranted, setChecked) => {
  try {
    const check = await navigator.permissions.query({ name });
    if (!check) throw 'Could not get permission state';
    setGranted(check?.state === 'granted');
  } catch (e) {
    if (e.toString().includes('is not a valid value for enumeration PermissionName')) {
      // Firefox doesn't support this
      setGranted(true);
    } else {
      Sentry.captureException(e);
      console.log('%cError checking permission for %s: %s', 'background:red;', name, e);
    }
  } finally {
    setChecked(true);
  }
};

export function useBrowserPermission(name) {
  const [granted, setGranted] = useState(false);
  const [checked, setChecked] = useState(false);

  useEffect(() => {
    //if (checked) return;

    const check = async () => {
      await checkPermission(name, setGranted, setChecked);
    };

    check();
  }, [name /*, checked*/]);

  return [granted, checked];
}

export default useBrowserPermission;
