import { click } from 'components/side-panel/components/testing/helpers';
export default async function ({ subType, payload }) {
  console.log(payload);
  switch (subType) {
    case 'localVideoToggle':
      if (!click('toggle-presenter-video-on')) {
        click('toggle-presenter-video-off');
      }
      break;
    case 'localVideoToggleOn':
      click('toggle-presenter-video-on');
      break;
    case 'localVideoToggleOff':
      click('toggle-presenter-video-off');
      break;
    case 'micToggle':
      if (!click('toggle-presenter-mic-on')) {
        click('toggle-presenter-mic-off');
      }
      break;
    case 'micToggleOn':
      click('toggle-presenter-mic-on');
      break;
    case 'micToggleOff':
      click('toggle-presenter-mic-off');
      break;
    default:
      console.log('No Subtype Found For ', subType, ' In Meeting');
  }
}
