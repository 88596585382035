import { Button, IconButton, Typography } from '@material-ui/core';
import {
  makeStyles,
  // useTheme
} from '@material-ui/core/styles';
import Switch from '@material-ui/core/Switch';
import BarChartIcon from '@material-ui/icons/BarChart';
import ChatBubbleIcon from '@material-ui/icons/ChatBubble';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import MicIcon from '@material-ui/icons/Mic';
import MicOffIcon from '@material-ui/icons/MicOff';
import RaiseHandIcon from '@material-ui/icons/PanTool';
import StarIcon from '@material-ui/icons/Star';
import VideoCallIcon from '@material-ui/icons/VideoCall';
import VideocamIcon from '@material-ui/icons/Videocam';
import VideocamOffIcon from '@material-ui/icons/VideocamOff';
import { kickUser, setUserInGrid, stopUserVideo } from 'api/routes/session';
import { putDownHand, updateRaisedHand } from 'api/routes/userSession';
import { Modal, ModalBody, ModalFooter } from 'components/Modal';
import { getFeatureName } from 'constants/features';
import VideoStatus from 'enums/VideoStatus';
import { useLatestPendingRequest } from 'hooks/usePendingRequests';
import PropTypes from 'prop-types';
import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { setPopoutAction } from 'store/app/appActions';
// import useShowVolumeIndicator from 'hooks/useShowVolumeIndicator';
import {
  enterGridAction,
  leaveGridAction,
  setCurrentAnalyticsUserIdAction,
  toggleRosterUserMutedAction,
  toggleVideoAction,
  updateRosterAttributeAction,
} from 'store/chime/chimeActions';
import { setConversationIdAction } from 'store/session-message/sessionMessageActions';
import { setRaisedHandAction } from 'store/session/sessionActions';
import { showGenericErrorNotification } from 'utils/utilMethods';

const useStyles = makeStyles((theme) => ({
  user: {
    display: 'flex',
    alignItems: 'center',
    padding: '0.5rem 0',
    borderBottom: '2px solid #fff2',
  },
  userName: (isUser) => ({
    maxWidth: '20ch',
    textOverflow: 'ellipsis',
    overflowX: 'hidden',
    fontWeight: 'bold',
    //fontSize: '0.8rem',
    paddingLeft: '0.25rem',
    color: isUser ? theme.palette.primary.main : 'inherit',
  }),
  buttons: {
    flexGrow: 1,
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center',
    '& > *': {
      minWidth: '1.5rem',
    },
    '& .MuiIconButton-root': {
      color: theme.palette.text.primary,
      '&.Mui-disabled': {
        color: theme.palette.text.primary,
      },
    },
  },
}));

RosterUser.propTypes = {
  user: PropTypes.object.isRequired,
  gridUsers: PropTypes.object.isRequired,
  mutedAll: PropTypes.bool,
};

export default function RosterUser({ user, mutedAll, gridUsers }) {
  // const theme = useTheme();
  const dispatch = useDispatch();
  const localVideoStatus = useSelector((store) => store.chime.videoStatus);
  const role = useSelector((store) => store.user.role);
  const userId = useSelector((store) => store.user.userId);
  const currentUsersAudioDevice = useSelector((store) => store.chime.currentAudioInputDevice);
  const chatDisabled = useSelector((store) => store.event.disabledFeatures.chat);
  const [kickOpen, setKickOpen] = useState(false);
  const raisedHandStore = user.handIsRaised;
  const putDownPending = useLatestPendingRequest('putDownHand', 'userId', user.userId);
  const raisedHand = putDownPending ? false : raisedHandStore;
  const isCurrentUser = user.userId === userId;
  const inGrid = !!gridUsers[user.userId];
  // const showVolumeIndicator = useShowVolumeIndicator({ attendeeId: user.attendeeId });
  let videoIsActive = isCurrentUser
    ? localVideoStatus === VideoStatus.Enabled
    : user.videoStatus === VideoStatus.Enabled;

  const canManage = role === 'presenter' || role === 'admin';
  const canDirectMessage = canManage
    ? !isCurrentUser
    : !isCurrentUser && (user.role === 'presenter' || user.role === 'admin');
  const canToggleVideo = (canManage && videoIsActive) || isCurrentUser;
  let canToggleMute = (canManage && !user.muted) || (isCurrentUser && (canManage || !mutedAll));
  if (canToggleMute && isCurrentUser && !currentUsersAudioDevice) {
    canToggleMute = false;
  }

  const classes = useStyles(isCurrentUser);

  const handleToggleMuteUser = async () => {
    dispatch(
      toggleRosterUserMutedAction({
        userId: user.userId,
      })
    );
  };

  const handleToggleVideo = async () => {
    if (isCurrentUser) {
      dispatch(toggleVideoAction());
    } else {
      await stopUserVideo(user.userId);
    }
  };

  const handleToggleInGrid = async () => {
    const newInGrid = !inGrid;
    const timestamp = Date.now();

    const reqOptions = {
      retries: 2,
      onSuccess() {
        // if (newInGrid) {
        //   dispatch(enterGridAction({ userId: user.userId, timestamp }));
        // } else {
        //   dispatch(leaveGridAction({ userId: user.userId }));
        // }
      },
      onError(error, reason, retriesLeft) {
        if (retriesLeft <= 0) {
          showGenericErrorNotification(`${newInGrid ? 'adding to' : 'removing from'} stage`);
          if (newInGrid) {
            dispatch(leaveGridAction({ userId: user.userId }));
          } else {
            dispatch(enterGridAction({ userId: user.userId, timestamp }));
          }
        }
      },
    };
    if (newInGrid) {
      dispatch(enterGridAction({ userId: user.userId, timestamp }));
    } else {
      dispatch(leaveGridAction({ userId: user.userId }));
    }
    setUserInGrid(user.userId, newInGrid, reqOptions);
  };

  const handleViewAnalytics = () => {
    dispatch(setCurrentAnalyticsUserIdAction({ userId: user.userId }));
    dispatch(
      setPopoutAction({
        popout: {
          open: true,
          name: 'User Analytics',
        },
      })
    );
  };

  const onKickConfirm = async () => {
    setKickOpen(false);
    await kickUser(user.userId);
  };

  const handleKickUser = () => void setKickOpen(true);
  const onKickCancel = () => void setKickOpen(false);

  const handleHandRaise = () => {
    if (isCurrentUser) {
      updateRaisedHand(false, {
        retries: 2,
        onSuccess() {
          dispatch(setRaisedHandAction({ handIsRaised: false }));
        },
        onError(error, reason, retriesLeft) {
          if (retriesLeft === 0) {
            showGenericErrorNotification('putting down your hand');
          }
        },
      });
    } else {
      putDownHand(user.userId, {
        retries: 2,
        onSuccess() {
          dispatch(
            updateRosterAttributeAction({
              userId: user.userId,
              key: 'handIsRaised',
              value: false,
            })
          );
        },
        onError(error, reason, retriesLeft) {
          if (retriesLeft === 0) {
            showGenericErrorNotification('putting down their hand');
          }
        },
      });
    }
  };

  const handleChatWithUser = () => {
    if (isCurrentUser) return;
    const conversationId = user.userId;
    const popout = {
      open: true,
      name: getFeatureName('chat'),
    };
    dispatch(setConversationIdAction({ conversationId }));
    dispatch(setPopoutAction({ popout }));
  };

  const isPresenter = user.role === 'presenter';

  let VideoIcon;
  if (inGrid) {
    VideoIcon = videoIsActive ? VideoCallIcon : () => <VideoCallIcon style={{ color: 'red' }} />;
  } else {
    VideoIcon = videoIsActive ? VideocamIcon : () => <VideocamOffIcon style={{ color: 'red' }} />;
  }

  const MicIconToUse = user.muted ? MicOffIcon : MicIcon;

  return (
    <div className={classes.user}>
      <Modal open={kickOpen} onClose={onKickCancel} title="Really kick user?" variant="small">
        <ModalBody>
          <Typography variant="body">
            Are you sure you want to kick <b>{user.name}</b>?
          </Typography>
        </ModalBody>

        <ModalFooter>
          <Button variant="contained" color="secondary" onClick={onKickCancel}>
            No
          </Button>

          <Button variant="contained" color="primary" onClick={onKickConfirm}>
            Yes
          </Button>
        </ModalFooter>
      </Modal>

      {canManage && (
        <Switch
          id={`user-grid-switch-${user.userId}`}
          checked={inGrid}
          onChange={handleToggleInGrid}
          color="primary"
        />
      )}

      {isPresenter && <StarIcon style={{ color: 'gold' }} />}

      {raisedHand && (
        <IconButton onClick={handleHandRaise} disabled={!canManage && !isCurrentUser}>
          <RaiseHandIcon style={{ color: 'gold' }} />
        </IconButton>
      )}

      <div className={classes.userName}>{user.name}</div>

      <div className={classes.buttons}>
        <IconButton
          id={`user-video-icon-${user.userId}`}
          data-active={videoIsActive ? 1 : 0}
          data-ingrid={inGrid ? 1 : 0}
          onClick={handleToggleVideo}
          disabled={!canToggleVideo}
        >
          <VideoIcon />
        </IconButton>

        <IconButton
          id={`user-mic-icon-${user.userId}`}
          data-muted={user.muted ? 1 : 0}
          onClick={handleToggleMuteUser}
          disabled={!canToggleMute}
        >
          <MicIconToUse
            style={{
              color: user.muted
                ? 'red'
                : // : showVolumeIndicator
                  // ? theme.palette.primary.main
                  'inherit',
            }}
          />
        </IconButton>

        {!chatDisabled && canDirectMessage && (
          <IconButton onClick={handleChatWithUser}>
            <ChatBubbleIcon />
          </IconButton>
        )}
        {canManage && (
          <IconButton onClick={handleViewAnalytics}>
            <BarChartIcon style={{ color: 'gold' }} />
          </IconButton>
        )}

        <div>
          {canManage && !isCurrentUser && (
            <IconButton onClick={handleKickUser}>
              <ExitToAppIcon />
            </IconButton>
          )}
        </div>
      </div>
    </div>
  );
}
