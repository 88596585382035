import { useEffect } from 'react';

export default function useKeyPress(callback, targetKey) {
  function downHandler({ keyCode }) {
    if (keyCode === targetKey) {
      callback();
    }
  }
  useEffect(() => {
    window.addEventListener('keydown', downHandler);
    return () => {
      window.removeEventListener('keydown', downHandler);
    };
  }, [callback]);
}
