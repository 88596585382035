import { countKeys, historyKeys } from 'constants/analytics';
import set from 'lodash/set';

export const defaultHistoryObject = () => ({
  history: [],
  current: false,
  totalTime: 0,
});
export const defaultCountObject = () => ({ history: [], count: 0 });

export const defaultAnalyticsObject = () => {
  const obj = {};
  historyKeys.forEach((key) => set(obj, key, defaultHistoryObject()));
  countKeys.forEach((key) => set(obj, key, defaultCountObject()));
  return obj;
};

export const defaultTotals = () => {
  const obj = {};
  [...historyKeys, ...countKeys].map((key) => set(obj, key, 0));
  return obj;
};
