import { createReducer } from '@reduxjs/toolkit';
import { merge, findIndex } from 'lodash';
import * as act from 'store/session-question/sessionQuestionActions';
import { buildActionStatus, standardActions } from 'store/utils';
import { removeOneBy } from 'utils/utilMethods';

const initialState = {
  actionStatus: buildActionStatus(act),
  questions: [],
  isQuestionUnread: {},
  unreadQuestions: 0,
};

function recountUnread(state) {
  state.unreadQuestions = Object.keys(state.isQuestionUnread).length;
}

const sessionQuestionReducer = createReducer(initialState, {
  ...standardActions(act),
  [act.resetStateAction]: () => ({
    ...initialState,
  }),
  [act.putQuestionAction]: (state, { payload }) => {
    state.questions.push(payload);
  },
  [act.deleteQuestionAction]: (state, { payload: { questionId } }) => {
    removeOneBy(state.questions, 'questionId', questionId);
  },
  [act.setAllQuestionsAction]: (state, { payload: { questions } }) => {
    state.questions = questions;
  },
  [act.updateQuestionAction]: (state, { payload: { questionId, data } }) => {
    let idx = findIndex(state.questions, ['questionId', questionId]);
    if (idx > -1) {
      merge(state.questions[idx], data);
    }
  },
  [act.setQuestionAnswerAction]: (state, { payload: { questionId, answer } }) => {
    let idx = findIndex(state.questions, ['questionId', questionId]);
    if (idx > -1) {
      state.questions[idx].answer = answer;
    }
  },
  [act.setQuestionHiddenAction]: (state, { payload: { questionId, hidden } }) => {
    let idx = findIndex(state.questions, ['questionId', questionId]);
    if (idx > -1) {
      state.questions[idx].hidden = hidden;
    }
  },
  [act.setUnreadQuestionsAction]: (state, { payload }) => {
    state.isQuestionUnread = payload;
    recountUnread(state);
  },
  [act.addUnreadQuestionAction]: (state, { payload: { questionId } }) => {
    state.isQuestionUnread[questionId] = true;
    recountUnread(state);
  },
  [act.removeUnreadQuestionAction]: (state, { payload: { questionId } }) => {
    delete state.isQuestionUnread[questionId];
    recountUnread(state);
  },
});

export default sessionQuestionReducer;
