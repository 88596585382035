import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import { createExternalTestToken } from 'api/routes/user';
import config from 'config';
import { getFakeUser } from 'utils/fakeUsers';
import get from 'lodash/get';
import { useHistory } from 'react-router-dom';
import routes from 'constants/routes';
import makeStyles from '@material-ui/core/styles/makeStyles';

const useStyles = makeStyles({
  main: {
    margin: '.5em',
  },
  container: {
    display: 'flex',
  },
  button: {
    marginLeft: '1em',
  },
});

const { STAGE } = config;
const testAccountId = 'mosaic-testing';
const fakeTitle = 'Test Broadcast';

const getToken = async (role, eventId, title, isForRecordingUser = false, type) => {
  const { firstName, lastName, email } = getFakeUser();
  const { token } = await createExternalTestToken(
    firstName,
    lastName,
    role,
    email,
    eventId,
    title,
    isForRecordingUser,
    type,
    null
  );
  return token;
};

export default function Home() {
  const history = useHistory();
  const classes = useStyles();

  let sessionId;
  if (STAGE === 'confqa') {
    sessionId = '63765ab3-2603-45e8-bbe8-6b1d98c933fd';
  } else if (process.env.REACT_APP_CONFTWO) {
    sessionId = '63765ab3-2603-45e8-bbe8-6b1d98c933fz';
  } else if (STAGE === 'demo') {
    sessionId = '63765ab3-2603-45e8-bbe8-6b1d98c933zz';
  } else {
    sessionId = '69765ab3-2603-45e8-bbe8-6b1d98c933fe';
  }
  const extraSessions = {
    2: '63765ab3-2603-45e8-bbe8-6bzzzzc933fd',
    3: '63765ab3-2603-45e8-bbe8-6aaaazc933fd',
    4: '6376z8ui-2603-45e8-bbe8-6aaaazc933fd',
    5: '6376z8ui-9993-45e8-bbe8-6aaaazc933fd',
  };
  const showAdditionalSessions = ['broadcast', 'develop'].includes(STAGE);
  const sessionNumbers = Object.keys(extraSessions).sort();

  const fakeLogin = async (role, type, session = '1', isRecording = false) => {
    sessionId = get(extraSessions, session, sessionId);
    const token = await getToken(role, sessionId, `${fakeTitle} - ${session}`, isRecording, type);
    history.push(`${routes.SSO.path}?token=${token}&accountid=${testAccountId}`);
  };

  return (
    <Grid container>
      <Grid item xs={12} className={classes.main}>
        Broadcast Sessions
      </Grid>
      <Grid item xs={12} className={classes.main}>
        <h6>Session 1</h6>
        <div className={classes.container}>
          <Button
            variant="contained"
            className={classes.button}
            onClick={() => fakeLogin('admin', 'settings')}
          >
            Settings
          </Button>
          <Button
            className={classes.button}
            variant="contained"
            onClick={() => fakeLogin('attendee', 'broadcast')}
            id="random-attendee"
          >
            Attendee
          </Button>
          <Button
            className={classes.button}
            variant="contained"
            onClick={() => fakeLogin('attendee', 'broadcastNoIVS')}
            id="random-attendee"
          >
            Attendee(No Ivs)
          </Button>
          <Button
            variant="contained"
            onClick={() => fakeLogin('presenter', 'broadcast')}
            id="random-presenter"
            className={classes.button}
          >
            Presenter
          </Button>
          <Button
            className={classes.button}
            variant="contained"
            id="random-vod"
            onClick={() => fakeLogin('attendee', 'vod')}
          >
            Vod
          </Button>
          <Button
            variant="contained"
            className={classes.button}
            onClick={() => fakeLogin('presenter', 'streaming', 'none', true)}
            id="random-presenter"
          >
            Recording Page
          </Button>
        </div>
      </Grid>

      {showAdditionalSessions &&
        sessionNumbers.map((sessionNumber) => (
          <Grid key={sessionNumber} item xs={12} className={classes.main}>
            <h6>Session {sessionNumber}</h6>
            <div className={classes.container}>
              <Button
                className={classes.button}
                variant="contained"
                onClick={() => fakeLogin('admin', 'settings', sessionNumber)}
              >
                Settings
              </Button>
              <Button
                className={classes.button}
                variant="contained"
                onClick={() => fakeLogin('attendee', 'broadcast', sessionNumber)}
                id={`random-attendee-${sessionNumber}`}
              >
                Attendee
              </Button>
              <Button
                className={classes.button}
                variant="contained"
                onClick={() => fakeLogin('presenter', 'broadcast', sessionNumber)}
                id={`random-presenter-${sessionNumber}`}
              >
                Presenter
              </Button>
              <Button
                className={classes.button}
                variant="contained"
                id={`random-vod-${sessionNumber}`}
                onClick={() => fakeLogin('attendee', 'vod', sessionNumber)}
              >
                Vod
              </Button>
              <Button
                variant="contained"
                className={classes.button}
                onClick={() => fakeLogin('presenter', 'streaming', sessionNumber, true)}
                id="random-presenter"
              >
                Recording Page
              </Button>
            </div>
          </Grid>
        ))}
    </Grid>
  );
}
