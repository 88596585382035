import { basePost, promisedPost } from 'api/apiDetour';

const baseEndpoint = 'session-questions';

const post = async ({ endpoint, data = {}, isSigned = false }) => {
  //eslint-disable-line
  let resp = await basePost(endpoint, data, baseEndpoint, isSigned);
  return resp;
};

export const getSessionQuestions = async () => {
  const resp = await post({ endpoint: 'list' });
  return resp;
};

export const updateQuestionVotes = (questionId, updateType, options) => {
  options = options ?? {};
  options.data = { updateType, questionId };
  return promisedPost('update-votes', baseEndpoint, 'questionUpvote', options);
};

export const createQuestion = async (question) => {
  const resp = await post({ endpoint: 'create', data: { question } });
  return resp;
};

export const hideQuestion = (questionId, options) => {
  options = options ?? {};
  options.data = { questionId };
  return promisedPost('hide', baseEndpoint, 'questionHide', options);
};

export const answerQuestion = (questionId, answer, options) => {
  options = options ?? {};
  options.data = { questionId, answer };
  return promisedPost('answer', baseEndpoint, 'questionAnswer', options);
};
