import { Button, LinearProgress, TextField, Typography } from '@material-ui/core';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import PublishIcon from '@material-ui/icons/Publish';
import { addVideo, updateVideoName } from 'api/routes/event';
import { createVod, getVods } from 'api/routes/vod';
import { putS3Video } from 'api/routes/sessionResources';
import axios from 'axios';
import logger from 'logger';
import PropTypes from 'prop-types';
import { useState } from 'react';
import Dropzone from 'react-dropzone';
import { useDispatch, useSelector } from 'react-redux';
import { setEventAction, setVodsAction, updateVodAction } from 'store/event/eventActions';
import { showNotificationAction } from 'store/utils/utilActions';
import { useStyles } from '../index.styles';
import find from 'lodash/find';
import last from 'lodash/last';
import deepEqual from 'react-fast-compare';
import { Modal, ModalBody, ModalFooter } from 'components/Modal';

const FileUpload = ({ open, onClose, isVodUpload = false }) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [uploadState, setUploadState] = useState('');
  const [uploadProgress, setUploadProgress] = useState(0);
  const [currentFileName, setCurrentFileName] = useState('');
  const [vodId, setVodId] = useState('');
  const [videoKey, setVideoKey] = useState('');
  const [originalFileName, setOriginalFileName] = useState('');
  const eventId = useSelector((store) => store.event.eventId);
  const videos = useSelector((store) => store.event.videos, deepEqual);
  const accountId = useSelector((store) => store.user.accountId);

  const loadVideo = (file) =>
    new Promise((resolve, reject) => {
      try {
        let video = document.createElement('video');
        video.preload = 'metadata';

        video.onloadedmetadata = function () {
          resolve(this);
        };

        video.onerror = function () {
          reject(new Error('Invalid video. Please select a video file.'));
        };

        video.src = window.URL.createObjectURL(file);
      } catch (e) {
        reject(e);
      }
    });

  const handleSubmit = async (files) => {
    if (files.length === 0) return;
    setUploadState('uploading');

    for (const file of files) {
      if (!isVodUpload && find(videos, (vid) => last(vid.key.split('/')) === file.name)) {
        dispatch(
          showNotificationAction({
            message: 'Video with this file name already exists!',
            type: 'error',
          })
        );
        setUploadState(null);
        setOriginalFileName('');
        setCurrentFileName('');
        setUploadProgress(0);
        return;
      }
      setOriginalFileName(file.name);
      const videoData = await loadVideo(file);
      const videoDuration = Math.round(videoData.duration);
      let s3Path = `${accountId}/${eventId}/${isVodUpload ? 'archive' : 'mp4s'}/${file.name}`;
      const s3url = await putS3Video(s3Path, file.type);
      const config = {
        headers: {
          'Content-Type': file.type,
        },
        onUploadProgress: (progressEvent) =>
          setUploadProgress((progressEvent.loaded / progressEvent.total) * 100),
      };

      try {
        const results = await axios.put(s3url.url, file, config);
        if (results.status === 200) {
          if (isVodUpload) {
            const vod = await createVod(s3Path, videoDuration, file.name);
            setVodId(vod.vod.vodId);
            const response = await getVods();
            dispatch(
              setVodsAction({
                vods: response.vods,
              })
            );
          } else {
            const updateEvent = await addVideo({
              name: file.name,
              key: file.name,
              size: file.size,
              duration: videoDuration,
            });

            const newVideo = find(updateEvent.event.videos, (vid) => vid.name === file.name);

            if (newVideo?.key) setVideoKey(newVideo.key);
            dispatch(
              setEventAction({
                eventId: updateEvent.event.eventId,
                title: updateEvent.event.title,
                description: updateEvent.event.description,
                videos: updateEvent.event.videos,
                standby: updateEvent.event.standby,
                blockedUsers: updateEvent.event.blockedUsers,
              })
            );
          }
        }
      } catch (error) {
        logger.error('FileUpload', { error });
      }
    }

    setUploadState('done');
  };

  const onChangeRenameFile = (event) => void setCurrentFileName(event.target.value);

  const onClickUploadAnother = async () => {
    if (currentFileName) {
      if (isVodUpload) {
        dispatch(updateVodAction({ vodId, name: currentFileName }));
      } else {
        const updatedEvent = await updateVideoName(currentFileName, videoKey);
        dispatch(
          setEventAction({
            eventId: updatedEvent.event.eventId,
            title: updatedEvent.event.title,
            description: updatedEvent.event.description,
            videos: updatedEvent.event.videos,
            standby: updatedEvent.event.standby,
            blockedUsers: updatedEvent.event.blockedUsers,
          })
        );
      }
    }

    setUploadState(null);
    setOriginalFileName('');
    setCurrentFileName('');
    setUploadProgress(0);
  };

  const onClickContinue = async () => {
    await onClickUploadAnother();

    onClose();
  };

  return (
    <Modal open={open} onClose={onClose} title="Upload Video">
      <ModalBody>
        <div className={classes.uploadState}>
          {uploadState === 'uploading' && <PublishIcon />}
          {uploadState === 'done' && (
            <div className="success">
              <CheckCircleIcon color="primary" />
              Upload successful!
            </div>
          )}
          {(uploadState === 'uploading' || uploadState === 'done') && (
            <>
              <div className="fileName">{originalFileName}</div>
              <LinearProgress variant={'determinate'} value={uploadProgress} />
            </>
          )}
        </div>

        {uploadState ? (
          <TextField
            value={currentFileName}
            placeholder={originalFileName}
            onChange={onChangeRenameFile}
            label="Rename Video"
            variant="outlined"
            size="small"
          />
        ) : (
          <Dropzone
            accept=".mp4, .webm"
            multiple={false}
            onDrop={(acceptedFiles) => handleSubmit(acceptedFiles)}
          >
            {({ getRootProps, getInputProps /*, isDragActive*/ }) => (
              <div {...getRootProps()} className={classes.uploadArea}>
                <input {...getInputProps()} />
                <PublishIcon />
                <Button variant="contained" color="primary">
                  Select File
                </Button>
                <Typography style={{ color: 'red', fontSize: '.75rem', marginTop: '.5rem' }}>
                  * Maximmum of 5GB upload size. Mp4 and Webm formats only.
                </Typography>
              </div>
            )}
          </Dropzone>
        )}
      </ModalBody>

      <ModalFooter>
        {uploadState && (
          <>
            <Button
              variant="outlined"
              color="primary"
              onClick={onClickUploadAnother}
              disabled={uploadState !== 'done'}
            >
              Upload Another File
            </Button>

            <Button
              variant="contained"
              color="primary"
              onClick={onClickContinue}
              disabled={uploadState !== 'done'}
            >
              Continue
            </Button>
          </>
        )}
      </ModalFooter>
    </Modal>
  );
};

FileUpload.propTypes = {
  open: PropTypes.bool,
  onClose: PropTypes.func.isRequired,
  isVodUpload: PropTypes.bool,
};

export default FileUpload;
