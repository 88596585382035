import { makeStyles, emphasize } from '@material-ui/core/styles';

const lineHeight = 1.2;

export const questionHeight = 74;
export const questionMargin = 6;
export const questionFontSize = 14;
export const questionLineHeight = Math.ceil(questionFontSize * lineHeight);
export const questionCharsPerLine = 22;

const useStyles = makeStyles((theme) => {
  const color = theme.type === 'light' ? theme.palette.background : theme.palette.secondary;
  const backgroundColor =
    theme.type === 'light' ? theme.palette.secondary : theme.palette.background;

  return {
    wrapper: {
      padding: `${questionMargin}px 0.75rem`,
    },
    root: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'space-evenly',
      height: '100%',
      backgroundColor: backgroundColor.main,
      border: '1px solid',
      borderColor: theme.type === 'light' ? backgroundColor.main : theme.palette.background.border,
      color: color.dark,
      padding: '0.5rem',
      ...theme.mixins.borderRadius(),
      ...theme.mixins.shadow(),
      '&[data-hidden="1"]': {
        color: '#b9b9b9',
        borderColor: 'currentColor',
        backgroundColor: '#3e3e3e',
        '& *': {
          color: `inherit!important`,
        },
      },
    },
    header: {
      display: 'flex',
      alignItems: 'center',
      marginBottom: '0.25rem',
    },
    userName: {
      fontWeight: 'bold',
      textTransform: 'uppercase',
      fontSize: '90%',
      color: theme.palette.primary.main,
    },
    time: {
      flexGrow: 1,
      marginLeft: '.5rem',
      textTransform: 'uppercase',
      fontSize: '70%',
    },
    voteCount: {
      fontSize: '90%',
      fontWeight: 'bold',
      marginRight: '0.5rem',
      marginLeft: '0.5rem',
      alignSelf: 'flex-end',
      color: color.dark,
      '&[data-self-voted="1"]': {
        color: theme.palette.primary.main,
      },
    },
    voteIcon: {
      fontSize: '100%',
      color: color.dark,
      '&[data-self-voted="1"]': {
        color: theme.palette.primary.main,
      },
    },
    question: {
      display: 'flex',
      alignItems: 'center',
      overflow: 'hidden',
      // maxHeight: '3.6em',
      lineHeight: lineHeight,
      whiteSpace: 'pre-wrap',
      wordBreak: 'break-word',
      fontSize: questionFontSize,
      marginBlockStart: 0,
      marginBlockEnd: 0,
      letterSpacing: 0,
    },
    answerArea: {
      position: 'absolute',
      left: '1.5rem',
      right: '1.5rem',
      top: 'calc(100% - 2rem)',
      zIndex: 1100,
      backgroundColor: backgroundColor.main,
      padding: '0.5rem 1rem',
      border: '1px solid black',
      ...theme.mixins.borderRadius(),
      ...theme.mixins.shadow(),
    },
    answerInput: {
      color: color.dark,
      fontSize: '90%',
      fontWeight: 'bold',
      borderBottom: '1px solid #b9b9b9',
      marginBottom: '1rem',
    },
    answerText: {
      flexGrow: 1,
      margin: '0 0.5rem',
    },
    buttons: {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      marginTop: '0.25rem',
    },
    deleteIcon: {
      color: color.dark,
    },
    blockUserIcon: {
      color: theme.palette.danger.main,
    },
    hiddenIcon: {
      color: color.dark,
    },
    editIcon: {
      color: color.dark,
    },
    cancelButton: {
      marginRight: '1rem',
      color: color.dark,
      transition: theme.transitions.create('color', {
        duration: theme.transitions.duration.shortest,
      }),
      '&:hover': {
        color: emphasize(color.dark),
      },
      '& .MuiSvgIcon-root': {
        fontSize: '85%',
        color: 'inherit',
      },
    },
    saveIcon: {
      fontSize: '85%',
      color: theme.palette.primary.main,
    },
    expand: {
      color: theme.palette.primary.main,
      fontSize: '0.9rem',
      fontWeight: 'bold',
      '& .MuiSvgIcon-root': {
        fontSize: '150%',
        transform: 'rotate(180deg)',
        transition: theme.transitions.create('transform', {
          duration: theme.transitions.duration.shortest,
        }),
      },
      '& *': {
        color: theme.palette.primary.main,
      },
    },
    expandOpen: {
      '& .MuiSvgIcon-root': {
        transform: 'rotate(0deg)',
      },
    },
    answerContainer: {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
    },
    answerButton: {
      backgroundColor: '#f4f4f4',
      color: '#111',
      '&:hover, &[data-answering="1"]': {
        backgroundColor: theme.palette.primary.main,
      },
    },
  };
});

export default useStyles;
