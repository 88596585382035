import { Switch } from '@material-ui/core';
import { makeStyles, emphasize } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '3.5rem',
    height: '1.65rem',
    padding: 0,
  },
  switchBase: {
    padding: '0.15rem',
    color: '#ff6a00',
  },
  thumb: {
    width: '1.3rem',
    height: '1.3rem',
    backgroundColor: emphasize(theme.palette.text.primary),
  },
  track: ({ texton, textoff }) => ({
    // background: 'transparent!important',
    backgroundColor: emphasize(theme.palette.background.paper),
    borderWidth: 1,
    borderStyle: 'solid',
    borderColor: emphasize(theme.palette.background.paper),
    opacity: '1 !important',
    borderRadius: 20,
    position: 'relative',
    '&:before, &:after': {
      display: 'inline-block',
      position: 'absolute',
      top: '50%',
      width: '50%',
      transform: 'translateY(-50%)',
      color: theme.palette.text.primary,
      textAlign: 'center',
      fontWeight: 'bold',
      fontSize: '80%',
    },
    '&:before': {
      content: `"${texton ?? 'YES'}"`,
      left: 2,
      opacity: 0,
    },
    '&:after': {
      content: `"${textoff ?? 'NO'}"`,
      right: 2,
    },
  }),
  checked: {
    track: {
      backgroundColor: 'red',
    },
    '&$switchBase': {
      // color: '#185a9d',
      transform: 'translateX(1.75rem)',
      '&:hover': {
        backgroundColor: 'rgba(24,90,257,0.08)',
      },
    },
    '& $thumb': {
      // backgroundColor: theme.palette.success.main,
      backgroundColor: theme.palette.primary.main,
    },
    '& + $track': {
      // background: 'transparent!important',
      backgroundColor: `${emphasize(theme.palette.background.paper)} !important`,
      '&:before': {
        opacity: 1,
      },
      '&:after': {
        opacity: 0,
      },
    },
  },
}));

function StyledSwitch(props) {
  const classes = useStyles(props);
  return <Switch {...props} classes={classes} />;
}

export default StyledSwitch;
export { StyledSwitch };
