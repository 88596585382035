import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import PropTypes from 'prop-types';
import VodsTable from './components/VodsTable';
import { useStyles } from '../../index.styles';

RecordingsTab.propTypes = {
  tabName: PropTypes.string.isRequired,
  setUploadOpen: PropTypes.func.isRequired,
};

export default function RecordingsTab({ tabName, setUploadOpen }) {
  const classes = useStyles();

  return (
    <div className={classes.page}>
      <Typography variant="h2">{tabName}</Typography>

      <div style={{ textAlign: 'right' }}>
        <Button
          onClick={() => void setUploadOpen(true)}
          variant="contained"
          color="primary"
          title="Placeholder"
          style={{ marginLeft: '1rem' }}
        >
          Upload Video
        </Button>
      </div>
      <div style={{ textAlign: 'right' }}></div>

      <div className={classes.fileTableWrapper}>
        <VodsTable />
      </div>
    </div>
  );
}
