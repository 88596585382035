import { IconButton, Snackbar } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import MuiAlert from '@material-ui/lab/Alert';
import PropTypes from 'prop-types';

const Alert = (props) => <MuiAlert elevation={6} variant="filled" {...props} />;

Notification.propTypes = {
  duration: PropTypes.number,
  verticalLocation: PropTypes.string,
  horizontalLocation: PropTypes.string,
  open: PropTypes.bool.isRequired,
  setOpen: PropTypes.func.isRequired,
  message: PropTypes.string.isRequired,
  notificationType: PropTypes.string.isRequired,
  icon: PropTypes.any,
  clickHandler: PropTypes.func,
  notificationStyles: PropTypes.object,
};

Notification.defaultProps = {
  duration: 6000,
  verticalLocation: 'top',
  horizontalLocation: 'right',
  clickHandler: () => {},
  notificationStyles: {},
};

export default function Notification(props) {
  const handleClose = (reason) => {
    props.setOpen(false);
    if (!['clickaway', 'close', 'duration'].includes(reason)) {
      props.clickHandler();
    }
  };

  return (
    <Snackbar
      anchorOrigin={{
        vertical: props.verticalLocation,
        horizontal: props.horizontalLocation,
      }}
      open={props.open}
      autoHideDuration={props.duration}
      onClick={() => handleClose('default')}
      onClose={() => handleClose('duration')}
      style={{ cursor: 'pointer' }}
    >
      <Alert
        icon={props.icon ? <props.icon fontSize="inherit" /> : false}
        style={{ minWidth: 250 }}
        onClose={() => handleClose('close')}
        severity={props.notificationType}
        data-testid="alert"
        action={
          <IconButton
            size="small"
            aria-label="close-action"
            color="inherit"
            onClick={() => handleClose('close')}
            data-testid="close-one"
          >
            <CloseIcon fontSize="small" />
          </IconButton>
        }
      >
        <span style={{ ...props.notificationStyles }}>{props.message}</span>
      </Alert>
    </Snackbar>
  );
}
