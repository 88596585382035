import { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Button, Typography } from '@material-ui/core';
import { ToggleButton, ToggleButtonGroup } from '@material-ui/lab';
import PropTypes from 'prop-types';
import {
  pollIsEnded,
  pollGetTotalAnswers,
  useMyPollVote,
  PropTypePoll,
  pollIsRunning,
} from 'utils/polling';
import ButtonStrip from 'components/ButtonStrip';
import useInterval from 'hooks/useInterval';
import useCommonClasses from 'components/polling/PollCommon.style';
import PollOptionResult from 'components/polling/PollOptionResult';
import PollHeader from 'components/polling/PollHeader';
import { setFocusedPollIdAction } from 'store/session-polling/sessionPollingActions';

PollAttendee.propTypes = {
  poll: PropTypePoll,
  expanded: PropTypes.bool,
};

export default function PollAttendee({ poll, expanded }) {
  const dispatch = useDispatch();
  const commonClasses = useCommonClasses();
  const [resultDisplayType, setResultDisplayType] = useState('percent');

  const focused = useSelector((state) => state.sessionPolling.focusedPollId === poll.pollId);
  if (focused) expanded = true;

  const myVote = useMyPollVote(poll.pollId);

  const [forceUpdateTick, setForceUpdateTick] = useState(0);
  useInterval(() => setForceUpdateTick(forceUpdateTick + 1), 1000);

  const totalAnswers = pollGetTotalAnswers(poll);
  const options = Object.keys(poll.answerOptions).sort((a, b) =>
    poll.answerOptions[a].order > poll.answerOptions[b].order ? 1 : -1
  );

  const onClickDetails = () => {
    dispatch(setFocusedPollIdAction({ focusedPollId: poll.pollId }));
  };

  const onChangeResultDisplayType = (event, value) =>
    void setResultDisplayType(value || resultDisplayType);

  const isEnded = pollIsEnded(poll);
  const isRunning = pollIsRunning(poll);

  return (
    <div className={commonClasses.poll}>
      <PollHeader poll={poll} />

      {expanded ? (
        <>
          <hr />

          <div className={commonClasses.optionsHeader}>
            <div>Details{poll.resultsShared && ' & Results'}</div>

            {poll.resultsShared && (
              <ToggleButtonGroup
                exclusive
                className={commonClasses.switcher}
                size="small"
                value={resultDisplayType}
                onChange={onChangeResultDisplayType}
              >
                <ToggleButton value="numeric">#</ToggleButton>
                <ToggleButton value="percent">%</ToggleButton>
              </ToggleButtonGroup>
            )}
          </div>

          <div className={commonClasses.options}>
            {options.map((option, i) => (
              <PollOptionResult
                key={i}
                optionIdx={i}
                option={option}
                showResults={poll.resultsShared}
                isCorrect={poll.correctAnswer === option && isEnded}
                answerCount={poll.answerOptions[option].count}
                totalAnswers={totalAnswers}
                isMyVote={option === myVote}
                asNumeric={resultDisplayType === 'numeric'}
              />
            ))}
          </div>

          {poll.resultsShared && (
            <div className={commonClasses.shareResultsArea}>
              <Typography variant="body2">Results are available for all attendees.</Typography>
            </div>
          )}
        </>
      ) : (
        <ButtonStrip extraStyles={isRunning ? commonClasses.live : ''}>
          <Button variant="text" color="secondary" onClick={onClickDetails}>
            {isRunning ? 'Vote' : `Details${isEnded && ' & Results'}`}
          </Button>
        </ButtonStrip>
      )}
    </div>
  );
}
