import PropTypes from 'prop-types';
import ScreenPage from 'components/ScreenPage';

LoadingPage.propTypes = {
  children: PropTypes.node,
};

export default function LoadingPage({ children }) {
  return (
    <ScreenPage type="loading" header="Loading">
      {children}
    </ScreenPage>
  );
}
