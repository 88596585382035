import React from 'react';
import { useDispatch } from 'react-redux';
import { Typography } from '@material-ui/core';
import { withStyles, fade } from '@material-ui/core/styles';
import IconButton from '@material-ui/core/IconButton';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import { setPopoutAction } from 'store/app/appActions';
import PropTypes from 'prop-types';

const styles = (theme) => ({
  root: {
    backgroundColor: theme.palette.background.sidePanelTitle || theme.palette.background.main,
    borderBottom: theme.palette.type === 'light' ? '1px solid' : 0,
    borderBottomColor: fade(theme.palette.text.primary, 0.25),
    lineHeight: '1',
    height: theme.sidePanel.titleHeight + 'rem',
    paddingLeft: theme.sidePanel.gutter,
    paddingRight: theme.sidePanel.gutter,
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    [theme.breakpoints.downWithLandscape('mobile')]: {
      padding: 0,
      '& > span': {
        flexGrow: 1,
        textAlign: 'center',
      },
    },
  },
  closeButton: {
    padding: 0,
  },
});

const Title = ({ children, classes }) => {
  const dispatch = useDispatch();

  const handleClose = () => dispatch(setPopoutAction({ popout: { open: false, name: '' } }));

  return (
    <Typography className={classes.root} variant="h5">
      <span>{children}</span>

      <IconButton
        id="close-sidebar"
        variant="outlined"
        className={classes.closeButton}
        onClick={handleClose}
      >
        <ChevronRightIcon />
      </IconButton>
    </Typography>
  );
};

export default withStyles(styles)(Title);

Title.propTypes = {
  children: PropTypes.node,
  classes: PropTypes.object,
};
