import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Notification from 'components/notifications/Notification';
import { setConversationIdAction } from 'store/session-message/sessionMessageActions';
import { closeNotificationAction } from 'store/utils/utilActions';
import { setPopoutAction } from 'store/app/appActions';
import PropTypes from 'prop-types';
NotificationWrapper.propTypes = {
  children: PropTypes.node,
};
export default function NotificationWrapper({ children }) {
  const open = useSelector((store) => store.utils.open);
  const message = useSelector((store) => store.utils.message);
  const type = useSelector((store) => store.utils.type);
  const icon = useSelector((store) => store.utils.icon);
  const notiPopoutName = useSelector((store) => store.utils.notiPopoutName);
  const notiPopoutData = useSelector((store) => store.utils.notiPopoutData);
  const duration = useSelector((store) => store.utils.duration);
  const isNotRecordingUser = useSelector((store) => !store.user.isRecordingUser);
  const currentPopoutIsOpen = useSelector((store) => store.app.popout.open);
  const currentPopoutName = useSelector((store) => store.app.popout.name);
  const dispatch = useDispatch();

  return (
    <>
      <Notification
        open={isNotRecordingUser && open}
        duration={duration}
        notificationStyles={{ cursor: 'pointer' }}
        clickHandler={() => {
          if (notiPopoutName && (notiPopoutName !== currentPopoutName || currentPopoutIsOpen)) {
            if (notiPopoutData && notiPopoutData.conversationId) {
              dispatch(setConversationIdAction({ conversationId: notiPopoutData.conversationId }));
            }

            dispatch(
              setPopoutAction({
                popout: {
                  open: true,
                  name: notiPopoutName,
                },
              })
            );
          }
        }}
        setOpen={() => dispatch(closeNotificationAction())}
        verticalLocation="bottom"
        message={message}
        notificationType={type}
        icon={icon}
      />
      {children}
    </>
  );
}
