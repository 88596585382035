import { createReducer } from '@reduxjs/toolkit';
import { merge } from 'lodash';
import { buildActionStatus, standardActions } from 'store/utils';
import * as ua from './userActions';

const initialState = {
  accountId: '',
  firstName: '',
  lastName: '',
  email: '',
  moodle: false,
  classMode: null,
  userId: '',
  externalAccountId: '',
  isRecordingUser: false,
  meetings: [],
  admin: false,
  role: '',
  isLoggedIn: null,
  reloadUrl: null,
  actionStatus: buildActionStatus(ua),
};

const userReducer = createReducer(initialState, {
  ...standardActions(ua),
  [ua.setUserAction]: (state, { payload }) => {
    merge(state, payload);
  },
  [ua.setIsLoggedInAction]: (state, { payload }) => {
    state.isLoggedIn = payload.isLoggedIn;
  },
  [ua.setReloadUrlAction]: (state, { payload }) => {
    state.reloadUrl = payload.reloadUrl;
  },
  [ua.resetUserAction]: () => initialState,
});
export default userReducer;
