import { createAction as ca } from '@reduxjs/toolkit';

export const chatMessageAction = ca('sessionMessage/chatMessageAction');
export const errorAction = ca('sessionMessage/errorAction');
export const initAction = ca('sessionMessage/initAction');
export const putMessageAction = ca('sessionMessage/putMessageAction');
export const removeMessageAction = ca('sessionMessage/removeMessageAction');
export const updateMessageAction = ca('sessionMessage/updateMessageAction');
export const resetStateAction = ca('sessionMessage/resetStateAction');
export const setAllMessagesAction = ca('sessionMessage/setAllMessagesAction');
export const setUnreadMessagesAction = ca('sessionMessage/setUnreadMessagesAction');
export const setConversationIdAction = ca('sessionMessage/setConversationIdAction');
export const successAction = ca('sessionMessage/successAction');
