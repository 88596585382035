import { basePost, promisedPost } from 'api/apiDetour';

const baseEndpoint = 'session-message';

const post = async ({ endpoint, data = {}, isSigned = false }) => {
  const resp = await basePost(endpoint, data, baseEndpoint, isSigned);
  return resp;
};

export const getMessages = async () => {
  const resp = await post({ endpoint: 'get' });
  return resp;
};

export const createGroupMessage = (message, options) => {
  options = options ?? {};
  options.data = { message };
  return promisedPost('create-group', baseEndpoint, 'chat', options);
};

export const createPrivateMessage = (message, recipientId, recipientName, options) => {
  options = options ?? {};
  options.data = { message, recipientId, recipientName };
  return promisedPost('create-private', baseEndpoint, 'chat', options);
};

export const createQueueMessage = (message, options) => {
  options = options ?? {};
  options.data = { message };
  return promisedPost('create-queue', baseEndpoint, 'chat', options);
};

export const createPresentersMessage = (message, options) => {
  options = options ?? {};
  options.data = { message };
  return promisedPost('create-presenters', baseEndpoint, 'chat', options);
};
