import { makeStyles } from '@material-ui/core/styles';
import { Button, Typography } from '@material-ui/core';
import { Modal, ModalBody, ModalFooter } from 'components/Modal';
import PropTypes from 'prop-types';

const useStyles = makeStyles((theme) => ({
  startButton: {
    backgroundColor: theme.palette.danger.main,
  },
}));

PollInProgressModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  onStart: PropTypes.func.isRequired,
  pollName: PropTypes.string,
  pollQuestion: PropTypes.string.isRequired,
};

export default function PollInProgressModal({ isOpen, onClose, onStart, pollName, pollQuestion }) {
  const classes = useStyles();

  return (
    <Modal open={isOpen} onClose={onClose} title="Attention: Poll In Progress" variant="small">
      <ModalBody>
        <Typography variant="body1">
          Start New will end this poll:
          <br />
          {pollName && (
            <>
              <b>Name:</b> {pollName}
              <br />
            </>
          )}
          <b>Question:</b> {pollQuestion}
        </Typography>
      </ModalBody>

      <ModalFooter>
        <Button variant="contained" color="secondary" onClick={onClose}>
          Cancel
        </Button>

        <Button variant="contained" className={classes.startButton} onClick={onStart}>
          Start New Poll
        </Button>
      </ModalFooter>
    </Modal>
  );
}
