import Box from '@material-ui/core/Box';
import { makeStyles } from '@material-ui/core/styles';
import useTranscription from 'hooks/useTranscription';
import React from 'react';

export default function MeetingCaptions() {
  const transcription = useTranscription();
  const classes = useStyles();

  return <Box className={classes.root}>{transcription}</Box>;
}

const useStyles = makeStyles(() => ({
  root: {
    position: 'absolute',
    top: 'calc(100vh - 100px);',
    left: '5%',
    zIndex: '9999',
    pointerEvents: 'none',
    padding: '1em',
    backgroundColor: 'rgba(.5,.5,.5,.5)',
    color: 'white',
    fontSize: '1.3em',
  },
}));
