import { createReducer } from '@reduxjs/toolkit';
import * as sra from 'store/session-resource/sessionResourceActions';
import { buildActionStatus, standardActions } from 'store/utils';

const initialState = {
  actionStatus: buildActionStatus(sra),
  currentInput: 'Standby.mp4',
  videoKey: 'Standby.mp4',
  shouldLoop: true,
  showVideo: true,
  videoTimeSet: null,
  nextInput: { key: '', name: '' },
  mediaProgress: { currentTime: 0, endTime: 0 },
};

const sessionResourceReducer = createReducer(initialState, {
  ...standardActions(sra),
  [sra.resetStateAction]: () => ({
    ...initialState,
  }),
  [sra.setSessionResource]: (
    state,
    { payload: { showVideo, videoKey, shouldLoop, currentInput, nextInput } }
  ) => {
    state.videoKey = videoKey;
    state.shouldLoop = shouldLoop;
    state.showVideo = showVideo;
    state.currentInput = currentInput;
    state.nextInput = nextInput;
  },
  [sra.setInputAction]: (state, { payload: { showVideo, videoKey, shouldLoop, currentInput } }) => {
    state.videoKey = videoKey;
    state.shouldLoop = shouldLoop;
    state.showVideo = showVideo;
    state.currentInput = currentInput;
  },
  [sra.setNextInputAction]: (state, { payload: { nextInput } }) => {
    state.nextInput = nextInput;
  },
  [sra.setCurrentInputAction]: (state, { payload: { currentInput } }) => {
    state.currentInput = currentInput;
  },
  [sra.setVideoTimeSetAction]: (state, { payload: { videoTimeSet } }) => {
    state.videoTimeSet = videoTimeSet;
  },
  [sra.setMediaProgressAction]: (state, { payload: { currentTime, endTime } }) => {
    state.mediaProgress = { currentTime, endTime };
  },
});

export default sessionResourceReducer;
