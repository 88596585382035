import { createAction as ca } from '@reduxjs/toolkit';
export const authorizeUserAction = ca('user/authorizeUserAction');
export const errorAction = ca('user/errorAction');
export const initAction = ca('user/initAction');
export const initializeAnalyticsRouteAction = ca('user/initializeAnalyticsRouteAction');
export const initializeMeetingRouteAction = ca('user/initializeMeetingRouteAction');
export const initializeRecordingRouteAction = ca('user/initializeRecordingRouteAction');
export const initializeSettingsRouteAction = ca('user/initializeSettingsRouteAction');
export const initializeTokenAction = ca('user/initializeTokenAction');
export const initializeVodRouteAction = ca('user/initializeVodRouteAction');
export const resetUserAction = ca('user/resetUserAction');
export const setIsLoggedInAction = ca('user/setIsLoggedInAction');
export const setReloadUrlAction = ca('user/setReloadUrlAction');
export const setUserAction = ca('user/setUserAction');
export const successAction = ca('user/successAction');
