import { PhoneIcon } from 'svg';
import { makeStyles } from '@material-ui/core/styles';
import Tooltip from '@material-ui/core/Tooltip';
import Avatar from '@material-ui/core/Avatar';
import { useState } from 'react';
import ExitMeetingDialog from 'components/ExitMeetingDialog';

const useStyles = makeStyles((theme) => ({
  exitContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    margin: '.5rem auto',
  },
  exitIcon: {
    fontSize: '2rem',
    cursor: 'pointer',
    color: 'white',
    backgroundColor: '#C32525',
    width: theme.spacing(4),
    height: theme.spacing(4),

    '&:hover': {
      opacity: '.75',
    },

    [theme.breakpoints.downHeight('md')]: {
      width: theme.spacing(3),
      height: theme.spacing(3),
    },
  },
}));

export default function ExitButton() {
  const classes = useStyles();
  const [showDialog, setshowDialog] = useState(false);

  return (
    <div className={classes.exitContainer}>
      <Tooltip title="Leave Meeting" placement="left">
        <Avatar className={classes.exitIcon}>
          <PhoneIcon onClick={() => setshowDialog(!showDialog)} />
        </Avatar>
      </Tooltip>
      <ExitMeetingDialog open={showDialog} setOpen={setshowDialog} />
    </div>
  );
}
