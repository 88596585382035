import { makeStyles } from '@material-ui/core/styles';
import { Typography, IconButton } from '@material-ui/core';
import ReplayIcon from '@material-ui/icons/Replay';
import moment from 'moment';
import PropTypes from 'prop-types';
import { parseUrls } from 'utils/utilMethods';

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor:
      theme.type === 'dark' ? theme.palette.secondary.main : theme.palette.background.main,
    color: theme.type === 'dark' ? theme.palette.background.dark : theme.palette.secondary.main,
    margin: `0.5rem ${theme.sidePanel.gutter}`,
    padding: '1rem',
    alignSelf: 'flex-start',
    border: '1px solid #0002',
    ...theme.mixins.borderRadius(),
    ...theme.mixins.shadow(true),
    '&[data-isme="1"]': {
      alignSelf: 'flex-end',
      backgroundColor: theme.palette.primary.main,
      color: `${theme.palette.secondary.main}!important`,
      '& .MuiLink-root': {
        color: `${theme.palette.text.primary}!important`,
      },
    },
    '&[data-status="pending"]': {
      opacity: '0.6 !important',
    },
    '&[data-status="error"]': {
      borderColor: 'red',
      borderWidth: 2,
    },
  },
  header: {
    display: 'flex',
    alignItems: 'center',
  },
  private: {
    textTransform: 'uppercase',
    fontSize: '0.6rem',
    color: `${theme.palette.background.main}!important`,
    backgroundColor: theme.palette.text.primary,
    height: '2em',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    padding: '0 0.5rem',
    borderRadius: '0.5rem 0.5rem 0 0',
    border: '1px solid black',
    boxShadow: '-2px 2px 4px #0004',
    whiteSpace: 'nowrap',
    marginTop: '-1.3rem',
    float: 'left',
  },
  retry: {
    marginLeft: '0.5rem',
    '& .MuiSvgIcon-root': {
      fontSize: '1rem',
    },
  },
  userName: {
    fontWeight: 'bold',
    textTransform: 'uppercase',
    fontSize: '90%',
    color: theme.palette.primary.main,
    marginRight: '0.5rem',
    '&[data-isme="1"]': {
      color: `${theme.palette.secondary.main}!important`,
    },
  },
  date: {
    opacity: 0.75,
    fontSize: '90%',
  },
  message: {
    whiteSpace: 'pre-wrap',
    wordBreak: 'break-word',
    marginBottom: 0,
  },
  menuButton: {
    bottom: '0.5em',
    marginLeft: '1rem',
  },
  menuIcon: {},
}));

ChatMessage.propTypes = {
  isMe: PropTypes.bool,
  status: PropTypes.string,
  message: PropTypes.object.isRequired,
  retry: PropTypes.func,
};

ChatMessage.defaultProps = {
  status: 'sent',
};

export default function ChatMessage({ message, isMe, status, retry }) {
  const classes = useStyles();

  const onClickRetry = async () => {
    await retry(message);
  };

  return (
    <div
      className={classes.root}
      data-testid="message-box"
      data-isme={isMe ? 1 : 0}
      data-status={status}
    >
      {message.recipientId && (
        <Typography className={classes.private}>{`Private${
          isMe ? ` to ${message.recipientName}` : ''
        }`}</Typography>
      )}
      <div className={classes.header}>
        <Typography className={classes.userName} data-isme={isMe ? 1 : 0}>
          {message.senderName}
        </Typography>
        <Typography className={classes.date}>
          {moment(parseInt(message.timestamp)).format('h:mm A')}
        </Typography>
        {status === 'error' && (
          <div className={classes.retry}>
            <IconButton onClick={onClickRetry}>
              <ReplayIcon />
            </IconButton>
          </div>
        )}
      </div>
      <p className={classes.message}>{parseUrls(message.message)}</p>
    </div>
  );
}
