import { makeStyles } from '@material-ui/core/styles';
import { Typography } from '@material-ui/core';
import PropTypes from 'prop-types';
import { pollIsRunning, PropTypePoll } from 'utils/polling';
import moment from 'moment';

// eslint-disable-next-line no-unused-vars
const useStyles = makeStyles((theme) => ({
  header: {
    display: 'flex',
    flexDirection: 'column',
    padding: '0.5rem',

    '& > *:last-child': {
      marginTop: '0.5rem',
    },
  },
}));

function formatTime(time) {
  const formattedTime = moment(time);
  if (!formattedTime.isSame(new Date(), 'day')) return formattedTime.format('MMM Do, h:mm a');
  return `at ${formattedTime.format('hh:mm a')}`;
}

function timeStatus(poll) {
  if (poll.endedAt > 0 && !pollIsRunning(poll)) return `Ended ${formatTime(poll.endedAt)}`;

  if (poll.startedAt > 0) {
    let text = `Started at ${formatTime(poll.startedAt)}`;

    if (poll.endedAt > 0) text += ` and will end at ${formatTime(poll.endedAt)}`;

    return text;
  }

  return 'Not yet started';
}

PollHeader.propTypes = {
  poll: PropTypePoll,
  canManage: PropTypes.bool,
};

export default function PollHeader({ poll, canManage }) {
  const classes = useStyles();

  return (
    <div className={classes.header}>
      {canManage && poll.name && (
        <Typography variant="body2">
          <i>{poll.name}</i>
        </Typography>
      )}

      <Typography variant="body1">{poll.questionText}</Typography>

      <Typography variant="caption">{timeStatus(poll)}</Typography>
    </div>
  );
}
