import { createReducer } from '@reduxjs/toolkit';
import defaultPermissions from 'constants/defaultPermissions';
import { merge, findIndex } from 'lodash';
import * as ea from 'store/event/eventActions';
import { buildActionStatus, standardActions } from 'store/utils';

const initialState = {
  actionStatus: buildActionStatus(ea),
  eventId: null,
  title: '',
  description: '',
  videos: [],
  standby: {},
  vods: [],
  meetingEvents: [],
  permissions: defaultPermissions,
  disabledFeatures: {},
};

const eventReducer = createReducer(initialState, {
  ...standardActions(ea),
  [ea.resetStateAction]: () => ({
    ...initialState,
  }),
  [ea.setEventAction]: (state, { payload }) => {
    merge(state, payload);

    if (payload.disabledFeatures) {
      const disabledFeatures = {};
      for (let feature of payload.disabledFeatures) {
        disabledFeatures[feature] = true;
      }
      state.disabledFeatures = disabledFeatures;
    }
  },
  [ea.setVideosAction]: (state, { payload: { videos } }) => {
    state.videos = videos;
  },
  [ea.setVodsAction]: (state, { payload: { vods } }) => {
    state.vods = vods;
  },
  [ea.setVodAction]: (state, { payload: { vod } }) => {
    let idx = findIndex(state.vods, ['vodId', vod.vodId]);
    if (idx > -1) {
      state.vods[idx] = vod;
    } else {
      state.vods.push(vod);
    }
  },
  [ea.setPermissionAction]: (state, { payload: { id, perm, on } }) => {
    const permissions = state.permissions;
    permissions[id] = permissions[id] || {};
    permissions[id][perm] = on;
  },
  [ea.updatePermissionsAction]: (state, { payload: { groupName, permission } }) => {
    state.permissions[groupName] = permission;
  },
  [ea.setTypeAction]: (state, { payload }) => {
    state.type = payload;
  },
  [ea.setMeetingEventsAction]: (state, { payload }) => {
    state.meetingEvents.push(payload);
  },
  [ea.resetMeetingEventsAction]: (state) => {
    state.meetingEvents = [];
  },
  [ea.setFeatureDisabledAction]: (state, { payload: { feature, disabled } }) => {
    state.disabledFeatures[feature] = disabled;
  },
  [ea.setFeaturesDisabledAction]: (state, { payload: { disabledFeatures } }) => {
    state.disabledFeatures = disabledFeatures;
  },
});

export default eventReducer;
