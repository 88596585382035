import { getAccounts } from 'api/routes/account';
import { getEvents } from 'api/routes/event';
import { getUsers } from 'api/routes/user';
import { ecsInfo } from 'api/routes/sessionResources';
import { listSessions } from 'api/routes/session';
import { getPollTemplates } from 'api/routes/sessionPolls';
import { takeEvery, call } from 'redux-saga/effects';
import * as ma from 'store/management/managementActions';
import { put } from 'store/utils';

function* getDataFlowSaga({ payload }) {
  const key = 'getDataFlowSaga';
  yield put(ma.initAction({ key }));
  const getActiveSessions = true;
  let data = null;
  try {
    const { dataKey } = payload;
    switch (dataKey) {
      case 'accounts':
        const { accounts } = yield call(getAccounts);
        data = accounts;
        break;
      case 'events':
        const { events } = yield call(getEvents, getActiveSessions);
        for (let evt of events) {
          evt.manageInfo = { accountId: evt.accountId, eventId: evt.eventId };
        }
        data = events;
        break;
      case 'sessions':
        const { sessions } = yield call(listSessions);
        data = sessions;
        break;
      case 'polls':
        const { polls } = yield call(getPollTemplates);
        data = polls;
        break;
      case 'users':
        const { users } = yield call(getUsers);
        data = users;
        break;
      case 'ecs':
        const { ecs } = yield call(ecsInfo);
        data = ecs;
        break;
    }
    if (data) {
      yield put(ma.setDataAction({ dataKey, data }));
    }

    yield put(ma.successAction({ key }));
  } catch (error) {
    yield put(ma.errorAction({ key, error }));
  }
}

export default function* sessionWatcher() {
  yield takeEvery(ma.getDataAction().type, getDataFlowSaga);
}
