import { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { makeStyles, fade } from '@material-ui/core/styles';
import { Button, Typography } from '@material-ui/core';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import PropTypes from 'prop-types';
import PollOptionResult from 'components/polling/PollOptionResult';
import { answerPoll } from 'api/routes/sessionPollAnswers';
import { pollIsEnded, pollGetTimeDisplay, useMyPollVote, PropTypePoll } from 'utils/polling';
import { setMyAnswerAction } from 'store/session-polling/sessionPollingActions';
import useInterval from 'hooks/useInterval';

const useStyles = makeStyles((theme) => ({
  pollComplete: {
    textAlign: 'center',

    // Check mark
    '& > *:first-child': {
      fontSize: '2.5rem',
      color: theme.palette.success.main,
      marginBottom: '0.5rem',
    },
  },
  question: {
    borderWidth: 1,
    borderStyle: 'solid',
    borderColor: fade(theme.palette.text.primary, 0.25),
    borderRadius: '0.5rem',
    padding: '0.5rem',
    marginTop: '2rem',
    marginBottom: '2rem',
  },
  options: {
    marginTop: '1rem',
    ...theme.mixins.flexVerticalList('0.5rem'),
  },
  option: {
    cursor: 'pointer',
    padding: '0.5rem',
    borderWidth: 1,
    borderStyle: 'solid',
    borderColor: fade(theme.palette.text.primary, 0.25),
    borderRadius: '0.5rem',

    '&[data-selected="1"]': {
      borderColor: theme.palette.primary.main,

      '& > *:first-child': {
        color: `${theme.palette.primary.main}!important`,
      },
    },

    // Check and selection indicator
    '& > *:first-child': {
      display: 'flex',
      alignItems: 'center',
      marginBottom: '0.5rem',

      '& > *:last-child': {
        fontWeight: 'bold',
        marginLeft: '0.5ch',
      },
    },

    // Option text
    '& > *:nth-child(2)': {},
  },
  instructions: {
    fontSize: '0.9rem',
  },
  timer: {
    textAlign: 'center',

    '& > *:first-child': {
      fontSize: '2rem',
      fontWeight: 'bold',
    },

    '& > *:last-child': {
      fontSize: '0.8rem',
      fontWeight: 'bold',
    },
  },
  bottomButtons: {
    marginTop: '2rem',
    ...theme.mixins.flexVerticalList('0.5rem'),

    '& > *': {
      width: '100%',
      padding: '1rem',
      textTransform: 'uppercase',
    },
  },
}));

PollAttendeeActive.propTypes = {
  poll: PropTypePoll,
  onClickSeeResults: PropTypes.func.isRequired,
  setLookingAtActivePollId: PropTypes.func.isRequired,
  onClickBack: PropTypes.func.isRequired,
};

export default function PollAttendeeActive({
  poll,
  onClickSeeResults,
  setLookingAtActivePollId,
  onClickBack,
}) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [selectedAnswerIdx, setSelectedAnswerIdx] = useState(-1);

  const { pollId } = poll;
  const options = Object.keys(poll.answerOptions).sort((a, b) =>
    poll.answerOptions[a].order > poll.answerOptions[b].order ? 1 : -1
  );
  const myVote = useMyPollVote(pollId);
  const myVoteIdx = options.findIndex((option) => option === myVote);

  useEffect(() => {
    setLookingAtActivePollId(pollId);
  }, [pollId]);

  useEffect(() => {
    if (myVoteIdx >= 0) {
      setSelectedAnswerIdx(myVoteIdx);
    }
  }, [myVoteIdx]);

  const [forceUpdateTick, setForceUpdateTick] = useState(0);
  useInterval(() => setForceUpdateTick(forceUpdateTick + 1), 1000);

  const onClickSubmitAnswer = async () => {
    const { answer, success } = await answerPoll(
      pollId,
      options[selectedAnswerIdx],
      poll.isEventPoll
    );
    if (success) {
      dispatch(setMyAnswerAction({ pollId, answer }));
    }
  };

  const onClickAnswer = (event, idx) => {
    setSelectedAnswerIdx(idx);
  };

  const isEnded = pollIsEnded(poll);

  return (
    <div className={classes.poll}>
      {isEnded ? (
        <div className={classes.pollComplete}>
          <CheckCircleIcon />
          <div>
            <b>Poll Complete</b>
          </div>
          <br />
          {myVote && <div>THANK YOU FOR PARTICIPATING</div>}
        </div>
      ) : (
        poll.displayDuration &&
        !!poll.endedAt && (
          <div className={classes.timer}>
            <div>{pollGetTimeDisplay(poll)}</div>
            <div>TIME REMAINING</div>
          </div>
        )
      )}

      <div className={classes.question}>{poll.questionText}</div>

      {!isEnded && (
        <div className={classes.instructions}>
          <div>There are {options.length} choices.</div>
          <div>
            <b>Select one.</b>
          </div>
          <div>You have until the poll closes to change your answer.</div>
        </div>
      )}

      <div className={classes.options}>
        {isEnded ? (
          myVote ? (
            <PollOptionResult
              optionIdx={myVoteIdx}
              option={myVote}
              showResults={false}
              isCorrect={false}
              answerCount={0}
              totalAnswers={0}
              isMyVote={true}
            />
          ) : null
        ) : (
          options.map((option, i) => (
            <div
              key={i}
              className={classes.option}
              data-idx={i}
              id={`poll-answer-${i}`}
              data-selected={i === selectedAnswerIdx ? 1 : 0}
              onClick={(event) => onClickAnswer(event, i)}
            >
              <div>
                {i === selectedAnswerIdx ? <CheckBoxIcon /> : <CheckBoxOutlineBlankIcon />}
                <Typography>{i + 1}</Typography>
              </div>

              <div>{option}</div>
            </div>
          ))
        )}
      </div>

      <div className={classes.bottomButtons}>
        {isEnded ? (
          <>
            {poll.resultsShared && (
              <Button
                variant="contained"
                color="primary"
                onClick={(event) => onClickSeeResults(event, pollId)}
              >
                See Results
              </Button>
            )}
            <Button variant="contained" onClick={onClickBack}>
              Close
            </Button>
          </>
        ) : (
          <>
            {selectedAnswerIdx >= 0 && (
              <Button
                variant="contained"
                color="primary"
                id="poll-submit"
                onClick={onClickSubmitAnswer}
                disabled={myVoteIdx === selectedAnswerIdx}
              >
                {myVoteIdx === selectedAnswerIdx ? 'Submitted' : 'Submit'}
              </Button>
            )}
          </>
        )}
      </div>
    </div>
  );
}
