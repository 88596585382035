import { basePost } from 'api/apiDetour';
const post = async ({ endpoint, data = {}, isSigned = false }) => {
  let resp = await basePost(endpoint, data, 'reporting', isSigned);
  return resp;
};

export const questionsCsv = async (accountId, date) => {
  let resp = await post({ endpoint: 'questions-csv', data: { date, accountId } });
  return resp;
};

export const userAnalytics = async (accountId, date) => {
  let resp = await post({ endpoint: 'user-analytics', data: { date, accountId } });
  return resp;
};

export const vodAnalytics = async (accountId, date) => {
  let resp = await post({ endpoint: 'vod-analytics', data: { date, accountId } });
  return resp;
};

export const managementAnalaytics = async (accountId, eventIds) => {
  let resp = await post({
    endpoint: 'user-analytics',
    data: { accountId, eventIds, management: true },
  });
  return resp;
};
export const reportingTesting = async (accountId, dataType, startTimestamp, endTimestamp) => {
  let resp = await post({
    endpoint: 'reporting-testing',
    data: { accountId, dataType, startTimestamp, endTimestamp },
  });
  return resp;
};
