import { ChatIcon as ChatSVGIcon } from 'svg';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import DefaultIcon from './DefaultIcon';
import { getFeatureName } from 'constants/features';

ChatIcon.propTypes = {
  hideIcon: PropTypes.bool.isRequired,
};

export default function ChatIcon({ hideIcon }) {
  const unreadMessages = useSelector((store) => store.sessionMessage.unreadMessages);

  return (
    <DefaultIcon
      IconComp={ChatSVGIcon}
      badgeContent={unreadMessages}
      buttonId="chat-icon"
      hideBadge={unreadMessages <= 0}
      hideIcon={hideIcon}
      name={getFeatureName('chat')}
    />
  );
}
