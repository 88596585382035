import { useTheme } from '@material-ui/core/styles';
import PropTypes from 'prop-types';

ByCombobulateImage.propTypes = {
  style: PropTypes.object,
};

export default function ByCombobulateImage({ style }) {
  const theme = useTheme();

  return <img src={theme.palette.endLogo} style={style} alt="logo" />;
}
