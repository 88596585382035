import { IconButton } from '@material-ui/core';
import CancelIcon from '@material-ui/icons/Cancel';
import PropTypes from 'prop-types';
import ReactHlsPlayer from 'react-hls-player';
import { supportsVideoType } from 'utils/utilMethods';

VodPreviewModal.propTypes = {
  vodUrl: PropTypes.string.isRequired,
  onClose: PropTypes.func.isRequired,
};

export default function VodPreviewModal({ vodUrl, onClose }) {
  return (
    <div className="wrapper">
      <div className="header">
        <div className="title">Preview</div>
        <IconButton onClick={onClose}>
          <CancelIcon />
        </IconButton>
      </div>

      <div className="body" style={{ width: '45rem' }}>
        {/ipad|iphone/i.test(window.navigator.userAgent) ||
        supportsVideoType('hls') === 'probably' ? (
          <video controls src={vodUrl} style={{ width: '100%', height: '100%' }}>
            <source src={vodUrl} type="application/x-mpegURL"></source>
          </video>
        ) : (
          <ReactHlsPlayer
            src={vodUrl}
            autoplay={true}
            controls={true}
            style={{ width: '100%', height: '100%' }}
          />
        )}
      </div>
    </div>
  );
}
