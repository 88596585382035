import { createMuiTheme, fade, emphasize } from '@material-ui/core/styles';
import { defaultsDeep } from 'lodash';
import {
  baseThemeSettings,
  basePalette,
  baseProps,
  typography,
  mixins,
  overrides,
} from 'themes/base';

export const palette = defaultsDeep(
  {
    type: 'dark',
    background2: {
      border: '#292e40',
      main: '#191e32'
    },
    background: {
      main: '#191F33',
      light: emphasize('#0F121D', 0.16),
      dark: '#0F121D',
      border: '#51586E',
    },
    secondary: {
      main: '#f4f4f4',
      light: '#fff',
      dark: '#ececec',
    },
    sidePanelGradient: {
      main: '#191F33',
      light: '#0F121D',
      dark: '#0F121D',
    },
    text: {
      primary: '#f4f4f4',
      secondary: '#fff',
      disabled: '#ececec',
      hint: fade('#fff', 0.38),
      signifier: '#1876D2',
    },
    stage: {
      main: 'F4F4F4',
      dark: fade('#C7C7C7', 0.5),
      light: fade('#C7C7C7', 0.5),
    },
    input: {
      main: emphasize('#0F121D', 0.16),
    },
    icon: {
      nameTag: 'rgba(0,0,0,.6)',
      devicesBackground: '#1C2338',
      primary: {
        fill: '#FFFFFF',
        stroke: '#888',
        background: emphasize('#1C2338', 0.3),
        border: emphasize('#1C2338', 0.3),
      },
      secondary: {
        fill: '#FFFFFF',
        stroke: '#707070',
        background: fade('#FFFFFF', 0.61),
      },
      tertiary: {
        fill: '#E5E5E5',
        stroke: '#E5E5E5',
        background: '#FFFFFF',
      },
    },
  },
  basePalette
);

palette.background.default = palette.background.main;
palette.background.modal = palette.background.main;
palette.background.sideMenu = palette.background.dark;
palette.background.sidePanelTitle = palette.background.main;
palette.background.paper = emphasize(palette.background.dark, 0.05);
palette.background.seeThrough = fade(palette.background.dark, 0.5);

export const props = defaultsDeep({}, baseProps);

export const themeSettings = {
  ...baseThemeSettings,
  palette,
  props,
  typography: typography(palette),
  overrides: overrides(palette),
  mixins: mixins(palette),
};

export default createMuiTheme(themeSettings);
