// TODO: move this to the backend, make a POST route to set a permission, add websocket receiver on frontend to receive permission updates

const defaultPermissions = {
  admin: {
    all: true,
  },
  presenter: {
    all: true,
  },
  attendee: {
    askQuestion: true,
    answerQuestion: false,
    chatToPresenters: true,
    chatToAll: false,
    chatToAttendees: false,
    shareVideo: false,
    shareScreen: false,
  },
};

export default defaultPermissions;
