import { createReducer } from '@reduxjs/toolkit';
import VideoStatus from 'enums/VideoStatus';
import omitBy from 'lodash/omitBy';
import set from 'lodash/set';
import * as ca from 'store/chime/chimeActions';
import { buildActionStatus, standardActions } from 'store/utils';

const initialState = {
  actionStatus: buildActionStatus(ca),
  activeSpeakers: [],
  attendeeId: '',
  attendeeInBroadcast: false,
  audioInputDevices: null,
  audioOutputDevices: null,
  audioVideo: null,
  canUnmute: true,
  contentSharerId: null,
  contentTile: null,
  currentAnalyticsUserId: '',
  currentAudioInputDevice: null,
  currentAudioOutputDevice: null,
  currentVideoInputDevice: null,
  guestSpeakerId: null,
  hasInitialized: false,
  hasJoined: false,
  isSharingContent: false,
  videoPreviewDevice: null,
  localTileId: null,
  meetingId: '',
  meetingStatus: null,
  meetingUserHasInitialized: false,
  muted: false,
  name: '',
  reconnecting: false,
  reconnectingList: {},
  connectionIsPoor: false,
  roster: {},
  showCaptions: false,
  startAudio: false,
  startWithAudio: false,
  startWithVideo: false,
  tileMap: {},
  upstreamVideoQuality: 1,
  videoInputDevices: null,
  videoStatus: VideoStatus.Disabled,
  waitingPayload: null,
};

const chimeReducer = createReducer(initialState, {
  ...standardActions(ca),
  [ca.resetStateAction]: () => ({
    ...initialState,
  }),
  [ca.setJoinInfoAction]: (state, { payload: { attendeeId, meetingId, name, audioVideo } }) => {
    state.attendeeId = attendeeId;
    state.meetingId = meetingId;
    state.name = name;
    state.audioVideo = audioVideo;
  },
  [ca.setDeviceListsAction]: (
    state,
    { payload: { audioInputDevices, audioOutputDevices, videoInputDevices } }
  ) => {
    state.audioInputDevices = audioInputDevices;
    state.audioOutputDevices = audioOutputDevices;
    state.videoInputDevices = videoInputDevices;
  },
  [ca.setDeviceListAction]: (state, { payload: { key, devices } }) => {
    state[key] = devices;
  },
  [ca.setCurrentDeviceAction]: (state, { payload: { deviceType, deviceValue } }) => {
    state[deviceType] = deviceValue;
  },
  [ca.removeRosterAttendeeAction]: (state, { payload: { userId } }) => {
    state.roster = omitBy(state.roster, (id) => id.userId === userId);
  },
  [ca.addRosterAttendeeAction]: (state, { payload: { userId, value } }) => {
    state.roster[userId] = value;
  },
  [ca.updateRosterAttributeAction]: (state, { payload: { userId, key, value } }) => {
    set(state.roster, `${userId}.${key}`, value);
  },
  [ca.setCurrentAnalyticsUserIdAction]: (state, { payload: { userId } }) => {
    state.currentAnalyticsUserId = userId;
  },
  [ca.setActiveSpeakersAction]: (state, { payload: { activeSpeakers } }) => {
    state.activeSpeakers = activeSpeakers;
  },
  [ca.setMeetingStatusAction]: (state, { payload: { meetingStatus } }) => {
    state.meetingStatus = meetingStatus;
  },
  [ca.setHasJoinedAction]: (state, { payload: { hasJoined } }) => {
    state.hasJoined = hasJoined;
  },
  [ca.setUpstreamVideoQualityAction]: (state, { payload: { id } }) => {
    state.upstreamVideoQuality = id;
  },
  [ca.initializeMeetingUserAction]: (state) => {
    state.meetingUserHasInitialized = true;
  },
  [ca.startWithVideoAction]: (state, { payload }) => {
    state.startWithVideo = payload;
  },
  [ca.startWithAudioAction]: (state, { payload }) => {
    state.startWithAudio = payload;
  },
  [ca.initializeAction]: (state) => {
    state.hasInitialized = true;
  },
  [ca.startContentShareAction]: (state, { payload: { userId } }) => {
    state.isSharingContent = true;
    state.contentSharerId = userId;
  },
  [ca.stopContentShareAction]: (state) => {
    state.isSharingContent = false;
    state.contentSharerId = null;
  },
  [ca.setMutedAction]: (state, { payload }) => {
    state.muted = payload;
  },
  [ca.toggleVideoStatusAction]: (state, { payload }) => {
    state.videoStatus = payload;
  },
  [ca.toggleCaptionsAction]: (state) => {
    state.showCaptions = !state.showCaptions;
  },
  [ca.setAttendeeInBroadcastAction]: (state, { payload }) => {
    state.attendeeInBroadcast = payload;
  },
  [ca.setLocalTileAction]: (state, { payload: { tileId } }) => {
    state.localTileId = tileId;
  },
  [ca.setContentTileAction]: (state, { payload }) => {
    state.contentTile = payload;
  },
  [ca.setCanUnmuteAction]: (state, { payload: canUnmute }) => {
    state.canUnmute = canUnmute;
  },
  [ca.setTileAction]: (state, { payload: { tileId, userId, isPaused, isActive } }) => {
    state.tileMap[userId] = { tileId, isPaused, isActive };
  },
  [ca.setVideoPreviewAction]: (state, { payload }) => {
    state.videoPreviewDevice = payload;
  },
  [ca.removeTileAction]: (state, { payload: { tileId } }) => {
    state.tileMap = omitBy(state.tileMap, (value) => value.tileId === tileId);
  },
  [ca.setWaitingPayloadAction]: (state, { payload: { waitingPayload } }) => {
    state.waitingPayload = waitingPayload;
  },
  [ca.setReconnectAction]: (state, { payload: { reconnecting } }) => {
    state.reconnecting = reconnecting;
  },
  [ca.setPoorConnectionStatusAction]: (state, { payload: { isPoor } }) => {
    state.connectionIsPoor = isPoor;
  },
  [ca.addReconnectingUserAction]: (state, { payload: { userId, userName, timeStamp } }) => {
    set(state.reconnectingList, userId, { userId, userName, timeStamp });
  },
  [ca.removeReconnectingUserAction]: (state, { payload: { userId } }) => {
    state.reconnectingList = omitBy(state.reconnectingList, (value) => value.userId === userId);
  },
});

export default chimeReducer;
