import { basePost } from 'api/apiDetour';

const baseEndpoint = 'session-polling';

const post = async ({ endpoint, data = {}, isSigned = false }) => {
  const resp = await basePost(endpoint, data, baseEndpoint, isSigned);
  return resp;
};

function preSendPoll(poll) {
  poll.correctAnswerIdx = String(poll.correctAnswerIdx);
}

export const createPoll = async (poll) => {
  preSendPoll(poll);

  const resp = await post({
    endpoint: 'create',
    data: {
      poll,
    },
  });

  return resp;
};

export const editPoll = async (pollId, poll) => {
  preSendPoll(poll);

  const resp = await post({
    endpoint: 'edit',
    data: {
      pollId,
      poll,
    },
  });

  return resp;
};

export const getSessionPolls = async () => {
  const resp = await post({ endpoint: 'get-polls', data: { sessionOnly: true } });
  return resp;
};

export const getPollTemplates = async () => {
  const resp = await post({ endpoint: 'get-polls', data: { templates: true } });
  return resp;
};

export const getAllPolls = async () => {
  const resp = await await post({ endpoint: 'get-polls' });
  return resp;
};

export const startPoll = async (pollId) => {
  const resp = await post({ endpoint: 'start-poll', data: { pollId } });
  return resp;
};

export const endPoll = async (pollId) => {
  const resp = await post({ endpoint: 'end-poll', data: { pollId } });
  return resp;
};

export const setPollResultsShared = async (pollId, resultsShared) => {
  const resp = await post({
    endpoint: 'set-results-shared',
    data: { pollId, resultsShared },
  });
  return resp;
};

export const deletePoll = async (pollId, template) => {
  const resp = await post({
    endpoint: 'delete',
    data: { pollId, template },
  });
  return resp;
};
