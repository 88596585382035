import startCase from 'lodash/startCase';
import moment from 'moment';
export const columns = [
  {
    name: 'email',
    label: 'Email',
    options: {
      filter: true,
      sort: true,
      searchable: true,
    },
  },
  {
    label: 'Name',
    name: 'name',
    options: {
      filter: true,
      sort: true,
      searchable: true,
    },
  },
  {
    label: 'Role',
    name: 'role',
    options: {
      filter: true,
      sort: true,
      searchable: true,
      customBodyRender: (value) => startCase(value),
    },
  },
  {
    label: 'Event Name',
    name: 'eventName',
    options: {
      filter: true,
      sort: true,
      searchable: true,
    },
  },
  {
    label: 'Attendence',
    name: 'attendence',
    options: {
      filter: false,
      sort: true,
      searchable: false,
    },
  },
  {
    label: 'Active Camera',
    name: 'cameraActive',
    options: {
      filter: false,
      sort: true,
      searchable: false,
    },
  },
  {
    label: 'On Stage',
    name: 'videoInStream',
    options: {
      filter: false,
      sort: true,
      searchable: false,
    },
  },
  {
    label: 'Session Start Time',
    name: 'sessionStart',
    options: {
      customBodyRender: (value) => moment(parseInt(value)).format('YYYY-MM-DD hh:mm'),
      filter: false,
      sort: true,
      searchable: false,
    },
  },
  {
    label: 'Content Share',
    name: 'contentShare',
    options: {
      filter: false,
      sort: true,
      searchable: false,
    },
  },
  {
    label: 'Questions Asked',
    name: 'questionsAsked',
    options: {
      filter: false,
      sort: true,
      searchable: false,
    },
  },
  {
    label: 'Questions Voted',
    name: 'questionsVoted',
    options: {
      filter: false,
      sort: true,
      searchable: false,
    },
  },
  {
    label: 'Raised Hands',
    name: 'raisedHands',
    options: {
      filter: false,
      sort: true,
      searchable: false,
    },
  },

  {
    label: 'Messages',
    name: 'messages',
    options: {
      filter: false,
      sort: true,
      searchable: false,
    },
  },
  {
    label: 'Event Id',
    name: 'eventId',
    options: {
      filter: true,
      sort: true,
      searchable: true,
      display: false,
    },
  },
  {
    name: 'userId',
    label: 'User Id',
    options: {
      filter: true,
      sort: true,
      searchable: true,
      display: false,
    },
  },
  {
    name: 'sessionId',
    label: 'Session Id',
    options: {
      filter: true,
      sort: true,
      searchable: true,
      display: false,
    },
  },
  {
    name: 'externalAccountUserId',
    label: 'External Id',
    options: {
      filter: true,
      sort: true,
      searchable: true,
      display: false,
    },
  },
  {
    name: 'accountId',
    label: 'Account Id',
    options: {
      filter: true,
      sort: true,
      searchable: true,
      display: false,
    },
  },
];

export const options = {
  download: true,
  downloadOptions: {
    filename: 'MeetReports.csv',
    separator: ',',
    filterOptions: {
      useDisplayedColumnsOnly: false,
      useDisplayedRowsOnly: true,
    },
  },
};
