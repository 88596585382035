import { useSelector } from 'react-redux';
import deepEqual from 'react-fast-compare';
import { usePendingRequests } from 'hooks/usePendingRequests';

export const getGridUsers = (store, pendingGridRequests) => {
  let users = { ...store.session.gridUsers };

  for (const req of pendingGridRequests) {
    if (req.options.removeAllUsersFromGrid) {
      users = {};
    } else if (req.data.inGrid) {
      users[req.data.userId] = req.timestamp;
    } else {
      delete users[req.data.userId];
    }
  }

  return users;
};

export const useGridUsers = () => {
  const pendingGridRequests = usePendingRequests('grid');
  return useSelector((store) => getGridUsers(store, pendingGridRequests), deepEqual);
};

export const getGridUsersHash = (gridUsers) => {
  let gridUsersHash = '';
  for (const gu in gridUsers) {
    gridUsersHash += `${gu}:${gridUsers[gu]}`;
  }
  return gridUsersHash;
};

export default useGridUsers;
