// import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/styles';
import { useDispatch } from 'react-redux';
import { updateServicesOnlyAction } from 'store/app/appActions';
const useStyles = makeStyles(() => ({
  root: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexGrow: 1,
    height: '75vh',
  },
  content: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    flexGrow: 1,
  },
  buttons: {
    display: 'flex',
    marginTop: '2rem',
    flexDirection: 'row',
  },
  leftButton: {
    marginRight: '4rem',
  },
}));
export default function ChooseServices() {
  const classes = useStyles();
  const dispatch = useDispatch();
  const chooseFeatures = (servicesOnly) => {
    dispatch(updateServicesOnlyAction({ servicesOnly }));
  };

  return (
    <div className={classes.root}>
      <div className={classes.content}>
        <Typography variant="h4">Please Choose Full Application Or Services Only</Typography>
        <div className={classes.buttons}>
          <Button
            className={classes.leftButton}
            vairant="contained"
            size="large"
            color="secondary"
            onClick={() => chooseFeatures(false)}
          >
            <Typography variant="h5">Full Application</Typography>
          </Button>
          <Button
            vairant="contained"
            size="large"
            color="secondary"
            onClick={() => chooseFeatures(true)}
          >
            <Typography variant="h5">Services Only</Typography>
          </Button>
        </div>
      </div>
    </div>
  );
}
