import PropTypes from 'prop-types';
import DefaultIcon from './DefaultIcon';
import Icon from '@material-ui/icons/SettingsInputComponent';

TestingIcon.propTypes = {
  hideIcon: PropTypes.bool.isRequired,
};

export default function TestingIcon({ hideIcon }) {
  return (
    <>
      <DefaultIcon
        IconComp={() => <Icon style={{ color: 'red' }} />}
        name="Testing"
        hideIcon={hideIcon}
      />
    </>
  );
}
