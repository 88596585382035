import {
  ConsoleLogger,
  DefaultDeviceController,
  DefaultMeetingSession,
  LogLevel,
  MeetingSessionConfiguration,
  // VideoPriorityBasedPolicy,
} from 'amazon-chime-sdk-js';
import { joinMeeting } from 'api/routes/session';
import MeetingStatus from 'enums/MeetingStatus';
import { updateLocalVideoStatus } from 'api/routes/userSession';
import { select } from 'redux-saga/effects';
import * as aa from 'store/analytics/analyticsActions';
import * as ca from 'store/chime/chimeActions';
import upperFirst from 'lodash/upperFirst';
import { call, put } from 'store/utils';
import { getCookie } from 'hooks/useCookie';
import upstreamVideoQualities from 'constants/upstreamVideoQualities';
import { VIDEO_TILES_MAX } from 'constants/videos';
import { showNotificationAction } from 'store/utils/utilActions';
import VideoStatus from 'enums/VideoStatus';
import InfoIcon from '@material-ui/icons/Info';

const DEVICE_TYPES = ['audioInput', 'audioOutput', 'videoInput'];

export function* getJoinInfoSaga() {
  try {
    yield put(ca.initAction({ key: 'getJoinInfo' }));
    const response = yield call(joinMeeting);
    let isWaiting = false;
    if (response.error) {
      isWaiting = true;
      yield put(ca.setMeetingStatusAction({ meetingStatus: MeetingStatus.Waiting }));
      yield put(ca.startMessagingAction());
      // throw new MeetingNotStartedError(response.error || 'Meeting not started');
    } else {
      const { chimeMeeting, attendee, title, name, meetingId, sessionData } = response.joinInfo;
      const configuration = new MeetingSessionConfiguration(chimeMeeting, attendee);
      const logger = new ConsoleLogger('SDK', LogLevel.OFF);
      const deviceController = new DefaultDeviceController(logger);
      const meetingSession = new DefaultMeetingSession(configuration, logger, deviceController);
      // const priorityBasedDownlinkPolicy = new VideoPriorityBasedPolicy(logger);
      // configuration.videoDownlinkBandwidthPolicy = priorityBasedDownlinkPolicy;

      const payload = {
        attendeeId: attendee.AttendeeId,
        audioVideo: meetingSession.audioVideo,
        meetingId,
        name,
        title,
      };
      console.log('join payload', payload);

      yield put(ca.setJoinInfoAction(payload));
      yield put(aa.setUserSessionAnalyticsAction({ sessionData }));
      yield put(ca.successAction({ key: 'getJoinInfo' }));
    }
    return isWaiting;
  } catch (error) {
    yield put(ca.errorAction({ key: 'getJoinInfo', error: error.message }));
    throw error;
  }
}

export function* getAudioVideoDevicesSaga() {
  const key = 'getAudioVideoDevices';

  yield put(ca.initAction({ key }));

  try {
    const audioVideo = yield select((state) => state.chime.audioVideo);
    const isRecordingUser = yield select((state) => state.user.isRecordingUser);

    const deviceList = {};
    for (const type of DEVICE_TYPES) {
      deviceList[`${type}Devices`] = (yield audioVideo[`list${upperFirst(type)}Devices`]()).map(
        ({ label, deviceId }) => ({
          label,
          value: deviceId,
        })
      );
    }

    yield put(ca.setDeviceListsAction(deviceList));

    if (isRecordingUser) {
      audioVideo.realtimeMuteLocalAudio();
      audioVideo.realtimeSetCanUnmuteLocalAudio(false);
      yield put(ca.successAction({ key }));
      return;
    }

    for (const type of DEVICE_TYPES) {
      const devices = deviceList[`${type}Devices`];

      if (devices.length > 0) {
        //First attempts to get the default device from cookie
        //If no cookie exists, sets the cookie to 'default' to select the default device
        //If no device is found, select first device in the list
        const defaultDeviceCookie = getCookie('default ' + type) || 'default';
        const defaultDevice = devices.find((d) => d.value === defaultDeviceCookie) || devices[0];

        const deviceValue = defaultDevice?.value;
        const name = `${upperFirst(type)}Device`;
        if (!type.includes('video') && deviceValue !== '') {
          yield audioVideo[`choose${name}`](deviceValue);
        }

        yield put(
          ca.setCurrentDeviceAction({
            deviceType: `current${name}`,
            deviceValue,
          })
        );
      }
    }

    yield put(ca.successAction({ key }));
  } catch (error) {
    yield put(ca.errorAction({ key, error: error.message }));
  }
}

export function* addAudioObserversSaga(element) {
  yield put(ca.initAction({ key: 'addAudioObservers' }));
  try {
    const { audioVideo } = yield select(({ chime }) => ({
      audioVideo: chime.audioVideo,
    }));
    try {
      yield audioVideo.bindAudioElement(element);
    } catch (err) {
      console.error('Failed to bind audio element', err);
    }
    yield put(ca.successAction({ key: 'addAudioObservers' }));
  } catch (error) {
    yield put(ca.errorAction({ key: 'addAudioObservers', error: error.message }));
  }
}

/*export function* stopUserVideoSaga() {
  const key = 'stopUserVideoSaga';
  yield put(ca.initAction({ key }));
  try {
    yield put(ca.toggleVideoAction({ forceLocalOff: true }));
    yield put(ca.successAction({ key }));
  } catch (error) {
    yield put(ca.errorAction({ key, error }));
  }
}*/

/*export function* updateRosterVideoStatusSaga(userId, status) {
  const key = 'updateRosterVideoStatusSaga';
  yield put(ca.initAction({ key }));
  try {
    yield put(ca.updateRosterAttributeAction({ userId, key: 'videoStatus', value: status }));
    yield put(ca.successAction({ key }));
  } catch (error) {
    yield put(ca.errorAction({ key, error }));
  }
}*/

export function* stopWebcam() {
  const key = 'stopWebcam';
  yield put(ca.initAction({ key }));
  try {
    const { deviceId } = yield select(({ chime }) => ({
      deviceId: chime.currentVideoInputDevice,
    }));
    (yield navigator.mediaDevices.getUserMedia({ video: { deviceId } }))
      ?.getVideoTracks()
      ?.forEach((track) => {
        track.stop();
        track.enabled = false;
      });

    yield put(ca.successAction({ key }));
  } catch (error) {
    yield put(ca.errorAction({ key, error }));
  }
}

export function* startLocalVideo() {
  console.log('Start LOCAL VIDEO');
  const { audioVideo, currentVideoInputDevice, upstreamVideoQuality, tileMap } = yield select(
    ({ chime }) => ({
      audioVideo: chime.audioVideo,
      currentVideoInputDevice: chime.currentVideoInputDevice,
      upstreamVideoQuality: chime.upstreamVideoQuality,
      tileMap: chime.tileMap,
    })
  );
  try {
    console.log('STARTING LOCAL VIDEO', { tileMap, VIDEO_TILES_MAX });
    if (Object.keys(tileMap).length >= VIDEO_TILES_MAX) {
      yield put(ca.toggleVideoStatusAction(VideoStatus.Disabled));
      yield call(updateLocalVideoStatus, VideoStatus.Disabled);
      yield put(
        showNotificationAction({
          message: 'Video limit for stage has been reached. Video has been toggled off.',
          type: 'warning',
          icon: InfoIcon,
        })
      );
    } else {
      const { width, height, frameRate, maxBandwidthKbps } = upstreamVideoQualities[
        upstreamVideoQuality
      ];
      audioVideo.chooseVideoInputQuality(width, height, frameRate, maxBandwidthKbps);
      yield audioVideo.chooseVideoInputDevice(currentVideoInputDevice);
      audioVideo.startLocalVideoTile();
      yield call(updateLocalVideoStatus, VideoStatus.Enabled);
    }
  } catch (e) {
    console.log('Camera Toggle Error', e);
  }
}
