import { makeStyles } from '@material-ui/core/styles';
import ByCombobulateImage from 'components/ByCombobulateImage';
import PropTypes from 'prop-types';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100vw',
    ...theme.mixins.vhHeight(100),
    padding: '3rem',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
    backgroundColor: theme.palette.background2.main,
    fontWeight: 500,

    '& > *': {
      margin: '0 1rem',
    },

    '& > img': {
      height: '2rem',
    },

    '& > div > *': {
      color: theme.palette.secondary.main,
    },

    '& > div > * > span': {
      color: theme.palette.primary.main,
    },
  },
  child: {
    marginBottom: 40,
    width: 400,
    height: 200,
    backgroundColor: theme.palette.background2.border,
    justifyContent: 'center',
    alignItems: 'center',
    display: 'inherit',
    borderRadius: '5px',
    fontWeight: 600,
  },
}));
EndMeetingScreen.propTypes = {
  children: PropTypes.node.isRequired,
};

export default function EndMeetingScreen({ children }) {
  const classes = useStyles();
  return (
    <div className={classes.root}>
      <div className={classes.child}>{children}</div>
      <ByCombobulateImage />
    </div>
  );
}
