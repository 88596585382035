import { Modal } from '@material-ui/core';
import IconButton from '@material-ui/core/IconButton';
import { MuiThemeProvider, useTheme } from '@material-ui/core/styles';
import EditIcon from '@material-ui/icons/Edit';
import GetAppIcon from '@material-ui/icons/GetApp';
import PlayCircleOutlineIcon from '@material-ui/icons/PlayCircleOutline';
import RefreshIcon from '@material-ui/icons/Refresh';
import DeleteIcon from '@material-ui/icons/Delete';
import { downloadVod, getVods, deleteVod } from 'api/routes/vod';
import moment from 'moment';
import MUIDataTable from 'mui-datatables';
import { useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { setVodsAction } from 'store/event/eventActions';
import { formatSeconds } from 'utils/utilMethods';
import { getDataTableTheme, useStyles as useMainStyles } from '../../../index.styles';
import VodEditModal from './VodEditModal';
import VodPreviewModal from './VodPreviewModal';
import VodPublish from './VodPublish';
import deepEqual from 'react-fast-compare';

const VodsTable = () => {
  const mainClasses = useMainStyles();
  const theme = useTheme();
  const dispatch = useDispatch();
  const [previewVideoUrl, setPreviewVideoUrl] = useState('');
  const [vodToEdit, setVodToEdit] = useState(null);
  const vods = useSelector((store) => store.event.vods || [], deepEqual).map((vod) => ({
    ...vod,
    published: {
      vodId: vod.vodId,
      published: [null, undefined].includes(vod.published) ? false : vod.published,
    },
    name: {
      vodId: vod.vodId,
      name: vod.name,
    },
    data: { ...vod },
    duration: formatSeconds((vod.endTime - vod.startTime) / 1000),
  }));

  const handleCloseEditModal = () => {
    refreshVods();
    setVodToEdit(null);
  };

  const handleClosePreviewModal = () => {
    setPreviewVideoUrl('');
  };

  const handleDeleteVod = async (vod) => {
    await deleteVod(vod.vodId);
    await refreshVods();
  };

  const refreshVods = async () => {
    const response = await getVods();
    dispatch(
      setVodsAction({
        vods: response.vods,
      })
    );
  };

  const handleDownload = async (mp4File) => {
    let arr = mp4File.split('/');
    let videoKey = arr.splice(3, arr.length).join('/');
    const resp = await downloadVod(videoKey);
    if (resp.url) {
      const link = document.createElement('a');
      link.href = resp.url;
      link.setAttribute('target', '_blank');
      link.setAttribute('rel', 'noopener noreferrer');
      link.setAttribute('download', arr[arr.length]);
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    }
  };

  const columns = [
    {
      name: 'published',
      label: 'Published',
      options: {
        filter: true,
        sort: true,
        searchable: true,
        customBodyRender: (value) => {
          return <VodPublish color="primary" vodId={value.vodId} published={value.published} />;
        },
      },
    },
    {
      name: 'name',
      label: 'Title',
      options: {
        filter: false,
        sort: true,
        searchable: false,
        customBodyRender: (value) => <div>{value.name || 'N/A'}</div>,
      },
    },
    {
      name: 'url',
      label: 'Preview',
      options: {
        filter: false,
        sort: false,
        searchable: false,
        customBodyRender: (url) =>
          url ? (
            <IconButton
              onClick={() => {
                previewVideoUrl === url ? setPreviewVideoUrl('') : setPreviewVideoUrl(url);
              }}
              style={{
                color: previewVideoUrl === url ? theme.palette.primary.main : 'grey',
                cursor: 'pointer',
              }}
            >
              <PlayCircleOutlineIcon />
            </IconButton>
          ) : (
            <>N/A</>
          ),
      },
    },
    {
      name: 'duration',
      label: 'Duration',
      options: {
        filter: false,
        sort: true,
        searchable: false,
      },
    },
    {
      name: 'startTime',
      label: 'Started / Uploaded At',
      options: {
        filter: false,
        sort: true,
        searchable: false,
        customBodyRender: (value) => moment.utc(value).local().format('MMM D, YYYY h:mm:ss a'),
      },
    },
    {
      name: 'status',
      label: 'Status',
      options: {
        filter: false,
        sort: true,
        searchable: false,
      },
    },
    {
      name: 'mp4File',
      label: 'Download',
      options: {
        filter: false,
        sort: true,
        searchable: false,
        customBodyRender: (value) => (
          <IconButton onClick={() => handleDownload(value)}>
            <GetAppIcon />
          </IconButton>
        ),
      },
    },
    {
      name: 'data',
      label: 'Edit',
      options: {
        filter: false,
        sort: false,
        searchable: false,
        customBodyRender: (value) => (
          <IconButton onClick={() => setVodToEdit(value)}>
            <EditIcon />
          </IconButton>
        ),
      },
    },
    {
      name: 'data',
      label: 'Delete',
      options: {
        filter: false,
        sort: false,
        searchable: false,
        customBodyRender: (value) => (
          <IconButton onClick={() => handleDeleteVod(value)}>
            <DeleteIcon />
          </IconButton>
        ),
      },
    },
  ];

  const options = {
    print: false,
    download: false,
    viewColumns: false,
    filter: false,
    elevation: 0,
    sortOrder: { name: 'startTime', direction: 'desc' },
    filterType: 'multiselect',
    selectableRows: 'none',
    customToolbar: () => {
      return (
        <IconButton style={{ margin: '0rem .5rem' }} onClick={async () => refreshVods()}>
          <RefreshIcon />
        </IconButton>
      );
    },
  };

  const dataTableTheme = useMemo(() => getDataTableTheme(theme), [theme]);

  return (
    <>
      {Boolean(previewVideoUrl) && (
        <Modal open={true} onClose={() => handleClosePreviewModal()} className={mainClasses.modal}>
          <VodPreviewModal vodUrl={previewVideoUrl} onClose={handleClosePreviewModal} />
        </Modal>
      )}
      {Boolean(vodToEdit) && (
        <Modal open={true} onClose={() => handleCloseEditModal()} className={mainClasses.modal}>
          <VodEditModal vod={vodToEdit} onClose={handleCloseEditModal} />
        </Modal>
      )}
      <MuiThemeProvider theme={dataTableTheme}>
        <MUIDataTable style={{ width: '100%' }} data={vods} columns={columns} options={options} />
      </MuiThemeProvider>
    </>
  );
};

export default VodsTable;
