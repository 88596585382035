import { getMessages } from 'api/routes/sessionMessage';
import { updateLocalVideoStatus } from 'api/routes/userSession';
import { muteUser } from 'api/routes/session';
import { endSession, removeUser, forceUserLogout } from 'api/routes/session';
import has from 'lodash/has';
import routes from 'constants/routes';
import MeetingStatus from 'enums/MeetingStatus';
import VideoStatus from 'enums/VideoStatus';
import { select, takeEvery, delay } from 'redux-saga/effects';
import * as ca from 'store/chime/chimeActions';
import * as ua from 'store/user/userActions';
import * as sa from 'store/session/sessionActions';
import { call, put } from 'store/utils';
import {
  addAudioObserversSaga,
  getAudioVideoDevicesSaga,
  getJoinInfoSaga,
  stopWebcam,
  startLocalVideo,
} from './chimeSagas';
import * as sma from 'store/session-message/sessionMessageActions';
import { sendWebSocketMessageAction } from 'store/web-socket/webSocketActions';
import { getSessionPolls, getPollTemplates } from 'api/routes/sessionPolls';
import { getUserSessionAnswers } from 'api/routes/sessionPollAnswers';
import {
  setPollsAction,
  setMyAnswersAction,
  setTemplatesAction,
} from 'store/session-polling/sessionPollingActions';
import { showNotificationAction } from 'store/utils/utilActions';
import { setCookie } from 'hooks/useCookie';
import get from 'lodash/get';
import upstreamVideoQualities from 'constants/upstreamVideoQualities';

export function* chooseAudioOutputDeviceFlowSaga(action) {
  const key = 'chooseAudioOutputDeviceFlowSaga';
  yield put(ca.initAction({ key }));
  try {
    const { audioVideo } = yield select(({ chime }) => ({
      audioVideo: chime.audioVideo,
    }));
    const { device } = action.payload;
    yield audioVideo.chooseAudioOutputDevice(device.value);
    yield put(
      ca.setCurrentDeviceAction({
        deviceType: 'currentAudioOutputDevice',
        deviceValue: device.value,
      })
    );

    setCookie('default audioOutput', device.value, 3650);

    yield put(ca.successAction({ key }));
  } catch (error) {
    yield put(ca.errorAction({ key, error }));
  }
}
export function* enterMeetingFlowSaga(action) {
  const key = 'enterMeetingFlowSaga';
  yield put(ca.initAction({ key }));
  try {
    const { videoRef } = action.payload;
    const { startWithVideo, startWithAudio, audioVideo } = yield select(({ chime }) => ({
      startWithAudio: chime.startWithAudio,
      startWithVideo: chime.startWithVideo,
      audioVideo: chime.audioVideo,
    }));
    yield audioVideo.stopVideoPreviewForVideoInput(videoRef);
    yield put(ca.setVideoPreviewAction(null));
    if (!startWithAudio) {
      audioVideo.realtimeMuteLocalAudio();
      yield put(ca.setMutedAction(audioVideo.realtimeIsLocalAudioMuted()));
    }
    yield put(ca.toggleVideoStatusAction(VideoStatus.Disabled));
    if (startWithVideo) {
      yield put(ca.toggleVideoAction());
    }
    audioVideo.start();
    yield put(ca.setHasJoinedAction({ hasJoined: true }));
    yield put(ca.successAction({ key }));
  } catch (error) {
    yield put(ca.errorAction({ key, error }));
  }
}

export function* chooseAudioInputDeviceFlowSaga(action) {
  const key = 'chooseAudioInputDeviceFlowSaga';
  yield put(ca.initAction({ key }));
  try {
    const { audioVideo } = yield select(({ chime }) => ({
      audioVideo: chime.audioVideo,
    }));
    const { device } = action.payload;
    yield audioVideo.chooseAudioInputDevice(device.value);
    yield put(
      ca.setCurrentDeviceAction({
        deviceType: 'currentAudioInputDevice',
        deviceValue: device.value,
      })
    );

    setCookie('default audioInput', device.value, 3650);

    yield put(ca.successAction({ key }));
  } catch (error) {
    yield put(ca.errorAction({ key, error }));
  }
}

export function* chooseVideoInputDeviceFlowSaga(action) {
  const key = 'chooseVideoInputDeviceFlowSaga';
  yield put(ca.initAction({ key }));
  try {
    const { audioVideo, videoPreviewDevice } = yield select(({ chime }) => ({
      audioVideo: chime.audioVideo,
      videoPreviewDevice: chime.videoPreviewDevice,
    }));
    const { device } = action.payload;

    //Stops current video preview before switching input
    if (videoPreviewDevice) {
      yield audioVideo.stopVideoPreviewForVideoInput(videoPreviewDevice);
    }

    yield audioVideo.chooseVideoInputDevice(device.value);

    yield put(
      ca.setCurrentDeviceAction({
        deviceType: 'currentVideoInputDevice',
        deviceValue: device.value,
      })
    );

    //Reactivates video preview with new video
    if (videoPreviewDevice) {
      yield audioVideo.startVideoPreviewForVideoInput(videoPreviewDevice);
    }
    setCookie('default videoInput', device.value, 3650);

    yield put(ca.successAction({ key }));
  } catch (error) {
    yield put(ca.errorAction({ key, error }));
  }
}

export function* leaveRoomFlowSaga(action) {
  const key = 'leaveRoomFlowSaga';
  const { shouldEndSession, forceLogout } = action.payload;
  yield put(ca.initAction({ key }));
  const {
    audioVideo,
    socket,
    push,
    role,
    sessionId,
    eventId,
    userId,
    accountId,
    isBroadcast,
  } = yield select(({ chime, webSocket, utils, user, session, event, app }) => ({
    accountId: user.accountId,
    audioVideo: chime.audioVideo,
    eventId: event.eventId,
    push: utils.push,
    role: user.role,
    sessionId: session.sessionId,
    socket: webSocket.socket,
    userId: user.userId,
    isBroadcast: app.appType === 'broadcast',
  }));
  try {
    if (forceLogout) {
      yield call(forceUserLogout, accountId, eventId, sessionId, userId);
    } else if (shouldEndSession && ['presenter', 'admin'].includes(role)) {
      yield call(endSession);
    } else {
      yield call(removeUser);
    }
    if (!(isBroadcast && role === 'attendee')) {
      try {
        yield call(stopWebcam);
      } catch (e) {
        console.log(e);
      }
      try {
        if (audioVideo !== null) audioVideo.stop();
      } catch (e) {
        console.log(e);
      }
    }
    try {
      yield socket.close(3511);
    } catch (error) {
      console.log(error);
    }
    push(forceLogout ? routes.LOGOUT.path : routes.END.path);
    yield put(ca.resetStateAction());
    yield put(sma.resetStateAction());

    yield put(ca.successAction({ key }));
  } catch (error) {
    push(forceLogout ? routes.LOGOUT.path : routes.END.path);
    yield put(ca.errorAction({ key, error }));
  }
}

export function* chooseUpstreamVideoQualityFlowSaga(action) {
  const key = 'chooseUpstreamVideoQualityFlowSaga';
  yield put(ca.initAction({ key }));
  try {
    const { currentVideoInputDevice, audioVideo, videoStatus, gridUsers, userId } = yield select(
      ({ chime, session, user }) => ({
        currentVideoInputDevice: chime.currentVideoInputDevice,
        audioVideo: chime.audioVideo,
        videoStatus: chime.videoStatus,
        gridUsers: session.gridUsers,
        userId: user.userId,
      })
    );
    const { id } = action.payload;
    yield put(ca.setUpstreamVideoQualityAction({ id }));

    const inGrid = has(gridUsers, userId);

    if (inGrid && videoStatus === VideoStatus.Enabled) {
      audioVideo.stopLocalVideoTile();
      const { width, height, frameRate, maxBandwidthKbps } = upstreamVideoQualities[id];
      audioVideo.chooseVideoInputQuality(width, height, frameRate, maxBandwidthKbps);
      yield audioVideo.chooseVideoInputDevice(currentVideoInputDevice);
      audioVideo.startLocalVideoTile();
    }

    yield put(ca.successAction({ key }));
  } catch (error) {
    yield put(ca.errorAction({ key, error }));
  }
}
export function* toggleMuteSelfFlowSaga(action) {
  const key = 'toggleMuteSelfFlowSaga';
  yield put(ca.initAction({ key }));
  try {
    const forceMute = action?.payload?.forceMute || false;
    const { muted, audioVideo, userId } = yield select(({ chime, user }) => ({
      muted: chime.muted,
      userId: user.userId,
      audioVideo: chime.audioVideo,
    }));
    if (forceMute || !muted) {
      audioVideo.realtimeMuteLocalAudio();
    } else {
      audioVideo.realtimeUnmuteLocalAudio();
    }
    let isLocalMuted = audioVideo.realtimeIsLocalAudioMuted();
    yield put(ca.setMutedAction(isLocalMuted));
    yield put(
      ca.updateRosterAttributeAction({
        userId,
        key: 'muted',
        value: isLocalMuted,
      })
    );
    yield put(ca.successAction({ key }));
  } catch (error) {
    yield put(ca.errorAction({ key, error }));
  }
}
export function* toggleRosterUserMutedFlowSaga(action) {
  const key = 'toggleRosterUserMutedFlowSaga';
  yield put(ca.initAction({ key }));
  try {
    const { userId } = action.payload;
    const { user, isLocalUser } = yield select(({ chime, user }) => ({
      isLocalUser: user.userId === userId,
      user: chime.roster[userId],
    }));
    if (isLocalUser) {
      yield toggleMuteSelfFlowSaga();
    } else if (!user.muted) {
      yield put(
        ca.updateRosterAttributeAction({
          userId,
          key: 'muted',
          value: true,
        })
      );
      yield call(muteUser, userId);
    }
    yield put(ca.successAction({ key }));
  } catch (error) {
    yield put(ca.errorAction({ key, error }));
  }
}

export function* joinMeetingFlowSaga(action) {
  const key = 'joinMeetingFlowSaga';
  yield put(ca.initAction({ key }));
  yield put(ca.setMeetingStatusAction({ meetingStatus: MeetingStatus.Loading }));
  const { socket } = yield select(({ chime }) => ({
    socket: chime.socket,
  }));
  try {
    const { audioRef } = action.payload;
    const isWaiting = yield call(getJoinInfoSaga);
    if (!isWaiting) {
      yield call(getAudioVideoDevicesSaga);
      yield call(addAudioObserversSaga, audioRef);
      yield put(ca.setMeetingStatusAction({ meetingStatus: MeetingStatus.Succeeded }));

      let { messages } = yield call(getMessages);
      const chatMessages = [];
      const queueMessages = [];
      const presenterMessages = [];
      (messages || []).forEach((msg) => {
        msg.queueMessages
          ? queueMessages.push(msg)
          : msg.presentersMessage
          ? presenterMessages.push(msg)
          : chatMessages.push(msg);
      });

      yield put(sma.setAllMessagesAction({ messages: chatMessages, presentersMessage: false }));
      yield put(sma.setAllMessagesAction({ messages: presenterMessages, presentersMessage: true }));
      yield put(
        sma.setAllMessagesAction({
          messages: queueMessages,
          presentersMessage: false,
          queueMessage: true,
        })
      );

      const { polls, serverTime } = yield call(getSessionPolls);
      console.log(polls);
      yield put(setPollsAction({ polls, serverTime }));

      const { polls: templates } = yield call(getPollTemplates);
      yield put(setTemplatesAction({ polls: templates }));

      const myAnswers = {};
      const { answers } = yield call(getUserSessionAnswers);
      if (answers) {
        for (const answerObj of answers) {
          const { pollId, answer } = answerObj;
          myAnswers[pollId] = answer;
        }
      }
      yield put(setMyAnswersAction({ myAnswers }));

      if (!socket) yield put(ca.startMessagingAction());
      console.log('setting the waiting payload to null');
      yield put(ca.setWaitingPayloadAction({ waitingPayload: null }));
    }
    yield put(ca.successAction({ key }));
  } catch (error) {
    yield put(ca.setMeetingStatusAction({ meetingStatus: MeetingStatus.Failed }));
    yield put(ca.errorAction({ key, error: error?.message || error }));
    throw error;
  }
}

export function* broadcastAttendeeFlowSaga() {
  const key = 'broadcastAttendeeFlowSaga';
  yield put(ca.initAction({ key }));
  try {
    yield put(ca.setMeetingStatusAction({ meetingStatus: MeetingStatus.Succeeded }));

    let { messages } = yield call(getMessages);
    const chatMessages = [];
    const presenterMessages = [];
    (messages || []).forEach((msg) => {
      msg.presentersMessage ? presenterMessages.push(msg) : chatMessages.push(msg);
    });
    yield put(sma.setAllMessagesAction({ messages: chatMessages, presentersMessage: false }));
    yield put(sma.setAllMessagesAction({ messages: presenterMessages, presentersMessage: true }));

    const { polls, serverTime } = yield call(getSessionPolls);
    yield put(setPollsAction({ polls, serverTime }));

    const myAnswers = {};
    const { answers } = yield call(getUserSessionAnswers);
    if (answers) {
      for (const answerObj of answers) {
        const { pollId, answer } = answerObj;
        myAnswers[pollId] = answer;
      }
    }
    yield put(setMyAnswersAction({ myAnswers }));

    yield put(ca.successAction({ key }));
  } catch (error) {
    yield put(ca.errorAction({ key, error: error?.message || error }));
    throw error;
  }
}

export function* togglePreviewFlowSaga(action) {
  const key = 'togglePreviewFlowSaga';
  yield put(ca.initAction({ key }));
  try {
    const { audioVideo, currentVideoInputDevice, startWithAudio, startWithVideo } = yield select(
      ({ chime }) => ({
        audioVideo: chime.audioVideo,
        currentVideoInputDevice: chime.currentVideoInputDevice,
        startWithAudio: chime.startWithAudio,
        startWithVideo: chime.startWithVideo,
      })
    );
    const {
      videoDevice = null,
      // audioDevice = null,
      setStartWithVideo = false,
      setStartWithAudio = false,
      startWithAudioState,
    } = action.payload;
    if (setStartWithAudio) {
      //If startWithAudioState is not provided, toggle is used
      yield put(
        ca.startWithAudioAction(
          typeof startWithAudioState !== 'undefined' ? startWithAudioState : !startWithAudio
        )
      );
    }
    if (setStartWithVideo) {
      if (!startWithVideo) {
        if (currentVideoInputDevice) {
          yield audioVideo.chooseVideoInputDevice(currentVideoInputDevice);
          yield audioVideo.startVideoPreviewForVideoInput(videoDevice);
          yield put(ca.setVideoPreviewAction(videoDevice));
        }
      } else {
        yield audioVideo.stopVideoPreviewForVideoInput(videoDevice);
        yield put(ca.setVideoPreviewAction(null));
      }
      yield put(ca.startWithVideoAction(!startWithVideo));
    }
    yield put(ca.successAction({ key }));
  } catch (error) {
    if (error.cause?.name === 'NotReadableError') {
      yield put(
        showNotificationAction({
          message: "Cannot connect to camera. Make sure it isn't in use by another application.",
          type: 'error',
        })
      );
    }

    yield put(ca.errorAction({ key, error }));
  }
}

export function* deviceChangedFlowSaga(action) {
  const key = 'deviceChangedFlowSaga';
  yield put(ca.initAction({ key }));
  try {
    const { deviceList, deviceType, freshDeviceList } = action.payload;
    const currentDevice = yield select(({ chime }) => chime?.[deviceType]);
    if (!currentDevice) return null;
    let hasCurrentDevice = false;
    const availableDevices = freshDeviceList.map(({ deviceId, label }) => {
      if (currentDevice && deviceId === currentDevice.value) hasCurrentDevice = true;
      return {
        label,
        value: deviceId,
      };
    });
    if (!hasCurrentDevice) {
      yield put(
        ca.setCurrentDeviceAction({
          deviceType,
          deviceValue: availableDevices.length > 0 ? availableDevices[0].value : null,
        })
      );
    }
    yield put(ca.setDeviceListAction({ key: deviceList, devices: availableDevices }));
    yield put(ca.successAction({ key }));
  } catch (error) {
    yield put(ca.errorAction({ key, error }));
    throw error;
  }
}

export function* setCanUnmuteLocalAudioFlowSaga(action) {
  const key = 'setCanUnmuteLocalAudioFlowSaga';
  yield put(ca.initAction({ key }));
  try {
    const { canUnmute } = action.payload;
    const audioVideo = yield select(({ chime }) => chime.audioVideo);
    canUnmute ? audioVideo.realtimeUnmuteLocalAudio() : audioVideo.realtimeMuteLocalAudio();
    yield put(ca.successAction({ key }));
  } catch (error) {
    yield put(ca.errorAction({ key, error }));
    throw error;
  }
}

export function* audioVideoTileDidUpdateFlowSaga(action) {
  const key = 'audioVideoTileDidUpdateFlowSaga';
  yield put(ca.initAction({ key }));
  try {
    const { tileState } = action.payload;
    const userId = tileState.boundExternalUserId;
    if (tileState.isContent) {
      yield put(
        ca.setContentTileAction({
          id: userId,
          tileId: tileState.tileId,
        })
      );
      yield put(ca.startContentShareAction({ userId }));
    } else if (tileState.localTile) {
      yield put(
        ca.setLocalTileAction({
          tileId: tileState.tileId,
        })
      );
    } else {
      yield put(
        ca.setTileAction({
          userId,
          tileId: tileState.tileId,
          isActive: tileState.active,
          isPaused: tileState.paused,
        })
      );
    }
    yield put(ca.successAction({ key }));
  } catch (error) {
    yield put(ca.errorAction({ key, error }));
    throw error;
  }
}

export function* audioVideoTileWasRemovedFlowSaga(action) {
  const key = 'audioVideoTileWasRemovedFlowSaga';
  yield put(ca.initAction({ key }));
  try {
    const { tileId } = action.payload;
    const { contentTile } = yield select(({ chime }) => ({ contentTile: chime.contentTile }));
    if (tileId === contentTile?.tileId) {
      yield put(ca.stopContentShareAction());
      yield put(ca.setContentTileAction({}));
    } else {
      yield put(ca.removeTileAction({ tileId }));
    }
    yield put(ca.successAction({ key }));
  } catch (error) {
    yield put(ca.errorAction({ key, error }));
    throw error;
  }
}

export function* toggleVideoFlowSaga(action) {
  const key = 'toggleVideoFlowSaga';
  yield put(ca.initAction({ key }));
  try {
    const { forceLocalOff = false } = action?.payload || {};
    const { videoStatus, audioVideo, gridUsers, userId } = yield select(
      ({ chime, session, user }) => ({
        videoStatus: chime.videoStatus,
        audioVideo: chime.audioVideo,
        gridUsers: session.gridUsers,
        userId: user.userId,
      })
    );
    const turnOff = forceLocalOff || videoStatus !== VideoStatus.Disabled;
    const status = turnOff ? VideoStatus.Disabled : VideoStatus.Enabled;
    yield put(ca.toggleVideoStatusAction(status));
    if (turnOff) {
      try {
        audioVideo.stopLocalVideoTile();
      } catch (e) {
        console.log('Attempt To Stop Local Video Failed');
      }
    } else if (has(gridUsers, userId)) yield call(startLocalVideo);
    yield call(updateLocalVideoStatus, status);
    yield put(ca.successAction({ key }));
  } catch (error) {
    yield put(ca.toggleVideoStatusAction(VideoStatus.Disabled));
    yield put(ca.errorAction({ key, error }));
  }
}
export function* ensureVideoFlowSaga() {
  const key = 'ensureVideoFlowSaga';
  yield put(ca.initAction({ key }));
  try {
    const { videoStatus } = yield select(({ chime }) => ({ videoStatus: chime.videoStatus }));
    if (videoStatus === VideoStatus.Enabled) yield call(startLocalVideo);

    yield put(ca.successAction({ key }));
  } catch (error) {
    yield put(ca.errorAction({ key, error }));
  }
}

export function* pauseRemoteVideoTileFlowSaga(action) {
  const key = 'pauseRemoteVideoTileFlowSaga';
  yield put(ca.initAction({ key }));
  const attendeeId = action?.payload?.attendeeId;
  try {
    const { tileMap, roster, audioVideo } = yield select(({ chime }) => ({
      roster: chime.roster,
      tileMap: chime.tileMap,
      audioVideo: chime.audioVideo,
    }));
    const user = Object.values(roster).find((user) => user.attendeeId === attendeeId);
    if (!user) {
      console.log('NO USER FOUND');
    } else {
      const { tileId, isPaused } = get(tileMap, get(user, 'userId', ''), 'tileId', null);

      if (tileId && !isPaused) {
        console.log('Pausing Video Tile for ', user.name, ' Id: ', user.attendeeId);
        audioVideo.pauseVideoTile(parseInt(tileId));
      }
    }
    yield put(ca.successAction({ key }));
  } catch (error) {
    console.log('PAUSE SAGA ERROR', error, error.message);
    yield put(ca.errorAction({ key, error }));
  }
}

export function* hostStartedMeeting() {
  const key = 'hostStartedMeeting';
  yield put(ca.initAction({ key }));
  try {
    yield delay(2500);
    const { waitingPayload } = yield select(({ chime }) => ({
      waitingPayload: chime.waitingPayload,
    }));
    yield put(ua.initializeMeetingRouteAction(waitingPayload));
  } catch (error) {
    yield put(ca.errorAction({ key, error }));
  }
}

export function* leaveGrid(action) {
  const key = 'leaveGrid';
  yield put(ca.initAction({ key }));
  try {
    const { audioVideo, thisUserId } = yield select(({ chime, user }) => ({
      audioVideo: chime.audioVideo,
      thisUserId: user.userId,
    }));
    const { userId } = action.payload;
    if (thisUserId === userId) {
      audioVideo.stopLocalVideoTile();
    }
    yield put(sa.removeUserFromGridAction({ userId }));
  } catch (error) {
    yield put(ca.errorAction({ key, error }));
  }
}

export function* enterGrid(action) {
  const key = 'enterGrid';
  yield put(ca.initAction({ key }));
  try {
    const { thisUserId, videoStatus } = yield select(({ user, chime }) => ({
      thisUserId: user.userId,
      videoStatus: chime.videoStatus,
    }));
    const { userId, timestamp } = action.payload;
    yield put(sa.addUserToGridAction({ userId, timestamp }));
    if (thisUserId === userId && videoStatus === VideoStatus.Enabled) {
      yield call(startLocalVideo);
    }
  } catch (error) {
    yield put(ca.errorAction({ key, error }));
  }
}

export function* reconnectingFlowSaga() {
  const key = 'reconnectingFlowSaga';
  yield put(ca.initAction({ key }));
  try {
    const { userId, name } = yield select(({ user }) => ({
      userId: user.userId,
      name: `${user.firstName} ${user.lastName}`,
    }));
    yield put(
      sendWebSocketMessageAction({
        type: 'reconnecting',
        payload: { userId: userId, timeStamp: Date.now(), userName: name },
      })
    );
    yield put(ca.successAction({ key }));
  } catch (error) {
    yield put(ca.errorAction({ key, error }));
  }
}

export function* stopReconnectingFlowSaga() {
  const key = 'stopReconnectingFlowSaga';
  yield put(ca.initAction({ key }));
  try {
    const { userId } = yield select(({ user }) => ({
      userId: user.userId,
    }));
    yield put(
      sendWebSocketMessageAction({
        type: 'stop-reconnecting',
        payload: { userId: userId },
      })
    );
    yield put(ca.successAction({ key }));
  } catch (error) {
    yield put(ca.errorAction({ key, error }));
  }
}

export function* removeChimeFlowSaga() {
  const key = 'removeChimeFlowSaga';
  yield put(ca.initAction({ key }));
  const { audioVideo } = yield select(({ chime }) => ({
    audioVideo: chime.audioVideo,
  }));
  try {
    yield call(removeUser);

    try {
      console.log('ATTEMPT TO STOP VIDEO');
      yield call(stopWebcam);
      console.log('stop webcam complete');
    } catch (e) {
      console.log('COULD NOT STOP VIDEO');
    }

    try {
      audioVideo.stop();
    } catch (e) {
      console.log('not stopping av');
    }

    yield put(ca.resetStateAction());
    yield put(ca.successAction({ key }));
  } catch (error) {
    yield put(ca.errorAction({ key, error }));
  }
}

export function* startGuestChimeFlowSaga(action) {
  const key = 'joinMeetingFlowSaga';
  yield put(ca.initAction({ key }));
  yield put(ca.setMeetingStatusAction({ meetingStatus: MeetingStatus.Loading }));
  try {
    const { audioRef } = action.payload;
    console.log('Calling join');
    const isWaiting = yield call(getJoinInfoSaga);
    if (!isWaiting) {
      yield call(getAudioVideoDevicesSaga);
      console.log('AUDIO REF', audioRef);
      yield call(addAudioObserversSaga, audioRef);
      yield put(ca.setMeetingStatusAction({ meetingStatus: MeetingStatus.Succeeded }));
      // const { audioVideo } = yield select(({ chime }) => ({
      //   audioVideo: chime.audioVideo,
      // }));
      // audioVideo.start();
      yield put(ca.setHasJoinedAction({ hasJoined: true }));
    }
    yield put(ca.successAction({ key }));
  } catch (error) {
    yield put(ca.setMeetingStatusAction({ meetingStatus: MeetingStatus.Failed }));
    yield put(ca.errorAction({ key, error: error?.message || error }));
    throw error;
  }
}

export default function* chimeWatcher() {
  yield takeEvery(ca.audioVideoTileDidUpdateAction().type, audioVideoTileDidUpdateFlowSaga);
  yield takeEvery(ca.audioVideoTileWasRemovedAction().type, audioVideoTileWasRemovedFlowSaga);
  yield takeEvery(ca.chooseAudioInputDeviceAction().type, chooseAudioInputDeviceFlowSaga);
  yield takeEvery(ca.chooseAudioOutputDeviceAction().type, chooseAudioOutputDeviceFlowSaga);
  yield takeEvery(ca.chooseUpstreamVideoQualityAction().type, chooseUpstreamVideoQualityFlowSaga);
  yield takeEvery(ca.chooseVideoInputDeviceAction().type, chooseVideoInputDeviceFlowSaga);
  yield takeEvery(ca.deviceChangedAction().type, deviceChangedFlowSaga);
  yield takeEvery(ca.enterChimeMeetingAction().type, enterMeetingFlowSaga);
  yield takeEvery(ca.leaveRoomAction().type, leaveRoomFlowSaga);
  yield takeEvery(ca.setCanUnmuteAudioAction().type, setCanUnmuteLocalAudioFlowSaga);
  yield takeEvery(ca.toggleMuteSelfAction().type, toggleMuteSelfFlowSaga);
  yield takeEvery(ca.togglePreviewAction().type, togglePreviewFlowSaga);
  yield takeEvery(ca.toggleRosterUserMutedAction().type, toggleRosterUserMutedFlowSaga);
  yield takeEvery(ca.toggleVideoAction().type, toggleVideoFlowSaga);
  yield takeEvery(ca.hostStartedMeetingAction().type, hostStartedMeeting);
  yield takeEvery(ca.leaveGridAction().type, leaveGrid);
  yield takeEvery(ca.enterGridAction().type, enterGrid);
  yield takeEvery(ca.ensureVideoAction().type, ensureVideoFlowSaga);
  yield takeEvery(ca.pauseRemoteVideoTileAction().type, pauseRemoteVideoTileFlowSaga);
  yield takeEvery(ca.reconnectingAction().type, reconnectingFlowSaga);
  yield takeEvery(ca.stopReconnectingAction().type, stopReconnectingFlowSaga);
  yield takeEvery(ca.broadcastAttendeeAction().type, broadcastAttendeeFlowSaga);
  yield takeEvery(ca.startGuestChimeAction().type, startGuestChimeFlowSaga);
  yield takeEvery(ca.removeChimeAction().type, removeChimeFlowSaga);
}
