import { removeVideo } from 'api/routes/event';
import { IconButton, Modal } from '@material-ui/core';
import PlayCircleOutlineIcon from '@material-ui/icons/PlayCircleOutline';
import useTheme from '@material-ui/styles/useTheme';
import { MuiThemeProvider } from '@material-ui/core/styles';
import { useState, useMemo } from 'react';
import logger from 'logger';
import moment from 'moment';
import MUIDataTable from 'mui-datatables';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import { setVideosAction } from 'store/event/eventActions';
import { formatSeconds } from 'utils/utilMethods';
import StreamVideo from 'routes/recording/components/StreamVideo';
import { useStyles, getDataTableTheme } from '../../../index.styles';
import { getS3VideoUrl } from 'api/routes/sessionResources';
import MediaEditModal from './MediaEditModal';
import EditIcon from '@material-ui/icons/Edit';
import find from 'lodash/find';

const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB'];
function bytesToSize(bytes) {
  if (bytes === 0) return '0 Byte';
  const i = parseInt(Math.floor(Math.log(bytes) / Math.log(1024)));
  return Math.round(bytes / Math.pow(1024, i), 2) + ' ' + sizes[i];
}

const FilesTable = ({ files }) => {
  const dispatch = useDispatch();
  const theme = useTheme();
  const classes = useStyles();
  const [previewVideoUrl, setPreviewVideoUrl] = useState('');
  const [editRow, setEditRow] = useState(null);

  const handleCloseEditModal = () => {
    setEditRow(null);
  };

  const handleDeleteFile = async (video) => {
    logger.info('Deleting conf file from s3', { video });
    const updatedEvent = await removeVideo(video);
    dispatch(setVideosAction({ videos: updatedEvent.event.videos }));
  };

  const videos = [...(files || [])];

  for (const videoId in videos) {
    const video = videos[videoId];
    videos[videoId] = {
      ...video,
      url: video.key,
    };
  }

  const columns = [
    {
      name: 'name',
      label: 'Name',
      options: {
        filter: false,
        sort: true,
      },
    },
    {
      name: 'key',
      label: 'Preview',
      options: {
        filter: false,
        sort: false,
        searchable: false,
        customBodyRender: (key) => (
          <IconButton
            onClick={async () => {
              const urlInfo = await getS3VideoUrl(key);
              if (urlInfo?.url) setPreviewVideoUrl(urlInfo.url);
            }}
          >
            <PlayCircleOutlineIcon />
          </IconButton>
        ),
      },
    },
    {
      name: 'size',
      label: 'Size',
      options: {
        filter: false,
        sort: true,
        searchable: false,
        customBodyRender: (value) => bytesToSize(value),
      },
    },
    {
      name: 'uploaded',
      label: 'Uploaded',
      options: {
        filter: false,
        sort: true,
        searchable: false,
        customBodyRender: (value) => moment.utc(value).local().format('MMM D, YYYY h:mm:ss a'),
      },
    },
    {
      name: 'duration',
      label: 'Duration',
      options: {
        filter: false,
        sort: true,
        searchable: false,
        customBodyRender: (value) => formatSeconds(value),
      },
    },
    {
      name: 'key',
      label: 'Edit',
      options: {
        filter: false,
        sort: false,
        searchable: false,
        customBodyRender: (key) => {
          let vid = find(videos, (vid) => vid.key === key);
          return (
            <IconButton
              onClick={() => {
                setEditRow(vid);
              }}
            >
              <EditIcon />
            </IconButton>
          );
        },
      },
    },
    {
      name: 'bucket',
      options: {
        filter: false,
        sort: false,
        display: false,
        viewColumns: false,
        searchable: false,
      },
    },
  ];

  const dataTableTheme = useMemo(() => getDataTableTheme(theme), [theme]);

  async function onRowsDelete(rowsDeleted) {
    for (const data of rowsDeleted.data) {
      const file = videos[data.dataIndex];
      await handleDeleteFile(file);
    }
  }

  /*const customSearchRender = (searchText, handleSearch, hideSearch, opts) => {
    return (
      <div>
        <TextField
          variant="outlined"
          value={searchText}
          placeholder={opts.searchPlaceholder}
          className={classes.textField}
        />

      </div>
    )
  };*/

  const options = {
    // customSearchRender,
    searchPlaceholder: 'Search Files',
    onRowsDelete,
    print: false,
    download: false,
    filter: false,
    viewColumns: false,
    elevation: 0,
    // selectToolbarPlacement: 'none',
    sortOrder: { name: 'name', direction: 'asc' },
    rowsPerPageOptions: [],
    selectableRows: 'single',
  };

  return (
    <MuiThemeProvider theme={dataTableTheme}>
      <MUIDataTable
        style={{ width: '100%' }}
        title="Media Files"
        data={videos}
        columns={columns}
        options={options}
      />

      {Boolean(previewVideoUrl) && (
        <Modal open={true} onClose={() => setPreviewVideoUrl('')} className={classes.previewModal}>
          <div className={classes.previewVideoWrapper}>
            <StreamVideo loop={false} videoUrl={previewVideoUrl} showControls={true} />
          </div>
        </Modal>
      )}

      {Boolean(editRow) && (
        <Modal open={true} onClose={() => handleCloseEditModal()} className={classes.modal}>
          <MediaEditModal video={editRow} onClose={handleCloseEditModal} />
        </Modal>
      )}
    </MuiThemeProvider>
  );
};

FilesTable.propTypes = {
  files: PropTypes.array,
};

export default FilesTable;
