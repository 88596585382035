import { makeStyles, emphasize } from '@material-ui/core/styles';
import { MAX_COL, MAX_ROW } from './GridView.size.js';

const gridCSS = {};
for (let cr = 2; cr <= MAX_ROW; ++cr) {
  gridCSS[`&[data-span-row="${cr}"]`] = { gridRow: `auto / span ${cr}` };
}
for (let cc = 2; cc <= MAX_COL; ++cc) {
  gridCSS[`&[data-span-column="${cc}"]`] = { gridColumn: `auto / span ${cc}` };
}

export default makeStyles((theme) => ({
  gridView: (props) => ({
    position: 'relative',
    display: 'grid',
    justifyContent: 'center',
    alignItems: 'center',
    height: props.showWarning ? 'calc(100% - 2em)' : '100%',
    padding: '.75rem',
    gridTemplateColumns: '1fr '.repeat(MAX_COL).trimRight(),
    gridTemplateRows: '1fr '.repeat(MAX_ROW).trimRight(),
    //gridGap: '0.25rem',
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center',
    backgroundSize: '30vh',

    '&[data-empty="1"]': {
      ...theme.mixins.logoBackgroundImageUrl(),
    },

    '& .sortMode': {
      display: 'flex',
      position: 'absolute',
      top: '2rem',
      right: '4rem',
      opacity: 0.5,
      transition: '0.25s ease opacity',
      alignItems: 'flex-end',
      flexDirection: 'column',
      flexWrap: 'wrap',
      fontSize: '0%',

      '& > div:first-child': {
        cursor: 'pointer',
        display: 'flex',
        justifyContent: 'flex-end',
        alignItems: 'center',
        minWidth: '2.25rem',
        height: '2.25rem',
        padding: '0.25rem 0.5rem',
        fontWeight: 'bold',
        backgroundColor: '#0004',
        color: '#f4f4f4',
        ...theme.mixins.borderRadius(),
      },

      '&:hover, &[data-open="1"]': {
        opacity: 1,
      },

      '&[data-open="1"]': {
        fontSize: '100%',

        '& > div:first-child': {
          //minWidth: '6rem',
        },
      },

      '& > .sortSelection': {
        backgroundColor: theme.palette.background.dark,
        ...theme.mixins.borderRadius(),

        '& > *': {
          padding: '0.25rem 0.4rem',
          fontWeight: 'bold',
          cursor: 'pointer',
          ...theme.mixins.borderRadius(),

          '&[data-selected="1"]': {
            backgroundColor: theme.palette.primary.main,
          },
          '&:hover': {
            backgroundColor: theme.palette.primary.light,
          },
        },
      },
    },

    '&:hover': {
      '& .sortMode': {
        display: 'flex',
      },
    },
  }),
  cell: {
    overflow: 'hidden',
    width: '100%',
    height: '100%',
    padding: '.75em',
    /*position: 'relative',
    width: '100%',
    paddingBottom: '56.25%',*/

    ...gridCSS,
  },
  pageControl: (props) => ({
    position: 'absolute',
    top: props.hasContent ? 'calc(1rem + 75%)' : '1rem',
    left: '1rem',
    width: '4rem',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: '0.25rem',
    backgroundColor: '#0004',
    color: '#f4f4f4',
    ...theme.mixins.borderRadius(),
    '& > *[disabled]': {
      opacity: 0.5,
    },
    '& .MuiIconButton-root': {
      color: 'inherit',
    },
  }),
  currentlyPresenting: {
    backgroundColor: theme.palette.background.main,
    width: '100%',
    height: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  currentlyPresentingText: {
    fontWeight: 'bold',
  },
  videoPreview: {
    height: '1.5rem',
    width: '16rem',
    position: 'absolute',
    overflow: 'hidden',
    bottom: '2rem',
    left: '2rem',
    opacity: 0.75,
    zIndex: '999',
    transition: '0.25s ease opacity, 0.5s ease height',
    '&[data-hidden="1"]': {
      display: 'none',
    },
    '&[data-open="1"]': {
      height: '10.5rem',
    },
    '& .drag': {
      height: '1.5rem',
      display: 'flex',
      justifyContent: 'flex-end',
      alignItems: 'center',
      padding: '0 0.5rem',
      background: theme.palette.background.dark,
      border: '1px solid',
      borderColor: emphasize(theme.palette.background.dark),
      borderRadius: '0.5rem 0.5rem 0 0',
      cursor: 'grab',
    },
    '&:hover': {
      opacity: 1,
    },
    '& video': {
      height: '100%',
      width: '100%',
      position: 'absolute',
      objectFit: 'cover',
      backgroundColor: theme.palette.background.dark,
    },
  },
  videoPreviewName: {
    flexGrow: 1,
  },
  notInStreamWarning: {
    // position: 'absolute',
    top: 0,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: '2em',
    width: '100%',
    fontWeight: 'bold',
    fontSize: '1rem',
    backgroundColor: theme.palette.primary.main,
    color: 'white',
    '& > *': {
      margin: '0 1rem',
    },
  },
  reconnecting: {
    position: 'absolute',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: 'rgba(0, 0, 0, 0.5)',
    width: '100%',
    height: '100%',
    zIndex: '9999999',
    color: 'white',
  },
}));
