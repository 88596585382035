import { makeStyles } from '@material-ui/core/styles';
import LoadingPage from 'components/LoadingPage';
import PropTypes from 'prop-types';
import React, { useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import VideoPlayer from 'components/video/videoPlayer';
import { useParams } from 'react-router';
import ScreenPage from 'components/ScreenPage';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: theme.palette.background.main,
  },
}));

VideoWrapper.propTypes = {
  isAttendee: PropTypes.bool,
};

export default function VideoWrapper(props) {
  const playbackUrl = useSelector((store) => store.session.playbackUrl);
  const status = useSelector((store) => store.session.status);
  const title = useSelector((state) => state.event.title);
  // const resourceStatus = useSelector((store) => store.session.resourceStatus);
  let { noIvs } = useParams();
  const classes = useStyles(props);
  const { IVSPlayer } = window;
  const { isPlayerSupported } = IVSPlayer;
  const [loading, setLoading] = useState(false);
  const player = useRef(null);
  const stream = { playbackUrl };

  useEffect(() => {
    const { ENDED, PLAYING, READY } = IVSPlayer.PlayerState;
    const { ERROR } = IVSPlayer.PlayerEventType;

    if (!isPlayerSupported) {
      console.warn('The current browser does not support the Amazon IVS player.');

      return;
    }

    const onStateChange = () => {
      const newState = player.current.getState();

      setLoading(newState !== PLAYING);
    };

    const onError = (err) => {
      console.warn('Player Event - ERROR:', err);
    };

    player.current = IVSPlayer.create();
    player.current.addEventListener(READY, onStateChange);
    player.current.addEventListener(PLAYING, onStateChange);
    player.current.addEventListener(ENDED, onStateChange);
    player.current.addEventListener(ERROR, onError);

    setLoading(true);

    return () => {
      player.current.removeEventListener(READY, onStateChange);
      player.current.removeEventListener(PLAYING, onStateChange);
      player.current.removeEventListener(ENDED, onStateChange);
      player.current.removeEventListener(ERROR, onError);
      // player.current.removeEventListener(TEXT_METADATA_CUE, () => {});
    };
    // eslint-disable-next-line
  }, [IVSPlayer, isPlayerSupported]);

  if (!isPlayerSupported) {
    return null;
  }

  return (
    <div className={classes.root}>
      {status === 'LIVE' && (
        <>
          {noIvs ? (
            <div>Video Stream has been Disabled for testing</div>
          ) : (
            <VideoPlayer loading={loading} player={player.current} streamData={stream} />
          )}
        </>
      )}
      {status === 'OFFLINE' && (
        <ScreenPage type="message" header={title}>
          The broadcast is currently offline.
        </ScreenPage>
      )}
      {status === 'CREATING' && (
        <LoadingPage type="waiting">The broadcast will begin momentarily.</LoadingPage>
      )}
    </div>
  );
}
