import PropTypes from 'prop-types';
import Base from '../components/Base';

Users.propTypes = {
  data: PropTypes.array.isRequired,
  refresh: PropTypes.func.isRequired,
};
export default function Users({ data, refresh }) {
  const columns = [
    {
      name: 'email',
      label: 'Email',
      options: {
        filter: true,
        sort: true,
        searchable: true,
      },
    },
    {
      label: 'Name',
      name: 'name',
      options: {
        filter: true,
        sort: true,
        searchable: true,
      },
    },

    {
      name: 'userId',
      label: 'User Id',
      options: {
        filter: true,
        sort: true,
        searchable: true,
      },
    },
    {
      name: 'externalAccountUserId',
      label: 'External Id',
      options: {
        filter: true,
        sort: true,
        searchable: true,
      },
    },
    {
      name: 'accountId',
      label: 'Account Id',
      options: {
        filter: true,
        sort: true,
        searchable: true,
      },
    },
  ];

  const options = {};

  return <Base name="Users" columns={columns} data={data} options={options} refresh={refresh} />;
}
