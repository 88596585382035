import PropTypes from 'prop-types';
import DefaultIcon from './DefaultIcon';
import EmojiPeopleIcon from '@material-ui/icons/EmojiPeople';
import { useSelector } from 'react-redux';
import deepEqual from 'react-fast-compare';

TheStageIcon.propTypes = {
  hideIcon: PropTypes.bool.isRequired,
};

export default function TheStageIcon({ hideIcon }) {
  const unreadMessages = useSelector((store) => store.sessionMessage.unreadQueueMessages);
  const stageAttendees = useSelector((store) => store.session.stageAttendees, deepEqual);
  const userId = useSelector((store) => store.user.userId);
  const role = useSelector((store) => store.user.role);

  return (
    <>
      <DefaultIcon
        buttonId="stage-icon"
        badgeContent={unreadMessages}
        name="The Queue"
        hideIcon={hideIcon}
        hideBadge={
          !(role === 'presenter' || stageAttendees[userId]?.accepted) || unreadMessages <= 0
        }
        IconComp={EmojiPeopleIcon}
      />
    </>
  );
}
