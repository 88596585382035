export const wait = async (ms) =>
  new Promise((resolve) =>
    setTimeout(() => {
      resolve();
    }, ms)
  );

function doClick(el) {
  if (el) {
    el.click();
    return true;
  }

  return false;
}

export const click = (id) => {
  if (doClick(document.getElementById(id))) {
    console.log(`Clicking Element With ID: ${id}`);
  } else {
    console.log(`Element With ID Not Found: ${id}`);
  }
};

export const clickSelector = (selector) => {
  if (doClick(document.querySelector(selector))) {
    console.log(`Clicking Element With Selector: ${selector}`);
  } else {
    console.log(`Element With Selector Not Found: ${selector}`);
  }
};

export const typeInField = (elementId, value) => {
  const element = document.getElementById(elementId);
  const valueSetter = Object.getOwnPropertyDescriptor(element, 'value').set;
  const prototype = Object.getPrototypeOf(element);
  const prototypeValueSetter = Object.getOwnPropertyDescriptor(prototype, 'value').set;
  if (valueSetter && valueSetter !== prototypeValueSetter) {
    prototypeValueSetter.call(element, value);
  } else {
    valueSetter.call(element, value);
  }
  element.dispatchEvent(new Event('input', { bubbles: true }));
};

export const openSidebar = async (sidebarIconId) => {
  clickSelector(`#${sidebarIconId}[data-opened="0"]`);
  await wait(500);
};

export const closeSidebar = async () => {
  await wait(500);
  click('close-sidebar');
};

// TODO: use above 2 functions instead
export const openAndCloseSidebar = async (sidebarIconId, callbackActions) => {
  click(sidebarIconId);
  await wait(500);
  await callbackActions();
  await wait(500);
  click(sidebarIconId);
};

export const findQuestionElement = async (questionElementId) => {
  const listEl = document.querySelector('#questions-list-parent > div > div');
  listEl.scrollTop = 0;

  const scrollDownOneWindow = async () => {
    listEl.scrollTop += listEl.clientHeight;
    await wait(500);
  };

  let questionElement;
  while (listEl.scrollTop <= listEl.scrollHeight - listEl.clientHeight) {
    questionElement = document.getElementById(questionElementId);
    if (questionElement) return questionElement;

    await scrollDownOneWindow();
  }

  return null;
};
