import { createReducer } from '@reduxjs/toolkit';
import { buildActionStatus, standardActions } from 'store/utils';
import * as ma from './sessionMessageActions';
import { merge } from 'lodash';
import { findIndexBy, removeOneBy } from 'utils/utilMethods';

const initialState = {
  messages: [],
  presenterMessages: [],
  queueMessages: [],
  conversationId: 'all',
  transcript: [],
  unreadMessages: 0,
  unreadPresenterMessages: 0,
  unreadQueueMessages: 0,
  actionStatus: buildActionStatus(ma),
};

const sessionMessageReducer = createReducer(initialState, {
  ...standardActions(ma),
  [ma.resetStateAction]: () => ({
    ...initialState,
  }),
  [ma.putMessageAction]: (state, { payload: { message, presentersMessage, queueMessage } }) => {
    message.timestamp = message.timestamp || Date.now();
    (queueMessage
      ? state.queueMessages
      : presentersMessage
      ? state.presenterMessages
      : state.messages
    ).push(message);
  },
  [ma.removeMessageAction]: (state, { payload: { messageId } }) => {
    removeOneBy(state.messages, 'messageId', messageId);
  },
  [ma.updateMessageAction]: (
    state,
    { payload: { messageId, message, presentersMessage, queueMessage } }
  ) => {
    const msgType = queueMessage
      ? 'queueMessages'
      : presentersMessage
      ? 'presenterMessages'
      : 'messages';
    const messageIdx = findIndexBy(state[msgType], 'messageId', messageId);
    if (messageIdx >= 0) merge(state[msgType][messageIdx], message);
  },
  [ma.setUnreadMessagesAction]: (
    state,
    { payload: { count, presentersMessage, queueMessage } }
  ) => {
    state[
      queueMessage
        ? 'unreadQueueMessages'
        : presentersMessage
        ? 'unreadPresenterMessages'
        : 'unreadMessages'
    ] = count;
  },
  [ma.setAllMessagesAction]: (
    state,
    { payload: { messages, presentersMessage = false, queueMessage = false } }
  ) => {
    state[
      queueMessage ? 'queueMessages' : presentersMessage ? 'presenterMessages' : 'messages'
    ] = messages;
  },
  [ma.setConversationIdAction]: (state, { payload: { conversationId } }) => {
    state.conversationId = conversationId;
  },
});

export default sessionMessageReducer;
