import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import NotificationDialog from './notifications/NotificationDialog';
import { useBrowserPermission } from 'hooks/useBrowserPermission';

CheckPermissions.propTypes = {
  setHasCheckedPermissions: PropTypes.func,
};

export default function CheckPermissions({ setHasCheckedPermissions }) {
  const [notificationState, setNotificationState] = useState({
    open: false,
    msg: '',
    title: 'Permissions',
  });
  const [micGranted, hasCheckedMic] = useBrowserPermission('microphone');
  const [cameraGranted, hasCheckedCamera] = useBrowserPermission('camera');

  useEffect(() => {
    if (!hasCheckedMic || !hasCheckedCamera) return;

    setHasCheckedPermissions(true);

    let failed = [];
    if (!micGranted) failed.push('microphone');
    if (!cameraGranted) failed.push('camera');
    if (failed.length === 0) return;

    const last = failed.pop();
    if (failed.length === 0) {
      failed = last;
    } else if (failed.length === 1) {
      failed = failed[0] + ' and ' + last;
    } else {
      failed = failed.join(', ') + ', and ' + last;
    }

    let msg = 'In order to use this application, please grant access permissions for your ';
    msg += failed;
    msg += ' which can usually be set by clicking the icon to the left of the browser URL box.';

    setNotificationState((currentPermissionsInfo) => ({
      ...currentPermissionsInfo,
      open: true,
      msg,
    }));

    // eslint-disable-next-line
  }, [hasCheckedMic, hasCheckedCamera]);

  return <NotificationDialog state={notificationState} setState={setNotificationState} />;
}
