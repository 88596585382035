import { createReducer } from '@reduxjs/toolkit';
import { buildActionStatus, standardActions } from 'store/utils';
import * as act from './activeSpeakerActions';

const initialState = {
  actionStatus: buildActionStatus(act),
  active: {},
};

const activeSpeakerReducer = createReducer(initialState, {
  ...standardActions(act),
  [act.resetStateAction]: () => ({
    ...initialState,
  }),
  [act.setActiveSpeakersAction]: (state, { payload: { active } }) => {
    state.active = active;
  },
});

export default activeSpeakerReducer;
