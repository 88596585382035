import { createReducer } from '@reduxjs/toolkit';
import * as ma from 'store/management/managementActions';
import { buildActionStatus, standardActions } from 'store/utils';
const initialState = {
  actionStatus: buildActionStatus(ma),
  accounts: [],
  events: [],
  sessions: [],
  users: [],
  ecs: [],
  reportingAccounts: {}, //{accountId: {eventId:{data:[],hasQueried:bool}}}
};
const managementReducer = createReducer(initialState, {
  ...standardActions(ma),
  [ma.resetStateAction]: () => ({
    ...initialState,
  }),
  [ma.setDataAction]: (state, { payload: { dataKey, data } }) => {
    state[dataKey] = data;
  },
  [ma.initializeReportingAccountsAction]: (state, { payload: { accounts } }) => {
    state.reportingAccounts = accounts.reduce(
      (a, { accountId, accountName }) => ({
        ...a,
        [accountId]: {
          queriedEvents: [],
          data: [],
          accountId,
          accountName,
          hasQueriedEventNames: false,
          eventNames: [],
        },
      }),
      {}
    );
  },
  [ma.addAccountEventNamesAction]: (state, { payload: { events, accountId } }) => {
    state.reportingAccounts[accountId].eventNames = events;
    state.reportingAccounts[accountId].hasQueriedEventNames = true;
  },
  [ma.setReportingAccountDataAction]: (
    state,
    { payload: { accountId, data = [], eventIds = [] } }
  ) => {
    state.reportingAccounts[accountId] = {
      ...state.reportingAccounts[accountId],
      queryiedEventIds: (state.reportingAccounts[accountId].queriedEventIds || []).concat(eventIds),
      data: (state.reportingAccounts[accountId].data || []).concat(data),
      queriedEvents: (state.reportingAccounts[accountId].queriedEvents || []).concat(eventIds),
    };
  },
});
export default managementReducer;
