import GridView from 'components/GridView';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { setMutedAction } from 'store/chime/chimeActions';
import MeetingCaptions from 'components/MeetingCaptions';
import deepEqual from 'react-fast-compare';

export default function ViewContainerMeeting() {
  const startAudio = useSelector((store) => store.chime.startAudio);
  const showCaptions = useSelector((store) => store.chime.showCaptions);
  const audioVideo = useSelector((store) => store.chime.audioVideo, deepEqual);
  const dispatch = useDispatch();

  useEffect(() => {
    if (startAudio) {
      audioVideo.realtimeMuteLocalAudio();
      dispatch(setMutedAction(true));
    }
    const callback = (localMuted) => {
      dispatch(setMutedAction(localMuted));
    };
    if (audioVideo) {
      // TODO: ADD CALLBACK
      audioVideo.realtimeSubscribeToMuteAndUnmuteLocalAudio(callback);
    }
    return () => {
      if (audioVideo) {
        audioVideo.stopLocalVideoTile();
        // TODO: ADD CALLBACK
        audioVideo.realtimeUnsubscribeToMuteAndUnmuteLocalAudio(callback);
      }
    };
    // eslint-disable-next-line
  }, [startAudio]);

  return (
    <>
      <GridView />
      {showCaptions && <MeetingCaptions />}
    </>
  );
}
