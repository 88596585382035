import CONFIG from 'configuration/config';
import DEMO from 'configuration/config.demo';
import DEMO_DEVELOPMENT from 'configuration/config.demo.development';
import DEVELOP from 'configuration/config.develop';
import DEVELOP_DEVELOPMENT from 'configuration/config.develop.development';
import BETA from 'configuration/config.beta';
import BETA_DEVELOPMENT from 'configuration/config.beta.development';
import PROD from 'configuration/config.prod';
import PROD_DEVELOPMENT from 'configuration/config.prod.development';
import get from 'lodash/get';

let envMap = {
  demo: DEMO,
  'demo.development': DEMO_DEVELOPMENT,
  develop: DEVELOP,
  'develop.development': DEVELOP_DEVELOPMENT,
  beta: BETA,
  'beta.development': BETA_DEVELOPMENT,
  prod: PROD,
  'prod.development': PROD_DEVELOPMENT,
};

const getConfig = () => {
  const [rootStage, isDevelopment = false] = process.env.REACT_APP_STAGE.split('.');
  const stage = `${rootStage}${isDevelopment ? '.development' : ''}`;
  let isProduction = !isDevelopment && ['prod'].includes(rootStage.toLowerCase());
  let env = {
    ...CONFIG,
    ...envMap[stage],
    IS_DEVELOPMENT: isDevelopment,
    STAGE: rootStage.toLowerCase(),
    IS_PRODUCTION: isProduction,
  };
  let remoteApisToUse = get(process.env, 'REACT_APP_REMOTE_APIS', '').split(',') || [];
  remoteApisToUse.forEach((api) => {
    if (api) {
      let thisApiName = `${api.toUpperCase()}_API`;
      env = { ...env, [thisApiName]: envMap[rootStage][thisApiName] };
    }
  });

  return env;
};

export default getConfig();
