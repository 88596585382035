import { basePost } from 'api/apiDetour';
const post = async ({ endpoint, data = {}, isSigned = false }) => {
  let resp = await basePost(endpoint, data, 'vod', isSigned);
  return resp;
};

export const getAvailableVods = async () => {
  let resp = await post({ endpoint: 'list-event' });
  return resp;
};

export const getVods = async () => {
  let resp = await post({ endpoint: 'list' });
  return resp;
};

export const updateVod = async (vodId, update) => {
  let resp = await post({ endpoint: 'update', data: { vodId, update } });
  return resp;
};

export const getVod = async (vodId) => {
  let resp = await post({ endpoint: 'get', data: { vodId } });
  return resp;
};

export const createVod = async (s3Path, duration, name) => {
  const resp = await post({ endpoint: 'create', data: { s3Path, duration, name } });
  return resp;
};

export const downloadVod = async (key) => {
  const resp = await post({ endpoint: 'download', data: { key } });
  return resp;
};

export const deleteVod = async (vodId) => {
  const resp = await post({ endpoint: 'delete', data: { vodId } });
  return resp;
};

export const putS3Video = async (key, contentType) => {
  const resp = await post({
    endpoint: 'upload-stream-mp4',
    data: { key, contentType },
  });
  return resp;
};
