import { Button, TextField, Select, MenuItem } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import PropTypes from 'prop-types';
import routes from 'constants/routes';
import { getEvent } from 'api/routes/admin';
import { createExternalToken } from 'api/routes/admin';
import { startCase } from 'lodash';
import { Modal, ModalBody, ModalFooter } from 'components/Modal';

const USER_NAME_MAX = 32;

export const useStyles = makeStyles((theme) => ({
  textField: {
    '& > div > input': {
      padding: '0.5em 1em',
    },
  },
  userName: {
    alignItems: 'center',
    ...theme.mixins.flexHorizontalList('0.5rem'),
    '& > *': {
      flexGrow: 1,
    },
  },
}));

EventModal.propTypes = {
  accountId: PropTypes.string.isRequired,
  eventId: PropTypes.string.isRequired,
  open: PropTypes.bool,
  onClose: PropTypes.func,
};

export default function EventModal({ accountId, eventId, open, onClose }) {
  const classes = useStyles();
  const history = useHistory();
  const [loading, setLoading] = useState(false);
  const [firstName, setFirstName] = useState('Conference');
  const [lastName, setLastName] = useState('Support');
  const [role, setRole] = useState('presenter');
  const [eventData, setEventData] = useState({});
  const [sessionType, setSessionType] = useState('broadcast');

  useEffect(() => {
    if (!eventId) return;

    const fetchEvent = async () => {
      setLoading(true);

      try {
        const { event, session, error } = await getEvent(accountId, eventId);
        if (error) throw new Error(error.message);
        event.type = session?.type || 'meeting';
        setEventData(event);
      } catch (e) {
        setEventData({});
      }

      setLoading(false);
    };

    fetchEvent();
  }, [eventId]);

  const onChangeFirstName = (event) => {
    let name = event.currentTarget.value;
    name = name.substr(0, USER_NAME_MAX);
    setFirstName(name);
  };

  const onChangeLastName = (event) => {
    let name = event.currentTarget.value;
    name = name.substr(0, USER_NAME_MAX);
    setLastName(name);
  };

  const onClickJoinMeeting = async () => {
    const { externalToken } = await createExternalToken(
      eventData.accountId,
      eventData.eventId,
      firstName,
      lastName,
      role,
      eventData.title,
      eventData.currentSessionId ? eventData.type : sessionType
    );

    history.push(`${routes.SSO.path}?token=${externalToken}&accountid=${accountId}`);
  };

  const onClickCopyLink = async () => {
    const { externalToken } = await createExternalToken(
      eventData.accountId,
      eventData.eventId,
      firstName,
      lastName,
      role,
      eventData.title,
      eventData.currentSessionId ? eventData.type : sessionType
    );

    await navigator.clipboard.writeText(
      `${routes.SSO.path}?token=${externalToken}&accountid=${accountId}`
    );
  };

  const onClickSettings = async () => {
    const { externalToken } = await createExternalToken(
      eventData.accountId,
      eventData.eventId,
      firstName,
      lastName,
      'admin',
      eventData.title,
      `settings${startCase(eventData.currentSessionId ? eventData.type : sessionType)}`
    );

    history.push(`${routes.SSO.path}?token=${externalToken}&accountid=${accountId}`);
  };

  const onClickVods = async () => {
    const { externalToken } = await createExternalToken(
      eventData.accountId,
      eventData.eventId,
      firstName,
      lastName,
      'admin',
      eventData.title,
      `settingsVod`
    );

    history.push(`${routes.SSO.path}?token=${externalToken}&accountid=${accountId}`);
  };

  const onClickPolls = async () => {
    const { externalToken } = await createExternalToken(
      eventData.accountId,
      eventData.eventId,
      firstName,
      lastName,
      'admin',
      eventData.title,
      `settingsPolls`
    );

    history.push(`${routes.SSO.path}?token=${externalToken}&accountid=${accountId}`);
  };

  return (
    <Modal
      open={open}
      onClose={onClose}
      title={loading ? `Loading Event ${eventId}...` : `Manage Event - ${eventData.title}`}
    >
      <ModalBody>
        <div className={classes.userName}>
          <TextField
            autoFocus={true}
            label="First name"
            value={firstName}
            placeholder="John"
            onChange={onChangeFirstName}
          />
          <TextField
            label="Last name"
            value={lastName}
            placeholder="Smith"
            onChange={onChangeLastName}
          />
        </div>

        <Select label="Role" value={role} onChange={(event) => void setRole(event.target.value)}>
          <MenuItem value="presenter">Presenter</MenuItem>
          <MenuItem value="attendee">Attendee</MenuItem>
        </Select>

        {!eventData.currentSessionId && (
          <Select
            label="Type"
            value={sessionType}
            onChange={(event) => void setSessionType(event.target.value)}
            disabled={loading}
          >
            <MenuItem value="broadcast">Broadcast</MenuItem>
            <MenuItem value="meeting">Meeting</MenuItem>
          </Select>
        )}
      </ModalBody>

      <ModalFooter>
        <Button variant="contained" color="primary" onClick={onClickCopyLink} disabled={loading}>
          Copy Join Link
        </Button>

        <Button variant="contained" color="primary" onClick={onClickJoinMeeting} disabled={loading}>
          Join Meeting
        </Button>

        <Button variant="contained" color="primary" onClick={onClickSettings} disabled={loading}>
          Settings
        </Button>

        <Button variant="contained" color="primary" onClick={onClickVods} disabled={loading}>
          VODs
        </Button>

        <Button variant="contained" color="primary" onClick={onClickPolls} disabled={loading}>
          Polls
        </Button>
      </ModalFooter>
    </Modal>
  );
}

export { EventModal };
