import { makeStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import { Autocomplete } from '@material-ui/lab';
import { useDispatch, useSelector } from 'react-redux';
import { setConversationIdAction } from 'store/session-message/sessionMessageActions';
import deepEqual from 'react-fast-compare';

const useStyles = makeStyles((theme) => ({
  select: {
    margin: `1rem ${theme.sidePanel.gutter}`,
  },
}));

const restrictedRoles = {
  attendee: true,
};

export default function RecipientSelect() {
  const classes = useStyles();
  const dispatch = useDispatch();
  const roster = useSelector((store) => store.chime.roster, deepEqual);
  const userId = useSelector((store) => store.user.userId);
  const role = useSelector((store) => store.user.role);
  const messages = useSelector((store) => store.sessionMessage.messages, deepEqual);
  const conversationId = useSelector((store) => store.sessionMessage.conversationId);

  const handleChange = (e, value) =>
    void dispatch(setConversationIdAction({ conversationId: value.userId }));

  const hasConvo = {};
  const persistentUsers = {};
  for (let message of messages) {
    hasConvo[message.senderId ?? ''] = true;
    hasConvo[message.recipientId ?? ''] = true;

    //Track all disconnected users who have PMs with this user
    if (message.recipientId === userId) persistentUsers[message.senderId] = message.senderName;
    if (message.senderId === userId && message.recipientId && message.recipientId !== '')
      persistentUsers[message.recipientId] = message.recipientName;
  }

  const localRestricted = restrictedRoles[role];

  const rosterUsers = Object.values(roster)
    .filter((user) => {
      if (!user) return false;
      if (user.userId === userId) return false;
      if (localRestricted && restrictedRoles[user.role]) return false;
      if (!user.name || user.name === 'recording user') return false;
      if (persistentUsers[user.userId]) return false;
      return true;
    })
    .concat(Object.keys(persistentUsers).map((id) => ({ userId: id, name: persistentUsers[id] })))
    .sort((userA, userB) => {
      if (hasConvo[userA.userId] !== hasConvo[userB.userId]) return hasConvo[userA.userId];

      const aName = userA.name || '';
      const bName = userB.name || '';

      return aName.localeCompare(bName, 'en', { ignorePunctuation: true });
    });
  const defaultRosterUser = { name: 'All', userId: 'all' };

  if (conversationId !== 'all' && !rosterUsers.some((user) => user.userId === conversationId)) {
    dispatch(setConversationIdAction({ conversationId: 'all' }));
  }

  return (
    <Autocomplete
      className={classes.select}
      defaultValue={defaultRosterUser}
      disableClearable
      options={[defaultRosterUser, ...rosterUsers]}
      getOptionLabel={(option) => option.name || ''}
      getOptionSelected={(option, value) => option.userId === value.userId}
      value={
        (conversationId !== 'all' && rosterUsers.find((user) => user.userId === conversationId)) ||
        defaultRosterUser
      }
      renderInput={(params) => <TextField {...params} label="To:" variant="outlined" />}
      onChange={handleChange}
    />
  );
}
