import { createAction as ca } from '@reduxjs/toolkit';
export const addRosterAttendeeAction = ca('chime/addRosterAttendeeAction');
export const audioVideoTileDidUpdateAction = ca('chime/audioVideoTileDidUpdateAction');
export const audioVideoTileWasRemovedAction = ca('chime/audioVideoTileWasRemovedAction');
export const broadcastAttendeeAction = ca('user/broadcastAttendeeAction');
export const chooseAudioInputDeviceAction = ca('chime/chooseAudioInputDevice');
export const chooseAudioOutputDeviceAction = ca('chime/chooseAudioOutputDevice');
export const chooseUpstreamVideoQualityAction = ca('chime/chooseUpstreamVideoQualityAction');
export const chooseVideoInputDeviceAction = ca('chime/chooseVideoInputDevice');
export const deviceChangedAction = ca('chime/deviceChangedAction');
export const enterChimeMeetingAction = ca('chime/enterChimeMeetingAction');
export const errorAction = ca('chime/errorAction');
export const hostStartedMeetingAction = ca('chime/hostStartedMeetingAction');
export const initAction = ca('chime/initAction');
export const initializeAction = ca('chime/initializeAction');
export const initializeMeetingUserAction = ca('chime/initializeMeetingUserAction');
export const leaveRoomAction = ca('chime/leaveRoomAction');
export const reconnectingAction = ca('chime/reconnectingAction');
export const stopReconnectingAction = ca('chime/stopReconnectingAction');
export const addReconnectingUserAction = ca('chime/addReconnectingUserAction');
export const removeReconnectingUserAction = ca('chime/removeReconnectingUserAction');
export const setReconnectAction = ca('chime/setReconnectAction');
export const setPoorConnectionStatusAction = ca('chime/setPoorConnectionStatusAction');
export const removeRosterAttendeeAction = ca('chime/removeRosterAttendeeAction');
export const removeTileAction = ca('chime/removeTileAction');
export const removeChimeAction = ca('chime/removeChimeAction');
export const resetStateAction = ca('chime/resetStateAction');
export const setAttendeeInBroadcastAction = ca('chime/setAttendeeInBroadcastAction');
export const setActiveSpeakersAction = ca('chime/setActiveSpeakersAction');
export const setDeviceListAction = ca('chime/setDeviceListAction');
export const setDeviceListsAction = ca('chime/setDeviceListsAction');
export const setCanUnmuteAction = ca('chime/setCanUnmuteAction');
export const setCanUnmuteAudioAction = ca('chime/setCanUnmuteAudioAction');
export const setContentTileAction = ca('chime/setContentTileAction');
export const setCurrentAnalyticsUserIdAction = ca('chime/setCurrentAnalyticsUserIdAction');
export const setCurrentDeviceAction = ca('chime/setCurrentDeviceAction');
export const setHasJoinedAction = ca('chime/setHasJoinedAction');
export const setInitialParamsAction = ca('chime/setInitialParamsAction');
export const setJoinInfoAction = ca('chime/setJoinInfoAction');
export const setLocalTileAction = ca('chime/setLocalTileIdAction');
export const setMeetingStatusAction = ca('chime/setMeetingStatusAction');
export const setMutedAction = ca('chime/setMutedAction');
export const setTileAction = ca('chime/setTileAction');
export const setUpstreamVideoQualityAction = ca('chime/setUpstreamVideoQualityAction');
export const setWaitingPayloadAction = ca('chime/setWaitingPayloadAction');
export const setVideoPreviewAction = ca('chime/setVideoPreviewAction');
export const startContentShareAction = ca('chime/startContentShareAction');
export const startMessagingAction = ca('chime/startMessagingAction');
export const startWithAudioAction = ca('chime/setStartWithAudio');
export const startWithVideoAction = ca('chime/setStartWithVideo');
export const startGuestChimeAction = ca('chime/startGuestChimeAction');
export const stopContentShareAction = ca('chime/stopContentShareAction');
export const successAction = ca('chime/successAction');
export const toggleCaptionsAction = ca('chime/changeShowCaptions');
export const toggleMuteSelfAction = ca('chime/toggleMuteSelfAction');
export const togglePreviewAction = ca('chime/togglePreviewAction');
export const toggleRosterUserMutedAction = ca('chime/toggleRosterUserMutedAction');
export const toggleVideoAction = ca('chime/toggleVideoAction');
export const toggleVideoStatusAction = ca('chime/toggleVideoStatusAction');
export const updateRosterAttributeAction = ca('chime/updateRosterAttributeAction');
export const leaveGridAction = ca('chime/leaveGridAction');
export const enterGridAction = ca('chime/enterGridAction');
export const ensureVideoAction = ca('chime/ensureVideoAction');
export const pauseRemoteVideoTileAction = ca('chime/pauseRemoteVideoTileAction');
