import { useDispatch, useSelector } from 'react-redux';
import { setPermissionAction } from 'store/event/eventActions';
import deepEqual from 'react-fast-compare';

export default function usePermissions() {
  const dispatch = useDispatch();
  const permissions = useSelector((store) => store.event.permissions, deepEqual);

  const getPermissions = (id) => {
    return permissions[id] || {};
  };

  const getPermission = (id, perm) => {
    const perms = permissions[id];

    if (!perms) return false;
    if (perms.all) return true;
    if (perms[perm]) return true;

    return false;
  };

  const setPermission = (id, perm, on) => {
    dispatch(setPermissionAction({ id, perm, on }));
  };

  return { getPermission, getPermissions, setPermission };
}
