import { makeStyles } from '@material-ui/core/styles';
import GroupIcon from '@material-ui/icons/Group';
import SchoolIcon from '@material-ui/icons/School';
import ToggleButton from '@material-ui/lab/ToggleButton';
import ToggleButtonGroup from '@material-ui/lab/ToggleButtonGroup';
import SiteHeader from 'components/SiteHeader';
import { useDispatch, useSelector } from 'react-redux';
import { setAppTypeAction } from 'store/app/appActions';
import Meeting from './Meeting';
import Broadcast from './Broadcast';

const useStyles = makeStyles(() => ({
  root: {
    flexGrow: 1,
  },
}));

export default function Home() {
  const classes = useStyles();
  const dispatch = useDispatch();
  const appType = useSelector((store) => store.app.appType);

  return (
    <div className={classes.root}>
      <SiteHeader>
        <ToggleButtonGroup
          value={appType}
          exclusive
          onChange={(event, newAppType) => dispatch(setAppTypeAction({ appType: newAppType }))}
          aria-label="text alignment"
        >
          <ToggleButton value="broadcast" aria-label="broadcast">
            <GroupIcon />
          </ToggleButton>
          <ToggleButton value="meeting" aria-label="meeting">
            <SchoolIcon />
          </ToggleButton>
        </ToggleButtonGroup>
      </SiteHeader>

      {appType === 'broadcast' && <Broadcast />}
      {appType === 'meeting' && <Meeting />}
    </div>
  );
}
