import { makeStyles } from '@material-ui/core/styles';
import GridView from 'components/GridView';
import MeetingCaptions from 'components/MeetingCaptions';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { setMutedAction } from 'store/chime/chimeActions';
import deepEqual from 'react-fast-compare';

const useStyles = makeStyles(() => ({
  requestGuestModal: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    zIndex: 1000,
    padding: '1rem',
  },
}));

export default function ViewContainerBroadcast() {
  const classes = useStyles();
  const startAudio = useSelector((store) => store.chime.startAudio);
  const showCaptions = useSelector((store) => store.chime.showCaptions);
  const audioVideo = useSelector((store) => store.chime.audioVideo, deepEqual);
  const requestToJoinAsGuest = useSelector((store) => store.session.requestToJoinAsGuest);

  const dispatch = useDispatch();

  useEffect(() => {
    if (startAudio) {
      audioVideo.realtimeMuteLocalAudio();
      dispatch(setMutedAction(true));
    }
    const callback = (localMuted) => {
      dispatch(setMutedAction(localMuted));
    };
    if (audioVideo) {
      // TODO: ADD CALLBACK
      audioVideo.realtimeSubscribeToMuteAndUnmuteLocalAudio(callback);
    }
    return () => {
      if (audioVideo) {
        audioVideo.stopLocalVideoTile();
        // TODO: ADD CALLBACK
        audioVideo.realtimeUnsubscribeToMuteAndUnmuteLocalAudio(callback);
      }
    };
    // eslint-disable-next-line
  }, [startAudio]);

  return (
    <>
      <GridView />
      {showCaptions && <MeetingCaptions />}
      {requestToJoinAsGuest && (
        <div className={classes.requestGuestModal}>
          You have been requested to join as a guest speaker.
        </div>
      )}
    </>
  );
}
