import Button from '@material-ui/core/Button';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import clsx from 'clsx';
import useCookie from 'hooks/useCookie';
import startCase from 'lodash/startCase';
import { useEffect, useState } from 'react';
import {
  Link,
  Redirect,
  Route,
  Switch,
  useHistory,
  useRouteMatch,
  useLocation,
} from 'react-router-dom';
import { useSelector } from 'react-redux';
import { useToken } from 'utils/token';
import { loginToAdmin } from 'api/routes/admin';
import LoadingPage from 'components/LoadingPage';
import Accounts from './routes/Accounts';
import Events from './routes/Events';
import UserReporting from './routes/user-reporting';
import Users from './routes/Users';
import Sessions from './routes/Sessions';
import Ecs from './routes/Ecs';
import ManagementWrapper from './components/ManagementWrapper';
import routes from 'constants/routes';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    ...theme.mixins.vhHeight(100),
  },
  sideBar: {
    backgroundColor: theme.palette.background.light,
    width: '265px',
    padding: '1rem 0 1rem 0',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    alignItems: 'center',
  },
  navButton: {
    height: '3rem',
  },
  content: {
    flex: 4,
    backgroundColor: theme.palette.background.main,
  },
  title: {
    marginBottom: '1rem',
  },
  selected: {
    backgroundColor: theme.palette.primary.dark,
  },
}));

const pages = [
  { managePath: 'accounts', Component: Accounts },
  { managePath: 'events', Component: Events },
  { managePath: 'sessions', Component: Sessions },
  { managePath: 'users', Component: Users },
  { managePath: 'ecs', Component: Ecs },
  { managePath: 'reporting', Component: UserReporting, disableAutoSearch: true },
];

export default function Management() {
  const classes = useStyles();
  const { pathname } = useLocation();
  const selectedInit = pathname.replace(routes.MANAGEMENT.path, '').replaceAll('/', '');
  const [selected, setSelected] = useState(selectedInit);
  const [loading, setLoading] = useState('');
  const { path, url } = useRouteMatch();
  const history = useHistory();
  const { setToken, setRefreshToken } = useToken();
  const role = useSelector((state) => state.user.role);

  const [storedUserName] = useCookie('adminusername', '');
  const [storedPassword] = useCookie('adminpassword', '');

  useEffect(() => {
    if (role === 'root') return;

    if (storedUserName && storedPassword) {
      const login = async () => {
        setLoading('Logging in with saved credentials...');

        let response;
        try {
          response = await loginToAdmin(storedUserName, storedPassword);
        } catch (e) {} //eslint-disable-line

        if (response?.success) {
          setToken(response.token);
          setRefreshToken(response.refreshToken);
        }

        setLoading('');
      };

      login();
    } else {
      history.push(routes.LOGIN.path);
    }
  }, [role, storedUserName, storedPassword]);

  return loading ? (
    <LoadingPage>{loading}</LoadingPage>
  ) : (
    <div className={classes.root}>
      <div className={classes.sideBar}>
        <Typography variant="h5" className={classes.title}>
          Administrator
        </Typography>
        {pages.map(({ managePath }) => (
          <Button
            key={`${managePath}-btn`}
            component={Link}
            to={`${url}/${managePath}`}
            onClick={() => setSelected(managePath)}
            fullWidth
            className={clsx(classes.navButton, {
              [classes.selected]: selected === managePath,
            })}
          >
            {startCase(managePath)}
          </Button>
        ))}
      </div>
      <div className={classes.content}>
        <Switch>
          {pages.map(({ managePath, Component, disableAutoSearch = false }) => (
            <Route
              key={managePath}
              exact
              path={`${path}/${managePath}/`}
              component={() => (
                <ManagementWrapper
                  dataKey={managePath}
                  Component={Component}
                  disableAutoSearch={disableAutoSearch}
                />
              )}
            />
          ))}
          <Redirect to={`${path}/accounts/`} />
        </Switch>
      </div>
    </div>
  );
}
