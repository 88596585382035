import CircularProgress from '@material-ui/core/CircularProgress';
import Button from '@material-ui/core/Button';
import { makeStyles } from '@material-ui/core/styles';
import Tooltip from '@material-ui/core/Tooltip';
import FiberSmartRecordIcon from '@material-ui/icons/FiberSmartRecord';
import FullscreenIcon from '@material-ui/icons/Fullscreen';
import FullscreenExitIcon from '@material-ui/icons/FullscreenExit';
import StopScreenShareIcon from '@material-ui/icons/StopScreenShare';
import { VideoOnIcon, VideoOffIcon, MicOnIcon, MicOffIcon, ScreenShareIcon } from 'svg';
import VideoStatus from 'enums/VideoStatus';
import { useEffect, useState } from 'react';
import Avatar from '@material-ui/core/Avatar';
import { useDispatch, useSelector } from 'react-redux';
import { toggleMuteSelfAction, toggleVideoAction } from 'store/chime/chimeActions';
import { toggleRecordingAction } from 'store/session/sessionActions';
import { showNotification } from 'utils/utilMethods';
import deepEqual from 'react-fast-compare';
import * as Sentry from '@sentry/browser';
import { Typography } from '@material-ui/core';
import { Modal, ModalBody, ModalFooter } from 'components/Modal';
import PropTypes from 'prop-types';

PresenterDevices.propTypes = {
  isStageAttendee: PropTypes.bool,
};

const useStyles = makeStyles((theme) => ({
  deviceBox: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    margin: '.25rem auto 2rem auto',

    '& > *': {
      margin: '0.5rem',
      fontSize: '1.25em',
      cursor: 'pointer',
    },
  },
  iconWrapper: {
    margin: '0',
    padding: '0',
    border: '0',
    '& > *': {
      '&[data-im-sharing="1"]': {
        color: 'red',
      },
      '&[data-is-recording="1"]': {
        color: 'red',
      },
    },
  },
  icon: {
    margin: '.75rem 0',
    opacity: 1,
    color: theme.palette.icon.primary.fill,
    height: '1.25rem',
    fontSize: '1.25rem',
    [theme.breakpoints.downHeight('md')]: {
      margin: '.25rem 0',
    },
    [theme.breakpoints.downHeight('sm')]: {
      margin: '0',
    },
    '&[data-loading="1"]': {
      width: '15px !important',
      height: '15px !important',
    },

    '& > .cls-1': {
      fill: theme.palette.icon.primary.fill,
    },
    '& > .cls-2': {
      stroke: theme.palette.icon.primary.stroke,
    },
  },
  avatar: {
    backgroundColor: 'transparent',
    width: theme.spacing(4),
    height: theme.spacing(4),

    '&:hover:not([disabled])': {
      border: '2px solid',
      borderColor: theme.palette.icon.primary.border,
      backgroundColor: theme.palette.icon.primary.background,
    },
    '&[disabled]': {
      opacity: 0.5,
      color: theme.palette.error.main,
      cursor: 'not-allowed',
      pointerEvents: 'none',
      ...theme.mixins.grayscale(),
    },

    [theme.breakpoints.downHeight('md')]: {
      width: theme.spacing(3),
      height: theme.spacing(3),
    },
  },
}));

const displayMediaOptions = {
  video: {
    cursor: 'always',
  },
  audio: true,
};

const recordingIconMap = {
  STARTING: CircularProgress,
  STOPPING: CircularProgress,
  LIVE: FiberSmartRecordIcon,
  OFFLINE: FiberSmartRecordIcon,
};

export default function PresenterDevices({ isStageAttendee }) {
  const [openRecordingModal, setopenRecordingModal] = useState(false);
  const videoStatus = useSelector((store) => store.chime.videoStatus);
  const muted = useSelector((store) => store.chime.muted);
  const mutedAll = useSelector((store) => store.session.mutedAll);
  const audioVideo = useSelector((store) => store.chime.audioVideo, deepEqual);
  const contentTile = useSelector((store) => store.chime.contentTile);
  const servicesOnly = useSelector((store) => store.app.servicesOnly);
  const localUserIsSharing = useSelector(
    (store) => store.chime.contentSharerId === store.user.userId
  );
  const role = useSelector((store) => store.user.role);
  const isRecording = useSelector((store) => store.session.isRecording || 'OFFLINE');
  const appType = useSelector((store) => store.app.appType);
  const currentVideoInputDevice = useSelector((store) => store.chime.currentVideoInputDevice);
  const currentAudioInputDevice = useSelector((store) => store.chime.currentAudioInputDevice);
  const recordingFeatureDisabled = useSelector(
    (store) =>
      appType === 'meeting' &&
      isRecording === 'OFFLINE' &&
      store.event.disabledFeatures['recordings']
  );

  const classes = useStyles({ isRecording });
  const dispatch = useDispatch();
  const [isFullScreen, setIsFullScreen] = useState(!!document.fullscreenElement);

  const onFullScreenChange = () => void setIsFullScreen(!!document.fullscreenElement);
  useEffect(() => {
    document.addEventListener('fullscreenchange', onFullScreenChange);

    return () => {
      document.removeEventListener('fullscreenchange', onFullScreenChange);
    };
  }, []);

  const userIsSharing = !!contentTile?.id;
  const isPresenter = role === 'presenter' || role === 'admin';
  const isMuteBlocked = !isPresenter && mutedAll;

  const toggleMute = async (event) => {
    if (isMuteBlocked) {
      showNotification('All participants are muted.', 'error');
    } else if (event.currentTarget.hasAttribute('disabled')) {
      showNotification(
        'No microphone is available. Check the settings page, select a microphone device, and then try again.',
        'error'
      );
    } else {
      dispatch(toggleMuteSelfAction());
    }
  };

  const toggleRecording = async () => {
    closeRecModal();
    if (isRecording === 'LIVE' || isRecording === 'OFFLINE') {
      dispatch(toggleRecordingAction());
    }
  };

  const toggleVideo = async (event) => {
    if (event.currentTarget.hasAttribute('disabled')) {
      showNotification(
        'No camera is available. Check the settings page, select a camera device, and then try again.',
        'error'
      );
    } else {
      dispatch(toggleVideoAction());
    }
  };

  const toggleScreenShare = async () => {
    if (userIsSharing) {
      if (localUserIsSharing) {
        await audioVideo.stopContentShare();
      }

      return;
    }

    try {
      console.log('Starting screen share');
      const captureStream = await navigator.mediaDevices.getDisplayMedia(displayMediaOptions);
      console.log('capture stream', captureStream);
      await audioVideo.startContentShare(captureStream);
    } catch (err) {
      if (typeof err.message === 'string') {
        showNotification(err.message, 'error');
      } else {
        showNotification('Cannot Access Screen Share.', 'error');
      }
      Sentry.captureException(err);
      console.error('Error sharing screen: ', err);
    }
  };

  const ScreenShareIconToUse =
    userIsSharing && localUserIsSharing ? StopScreenShareIcon : ScreenShareIcon;

  let recordingTooltip;
  if (isRecording === 'OFFLINE') recordingTooltip = 'Start recording session';
  else if (isRecording === 'LIVE') recordingTooltip = 'Recording started';
  else recordingTooltip = 'Recording starting...';

  const RecordIconToUse = recordingIconMap[isRecording];

  const FullScreenIconToUse = isFullScreen ? FullscreenExitIcon : FullscreenIcon;
  const toggleFullScreen = () => {
    if (isFullScreen) {
      document.exitFullscreen();
    } else {
      document.documentElement.requestFullscreen();
    }
  };

  const closeRecModal = () => void setopenRecordingModal(false);

  return (
    <div className={classes.deviceBox}>
      {!servicesOnly && (
        <Tooltip title="Toggle microphone" placement="left">
          <Avatar className={classes.avatar} disabled={!currentAudioInputDevice}>
            <div
              className={classes.iconWrapper}
              id={`toggle-presenter-mic-${muted ? 'on' : 'off'}`}
              onClick={toggleMute}
            >
              {isMuteBlocked ? (
                <MicOffIcon className={classes.icon} color="disabled" />
              ) : muted ? (
                <MicOffIcon style={{ height: '1.8rem' }} className={classes.icon} />
              ) : (
                <MicOnIcon className={classes.icon} />
              )}
            </div>
          </Avatar>
        </Tooltip>
      )}
      {!servicesOnly && (
        <Tooltip title="Toggle video" placement="left">
          <Avatar className={classes.avatar} disabled={!currentVideoInputDevice}>
            <div
              className={classes.iconWrapper}
              id={`toggle-presenter-video-${videoStatus === VideoStatus.Enabled ? 'off' : 'on'}`}
              onClick={toggleVideo}
            >
              {videoStatus === VideoStatus.Enabled ? (
                <VideoOnIcon className={classes.icon} />
              ) : (
                <VideoOffIcon className={classes.icon} />
              )}
            </div>
          </Avatar>
        </Tooltip>
      )}
      {!isStageAttendee && !servicesOnly && (
        <Tooltip title="Toggle screen share" placement="left">
          <Avatar className={classes.avatar} disabled={userIsSharing && !localUserIsSharing}>
            <div className={classes.iconWrapper}>
              <ScreenShareIconToUse
                className={classes.icon}
                onClick={toggleScreenShare}
                data-im-sharing={localUserIsSharing ? 1 : 0}
              />
            </div>
          </Avatar>
        </Tooltip>
      )}
      {isPresenter && appType === 'meeting' && !servicesOnly && !recordingFeatureDisabled && (
        <Tooltip title={recordingTooltip} placement="left">
          <Avatar
            className={classes.avatar}
            disabled={['STARTING', 'STOPPING'].includes(isRecording) ? 1 : 0}
          >
            <div className={classes.iconWrapper}>
              <RecordIconToUse
                className={classes.icon}
                data-loading={['STARTING', 'STOPPING'].includes(isRecording) ? 1 : 0}
                data-is-recording={isRecording === 'LIVE' ? 1 : 0}
                onClick={() => setopenRecordingModal(true)}
              />
            </div>
          </Avatar>
        </Tooltip>
      )}
      {!isStageAttendee && document.fullscreenEnabled && (
        <Tooltip title={`${isFullScreen ? 'Exit' : 'Enter'} fullscreen`} placement="left">
          <Avatar className={classes.avatar}>
            <div className={classes.iconWrapper}>
              <FullScreenIconToUse className={classes.icon} onClick={toggleFullScreen} />
            </div>
          </Avatar>
        </Tooltip>
      )}
      <Modal
        title={`${isRecording === 'LIVE' ? 'Stop' : 'Start'} Recording`}
        open={openRecordingModal}
        onClose={closeRecModal}
      >
        <ModalBody>
          <Typography>
            Are you sure you want to {isRecording === 'LIVE' ? 'stop' : 'start'} recording?
          </Typography>
        </ModalBody>
        <ModalFooter>
          <Button variant="contained" color="secondary" onClick={closeRecModal}>
            Cancel
          </Button>

          <Button variant="contained" color="primary" onClick={toggleRecording}>
            {isRecording === 'LIVE' ? 'Stop' : 'Start'} Recording
          </Button>
        </ModalFooter>
      </Modal>
    </div>
  );
}
