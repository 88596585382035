import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import Paper from '@material-ui/core/Paper';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import MoreHorizIcon from '@material-ui/icons/MoreHoriz';
import ToggleOffIcon from '@material-ui/icons/ToggleOff';
import ToggleOnIcon from '@material-ui/icons/ToggleOn';
import Tooltip from '@material-ui/core/Tooltip';
import { getEvent } from 'api/routes/event';
import DeviceSwitcher from 'components/devices/DeviceSwitcher';
import { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import * as chimeActions from 'store/chime/chimeActions';
import deepEqual from 'react-fast-compare';

const useStyles = makeStyles((theme) => ({
  joinMeetingPopupWrapper: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    margin: '0 auto',
    width: `95vw`,

    ...theme.mixins.vhMinHeight(100),
  },
  joinMeetingPopup: {
    padding: '1.5rem',
    textAlign: 'center',
    border: `2px solid ${theme.palette.background.border}`,
    borderRadius: '15px',
  },
  videoContainer: {
    width: 480,
    maxWidth: '95vw',
    margin: '0 auto',
    background: theme.palette.background.main,
    borderRadius: '15px',
    '& > video': {
      width: '100%',
      borderRadius: '15px',
    },
  },
  joinButton: {
    marginTop: '0.5rem',
  },
  devices: {
    maxWidth: '30rem',
    margin: '0 auto',
  },
  toggles: {
    fontSize: '1.5em',
    margin: '1rem',
  },
}));

export default function JoinMeetingPopup() {
  const audioVideo = useSelector((store) => store.chime.audioVideo, deepEqual);
  const startWithAudio = useSelector((store) => store.chime.startWithAudio);
  const startWithVideo = useSelector((store) => store.chime.startWithVideo);
  const mutedAll = useSelector((store) => store.session.mutedAll);
  const role = useSelector((store) => store.user.role);
  const dispatch = useDispatch();
  const classes = useStyles();
  const videoElement = useRef(null);
  const [eventName, setEventName] = useState('');
  const [showDevices, setShowDevices] = useState(false);

  const isMasterMute = mutedAll && role !== 'presenter' && role !== 'admin';

  useEffect(() => {
    const currentVideo = videoElement.current;
    let ignore = false;
    const getInfo = async () => {
      const event = await getEvent();
      if (!ignore) setEventName(event?.name || '');
    };
    const stopVideoPreview = async () => {
      await audioVideo.stopVideoPreviewForVideoInput(currentVideo);
    };

    getInfo();
    return () => {
      ignore = true;
      stopVideoPreview();
    };
    // eslint-disable-next-line
  }, []);

  const toggleVideo = async () => {
    dispatch(
      chimeActions.togglePreviewAction({
        videoDevice: videoElement.current,
        setStartWithVideo: true,
      })
    );
  };
  const toggleAudio = () => {
    if (isMasterMute) return;

    dispatch(
      chimeActions.togglePreviewAction({
        audioDevice: null, // @TODO: should be set
        setStartWithAudio: true,
      })
    );
  };

  const joinMeeting = () => {
    dispatch(chimeActions.enterChimeMeetingAction({ videoRef: videoElement.current }));
  };

  return (
    <div className={classes.joinMeetingPopupWrapper}>
      <Paper elevation={3} className={classes.joinMeetingPopup}>
        <Typography variant="h4" gutterBottom>
          {eventName}
        </Typography>

        <div className={classes.videoContainer}>
          <video data-testid="preview-video-element" ref={videoElement} />
        </div>

        <Box display="flex" flexDirection="row" justifyContent="center">
          <Box
            display="flex"
            flexDirection="row"
            justifyContent="center"
            className={classes.toggles}
          >
            Video
            {startWithVideo ? (
              <ToggleOnIcon
                fontSize="large"
                data-testid="video-on-toggle"
                color="primary"
                onClick={toggleVideo}
                style={{ marginLeft: '0.5rem' }}
              />
            ) : (
              <ToggleOffIcon
                style={{ marginLeft: '0.5rem' }}
                fontSize="large"
                data-testid="video-off-toggle"
                onClick={toggleVideo}
              />
            )}
          </Box>
          <Box
            display="flex"
            flexDirection="row"
            justifyContent="center"
            className={classes.toggles}
          >
            Audio
            {startWithAudio && !isMasterMute ? (
              <ToggleOnIcon
                fontSize="large"
                data-testid="audio-on-toggle"
                color="primary"
                onClick={toggleAudio}
                style={{ marginLeft: '0.5rem' }}
              />
            ) : (
              <Tooltip placement="top" title={isMasterMute ? 'Attendees are currently muted.' : ''}>
                <ToggleOffIcon
                  style={{ marginLeft: '0.5rem' }}
                  fontSize="large"
                  data-testid="audio-off-toggle"
                  onClick={toggleAudio}
                  color={isMasterMute ? 'disabled' : 'inherit'}
                />
              </Tooltip>
            )}
          </Box>
          <Box
            display="flex"
            flexDirection="row"
            justifyContent="center"
            className={classes.toggles}
          >
            {showDevices ? (
              <MoreHorizIcon
                onClick={() => setShowDevices(!showDevices)}
                color="primary"
                style={{ position: 'relative', top: '0.5rem' }}
              />
            ) : (
              <MoreHorizIcon
                onClick={() => setShowDevices(!showDevices)}
                style={{ position: 'relative', top: '0.5rem' }}
              />
            )}
          </Box>
        </Box>

        <Button
          variant="contained"
          color="primary"
          onClick={joinMeeting}
          className={classes.joinButton}
          id="presenter-join-button"
        >
          Join
        </Button>

        {showDevices && (
          <div className={classes.devices}>
            <DeviceSwitcher qualityPanel={true} />
          </div>
        )}
      </Paper>
    </div>
  );
}
