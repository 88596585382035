import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined';
import { select, takeEvery } from 'redux-saga/effects';
import { put } from 'store/utils';
import * as uta from 'store/utils/utilActions';
import * as sma from './sessionMessageActions';
import { getFeatureName } from 'constants/features';

// Note: called from websockets, not POST requests
export function* chatMessageFlowSaga({ payload }) {
  const key = 'chatMessageFlowSaga';
  const { message, presentersMessage = false, queueMessage = false } = payload;
  yield put(sma.initAction({ key }));
  try {
    const { popout, unreadMessages, notiOpen, stageAttendees, userId, role } = yield select(
      ({ app, sessionMessage, utils, session, user }) => ({
        popout: app.popout,
        unreadMessages:
          sessionMessage[
            queueMessage
              ? 'unreadQueueMessages'
              : presentersMessage
              ? 'unreadPresenterMessages'
              : 'unreadMessages'
          ],
        notiOpen: utils.open,
        stageAttendees: session.stageAttendees,
        userId: user.userId,
        role: user.role,
      })
    );

    yield put(sma.putMessageAction({ message, presentersMessage, queueMessage }));

    //TODO We should toggle off these notifications after a certain number of users or with a setting
    if (queueMessage ? role === 'presenter' || stageAttendees[userId]?.accepted : true) {
      const chatPopoutName = getFeatureName(
        queueMessage ? 'queue' : presentersMessage ? 'presenterchat' : 'chat'
      );
      if (!popout.open || popout.name !== chatPopoutName) {
        if (!notiOpen)
          yield put(
            uta.showNotificationAction({
              message: `${message.senderName || 'Someone'} sent a message`,
              type: 'info',
              icon: InfoOutlinedIcon,
              notiPopoutName: chatPopoutName,
              notiPopoutData: { conversationId: message.recipientId ? message.senderId : 'all' },
            })
          );

        yield put(
          sma.setUnreadMessagesAction({
            count: unreadMessages + 1,
            presentersMessage,
            queueMessage,
          })
        );
      }
    }
    yield put(sma.successAction({ key }));
  } catch (error) {
    yield put(sma.errorAction({ key, error }));
  }
}

export default function* sessionMessageWatcher() {
  yield takeEvery(sma.chatMessageAction().type, chatMessageFlowSaga);
}
