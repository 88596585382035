import {
  clickSelector,
  openSidebar,
  closeSidebar,
  wait,
} from 'components/side-panel/components/testing/helpers';

export default async function ({ subType, payload }) {
  switch (subType) {
    case 'migToggleOff':
      await openSidebar('roster-icon');
      clickSelector(`#user-mic-icon-${payload.userId}`);
      await wait(200);
      await closeSidebar();
      break;
    case 'videoToggleOff':
      await openSidebar('roster-icon');
      clickSelector(`#user-video-icon-${payload.userId}`);
      await wait(200);
      await closeSidebar();
      break;
    case 'moveInStream':
      await openSidebar('roster-icon');
      clickSelector(`#user-grid-switch-${payload.userId}:not([checked])`);
      await wait(200);
      await closeSidebar();
      break;
    case 'moveOutOfStream':
      await openSidebar('roster-icon');
      clickSelector(`#user-grid-switch-${payload.userId}[checked]`);
      await wait(200);
      await closeSidebar();
      break;
    default:
      console.log('No Subtype Found For ', subType, ' In Roster');
  }
}
