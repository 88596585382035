import { Button, TextField, Typography } from '@material-ui/core';
import LinearProgress from '@material-ui/core/LinearProgress';
import { makeStyles } from '@material-ui/core/styles';
import { loginToAdmin } from 'api/routes/admin';
import useCookie from 'hooks/useCookie';
import { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import routes from 'constants/routes';
import { useToken } from 'utils/token';

const useStyles = makeStyles((theme) => ({
  adminLogin: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    maxWidth: '30rem',
    padding: '1rem',
    margin: '0 auto',
    ...theme.mixins.vhHeight(100),
    '& > *': {
      marginTop: '0.5rem',
      marginBottom: '0.5rem',
    },
  },
  form: {
    ...theme.mixins.flexVerticalList(),
  },
  deniedReason: {
    textAlign: 'center',
    color: 'red',
    height: '1em',
    overflow: 'visible',
  },
}));

export default function AdminLogin() {
  const history = useHistory();
  const classes = useStyles();
  const { setToken, setRefreshToken } = useToken();
  const [loading, setLoading] = useState(false);
  const [userName, setUserName] = useState('');
  const [password, setPassword] = useState('');
  const [deniedReason, setDeniedReason] = useState('');
  const [storedUserName, updateStoredUserName] = useCookie('adminusername', '');
  const [storedPassword, updateStoredPassword] = useCookie('adminpassword', '');
  const [checkedCookies, setCheckedCookies] = useState(false);

  // If we enter the page with username+password cookies then try auto-logging in with them.
  useEffect(() => {
    if (checkedCookies) return;

    setCheckedCookies(true);

    if (!storedUserName || !storedPassword) return;

    const tryLoginFromCookies = async () => {
      let response;
      try {
        response = await loginToAdmin(storedUserName, storedPassword);
      } catch (e) {} //eslint-disable-line

      if (response?.success) {
        setToken(response.token);
        setRefreshToken(response.refreshToken);
        // Refresh stored cookie durations.
        updateStoredUserName(storedUserName, 7);
        updateStoredPassword(storedPassword, 7);

        history.push(routes.MANAGEMENT.path);
      } else {
        // Clear invalid cookies so we don't check against them on this page anymore.
        updateStoredUserName('', 0);
        updateStoredPassword('', 0);
      }
    };

    tryLoginFromCookies();
  }, [checkedCookies, storedUserName, storedPassword]);

  const login = async () => {
    setLoading(true);
    setDeniedReason('');
    setCheckedCookies(true);

    let response;
    try {
      response = await loginToAdmin(userName.trim(), password);
    } catch (e) {
      setDeniedReason('Server error trying to login.');
    }

    if (response?.success) {
      setToken(response.token);
      setRefreshToken(response.refreshToken);
      updateStoredUserName(userName, 7);
      updateStoredPassword(password, 7);

      history.push(`${routes.MANAGEMENT.path}/accounts`);
    } else {
      setDeniedReason(response?.reason || 'Incorrect user name or password!');
      setLoading(false);
    }
  };

  const onChangeUserName = (event) => void setUserName(event.currentTarget.value);
  const onChangePassword = (event) => void setPassword(event.currentTarget.value);

  return (
    <div className={classes.adminLogin}>
      <Typography variant="h5" color="primary">
        Administrative Login
      </Typography>

      <form className={classes.form} noValidate autoComplete="off">
        <TextField
          fullWidth
          label="User Name"
          value={userName}
          disabled={loading}
          onChange={onChangeUserName}
        />

        <TextField
          fullWidth
          type="password"
          label="Password"
          value={password}
          disabled={loading}
          onChange={onChangePassword}
        />

        <Button
          variant="contained"
          color="primary"
          onClick={login}
          disabled={loading || !userName || !password}
        >
          {loading ? 'Logging In...' : 'Log In'}
        </Button>
      </form>

      <LinearProgress style={{ opacity: loading ? 1 : 0 }} />

      <div className={classes.deniedReason}>{deniedReason}</div>
    </div>
  );
}
