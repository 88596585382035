import { createLogger, format } from 'winston';
import BrowserConsole from 'winston-transport-browserconsole';
import config from './config';

const logger = createLogger({
  format: format.simple(),
  level: config.LOG_LEVEL,
  transports: [new BrowserConsole()],
});

export default logger;
