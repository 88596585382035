import PollOutlinedIcon from '@material-ui/icons/Poll';
import { getFeatureName } from 'constants/features';
import DefaultIcon from './DefaultIcon';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';

PollIcon.propTypes = {
  hideIcon: PropTypes.bool.isRequired,
};

export default function PollIcon({ hideIcon }) {
  const unseenPoll = useSelector((state) => state.sessionPolling.unseenPoll);

  return (
    <DefaultIcon
      buttonId="poll-icon"
      name={getFeatureName('polling')}
      IconComp={PollOutlinedIcon}
      hideIcon={hideIcon}
      badgeContent="!"
      badgeColor="error"
      hideBadge={!unseenPoll}
    />
  );
}
