// import Typography from '@material-ui/core/Typography';
import MUIDataTable from 'mui-datatables';
import { useSelector, useDispatch } from 'react-redux';
import CreateEditPoll from 'components/polling/CreateEditPoll';
import { useState, useMemo } from 'react';
import IconButton from '@material-ui/core/IconButton';
import Button from '@material-ui/core/Button';
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import { getDataTableTheme } from '../index.styles';
import { MuiThemeProvider, useTheme } from '@material-ui/core/styles';
import { deletePoll } from 'api/routes/sessionPolls';
import { removePollAction } from 'store/session-polling/sessionPollingActions';
import Tooltip from '@material-ui/core/Tooltip';

export default function PollTemplateTable() {
  const dispatch = useDispatch();
  const templates = useSelector((state) => state.sessionPolling.templates);
  const [isEditing, setIsEditing] = useState(false);
  const [editPollId, setEditPollId] = useState('');
  const theme = useTheme();
  const dataTableTheme = useMemo(() => getDataTableTheme(theme), [theme]);

  const handleEditPoll = (pollId) => {
    setEditPollId(pollId);
    setIsEditing(true);
  };

  const handleDelete = async (pollId) => {
    await deletePoll(pollId, true);
    dispatch(removePollAction({ pollId, template: true }));
  };

  const clearData = () => {
    setIsEditing(false);
    setEditPollId('');
  };

  const columns = [
    {
      name: 'name',
      label: 'Name',
      options: {
        filter: true,
        sort: true,
        searchable: true,
      },
    },
    {
      name: 'questionText',
      label: 'Question',
      options: {
        filter: true,
        sort: true,
        searchable: true,
      },
    },
    {
      name: 'pollId',
      label: 'Edit',
      options: {
        filter: false,
        sort: false,
        searchable: false,
        customBodyRender: (value) => (
          <IconButton onClick={() => handleEditPoll(value)}>
            <Tooltip placement="bottom" title="Click to edit poll or create duplicate">
              <EditIcon />
            </Tooltip>
          </IconButton>
        ),
      },
    },
    {
      name: 'pollId',
      label: 'Delete',
      options: {
        filter: false,
        sort: false,
        searchable: false,
        customBodyRender: (value) => (
          <IconButton onClick={() => handleDelete(value)}>
            <DeleteIcon />
          </IconButton>
        ),
      },
    },
  ];

  const options = {
    print: false,
    download: false,
    viewColumns: false,
    filter: false,
    elevation: 0,
    sortOrder: { name: 'createdAt', direction: 'desc' },
    filterType: 'multiselect',
    selectableRows: 'none',
    // customToolbar: () => {
    //   return (
    //     <IconButton style={{ margin: '0rem .5rem' }} onClick={async () => refreshVods()}>
    //       <RefreshIcon />
    //     </IconButton>
    //   );
    // },
  };

  return (
    <div>
      {isEditing ? (
        <>
          <IconButton style={{ fontSize: '1rem', margin: '1rem 0rem' }} onClick={clearData}>
            <ArrowBackIosIcon /> Back
          </IconButton>
          <CreateEditPoll
            isSettingsPage={true}
            editPollId={editPollId}
            onSubmitted={clearData}
            onClickBack={clearData}
          />
        </>
      ) : (
        <>
          <div style={{ display: 'flex', marginBottom: '1rem' }}>
            <div style={{ flexGrow: 1 }} />
            <Button onClick={() => setIsEditing(true)} variant="contained" color="primary">
              Create Poll Template
            </Button>
          </div>
          <MuiThemeProvider theme={dataTableTheme}>
            <MUIDataTable
              style={{ width: '100%' }}
              data={templates}
              columns={columns}
              options={options}
            />
          </MuiThemeProvider>
        </>
      )}
    </div>
  );
}
