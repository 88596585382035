import IconButton from '@material-ui/core/IconButton';
import { makeStyles } from '@material-ui/core/styles';
import Tooltip from '@material-ui/core/Tooltip';
import Typography from '@material-ui/core/Typography';
import PersonAddIcon from '@material-ui/icons/PersonAdd';
import LoadingPage from 'components/LoadingPage';
import useDeepEffect from 'hooks/useDeepEffect';
import logger from 'logger';
import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { blockUserAction } from 'store/session/sessionActions';
import { batchGetUsers } from 'api/routes/user';

const useStyles = makeStyles((theme) => ({
  root: {
    margin: `0.75rem ${theme.sidePanel.gutter}`,
  },
  user: {
    display: 'flex',
    alignItems: 'center',
    padding: '0.5rem 0',
    borderBottom: '2px solid #fff2',
  },
  userName: {
    flexGrow: 1,
    fontWeight: 'bold',
  },
  banIcon: {
    color: theme.palette.primary.main,
  },
}));

export default function BanList() {
  const classes = useStyles();
  const [displayBlockedUsers, setDisplayBlockedUsers] = useState([]);
  const [loading, setLoading] = useState(false);
  const blockedUsers = useSelector((store) => store.session.blockedUsers);

  const dispatch = useDispatch();

  const fetchUsers = async () => {
    setLoading(true);

    try {
      if (blockedUsers.length) {
        const newBlockedUsers = (await batchGetUsers(blockedUsers))
          .map((user) => ({
            name: `${user.firstName} ${user.lastName}`,
            userId: user.userId,
          }))
          .sort((userA, userB) => {
            const aName = userA.name || '';
            const bName = userB.name || '';
            return aName.localeCompare(bName, 'en', { ignorePunctuation: true });
          });

        setDisplayBlockedUsers(newBlockedUsers);
      } else {
        setDisplayBlockedUsers([]);
      }
    } catch (e) {
      logger.error('Error getting user names for block list', e);
    }

    setLoading(false);
  };

  useDeepEffect(() => {
    fetchUsers();
  }, [blockedUsers]);

  if (loading) return <LoadingPage>Loading ban list...</LoadingPage>;

  const unbanUser = (userId) => {
    dispatch(blockUserAction({ userId: userId, shouldBlock: false }));
    setLoading(true);
    setTimeout(() => {
      try {
        setLoading(false);
      } catch (e) {
        console.log(e);
      }
    }, 20000);
  };

  return (
    <div className={classes.root}>
      {!displayBlockedUsers.length ? (
        <Typography>No Users Are Currently Blocked</Typography>
      ) : (
        displayBlockedUsers.map((user) => (
          <div className={classes.user} key={user.userId}>
            <div className={classes.userName}>{user.name}</div>

            <Tooltip title="Unblock User">
              <IconButton onClick={() => unbanUser(user.userId)}>
                <PersonAddIcon className={classes.banIcon} />
              </IconButton>
            </Tooltip>
          </div>
        ))
      )}
    </div>
  );
}
