import PropTypes from 'prop-types';
import Button from '@material-ui/core/Button';
import Paper from '@material-ui/core/Paper';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import DeviceSwitcher from 'components/devices/DeviceSwitcher';
import { useEffect, useRef } from 'react';
import { useSelector } from 'react-redux';
import deepEqual from 'react-fast-compare';
import { Grid } from '@material-ui/core';

GuestJoinMeetingSetup.propTypes = {
  joinMeeting: PropTypes.func,
};

const useStyles = makeStyles((theme) => ({
  joinMeetingPopupWrapper: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    margin: '0 2rem',
    maxHeight: '100vh',

    ...theme.mixins.vhMinHeight(100),
  },
  joinMeetingPopup: {
    padding: '1.5rem',
    textAlign: 'center',
    border: `2px solid ${theme.palette.background.border}`,
    borderRadius: '15px',
    maxWidth: '1200px',
    maxHeight: '100vh',
    overflow: 'auto',
  },
  joinButton: {
    marginTop: '2rem',
  },
  mainContainer: {
    backgroundColor: theme.palette.background.light,
    padding: theme.spacing(3),
    marginTop: '2rem',
    borderRadius: '10px',
  },
}));

export default function GuestJoinMeetingSetup({ joinMeeting }) {
  const currentVideoInputDevice = useSelector((store) => store.chime.currentVideoInputDevice);
  const audioVideo = useSelector((store) => store.chime.audioVideo, deepEqual);
  const classes = useStyles();
  const videoElement = useRef(null);

  const turnOnVideoPreview = async () => {
    if (audioVideo && videoElement.current) {
      await audioVideo.chooseVideoInputDevice(currentVideoInputDevice);
      await audioVideo.startVideoPreviewForVideoInput(videoElement.current);
    }
  };

  const turnOffVideoPreview = async () => {
    if (audioVideo && videoElement.current)
      await audioVideo.stopVideoPreviewForVideoInput(videoElement.current);
  };

  useEffect(() => {
    turnOnVideoPreview();
    return () => {
      turnOffVideoPreview();
    };
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    turnOnVideoPreview();
  }, currentVideoInputDevice);

  return (
    <div className={classes.joinMeetingPopupWrapper}>
      <Paper elevation={3} className={classes.joinMeetingPopup}>
        <Typography variant="h5">Joining Queue</Typography>
        <Typography variant="body">
          Please check your audio and video are working below. These wil not be enabled until you
          are brought onto the stage.
        </Typography>

        <Paper elevation={3}>
          <Grid
            className={classes.mainContainer}
            container
            justifyContent="center"
            alignItems="center"
            spacing={2}
          >
            <Grid item xs={12} md={8}>
              <video
                style={{ width: '100%' }}
                data-testid="preview-video-element"
                ref={videoElement}
              />
              {/* <button onClick={togglePreview}>video</button> */}
            </Grid>
            <Grid item xs={12} md={4}>
              <DeviceSwitcher qualityPanel={true} />
            </Grid>
          </Grid>
        </Paper>

        <Button
          variant="contained"
          color="primary"
          onClick={joinMeeting}
          className={classes.joinButton}
          id="queue-join-button"
        >
          Join Queue
        </Button>
      </Paper>
    </div>
  );
}
