import PropTypes from 'prop-types';
import DefaultIcon from './DefaultIcon';
import { LeaderboardIcon as Icon } from 'svg';

LeaderBoardIcon.propTypes = {
  hideIcon: PropTypes.bool.isRequired,
};

export default function LeaderBoardIcon({ hideIcon }) {
  return (
    <>
      <DefaultIcon name="Leader Board" hideIcon={hideIcon} IconComp={Icon} />
    </>
  );
}
