import * as Sentry from '@sentry/react';
import { applyMiddleware, createStore } from 'redux';
import { composeWithDevTools } from 'redux-devtools-extension';
import createSagaMiddleware from 'redux-saga';
import rootReducer from 'store/rootReducer';
import rootSaga from 'store/rootSaga';
import cloneDeep from 'lodash/cloneDeep';
import { stringify } from 'flatted';

const STRINGIFY_KEYS = [
  'permissions',
  'audioInputDevices',
  'audioOutputDevices',
  'videoInputDevices',
  'popout',
];
const KEYS_TO_DELETE = [
  'analytics',
  'activeSpeaker',
  'management',
  'webSocket',
  'sessionMessage',
  'sessionQuestion',
  'actionStatus',
  'sessionPolling',
  'pendingRequests',
];

export default function configureStore() {
  const sagaMiddleware = createSagaMiddleware();
  const sentryReduxEnhancer = Sentry.createReduxEnhancer({
    stateTransformer: cleanState,
  });
  let middleware = [sagaMiddleware];
  const appliedMiddleWare = applyMiddleware(...middleware);
  const store = createStore(
    rootReducer,
    composeWithDevTools(appliedMiddleWare, sentryReduxEnhancer)
  );
  sagaMiddleware.run(rootSaga);
  return store;
}

const cleanState = (state) => {
  let transformedState = cloneDeep(state);
  Object.keys(transformedState).forEach((key) => {
    if (KEYS_TO_DELETE.includes(key)) delete transformedState[key];

    if (typeof transformedState[key] === 'object' && transformedState[key] !== null) {
      Object.keys(transformedState[key]).forEach((newKey) => {
        if (KEYS_TO_DELETE.includes(newKey)) delete transformedState[key][newKey];
        if (
          (STRINGIFY_KEYS.includes(key) || STRINGIFY_KEYS.includes(newKey)) &&
          typeof transformedState[key][newKey] === 'object' &&
          transformedState[key][newKey] !== null
        ) {
          transformedState[key][newKey] = stringify(transformedState[key][newKey]);
        }
      });
    }

    if (transformedState?.user?.email) delete transformedState.user.email;
    if (transformedState?.user?.lastName) delete transformedState.user.lastName;
    if (transformedState?.user?.firstName) delete transformedState.user.firstName;
  });

  return transformedState;
};
