import PropTypes from 'prop-types';
import DefaultIcon from './DefaultIcon';
import SubscriptionsIcon from '@material-ui/icons/Subscriptions';

StudioIcon.propTypes = {
  hideIcon: PropTypes.bool.isRequired,
};

export default function StudioIcon({ hideIcon }) {
  return (
    <>
      <DefaultIcon name="Session Studio" hideIcon={hideIcon} IconComp={SubscriptionsIcon} />
    </>
  );
}
