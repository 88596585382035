import { IconButton, Button, TextField, Typography, Slider, Tooltip } from '@material-ui/core';
import { useState } from 'react';
import { useDispatch } from 'react-redux';
import CancelIcon from '@material-ui/icons/Cancel';
import { updateVodAction } from 'store/event/eventActions';
import PropTypes from 'prop-types';
import { formatSeconds } from 'utils/utilMethods';

function valuetext(value) {
  return formatSeconds(value);
}

ValueLabelComponent.propTypes = {
  children: PropTypes.any,
  open: PropTypes.any,
  value: PropTypes.any,
};

function ValueLabelComponent(props) {
  const { children, open, value } = props;

  return (
    <Tooltip open={open} enterTouchDelay={0} placement="top" title={value}>
      {children}
    </Tooltip>
  );
}

VodEditModal.propTypes = {
  vod: PropTypes.object,
  onClose: PropTypes.func.isRequired,
};

export default function VodEditModal({ vod, onClose }) {
  const { vodId, startTime, endTime, trimStart, trimEnd, name } = vod;
  const duration = (endTime - startTime) / 1000;
  const dispatch = useDispatch();
  const [updatedName, setUpdatedName] = useState('');
  const [value, setValue] = useState([trimStart ? trimStart : 0, trimEnd ? trimEnd : duration]);

  const handleSave = async () => {
    const [start, end] = value;
    let update = {
      vodId,
      trimStart: parseInt(start),
      trimEnd: parseInt(end),
    };
    if (updatedName && updatedName !== name) update.name = updatedName;

    dispatch(updateVodAction(update));
    onClose();
  };

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <div className="wrapper">
      <div className="header">
        <div className="title">Edit</div>
        <IconButton onClick={onClose}>
          <CancelIcon />
        </IconButton>
      </div>

      <div className="body">
        <TextField
          style={{ width: '100%' }}
          value={updatedName}
          placeholder={name || ''}
          onChange={(e) => setUpdatedName(e.target.value)}
          label="Rename Video"
          variant="outlined"
          size="small"
          autoFocus
        />
        <Typography id="range-slider" gutterBottom>
          Trim Points
        </Typography>
        <Slider
          value={value}
          style={{ marginTop: '30px' }}
          ValueLabelComponent={ValueLabelComponent}
          onChange={handleChange}
          max={duration}
          valueLabelDisplay="on"
          valueLabelFormat={(x) => formatSeconds(x)}
          aria-labelledby="range-slider"
          getAriaValueText={valuetext}
        />
      </div>
      <div className="footer">
        <Button variant="outlined" color="primary" onClick={onClose}>
          Cancel
        </Button>

        <Button variant="contained" color="primary" onClick={handleSave}>
          Save
        </Button>
      </div>
    </div>
  );
}
