import { Typography, LinearProgress, CircularProgress } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import PoweredByImage from 'components/PoweredByImage';

const useStyles = makeStyles((theme) => ({
  wrapper: {
    width: '100vw',
    ...theme.mixins.vhHeight(100),
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  root: {
    minWidth: '35rem',
    maxWidth: '100vw',
    padding: '3rem',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',

    '& > *:not(:first-child)': {
      marginTop: '1rem',
    },

    '& > *:not(:last-child)': {
      marginBottom: '1rem',
    },

    '& .header': {
      width: '100%',
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'flex-end',
      paddingBottom: '0.5rem',
      paddingLeft: '1rem',
      paddingRight: '1rem',
      marginBottom: 0,
      /*borderBottom: '2px solid',
      borderBottomColor: theme.palette.primary.main,*/

      '& img': {
        height: '3rem',
        marginRight: '1rem',
      },
    },

    '& .MuiCircularProgress-svg': {
      color: theme.palette.primary.main,
    },

    '& .MuiLinearProgress-root': {
      color: theme.palette.primary.main,
      marginTop: 0,
      width: '100%',
    },
  },
}));

WaitingPage.propTypes = {
  children: PropTypes.node,
  header: PropTypes.string,
  type: PropTypes.string,
  progress: PropTypes.number,
};

export default function WaitingPage({ children, type, header, progress }) {
  type = type ?? 'message';
  const classes = useStyles();

  return (
    <div className={classes.wrapper}>
      <div className={classes.root}>
        <div className="header">
          <PoweredByImage />

          <Typography
            className="headerText"
            variant="h6"
            style={{ color: type === 'error' ? 'red' : '' }}
          >
            {header}
          </Typography>
        </div>

        <LinearProgress
          variant="determinate"
          value={typeof progress === 'number' ? progress : 100}
        />

        <Typography className="messageText" variant="body1">
          {children}
        </Typography>

        {type !== 'message' && type !== 'error' && (
          <CircularProgress
            style={{ opacity: typeof progress === 'number' ? 0 : 1 }}
            size={60}
            thickness={6}
            disableShrink={type === 'waiting'}
          />
        )}
      </div>
    </div>
  );
}
