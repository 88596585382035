import React from 'react';
import { Typography, CircularProgress, Button } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import PropTypes from 'prop-types';
import { startStreamAction } from 'store/session/sessionActions';
import PlayArrowIcon from '@material-ui/icons/PlayArrow';
import { useDispatch } from 'react-redux';

const useStyles = makeStyles((theme) => ({
  root: {
    height: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
    padding: '1rem',
    textAlign: 'center',
  },
  box: {
    background: theme.palette.background.main,
    padding: '0.5rem',
    ...theme.mixins.shadow(),
  },
  progress: {
    marginTop: '1rem',
  },
  startContainer: {
    marginTop: '2rem',
    marginBottom: '2rem',
  },
  start: {
    backgroundColor: theme.palette.tertiary.main,
    padding: 0,
    height: '2.5rem',
    ...theme.mixins.borderRadius(),
    ...theme.mixins.shadow(),
  },
}));

export default function StreamOfflinePanel({ status }) {
  const classes = useStyles();
  const dispatch = useDispatch();

  return (
    <div className={classes.root}>
      <div></div>
      <div className={classes.box}>
        {status === 'STARTING' || status === 'CREATING' ? (
          <>
            <Typography variant="h6" color="primary">
              The stream is starting. Please wait...
            </Typography>
            <CircularProgress className={classes.progress} />
          </>
        ) : (
          <>
            <Typography variant="body1" color="primary">
              The stream is currently offline.
            </Typography>
            <div className={classes.startContainer}>
              <Button
                className={classes.start}
                onClick={() => {
                  dispatch(startStreamAction());
                }}
              >
                <PlayArrowIcon />
              </Button>
            </div>
          </>
        )}
      </div>
    </div>
  );
}

StreamOfflinePanel.propTypes = {
  status: PropTypes.string.isRequired,
};
