import CircularProgress from '@material-ui/core/CircularProgress';
import Switch from '@material-ui/core/Switch';
import PropTypes from 'prop-types';
import React, { useState, useEffect } from 'react';
import { updateVodAction } from 'store/event/eventActions';
import { useDispatch } from 'react-redux';
VodPublish.propTypes = {
  published: PropTypes.bool.isRequired,
  vodId: PropTypes.string.isRequired,
};

VodPublish.defaultProps = {
  published: false,
};

export default function VodPublish({ published, vodId }) {
  const dispatch = useDispatch();
  const [updatedPublished, setUpdatedPublished] = useState(published);
  const [saving, setSaving] = useState(false);
  const handlePublishUpdate = async () => {
    setSaving(true);
    dispatch(updateVodAction({ vodId, published: !published }));
  };
  useEffect(() => {
    setUpdatedPublished(published);
    setSaving(false);
  }, [published]);
  return saving ? (
    <CircularProgress size={20} color="primary" />
  ) : (
    <Switch color="primary" checked={updatedPublished} onChange={handlePublishUpdate} />
  );
}
