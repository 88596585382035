import CopyLink from '@material-ui/icons/Link';
import { useState } from 'react';
import { useSelector } from 'react-redux';
import DefaultIcon from './DefaultIcon';
import routes from 'constants/routes';
import config from 'config';

const { STAGE } = config;

export default function Icon() {
  const betaMeetingId = useSelector((store) => store.app.betaMeetingId);
  const [copied, setCopied] = useState(false);

  if (!betaMeetingId) return '';

  let copyNotifyTimer;
  const onClickCopy = async () => {
    await navigator.clipboard.writeText(
      `${window.location.origin}${routes.BETA.path}/${betaMeetingId}${window.location.pathname}`
    );

    if (copyNotifyTimer) clearTimeout(copyNotifyTimer);
    setCopied(true);
    copyNotifyTimer = setTimeout(() => void setCopied(false), 3500);
  };

  return (
    <DefaultIcon
      name={copied ? 'Copied!' : 'Copy beta meeting link'}
      shouldPopout={false}
      handleClick={onClickCopy}
      IconComp={CopyLink}
      hideIcon={!['beta', 'develop'].includes(STAGE)}
    />
  );
}
