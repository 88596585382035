import makeStyles from '@material-ui/styles/makeStyles';
import Content from 'components/side-panel/Content';
import Title from 'components/side-panel/Title';
import { useSelector } from 'react-redux';
import deepEqual from 'react-fast-compare';
import { useState, useEffect } from 'react';
import Popout from '../Popout';
import VideoPlayer from '../video/index';

const useStyles = makeStyles((theme) => ({
  sidePanel: {
    padding: 0,
    margin: 0,
    // border: '1px solid lightgrey',
    display: 'flex',
    flexDirection: 'column',
    backgroundImage: `linear-gradient(to top right, ${theme.palette.sidePanelGradient.light}, ${theme.palette.sidePanelGradient.dark})`,
    ...theme.mixins.vhHeight(100),
    maxWidth: '30rem',
    minWidth: '30rem',
    ...theme.mixins.shadow(true, 1, '-2px', '0px'),
    [theme.breakpoints.down('lg')]: {
      position: 'absolute',
      top: 0,
      left: 0,
      maxWidth: 'calc(100vw - 5rem)',
      minWidth: 'calc(100vw - 5rem)',
      zIndex: 101,
    },
    [theme.breakpoints.down('mobile')]: {
      position: 'absolute',
      top: 0,
      left: 0,
      maxWidth: 'calc(100vw - 3rem)',
      minWidth: 'calc(100vw - 3rem)',
      zIndex: 101,
    },
  },
}));
const Index = () => {
  const classes = useStyles();
  const popout = useSelector((store) => store.app.popout, deepEqual);
  const status = useSelector((store) => store.session.status);
  const [openStream, setOpenStream] = useState(false);

  const closePortal = () => {
    setOpenStream(false);
  };

  useEffect(() => {
    window.addEventListener('beforeunload', closePortal);
    return () => {
      window.removeEventListener('beforeunload', closePortal);
    };
  }, []);

  useEffect(() => {
    if (status !== 'LIVE' && openStream) {
      closePortal();
    }
  }, [status, openStream]);

  return (
    <>
      {openStream && (
        <Popout onClose={() => setOpenStream(false)}>
          <VideoPlayer />
        </Popout>
      )}
      {popout.open && (
        <div className={classes.sidePanel}>
          <Title>{popout.name}</Title>
          <Content setOpenStream={() => setOpenStream(!openStream)} openStream={openStream} />
        </div>
      )}
    </>
  );
};

export default Index;
