import { basePost, promisedPost } from 'api/apiDetour';

const baseEndpoint = 'user-session';

const post = async ({ endpoint, data = {}, isSigned = false }) => {
  let resp = await basePost(endpoint, data, 'user-session', isSigned);
  return resp;
};

export const updateLocalVideoStatus = async (videoStatus) => {
  let resp = await post({ endpoint: 'local-video-status', data: { videoStatus } });
  return resp;
};

export const updateRaisedHand = (handIsRaised, options) => {
  options = options ?? {};
  options.data = { handIsRaised };
  return promisedPost('raise-hand', baseEndpoint, 'raiseHand', options);
};

export const putDownHand = (userId, options) => {
  options = options ?? {};
  options.data = { userId };
  return promisedPost('put-down-hand', baseEndpoint, 'putDownHand', options);
};
