import AppWrapper from 'components/AppWrapper';
import MeetingUserInitialization from 'components/initialization/MeetingUserInitialization';
import ViewContainer from 'components/view-container';
import { useSelector } from 'react-redux';
import RecordingModal from 'components/RecordingModal';
export default function MeetingUserView() {
  const meetingUserHasInitialized = useSelector((store) => store.chime.meetingUserHasInitialized);

  return (
    <>
      <MeetingUserInitialization />
      {meetingUserHasInitialized && (
        <AppWrapper>
          <ViewContainer />
          <RecordingModal />
        </AppWrapper>
      )}
    </>
  );
}
