import { makeStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import PollPresenter from 'components/polling/PollPresenter';
import PollAttendee from 'components/polling/PollAttendee';
import { useSelector, useDispatch } from 'react-redux';
import { pollIsRunning } from 'utils/polling';
import { useEffect } from 'react';
import { setUnseenPollAction } from 'store/session-polling/sessionPollingActions';

const useStyles = makeStyles((theme) => ({
  listPoll: {
    ...theme.mixins.flexVerticalList(),
  },
  polls: {
    flexGrow: 1,
    ...theme.mixins.flexVerticalList(),
  },
  noPolls: {
    textAlign: 'center',
    fontSize: '1.25rem',
    marginTop: '5rem',
  },
}));

ListPoll.propTypes = {
  polls: PropTypes.array.isRequired,
  inSettings: PropTypes.bool,
  activePollId: PropTypes.string,
};

export default function ListPoll({ polls, inSettings }) {
  const classes = useStyles();
  const role = useSelector((state) => state.user.role);
  const unseenPoll = useSelector((state) => state.sessionPolling.unseenPoll);
  const dispatch = useDispatch();

  useEffect(() => {
    if (unseenPoll) dispatch(setUnseenPollAction({ unseenPoll: false }));
  }, [unseenPoll]);

  // const [forceUpdateTick, setForceUpdateTick] = useState(0);
  // useInterval(() => setForceUpdateTick(forceUpdateTick + 1), 1000);

  const canManage = role === 'presenter' || role === 'admin';
  const Poll = canManage ? PollPresenter : PollAttendee;

  const sortedPolls = [...polls].sort((a, b) => {
    if (pollIsRunning(a)) return -1;
    if (pollIsRunning(b)) return 1;
    return new Date(b.endedAt).getTime() - new Date(a.endedAt).getTime();
  });

  return (
    <div className={classes.listPoll}>
      {sortedPolls.length === 0 && <div className={classes.noPolls}>No polls to display.</div>}

      <div className={classes.polls}>
        {sortedPolls.map((poll) => (
          <Poll key={poll.pollId} poll={poll} inSettings={inSettings} />
        ))}
      </div>
    </div>
  );
}
