import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  broadcastStreamControls: {
    ...theme.mixins.vhMinHeight(100),
    overflowY: 'auto',
    margin: `0.75rem ${theme.sidePanel.gutter}`,
    ...theme.mixins.flexVerticalList(),
    '& button': {
      fontWeight: 'bold',
      fontSize: '1rem',
      color: 'white',

      '& .MuiSvgIcon-root': {
        marginRight: '0.5ch',
      },
    },
  },
  images: {
    maxHeight: '15rem',
    objectFit: 'cover',
    display: 'block',
    width: '100%',
    height: 'auto',
    ...theme.mixins.shadow(),
  },
  standbyButton: {
    backgroundColor: theme.palette.background.light,
  },
  stopButton: {
    // marginTop: '4rem !important',
    backgroundColor: theme.palette.tertiary.main,
  },
  stop: {
    backgroundColor: theme.palette.tertiary.main,
  },
  transitionBackground: {
    position: 'absolute',
    width: 400,
    top: '50%',
    left: 'calc(50vw - 200px)',
    backgroundColor: theme.palette.background.light,
    border: '2px solid #000',
    boxShadow: theme.shadows[5],
    padding: '0.5rem 1rem 0.75rem',
  },
  imgOverlay: {
    position: 'absolute',
    bottom: '0',
    width: '100%',
    padding: '0.5rem',
  },
  clearButtonOverlay: {
    position: 'absolute',
    top: '20px',
    backgroundColor: 'transparent',
    width: '100%',
    padding: '0.5rem',
  },
  imgContainer: {
    position: 'relative',
    color: 'white',
  },
  selectNextBox: {
    border: '4px dotted rgba(3, 173, 238, 0.2)',
    minHeight: '12rem',
    backgroundColor: 'rgba(3, 173, 238, 0.2)',
    padding: '0.25rem 2rem',
    ...theme.mixins.flexVerticalList('1rem'),
    justifyContent: 'center',
  },
  imgTitle: {
    display: 'inline-block',
    backgroundColor: theme.palette.imageLabel.main,
    fontSize: '70%',
    fontWeight: 'bold',
    padding: '0.25em 0.5em',
    '&[data-is-queue]': {
      backgroundColor: theme.palette.imageLabel.dark,
    },
  },

  timeSetContainer: {
    display: 'flex',
    alignItems: 'center',
    padding: '0 .5rem',
  },
}));

export default useStyles;
