import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { Typography } from '@material-ui/core';

const defaultErrorText = {
  404: 'Page not found',
  502: 'Internal server error',
};

const errorHelp = {
  404: "We couldn't find the page you were looking for. Please close this window and enter the session you are looking for through the Event App",
  502: 'Something went wrong with our servers. Please close this window and enter the session you are looking for through the Event App. Please try again or contact an admin if the problem persists.',
};

const styles = (theme) => ({
  root: {
    width: '100vw',
    ...theme.mixins.vhHeight(100),
    padding: '3rem',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  box: {
    width: '30rem',
  },
});

function HttpError({ code, text, help, classes }) {
  text = text ?? defaultErrorText[code];

  return (
    <div className={classes.root}>
      <div className={classes.box}>
        <Typography variant="h1">Error</Typography>
        <Typography variant="h4" color="primary">
          {code}
        </Typography>
        <Typography variant="body1" color="primary">
          {text}
        </Typography>
        <Typography variant="caption">{help || errorHelp[code]}</Typography>
      </div>
    </div>
  );
}

HttpError.propTypes = {
  text: PropTypes.string,
  help: PropTypes.string,
  code: PropTypes.number.isRequired,
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(HttpError);
