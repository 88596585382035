import { useState } from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { leaveRoomAction } from 'store/chime/chimeActions';

ExitMeetingDialog.propTypes = {
  open: PropTypes.bool,
  setOpen: PropTypes.func,
  showEndMeeting: PropTypes.bool,
};

export default function ExitMeetingDialog({ open, setOpen, showEndMeeting }) {
  const dispatch = useDispatch();
  const [isEndingSession, setIsEndingSession] = useState(false);
  const canEndSession = useSelector((store) => ['presenter', 'admin'].includes(store.user.role));

  const exitMeeting = async () => {
    dispatch(leaveRoomAction({ shouldEndSession: false }));
  };

  const endSession = async () => {
    dispatch(leaveRoomAction({ shouldEndSession: true }));
  };

  const onClickEndSession = () => void setIsEndingSession(true);
  const onClose = () => {
    setIsEndingSession(false);
    setOpen(false);
  };

  return (
    <Dialog open={open} onClose={onClose}>
      <DialogContent>
        {isEndingSession
          ? `END SESSION will end the session for all participants.
          Do you want to continue?`
          : 'Are you sure you want to leave the meeting?'}
      </DialogContent>

      <DialogActions style={{ display: 'flex', flexGrow: 1, justifyContent: 'space-between' }}>
        <Button onClick={onClose}>Cancel</Button>

        <div style={{ flexGrow: 1, minWidth: '4rem' }}></div>

        {!isEndingSession && (
          <Button onClick={exitMeeting} variant="contained" color="primary" autoFocus>
            Leave Meeting
          </Button>
        )}

        {isEndingSession && (
          <Button
            onClick={endSession}
            variant="contained"
            style={{ backgroundColor: 'red', marginLeft: '1rem' }}
          >
            Yes
          </Button>
        )}

        {!isEndingSession && canEndSession && showEndMeeting && (
          <Button
            onClick={onClickEndSession}
            variant="contained"
            style={{ backgroundColor: 'red', marginLeft: '1rem' }}
          >
            End Session
          </Button>
        )}
      </DialogActions>
    </Dialog>
  );
}
