import { createAction as ca } from '@reduxjs/toolkit';

export const addAccountEventNamesAction = ca('management/addAccountEventNamesAction');
export const errorAction = ca('management/errorAction');
export const getDataAction = ca('management/getDataAction');
export const initAction = ca('management/initAction');
export const initializeReportingAccountsAction = ca('management/initializeReportingAccountsAction');
export const resetStateAction = ca('management/resetStateAction');
export const setDataAction = ca('management/setDataAction');
export const setReportingAccountDataAction = ca('management/setReportingAccountDataAction');
export const successAction = ca('management/successAction');
