import { click, openAndCloseSidebar, wait } from 'components/side-panel/components/testing/helpers';

const randomInt = (max) => {
  return Math.floor(Math.random() * max);
};

export default async function ({ subType, payload }) {
  switch (subType) {
    case 'answerPoll':
      await openAndCloseSidebar('poll-icon', async () => {
        click(
          `poll-answer-${payload.randomAnswer ? randomInt(payload.numAnswers) : payload.answer}`
        );
        await wait(payload.randomTime ? randomInt(10000) : 200);
        click('poll-submit');
      });
      break;
    default:
      console.log('No Subtype Found For ', subType, ' In Poll');
  }
}
