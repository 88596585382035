import React from 'react';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import { useStyles } from 'components/devices/DeviceSwitcher';
import { useDispatch, useSelector } from 'react-redux';
import { setThemeAction } from 'store/app/appActions';
import { getCookie } from 'hooks/useCookie';
import { themes } from 'themes';
import themeConstants from 'constants/theme';

const selectableThemes = [];
for (const [themeKey, themeData] of Object.entries(themes)) {
  selectableThemes.push({ key: themeKey, name: themeData.name });
}

const enableStyleSheet = (styleSheet, enabled) => {
  let innerHTML = styleSheet.innerHTML;
  const currentState = !innerHTML.startsWith('/*');
  if (currentState === !!enabled) return;

  if (enabled) {
    innerHTML = innerHTML.substr(2, innerHTML.length - 4);
  } else {
    innerHTML = `/*${innerHTML}*/`;
  }

  styleSheet.innerHTML = innerHTML;
};

export default function ThemeSwitcher() {
  const dispatch = useDispatch();
  const theme = useSelector(
    (store) => store.app.theme || getCookie('theme') || themeConstants.defaultThemeName
  );
  const classes = useStyles();

  const changeTheme = async (event) => {
    // Hack for the Mui* style elements staying around when changing a theme.
    const newTheme = event.target.value;

    // All of the style elements with a data-meta tag are affected by this bug.
    const styleSheets = document.head.querySelectorAll('style[data-meta^="Mui"]');
    let styleSheet;

    // Store current theme on any untagged style elements since they're not deleted or altered.
    for (styleSheet of styleSheets) {
      if (!styleSheet.getAttribute('data-theme')) {
        styleSheet.setAttribute('data-theme', theme);
      }
    }

    dispatch(setThemeAction({ theme: newTheme }));

    // Any style elements that have data-meta starting with Mui and their data-theme NOT being the new theme will be commented out and vice versa.
    for (styleSheet of styleSheets) {
      enableStyleSheet(styleSheet, styleSheet.getAttribute('data-theme') === newTheme);
    }
  };

  return (
    <div className={classes.root}>
      <FormControl>
        <InputLabel id="theme-label">Theme</InputLabel>
        <Select labelId="theme-label" id="theme-select" value={theme} onChange={changeTheme}>
          {selectableThemes.map((theme) => (
            <MenuItem key={theme.key} value={theme.key}>
              {theme.name}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </div>
  );
}
