import { createAction as ca } from '@reduxjs/toolkit';

export const errorAction = ca('analytics/errorAction');
export const initAction = ca('analytics/initAction');
export const setShowAnalyticsAction = ca('analytics/setShowAnalyticsAction');
export const addSessionDataAction = ca('analytics/addSessionDataAction');
export const successAction = ca('analytics/successAction');
export const setUserSessionAnalyticsAction = ca('analytics/setUserSessionAnalyticsAction');
export const updateAnalyticsHistoryObjectAction = ca(
  'analytics/updateAnalyticsHistoryObjectAction'
);
export const addAnalyticUserAction = ca('analytics/addAnalyticUserAction');
export const updateAnalyticsCountObjectAction = ca('analytics/updateAnalyticsCountObjectAction');
