import { createReducer } from '@reduxjs/toolkit';
import { merge } from 'lodash';
import { buildActionStatus, standardActions } from 'store/utils';
import * as aa from './appActions';
import { setCookie } from 'hooks/useCookie';

const initialState = {
  actionStatus: buildActionStatus(aa),
  appType: 'meeting', //types: meeting | broadcast
  betaMeetingId: '',
  choose: false,
  didUserChoose: false,
  popout: {},
  selectedAnalytic: 'attendence',
  servicesOnly: false,
  shouldForceLowerResolution: false,
  showRecordingModal: false,
  showUsersInMeeting: true,
  theme: '',
};

const appReducer = createReducer(initialState, {
  ...standardActions(aa),
  [aa.setAppTypeAction]: (state, { payload: { appType } }) => {
    state.appType = appType;
  },
  [aa.setPopoutAction]: (state, { payload: { popout } }) => {
    state.popout = merge(state.popout, popout);
  },
  [aa.closePopoutAction]: (state) => {
    state.popout = {};
  },
  [aa.setSelectedAnalyticAction]: (state, { payload: { selected } }) => {
    state.selectedAnalytic = selected;
  },
  [aa.setShowUsersInMeetingAction]: (state, { payload: { show } }) => {
    state.showUsersInMeeting = show;
  },
  [aa.setBetaMeetingIdAction]: (state, { payload: { id } }) => {
    state.betaMeetingId = id;
  },
  [aa.setThemeAction]: (state, { payload: { theme } }) => {
    state.theme = theme;
    setCookie('theme', theme, 3650);
  },
  [aa.setShouldForceLowerResolutionAction]: (
    state,
    { payload: { shouldForceLowerResolution } }
  ) => {
    state.shouldForceLowerResolution = shouldForceLowerResolution;
  },
  [aa.setShowRecordingModalAction]: (state, { payload: { showRecordingModal } }) => {
    state.showRecordingModal = showRecordingModal;
  },
  [aa.setServicesOnlyAction]: (state, { payload: { servicesOnly } }) => {
    state.servicesOnly = servicesOnly;
    state.didUserChoose = true;
  },
  [aa.setChooseServicesAction]: (state, { payload: { servicesOnly, choose } }) => {
    state.servicesOnly = servicesOnly;
    state.choose = choose;
  },
});

export default appReducer;
