import { basePost } from 'api/apiDetour';

const post = async ({ endpoint, data = {}, isSigned = false }) => {
  let resp = await basePost(endpoint, data, 'admin', isSigned);
  return resp;
};

export const loginToAdmin = async (userName, password) => {
  let resp = await post({ endpoint: 'login', data: { userName, password } });
  return resp;
};

export const createExternalToken = async (
  accountId,
  eventId,
  firstName,
  lastName,
  role,
  title,
  type
) => {
  const resp = await post({
    endpoint: 'create-external-token',
    data: { accountId, eventId, firstName, lastName, role, title, type },
  });
  return resp;
};

export const getEvent = async (accountId, eventId) => {
  const resp = await post({
    endpoint: 'get-event-info',
    data: { accountId, eventId },
  });
  return resp;
};
