import Typography from '@material-ui/core/Typography';
import EndMeetingScreen from 'components/EndMeetingScreen';

export default function MeetingEnded() {
  return (
    <EndMeetingScreen>
      <Typography variant="div">The meeting has ended.</Typography>
    </EndMeetingScreen>
  );
}
