import IconButton from '@material-ui/core/IconButton';
import SettingsIcon from '@material-ui/icons/Settings';
import { startCase } from 'lodash';
import moment from 'moment';
import PropTypes from 'prop-types';
import { useState } from 'react';
import Base from '../components/Base';
import EventModal from '../components/EventModal';
import { deleteEvents } from 'api/routes/event';

Events.propTypes = {
  data: PropTypes.array.isRequired,
  refresh: PropTypes.func.isRequired,
};

const columns = [
  {
    name: 'title',
    label: 'Title',
    options: {
      filter: true,
      sort: true,
      searchable: true,
    },
  },
  {
    name: 'eventId',
    label: 'Event Id',
    options: {
      filter: true,
      sort: true,
      searchable: true,
    },
  },
  {
    name: 'accountId',
    label: 'Account Id',
    options: {
      display: false,
      filter: true,
      sort: true,
      searchable: true,
    },
  },
  {
    name: 'accountName',
    label: 'Account Name',
    options: {
      display: true,
      filter: true,
      sort: true,
      searchable: true,
    },
  },
  {
    name: 'currentSessionId',
    label: 'Current Session Id',
    options: {
      display: true,
      filter: true,
      sort: true,
      searchable: true,
    },
  },
  {
    name: 'sessionType',
    label: 'Session Type',
    options: {
      display: true,
      filter: true,
      sort: true,
      searchable: true,
      customBodyRender: (type) => startCase(type || ''),
    },
  },
  {
    name: 'activeUsers',
    label: 'Active Users',
    options: {
      filter: false,
      sort: true,
      searchable: false,
      customBodyRender: (users) => users || 0,
      sortDirection: 'desc',
    },
  },
  {
    name: 'updatedAt',
    label: 'Last Updated',
    options: {
      filter: false,
      display: false,
      sort: true,
      searchable: false,
      customBodyRender: (value) => moment(value).local().format('MM/DD/YY h:mm a'),
    },
  },
  {
    name: 'createdAt',
    label: 'Created',
    options: {
      filter: false,
      display: true,
      sort: true,
      searchable: false,
      customBodyRender: (value) => moment(value).local().format('M/D/YY h:mm a'),
    },
  },
  {
    name: 'videos',
    label: 'Videos',
    options: {
      filter: false,
      sort: true,
      searchable: false,
      customBodyRender: (videos) => (videos || []).length,
    },
  },
  {
    name: 'manageInfo',
    label: 'Manage',
    options: {
      filter: false,
      sort: false,
      searchable: false,
    },
  },
];

export default function Events({ data, refresh }) {
  const [modalOpen, setModalOpen] = useState(false);
  const [modalAccountId, setModalAccountId] = useState('');
  const [modalEventId, setModalEventId] = useState('');

  const openModal = ({ accountId, eventId }) => {
    setModalAccountId(accountId);
    setModalEventId(eventId);
    setModalOpen(true);
  };

  columns[columns.length - 1].options.customBodyRender = (value) => (
    <IconButton color="default" variant="contained" onClick={() => openModal(value)}>
      <SettingsIcon />
    </IconButton>
  );
  const options = {
    onRowsDelete: async (a) => {
      await deleteEvents(a.data.map(({ dataIndex }) => data[dataIndex]));
    },
    isRowSelectable: (idx, selected) =>
      !selected.data || selected.data.length < 5 || selected.lookup[idx],
  };

  return (
    <>
      <Base name="Events" columns={columns} data={data} options={options} refresh={refresh} />
      <EventModal
        open={modalOpen}
        accountId={modalAccountId}
        eventId={modalEventId}
        onClose={() => setModalOpen(false)}
      />
    </>
  );
}
