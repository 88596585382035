import Badge from '@material-ui/core/Badge';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import Tooltip from '@material-ui/core/Tooltip';
import { makeStyles } from '@material-ui/core/styles';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import React from 'react';
import { v4 as uuid } from 'uuid';
import { useSelector, useDispatch } from 'react-redux';
import { setPopoutAction } from 'store/app/appActions';
import { getFeatureType } from 'constants/features';
import deepEqual from 'react-fast-compare';

const useStyles = makeStyles((theme) => ({
  menuItemIcon: {
    fontSize: '250%',
  },
  menuListItemIcon: {
    color: 'inherit',
    minWidth: 'unset',
  },
  button: {
    display: 'flex',
    justifyContent: 'center',
    /*[theme.breakpoints.down('mobile')]: {
      background: theme.palette.primary.main + '22',
      borderRadius: '50%',
      '&:hover': {
        background: theme.palette.primary.main + '44',
      },
    },*/
  },
  activeButton: {
    color: theme.palette.primary.main,
    [theme.breakpoints.up('mobile')]: {
      backgroundImage: 'linear-gradient(to left, currentColor 4px, transparent 4px)',
    },
  },
}));

DefaultIcon.propTypes = {
  IconComp: PropTypes.any.isRequired,
  badgeClass: PropTypes.any,
  badgeClassCondition: PropTypes.bool,
  badgeContent: PropTypes.any,
  badgeColor: PropTypes.string,
  buttonId: PropTypes.string,
  handleClick: PropTypes.func.isRequired,
  hideBadge: PropTypes.bool,
  hideIcon: PropTypes.bool.isRequired,
  listItemClass: PropTypes.string,
  name: PropTypes.string.isRequired,
  shouldPopout: PropTypes.bool.isRequired,
};
DefaultIcon.defaultProps = {
  badgeClass: {},
  buttonId: uuid(),
  handleClick: () => {},
  hideBadge: true,
  hideIcon: false,
  shouldPopout: true,
};

export default function DefaultIcon({
  IconComp,
  badgeClass,
  badgeClassCondition,
  badgeContent,
  badgeColor,
  buttonId,
  handleClick,
  hideBadge,
  hideIcon,
  listItemClass,
  name,
  shouldPopout,
}) {
  const classes = useStyles();
  const popout = useSelector((store) => store.app.popout, deepEqual);
  const featureDisabled = useSelector(
    (store) => store.event.disabledFeatures[getFeatureType(name)]
  );
  const dispatch = useDispatch();
  const handleClickEvent = () => {
    handleClick();
    if (shouldPopout) {
      let popoutState =
        name !== popout.name
          ? {
              open: true,
              name,
            }
          : { open: false, name: '' };
      dispatch(setPopoutAction({ popout: popoutState }));
    }
  };

  return (
    !hideIcon &&
    !featureDisabled && (
      <Tooltip title={name} placement="left">
        <ListItem
          id={buttonId}
          data-opened={name === popout.name ? 1 : 0}
          button
          onClick={handleClickEvent}
          className={clsx({
            [classes.button]: true,
            [classes.activeButton]: name === popout.name,
            [listItemClass || '']: true,
          })}
        >
          <ListItemIcon className={classes.menuListItemIcon}>
            <Badge
              badgeContent={badgeContent}
              color={badgeColor ? badgeColor : 'primary'}
              invisible={hideBadge}
              className={clsx({
                [badgeClass]: badgeClassCondition,
              })}
            >
              <IconComp className={classes.menuItemIcon} />
            </Badge>
          </ListItemIcon>
        </ListItem>
      </Tooltip>
    )
  );
}
