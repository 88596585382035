import axios from 'axios';
import { Link } from '@material-ui/core';
import ErrorOutlineIcon from '@material-ui/icons/ErrorOutline';
import { showNotificationAction } from 'store/utils/utilActions';
import { store } from 'App';

export const downloadFile = (fileName, fileUrl) => {
  return new Promise(async (resolve, reject) => {
    if (fileUrl) {
      const resp = await axios.get(fileUrl);
      const blobUrl = window.URL.createObjectURL(new Blob([resp.data]));
      const link = document.createElement('a');
      link.href = blobUrl;
      link.setAttribute('download', fileName);
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      resolve('success');
    } else {
      reject(Error('Error Getting The File URL'));
    }
  });
};

export const removeQuery = (location, history, ...queryNames) => {
  location = { ...location };
  const searchParams = new URLSearchParams(location.search);
  const params = {};
  for (const [k, v] of searchParams.entries()) {
    params[k] = v;
  }

  queryNames.forEach((q) => {
    params[q] = null;
  });

  const str = [];
  for (const [k, v] of Object.entries(params)) {
    if (v !== null) {
      str.push(encodeURIComponent(k) + '=' + encodeURIComponent(v));
    }
  }
  location.search = '?' + str.join('&');

  history.replace(location);
};

const formats = {
  ogg: 'video/ogg; codecs="theora"',
  h264: 'video/mp4; codecs="avc1.42E01E"',
  webm: 'video/webm; codecs="vp8, vorbis"',
  vp9: 'video/webm; codecs="vp9"',
  hls: 'application/x-mpegURL; codecs="avc1.42E01E"',
};
let dummyVideo;
export const supportsVideoType = (type) => {
  if (!dummyVideo) {
    dummyVideo = document.createElement('video');
  }

  return dummyVideo.canPlayType(formats[type] ?? type);
};

export const formatSeconds = (secs) => {
  let sec_num = parseInt(secs, 10) || 0;
  let hours = Math.floor(sec_num / 3600);
  let minutes = Math.floor((sec_num - hours * 3600) / 60);
  let seconds = sec_num - hours * 3600 - minutes * 60;

  if (hours < 10) {
    hours = '0' + hours;
  }
  if (minutes < 10) {
    minutes = '0' + minutes;
  }
  if (seconds < 10) {
    seconds = '0' + seconds;
  }
  return `${hours > 0 ? hours + ':' : ''}${minutes}:${seconds}`;
};

//Matches a valid url
// eslint-disable-next-line
const urlPattern = /(https?:\/\/)?[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_\+.~#?&//=]*)?/gi;
//Matches an absolute url given a valid url
const absolutePattern = /^https?:\/\/|^\/\//i;
export const parseUrls = (message) => {
  //Gets an array of url matches in the message
  const urlMatches = [...message.matchAll(urlPattern)];

  let resultingMessage = [];
  let currentIndex = 0;

  for (const match of urlMatches) {
    const url = match[0];

    if (url.includes('..')) continue;

    //Gets the start index of this url
    const urlStartIndex = message.substring(currentIndex).search(urlPattern) + currentIndex;

    //Adds all non-url text prior to this url
    resultingMessage.push(message.substring(currentIndex, urlStartIndex));

    let targetUrl = url;
    if (url.search(absolutePattern) < 0) {
      targetUrl = '//' + url;
    }

    //Converts url to <a>
    resultingMessage.push(
      <Link href={targetUrl} key={urlStartIndex} target="_blank" rel="noreferrer">
        {url}
      </Link>
    );
    currentIndex = urlStartIndex + url.length;
  }

  //Adds all text after last URL (or if no URLs, just returns the message unaltered)
  resultingMessage.push(message.substring(currentIndex));
  return resultingMessage;
};

export const findIndexBy = (arr, key, value) => {
  for (let i = 0; i < arr.length; ++i) {
    if (arr[i][key] === value) {
      return i;
    }
  }

  return -1;
};

export const removeOneBy = (arr, key, value) => {
  const idx = findIndexBy(arr, key, value);
  if (idx > -1) {
    arr.splice(idx, 1);
  }

  return idx;
};

export const replaceOneBy = (arr, replacement, key, value) => {
  const idx = findIndexBy(arr, key, value);
  if (idx > -1) {
    arr[idx] = replacement;
  }

  return idx;
};

export const showNotification = (message, type, options) => {
  options = options ?? {};
  options.message = message;
  options.type = type;

  store.dispatch(showNotificationAction(options));
};

export const showGenericErrorNotification = (message, options) => {
  options = options ?? {};
  options.icon = options.icon ?? ErrorOutlineIcon;
  showNotification(`Error ${message}, please try again.`, 'error', options);
};
