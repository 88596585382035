import { makeStyles } from '@material-ui/core/styles';
import { getBetaEvent } from 'api/routes/event';
import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { setBetaMeetingIdAction } from 'store/app/appActions';
import AcknowledgeFeedback from './AcknowledgeFeedback';
import CreateEvent from './CreateEvent';
import JoinMeetingSetup from './JoinMeetingSetup';
import { useParams } from 'react-router-dom';

const ACCOUNT_ID = 'mosaic-testing';

const useStyles = makeStyles((theme) => ({
  beta: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    maxWidth: '40rem',
    padding: '1rem',
    margin: '0 auto',
    ...theme.mixins.vhHeight(100),
    '& > *': {
      marginTop: '0.5rem',
      marginBottom: '0.5rem',
    },
  },
  userName: {
    alignItems: 'center',
    ...theme.mixins.flexHorizontalList('0.5rem'),
  },
}));

export default function Beta() {
  const { eventId, type } = useParams();
  const classes = useStyles();
  const [token, setToken] = useState(null);
  const [eventName, setEventName] = useState('');
  const dispatch = useDispatch();

  useEffect(() => {
    if (!eventId) return;
    setEventName('');
    if (eventId) {
      const getEventData = async () => {
        let event;
        try {
          const response = await getBetaEvent(eventId);
          event = response.event;
        } catch (e) {} // eslint-disable-line

        setEventName(event?.title || 'Invalid!');
      };
      getEventData();

      dispatch(setBetaMeetingIdAction({ id: eventId }));
    }
  }, [eventId]);

  return (
    <div className={classes.beta}>
      {eventId ? (
        <>
          {token ? (
            <AcknowledgeFeedback token={token} accountId={ACCOUNT_ID} />
          ) : (
            <JoinMeetingSetup
              eventName={eventName}
              eventId={eventId}
              setToken={setToken}
              type={type || 'meeting'}
            />
          )}
        </>
      ) : (
        <CreateEvent eventName={eventName} setEventName={setEventName} eventId={eventId} />
      )}
    </div>
  );
}
