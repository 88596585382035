const users = [
  {
    firstName: 'Daphne',
    lastName: 'Schaden',
    email: 'SchadenDaphne@meet-fake-user.com',
  },
  {
    firstName: 'Larue',
    lastName: 'Stokes',
    email: 'StokesLarue@meet-fake-user.com',
  },
  {
    firstName: 'Oswald',
    lastName: 'Bode',
    email: 'BodeOswald@meet-fake-user.com',
  },
  {
    firstName: 'Louisa',
    lastName: 'Turner',
    email: 'TurnerLouisa@meet-fake-user.com',
  },
  {
    firstName: 'Bernadette',
    lastName: 'Rutherford',
    email: 'RutherfordBernadette@meet-fake-user.com',
  },
  {
    firstName: 'Dion',
    lastName: 'Jakubowski',
    email: 'JakubowskiDion@meet-fake-user.com',
  },
  {
    firstName: 'Dandre',
    lastName: 'Bashirian',
    email: 'BashirianDandre@meet-fake-user.com',
  },
  {
    firstName: 'Dandre',
    lastName: 'Mertz',
    email: 'MertzDandre@meet-fake-user.com',
  },
  {
    firstName: 'Tamara',
    lastName: 'Wolf',
    email: 'WolfTamara@meet-fake-user.com',
  },
  {
    firstName: 'Amalia',
    lastName: 'Abernathy',
    email: 'AbernathyAmalia@meet-fake-user.com',
  },
  {
    firstName: 'Donnell',
    lastName: 'McLaughlin',
    email: 'McLaughlinDonnell@meet-fake-user.com',
  },
  {
    firstName: 'Ansel',
    lastName: 'Von',
    email: 'VonAnsel@meet-fake-user.com',
  },
  {
    firstName: 'Hilda',
    lastName: 'Gottlieb',
    email: 'GottliebHilda@meet-fake-user.com',
  },
  {
    firstName: 'Taryn',
    lastName: 'Haley',
    email: 'HaleyTaryn@meet-fake-user.com',
  },
  {
    firstName: 'Deion',
    lastName: 'Collier',
    email: 'CollierDeion@meet-fake-user.com',
  },
  {
    firstName: 'Tad',
    lastName: 'Strosin',
    email: 'StrosinTad@meet-fake-user.com',
  },
  {
    firstName: 'Wellington',
    lastName: "O'Hara",
    email: "O'HaraWellington@meet-fake-user.com",
  },
  {
    firstName: 'Boyd',
    lastName: 'Waters',
    email: 'WatersBoyd@meet-fake-user.com',
  },
  {
    firstName: 'Margarette',
    lastName: 'Kuhic',
    email: 'KuhicMargarette@meet-fake-user.com',
  },
  {
    firstName: 'Abagail',
    lastName: 'Dietrich',
    email: 'DietrichAbagail@meet-fake-user.com',
  },
  {
    firstName: 'Zoey',
    lastName: 'Bode',
    email: 'BodeZoey@meet-fake-user.com',
  },
  {
    firstName: 'Harvey',
    lastName: 'Torphy',
    email: 'TorphyHarvey@meet-fake-user.com',
  },
  {
    firstName: 'Alfonzo',
    lastName: 'Leffler',
    email: 'LefflerAlfonzo@meet-fake-user.com',
  },
  {
    firstName: 'Elenora',
    lastName: 'Rosenbaum',
    email: 'RosenbaumElenora@meet-fake-user.com',
  },
  {
    firstName: 'Christy',
    lastName: 'Macejkovic',
    email: 'MacejkovicChristy@meet-fake-user.com',
  },
  {
    firstName: 'Norberto',
    lastName: 'Emmerich',
    email: 'EmmerichNorberto@meet-fake-user.com',
  },
  {
    firstName: 'Telly',
    lastName: 'Balistreri',
    email: 'BalistreriTelly@meet-fake-user.com',
  },
  {
    firstName: 'Kaleigh',
    lastName: 'Reilly',
    email: 'ReillyKaleigh@meet-fake-user.com',
  },
  {
    firstName: 'Nicolas',
    lastName: 'Boyle',
    email: 'BoyleNicolas@meet-fake-user.com',
  },
  {
    firstName: 'Gustave',
    lastName: 'Bauch',
    email: 'BauchGustave@meet-fake-user.com',
  },
  {
    firstName: 'Adolphus',
    lastName: 'Price',
    email: 'PriceAdolphus@meet-fake-user.com',
  },
  {
    firstName: 'Chase',
    lastName: 'Howe',
    email: 'HoweChase@meet-fake-user.com',
  },
  {
    firstName: 'Mina',
    lastName: 'Reilly',
    email: 'ReillyMina@meet-fake-user.com',
  },
  {
    firstName: 'Ludwig',
    lastName: 'Hoeger',
    email: 'HoegerLudwig@meet-fake-user.com',
  },
  {
    firstName: 'Elfrieda',
    lastName: 'Fritsch',
    email: 'FritschElfrieda@meet-fake-user.com',
  },
  {
    firstName: 'Nichole',
    lastName: 'Blick',
    email: 'BlickNichole@meet-fake-user.com',
  },
  {
    firstName: 'Jeffery',
    lastName: 'Jacobi',
    email: 'JacobiJeffery@meet-fake-user.com',
  },
  {
    firstName: 'Dalton',
    lastName: 'Balistreri',
    email: 'BalistreriDalton@meet-fake-user.com',
  },
  {
    firstName: 'Shanelle',
    lastName: 'Anderson',
    email: 'AndersonShanelle@meet-fake-user.com',
  },
  {
    firstName: 'Ibrahim',
    lastName: 'Grady',
    email: 'GradyIbrahim@meet-fake-user.com',
  },
  {
    firstName: 'Shawna',
    lastName: 'Heaney',
    email: 'HeaneyShawna@meet-fake-user.com',
  },
  {
    firstName: 'Louvenia',
    lastName: 'Wintheiser',
    email: 'WintheiserLouvenia@meet-fake-user.com',
  },
  {
    firstName: 'Neva',
    lastName: 'Gorczany',
    email: 'GorczanyNeva@meet-fake-user.com',
  },
  {
    firstName: 'Myra',
    lastName: 'Swift',
    email: 'SwiftMyra@meet-fake-user.com',
  },
  {
    firstName: 'Sharon',
    lastName: 'Kub',
    email: 'KubSharon@meet-fake-user.com',
  },
  {
    firstName: 'Simone',
    lastName: 'Cassin',
    email: 'CassinSimone@meet-fake-user.com',
  },
  {
    firstName: 'Gerson',
    lastName: 'Blanda',
    email: 'BlandaGerson@meet-fake-user.com',
  },
  {
    firstName: 'Torey',
    lastName: 'Kuhlman',
    email: 'KuhlmanTorey@meet-fake-user.com',
  },
  {
    firstName: 'Pablo',
    lastName: 'Cassin',
    email: 'CassinPablo@meet-fake-user.com',
  },
  {
    firstName: 'Evangeline',
    lastName: 'Kreiger',
    email: 'KreigerEvangeline@meet-fake-user.com',
  },
  {
    firstName: 'Bernhard',
    lastName: 'Olson',
    email: 'OlsonBernhard@meet-fake-user.com',
  },
  {
    firstName: 'Braden',
    lastName: 'Daugherty',
    email: 'DaughertyBraden@meet-fake-user.com',
  },
  {
    firstName: 'Wiley',
    lastName: 'Littel',
    email: 'LittelWiley@meet-fake-user.com',
  },
  {
    firstName: 'Peyton',
    lastName: 'Schoen',
    email: 'SchoenPeyton@meet-fake-user.com',
  },
  {
    firstName: 'Dessie',
    lastName: 'Dare',
    email: 'DareDessie@meet-fake-user.com',
  },
  {
    firstName: 'Alfonzo',
    lastName: 'Huels',
    email: 'HuelsAlfonzo@meet-fake-user.com',
  },
  {
    firstName: 'Ashton',
    lastName: "O'Hara",
    email: "O'HaraAshton@meet-fake-user.com",
  },
  {
    firstName: 'Isai',
    lastName: 'Schmeler',
    email: 'SchmelerIsai@meet-fake-user.com',
  },
  {
    firstName: 'Clemens',
    lastName: 'Nader',
    email: 'NaderClemens@meet-fake-user.com',
  },
  {
    firstName: 'Clare',
    lastName: 'Hartmann',
    email: 'HartmannClare@meet-fake-user.com',
  },
  {
    firstName: 'Cletus',
    lastName: 'Denesik',
    email: 'DenesikCletus@meet-fake-user.com',
  },
  {
    firstName: 'Tiara',
    lastName: 'Spencer',
    email: 'SpencerTiara@meet-fake-user.com',
  },
  {
    firstName: 'Stacy',
    lastName: 'Schmidt',
    email: 'SchmidtStacy@meet-fake-user.com',
  },
  {
    firstName: 'Lincoln',
    lastName: 'Kunde',
    email: 'KundeLincoln@meet-fake-user.com',
  },
  {
    firstName: 'Kailyn',
    lastName: 'Hilll',
    email: 'HilllKailyn@meet-fake-user.com',
  },
  {
    firstName: 'Erica',
    lastName: 'Shanahan',
    email: 'ShanahanErica@meet-fake-user.com',
  },
  {
    firstName: 'Reid',
    lastName: 'Boyer',
    email: 'BoyerReid@meet-fake-user.com',
  },
  {
    firstName: 'Chad',
    lastName: 'Schowalter',
    email: 'SchowalterChad@meet-fake-user.com',
  },
  {
    firstName: 'Adonis',
    lastName: 'Bradtke',
    email: 'BradtkeAdonis@meet-fake-user.com',
  },
  {
    firstName: 'Cristal',
    lastName: 'Morar',
    email: 'MorarCristal@meet-fake-user.com',
  },
  {
    firstName: 'Frederique',
    lastName: 'Kuhic',
    email: 'KuhicFrederique@meet-fake-user.com',
  },
  {
    firstName: 'Delmer',
    lastName: "O'Kon",
    email: "O'KonDelmer@meet-fake-user.com",
  },
  {
    firstName: 'Sandra',
    lastName: 'Dach',
    email: 'DachSandra@meet-fake-user.com',
  },
  {
    firstName: 'Abraham',
    lastName: 'Erdman',
    email: 'ErdmanAbraham@meet-fake-user.com',
  },
  {
    firstName: 'Leda',
    lastName: 'Kovacek',
    email: 'KovacekLeda@meet-fake-user.com',
  },
  {
    firstName: 'Mckayla',
    lastName: 'Mills',
    email: 'MillsMckayla@meet-fake-user.com',
  },
  {
    firstName: 'Alfreda',
    lastName: 'Moore',
    email: 'MooreAlfreda@meet-fake-user.com',
  },
  {
    firstName: 'Xander',
    lastName: 'Kshlerin',
    email: 'KshlerinXander@meet-fake-user.com',
  },
  {
    firstName: 'Webster',
    lastName: 'Torp',
    email: 'TorpWebster@meet-fake-user.com',
  },
  {
    firstName: 'Merle',
    lastName: 'Jones',
    email: 'JonesMerle@meet-fake-user.com',
  },
  {
    firstName: 'Isidro',
    lastName: 'Nicolas',
    email: 'NicolasIsidro@meet-fake-user.com',
  },
  {
    firstName: 'Kayli',
    lastName: 'Blanda',
    email: 'BlandaKayli@meet-fake-user.com',
  },
  {
    firstName: 'Fausto',
    lastName: 'Willms',
    email: 'WillmsFausto@meet-fake-user.com',
  },
  {
    firstName: 'Herbert',
    lastName: 'Skiles',
    email: 'SkilesHerbert@meet-fake-user.com',
  },
  {
    firstName: 'Tristian',
    lastName: 'Kuhic',
    email: 'KuhicTristian@meet-fake-user.com',
  },
  {
    firstName: 'Nikita',
    lastName: 'Medhurst',
    email: 'MedhurstNikita@meet-fake-user.com',
  },
  {
    firstName: 'Joaquin',
    lastName: 'Brekke',
    email: 'BrekkeJoaquin@meet-fake-user.com',
  },
  {
    firstName: 'Ottilie',
    lastName: 'Cruickshank',
    email: 'CruickshankOttilie@meet-fake-user.com',
  },
  {
    firstName: 'Marvin',
    lastName: 'Fadel',
    email: 'FadelMarvin@meet-fake-user.com',
  },
  {
    firstName: 'Sophia',
    lastName: 'Mueller',
    email: 'MuellerSophia@meet-fake-user.com',
  },
  {
    firstName: 'Drew',
    lastName: 'Powlowski',
    email: 'PowlowskiDrew@meet-fake-user.com',
  },
  {
    firstName: 'Fay',
    lastName: 'Oberbrunner',
    email: 'OberbrunnerFay@meet-fake-user.com',
  },
  {
    firstName: 'Leatha',
    lastName: 'Sauer',
    email: 'SauerLeatha@meet-fake-user.com',
  },
  {
    firstName: 'Kiara',
    lastName: 'Schamberger',
    email: 'SchambergerKiara@meet-fake-user.com',
  },
  {
    firstName: 'Quinten',
    lastName: 'Rowe',
    email: 'RoweQuinten@meet-fake-user.com',
  },
  {
    firstName: 'Ronny',
    lastName: 'Fadel',
    email: 'FadelRonny@meet-fake-user.com',
  },
  {
    firstName: 'Linnie',
    lastName: 'Pfannerstill',
    email: 'PfannerstillLinnie@meet-fake-user.com',
  },
  {
    firstName: 'Alysha',
    lastName: 'Mante',
    email: 'ManteAlysha@meet-fake-user.com',
  },
  {
    firstName: 'Emile',
    lastName: 'Runolfsson',
    email: 'RunolfssonEmile@meet-fake-user.com',
  },
  {
    firstName: 'Mariela',
    lastName: 'Beer',
    email: 'BeerMariela@meet-fake-user.com',
  },
];

export const getFakeUser = () => {
  const rand = Math.floor(Math.random() * 99);
  return users[rand];
};
