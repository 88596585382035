import get from 'lodash/get';
export default Object.entries({
  ADMIN: { path: '/admin' },
  BETA: { path: '/beta', params: '/:eventId?/:type?' },
  BROADCAST: { path: '/broadcast', params: '/:noIvs?' },
  DEFAULT_BETA: { path: '', params: '/:eventId?/:type?' },
  END: { path: '/end' },
  ERROR: { path: '/error', params: '/:error' },
  HOME: { path: '/' },
  LOGIN: { path: '/login' },
  LOGOUT: { path: '/logout' },
  MANAGEMENT: { path: '/management' },
  MEETING: { path: '/meeting' },
  RECORDING: { path: '/recording', params: '/:isStream' },
  SEARCH: { path: '/search' },
  SERVICES: { path: '/services' },
  SETTINGS: { path: '/settings', params: '/:type' },
  SSO: { path: '/sso' },
  VOD: { path: '/vod' },
  WAITING: { path: '/waiting' },
}).reduce(
  (a, [k, v]) => ({
    ...a,
    [k]: { ...v, params: get(v, 'params', '') },
  }),
  {}
);
