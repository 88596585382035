// import { createSession } from 'api/routes/session';
import { updateToken } from 'api/routes/user';
import { call, put, takeEvery } from 'redux-saga/effects';
import * as aa from 'store/app/appActions';
import * as ua from 'store/user/userActions';
import { setRefreshTokenCookie, setTokenCookie } from 'utils/token';

export function* updateServicesOnlyFlowSaga({ payload }) {
  const key = 'updateTokenFlowSaga';
  try {
    yield put(ua.initAction({ key }));
    const { servicesOnly } = payload;
    yield put(aa.setServicesOnlyAction({ servicesOnly }));
    const { token, refreshToken } = yield call(updateToken, { servicesOnly });
    setTokenCookie(token);
    setRefreshTokenCookie(refreshToken);
    yield put(ua.successAction({ key }));
  } catch (error) {
    yield put(ua.errorAction({ key, error }));
  }
}

export default function* appWatcher() {
  yield takeEvery(aa.updateServicesOnlyAction().type, updateServicesOnlyFlowSaga);
}
