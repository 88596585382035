import { makeStyles, withStyles } from '@material-ui/core/styles';
import { IconButton, Slider } from '@material-ui/core';
import VolumeOffIcon from '@material-ui/icons/VolumeOff';
import VolumeUpIcon from '@material-ui/icons/VolumeUp';
import PlayArrowIcon from '@material-ui/icons/PlayArrow';
import PauseIcon from '@material-ui/icons/Pause';
import FullscreenIcon from '@material-ui/icons/Fullscreen';
import ReplayIcon from '@material-ui/icons/Replay';
import { FullScreen, useFullScreenHandle } from 'react-full-screen';
import PropTypes from 'prop-types';
import { formatSeconds } from 'utils/utilMethods';

const useStyles = makeStyles((theme) => ({
  player: {
    position: 'relative',
    '& .MuiSvgIcon-root': {
      color: 'white',
    },

    '& .playerContainer': {
      position: 'absolute',
      zIndex: '10',
      bottom: 0,
      display: 'flex',
      flexDirection: 'column',
      padding: '0.5rem 1rem',
      paddingTop: '2rem',
      width: '100%',
      background: 'linear-gradient(to bottom, rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.7))',
      color: 'white',
      opacity: (props) => (props.isPlaying ? 0 : 1),
      transition: 'opacity 0.33s ease',
      '& > *:not(:last-child)': {
        marginRight: '0.75rem',
      },
    },
    '&:hover .playerContainer': {
      opacity: 1,
    },

    '& .playerUI': {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      width: '100%',
      margin: 0,
      padding: 0,
    },

    '& .smallContainer': {
      display: 'flex',
      alignItems: 'center',
    },
  },
  volumeSlider: {
    width: '6rem',
  },
  progress: {
    cursor: 'pointer',
    flexGrow: 1,
    height: '0.75rem',
  },
  fullScreenButton: {
    [theme.breakpoints.down('mobile')]: {
      display: 'none',
    },
  },
  timer: {
    width: '13ch',
    textAlign: 'center',
  },
}));

VodControls.propTypes = {
  children: PropTypes.node.isRequired,
  minTime: PropTypes.number,
  maxTime: PropTypes.number,
  toggleMute: PropTypes.func,
  togglePause: PropTypes.func,
  restart: PropTypes.func,
  onChangeVolume: PropTypes.func,
  currentTime: PropTypes.number,
  setCurrentTime: PropTypes.func,
  volume: PropTypes.number,
  muted: PropTypes.bool,
  paused: PropTypes.bool,
  duration: PropTypes.number,
};

function getProgress(currentTime, minTime, maxTime) {
  if (currentTime <= minTime) return 0;
  if (currentTime >= maxTime) return 100;

  currentTime -= minTime;
  maxTime -= minTime;

  return (currentTime / maxTime) * 100;
}

const VideoSlider = withStyles({
  root: {
    color: '#fff',
    height: 8,
  },
  thumb: {
    height: 14,
    width: 14,
    backgroundColor: '#fff',
    marginTop: -4,
  },
  track: {
    height: 4,
    borderRadius: 4,
  },
  rail: {
    height: 4,
    borderRadius: 4,
  },
})(Slider);

export default function VodControls({
  children,
  toggleMute,
  togglePause,
  restart,
  onChangeVolume,
  currentTime,
  setCurrentTime,
  volume,
  muted,
  paused,
  duration,
  minTime,
  maxTime,
}) {
  const classes = useStyles({ isPlaying: !paused });

  const fullScreen = useFullScreenHandle();

  const toggleFullscreen = () => {
    if (fullScreen.active) fullScreen.exit();
    else fullScreen.enter();
  };

  const onClickProgress = (event) => {
    if (event.currentTarget?.getBoundingClientRect) {
      const rect = event.currentTarget.getBoundingClientRect();
      const clickLocalX = (event.pageX - rect.x) / rect.width;
      const min = minTime || 0;
      const max = maxTime || duration || 1;

      setCurrentTime(clickLocalX + min + (max - min) * clickLocalX);
    }
  };

  let roundedTime = Math.round(currentTime) - (minTime || 0);
  return (
    <FullScreen handle={fullScreen}>
      <div className={classes.player}>
        {children}

        <div className="playerContainer">
          <div className="playerUI">
            <div className="smallContainer">
              {currentTime === (maxTime || duration || 1) ? (
                <IconButton onClick={restart}>
                  <ReplayIcon />
                </IconButton>
              ) : (
                <IconButton onClick={togglePause}>
                  {paused ? <PlayArrowIcon /> : <PauseIcon />}
                </IconButton>
              )}
              <div className={classes.timer}>
                {`${formatSeconds(roundedTime)}`} / {`${formatSeconds(maxTime || duration)}`}
              </div>
            </div>
            <div className="smallContainer">
              <IconButton onClick={toggleMute}>
                {muted ? <VolumeOffIcon /> : <VolumeUpIcon />}
              </IconButton>

              <VideoSlider
                min={0}
                max={1}
                step={0.01}
                value={volume}
                style={{ margin: '0rem 2rem 0rem .25rem', width: '60px' }}
                className={classes.volumeSlider}
                onChange={onChangeVolume}
              />

              <IconButton
                className={classes.fullScreenButton}
                style={fullScreen.active ? { display: 'block!important' } : {}}
                onClick={toggleFullscreen}
              >
                <FullscreenIcon />
              </IconButton>
            </div>
          </div>
          <div className="playerUI">
            <VideoSlider
              value={getProgress(currentTime, minTime || 0, maxTime || duration || 1)}
              className={classes.progress}
              onChange={onClickProgress}
            />
          </div>
        </div>
      </div>
    </FullScreen>
  );
}
