import { useState } from 'react';
import { getCookie, setCookie } from 'hooks/useCookie';

const TOKEN_DAYS = 90;

export const getTokenCookie = () => getCookie('token');
export const setTokenCookie = (tok) => setCookie('token', tok, TOKEN_DAYS);
export const getRefreshTokenCookie = () => getCookie('refreshtoken');
export const setRefreshTokenCookie = (tok) => setCookie('refreshtoken', tok, TOKEN_DAYS);

export const useToken = () => {
  const cookieToken = getTokenCookie();
  const cookieRefreshToken = getRefreshTokenCookie();
  const [token, setToken] = useState(cookieToken);
  const [refreshToken, setRefreshToken] = useState(cookieRefreshToken);

  const setTokenAndCookie = (tok) => {
    setTokenCookie(tok);
    setToken(tok);
  };

  const setRefreshTokenAndCookie = (tok) => {
    setRefreshTokenCookie(tok);
    setRefreshToken(tok);
  };

  return {
    token,
    refreshToken,
    setToken: setTokenAndCookie,
    setRefreshToken: setRefreshTokenAndCookie,
  };
};
