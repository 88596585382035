import { ThemeProvider } from '@material-ui/core/styles';
import CssBaseline from '@material-ui/core/CssBaseline';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { getCookie } from 'hooks/useCookie';
import darkTheme from 'themes/dark';
import lightTheme from 'themes/light';
import themeConstants from 'constants/theme';

const themes = {
  dark: darkTheme,
  light: lightTheme,
};

AppTheme.propTypes = {
  children: PropTypes.node,
};

export default function AppTheme({ children }) {
  const themeName = useSelector(
    (store) => store.app.themeName || getCookie('theme') || themeConstants.defaultThemeName
  );

  return (
    <ThemeProvider theme={themes[themeName]}>
      <CssBaseline />
      {children}
    </ThemeProvider>
  );
}
