import IconButton from '@material-ui/core/IconButton';
import InputAdornment from '@material-ui/core/InputAdornment';
import { makeStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import Tooltip from '@material-ui/core/Tooltip';
import CloseIcon from '@material-ui/icons/Close';
import FilterIcon from '@material-ui/icons/FilterList';
import SearchIcon from '@material-ui/icons/Search';
import PropTypes from 'prop-types';
import Popover from '@material-ui/core/Popover';
import { useState } from 'react';
import FilterBox from './FilterBox';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexGrow: 1,
    // justifyContent: 'space-between',
    alignItems: 'center',
  },
  toolBarButtons: {
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'flex-end',
    padding: '.75rem 0 .75rem 0',
  },
  toolBarSearch: {
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'flex-end',
    flexGrow: 1,
  },

  iconButton: {
    '&:hover': {
      color: theme.palette.primary.main,
    },
  },
  searchIcon: {
    marginRight: '.5rem',
  },
}));

RosterToolbar.propTypes = {
  searchFilter: PropTypes.string.isRequired,
  setSearchFilter: PropTypes.func.isRequired,
  filters: PropTypes.object.isRequired,
  setFilters: PropTypes.func.isRequired,
};

export default function RosterToolbar({ searchFilter, setSearchFilter, filters, setFilters }) {
  const classes = useStyles();
  const [showSearch, setShowSearch] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);

  return (
    <div className={classes.root}>
      <div className={classes.toolBarSearch}>
        {showSearch && (
          <TextField
            className={classes.search}
            margin="dense"
            value={searchFilter}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    className={classes.iconButton}
                    onClick={() => void setSearchFilter('')}
                  >
                    <CloseIcon />
                  </IconButton>
                </InputAdornment>
              ),
            }}
            onChange={(e) => {
              setSearchFilter(e.target.value);
            }}
          />
        )}
      </div>

      <div className={classes.toolBarButtons}>
        <Tooltip title="Search" placement="bottom" className={classes.searchIcon}>
          <IconButton
            className={classes.iconButton}
            onClick={() => {
              if (showSearch) {
                setSearchFilter('');
              }
              setShowSearch((v) => !v);
            }}
          >
            <SearchIcon />
          </IconButton>
        </Tooltip>
        <div>
          <Tooltip title="Filter" placement="bottom">
            <IconButton
              className={classes.iconButton}
              onClick={({ currentTarget }) => {
                setAnchorEl(currentTarget);
              }}
            >
              <FilterIcon />
            </IconButton>
          </Tooltip>
          <Popover
            id={anchorEl ? 'popover-filter' : undefined}
            open={Boolean(anchorEl)}
            anchorEl={anchorEl}
            onClose={() => {
              setAnchorEl(null);
            }}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'center',
            }}
            transformOrigin={{
              vertical: 'top',
              horizontal: 'center',
            }}
          >
            <FilterBox filters={filters} setFilters={setFilters} close={() => setAnchorEl(null)} />
          </Popover>
        </div>
      </div>
    </div>
  );
}
