import { IconButton } from '@material-ui/core';
import { Modal as MUIModal } from '@material-ui/core';
import { emphasize, makeStyles } from '@material-ui/core/styles';
import CancelIcon from '@material-ui/icons/Cancel';
import PropTypes from 'prop-types';

export const useStyles = makeStyles((theme) => ({
  modal: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',

    '& > .wrapper': {
      display: 'flex',
      flexDirection: 'column',
      ...theme.mixins.vhMaxHeight(95),
      backgroundColor: theme.palette.background.modal,
      ...theme.mixins.borderRadius(),
      ...theme.mixins.shadow(),

      '&': ({ variant }) => ({
        minWidth: variant === 'small' ? '22.5rem' : '45rem',
        maxWidth: variant === 'small' ? 'calc(min(45rem, 95vw))' : '95vw',
        minHeight: variant === 'small' ? '15rem' : '30rem',
      }),

      '& > .header': {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        height: '4rem',
        borderBottom: '1px solid',
        borderBottomColor: emphasize(theme.palette.background.modal, 0.33),
        marginBottom: '3rem',
        padding: '1rem',

        '& > .title': {
          fontSize: '1.1rem',
          fontWeight: 'bold',
        },
      },

      '& > .body': {
        display: 'flex',
        flexDirection: 'column',
        padding: '1rem 6rem',

        '& > *:not(:first-child)': {
          marginTop: '1rem',
        },
      },

      '& > .footer': {
        // display: 'flex',
        // justifyContent: 'flex-end',
        // flexGrow: 1,
        // alignItems: 'flex-end',
        // marginTop: '3rem',
        // padding: '1rem 6rem',
        // '& > *:not(:last-child)': {
        //   marginRight: '1rem',
        // },
        margin: '2rem 1rem .5rem 1rem',
        display: 'flex',
        justifyContent: 'space-between',
        flexGrow: 1,
      },
    },
  },
}));
const wrapperStyles = makeStyles((theme) => ({
  frontAndCenter: ({ frontAndCenterWidth }) => ({
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: '0 auto',
    widthh: `${frontAndCenterWidth}vw`,
    ...theme.mixins.vhMinHeight(100),
  }),
}));

Modal.propTypes = {
  children: PropTypes.node,
  title: PropTypes.any,
  onClose: PropTypes.func,
  open: PropTypes.bool,
  variant: PropTypes.string,
  frontAndCenter: PropTypes.bool,
  frontAndCenterWidth: PropTypes.number,
};

ModalWrapper.propTypes = {
  children: PropTypes.node,
  frontAndCenter: PropTypes.bool,
  frontAndCenterWidth: PropTypes.number,
};

function ModalWrapper({ frontAndCenter, frontAndCenterWidth, children }) {
  const classes = wrapperStyles({ frontAndCenterWidth });
  return frontAndCenter ? <div className={classes.frontAndCenter}>{children}</div> : children;
}

export default function Modal({
  children,
  title,
  open,
  variant,
  onClose,
  frontAndCenter = false,
  frontAndCenterWidth = 95,
}) {
  const classes = useStyles({ variant });
  return (
    <ModalWrapper frontAndCenter={frontAndCenter} frontAndCenterWidth={frontAndCenterWidth}>
      <MUIModal open={open} onClose={onClose} className={classes.modal}>
        <div className="wrapper">
          <div className="header">
            <div className="title">{title}</div>
            <IconButton onClick={onClose}>
              <CancelIcon />
            </IconButton>
          </div>

          {children}
        </div>
      </MUIModal>
    </ModalWrapper>
  );
}

ModalBody.propTypes = {
  children: PropTypes.node,
};

function ModalBody({ children }) {
  return <div className="body">{children}</div>;
}

ModalFooter.propTypes = {
  children: PropTypes.node,
};

function ModalFooter({ children }) {
  return <div className="footer">{children}</div>;
}

export { Modal, ModalBody, ModalFooter };
