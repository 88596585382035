import InputContainer from 'components/InputContainer';
import { cloneDeep, find, isEqualWith } from 'lodash';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import NewQuestionsNotification from './NewQuestionsNotification';
import QuestionInput from './QuestionInput';
import QuestionList from './QuestionList';
import QuestionsMenu from './QuestionsMenu';
import deepEqual from 'react-fast-compare';
import { setUnreadQuestionsAction } from 'store/session-question/sessionQuestionActions';
import { usePendingRequests } from 'hooks/usePendingRequests';

// Needed because Question.timeAsked could be slightly different between local state and server state
function questionIsEqual(q1, q2) {
  return (
    q1.questionId === q2.questionId &&
    q1.sessionId === q2.sessionId &&
    q1.question === q2.question &&
    q1.answer === q2.answer &&
    q1.userId === q2.userId &&
    q1.userName === q2.userName &&
    q1.hidden === q2.hidden
  );
}

function questionsAreEqual(questions1, questions2) {
  if (questions1.length !== questions2.length) return false;

  for (const question1 of questions1) {
    const question2 = find(questions2, ['questionId', question1.questionId]);

    if (!isEqualWith(question1, question2, questionIsEqual)) return false;
  }

  return true;
}

export default function Questions() {
  const dispatch = useDispatch();
  const [sorted, setSorted] = useState('Recent');
  const [filtered, setFiltered] = useState('Show All');
  const [filteredQuestions, setFilteredQuestions] = useState([]);
  const [hasChanges, setHasChanges] = useState(false);
  const [scrolledToTop, setScrolledToTop] = useState(true);
  const [bypassNextScroll, setBypassNextScroll] = useState(false);
  const userId = useSelector((store) => store.user.userId);
  const questions = useSelector((store) => store.sessionQuestion.questions, deepEqual);
  const askRequests = usePendingRequests('questionAsk');
  const hideRequests = usePendingRequests('questionHide');
  const myHideRequests = hideRequests.filter((req) => req.options.isMyQuestion);

  const hasHideRequest = {};
  for (let req of hideRequests) {
    hasHideRequest[req.data.questionId] = true;
  }

  useEffect(() => {
    dispatch(setUnreadQuestionsAction({}));
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (scrolledToTop || bypassNextScroll) {
      setFilteredQuestions(questions);
      setBypassNextScroll(false);
    } else {
      const newFilteredQuestions = cloneDeep(filteredQuestions);

      // Update these in real time since they don't change DOM sizes.
      for (const question of questions) {
        const filteredQuestion = find(newFilteredQuestions, ['questionId', question.questionId]);
        if (filteredQuestion) {
          filteredQuestion.voteCount = question.voteCount;
          filteredQuestion.timeAsked = question.timeAsked;
        }
      }

      setFilteredQuestions(newFilteredQuestions);

      setHasChanges(!isEqualWith(questions, filteredQuestions, questionsAreEqual));
    }
  }, [questions, scrolledToTop]); // eslint-disable-line react-hooks/exhaustive-deps

  let thisUsersActiveQuestionCount = 0;
  for (let ques of questions) {
    if (ques.userId === userId) {
      ++thisUsersActiveQuestionCount;
    }
  }
  thisUsersActiveQuestionCount += askRequests.length;
  thisUsersActiveQuestionCount -= myHideRequests.length;

  //if (status !== 'LIVE') return <StreamOfflinePanel status={status} role={role} />;

  const onScrolled = (scrollTop) => {
    if (scrollTop === 0) {
      setScrolledToTop(true);
      setHasChanges(false);
    } else {
      setScrolledToTop(false);
    }
  };

  const getUpdates = () => {
    setFilteredQuestions(questions);
    setHasChanges(false);
  };

  const bypassNextIfScrolled = () => {
    if (!scrolledToTop) setBypassNextScroll(true);
  };

  const questionsArray = filteredQuestions
    .filter((q) => {
      if (q.hidden || hasHideRequest[q.questionId]) return false;
      switch (filtered) {
        case 'Answered':
          return !!q.answer;
        case 'Not Answered':
          return !q.answer;
        case 'My Questions':
          return q.userId === userId;
        default:
          return true;
      }
    })
    .sort((a, b) => {
      if (sorted === 'Popular') {
        if (a.voteCount !== b.voteCount) return b.voteCount - a.voteCount;
      }

      if (sorted === 'Oldest') return a.timeAsked - b.timeAsked;

      return b.timeAsked - a.timeAsked;
    });

  return (
    <>
      <QuestionsMenu
        sorted={sorted}
        setSorted={setSorted}
        filtered={filtered}
        setFiltered={setFiltered}
      />

      <div style={{ flexGrow: 1 }} id="questions-list-parent">
        <QuestionList
          questionsArray={questionsArray}
          getUpdates={getUpdates}
          bypassNextIfScrolled={bypassNextIfScrolled}
          onScrolled={onScrolled}
        />

        {hasChanges && <NewQuestionsNotification onClick={getUpdates} />}
      </div>

      <InputContainer>
        <QuestionInput
          bypassNextIfScrolled={bypassNextIfScrolled}
          getUpdates={getUpdates}
          thisUsersActiveQuestionCount={thisUsersActiveQuestionCount}
        />
      </InputContainer>
    </>
  );
}
