import { makeStyles, emphasize } from '@material-ui/core/styles';

const useCommonClasses = makeStyles((theme) => ({
  poll: {
    width: '100%',
    border: '1px solid',
    borderColor: emphasize(theme.palette.background.paper),
    borderRadius: '0.5rem',
  },
  options: {
    padding: '0.5rem',
  },
  optionsHeader: {
    display: 'flex',
    justifyContent: 'space-between',

    '& > *:first-child': {
      fontWeight: 'bold',
      padding: '0.5rem',
    },
  },
  footer: {
    marginTop: '2rem',
  },
  shareResultsArea: {
    width: '100%',
    padding: '1rem 4rem',
    textAlign: 'center',

    '& > button': {
      minWidth: '100%',
      marginBottom: '1rem',
    },
  },
  switcher: {
    marginTop: '0.5rem',
    marginRight: '0.5rem',
    height: '2rem',

    '& > button': {
      backgroundColor: theme.palette.background.paper,
      '& > span': {
        fontWeight: 'bold',
        fontSize: '1rem',
      },
    },
  },
  live: {
    backgroundColor: '#03adee',
    borderRadius: '0px 0px 7px 7px',
    marginBottom: '-2px',
  },
}));

export default useCommonClasses;
export { useCommonClasses };
