import Typography from '@material-ui/core/Typography';
import PropTypes from 'prop-types';
import { useState } from 'react';
import Polling from 'components/side-panel/components/polling';
import PollTemplateTable from '../../components/PollTemplateTable.js';
import { useStyles } from '../../index.styles';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';

PollsTab.propTypes = {
  tabName: PropTypes.string.isRequired,
};

export default function PollsTab({ tabName }) {
  const classes = useStyles();
  const [tab, setTab] = useState(0);

  return (
    <div className={classes.page}>
      <Typography variant="h2">{tabName}</Typography>

      <div className={classes.fileTableWrapper}>
        <Tabs
          indicatorColor="primary"
          textColor="secondary"
          value={Number(tab)}
          onChange={(event, newTab) => void setTab(newTab)}
          aria-label="poll Template or Poll selection tabs"
        >
          <Tab label={'Poll Templates'} />
          <Tab label={'Polls'} />
        </Tabs>

        {tab === 0 && <PollTemplateTable />}
        {tab === 1 && <Polling isSettingsPage={true} />}
      </div>
    </div>
  );
}
