import { makeStyles, fade } from '@material-ui/core/styles';
import { Typography } from '@material-ui/core';
import { StyledSwitch as Switch } from 'components/StyledSwitch';
import { useDispatch, useSelector } from 'react-redux';
import { disableFeature } from 'api/routes/event';
import { setFeatureDisabledAction } from 'store/event/eventActions';
import { showGenericErrorNotification } from 'utils/utilMethods';
import { usePendingRequests } from 'hooks/usePendingRequests';
import {
  features,
  getFeatureName,
  getFeatureDescription,
  featureForType,
} from 'constants/features';
import config from 'config';
import PropTypes from 'prop-types';

export const useStyles = makeStyles((theme) => ({
  featureSwitcherWrapper: {
    '&[data-fromsettings="1"]': {
      display: 'flex',
      justifyContent: 'center',
    },
  },
  featureSwitcher: {
    margin: theme.sidePanel.gutter,
    padding: '1rem',
    ...theme.mixins.borderRadius(),
    ...theme.mixins.shadow(),
    ...theme.mixins.flexVerticalList(),
    '& > div': {
      width: '100%',
      alignItems: 'center',
      ...theme.mixins.flexHorizontalList('0.33rem'),
      '& > *:last-child': {
        flexGrow: 1,
      },
    },

    '&[data-fromsettings="0"]': {
      backgroundColor: theme.palette.background.light,
    },

    '&[data-fromsettings="1"]': {
      width: '45rem',
      maxWidth: '100%',

      '& > h6': {
        fontSize: '2rem',
      },

      '& > div': {
        display: 'block',
        borderWidth: 1,
        borderStyle: 'solid',
        borderColor: fade(theme.palette.text.primary, 0.33),
        padding: '1rem',
        borderRadius: 6,

        // Switch
        '& > *:first-child': {
          float: 'left',
        },

        // Feature name
        '& > div:first-of-type': {
          fontWeight: 'bold',
          marginBottom: '0.5em',
        },

        '& > div': {
          marginLeft: '5rem',
          fontSize: '110%',
        },
      },
    },
  },
  description: ({ fromSettings }) => ({
    display: fromSettings ? 'block' : 'none',
  }),
}));

FeatureSwitcher.propTypes = {
  fromSettings: PropTypes.bool,
};

export default function FeatureSwitcher(props) {
  const { fromSettings } = props;
  const classes = useStyles(props);
  const dispatch = useDispatch();
  const appType = useSelector((store) => store.app.appType);
  const disabledFeaturesStore = useSelector((store) => store.event.disabledFeatures);
  const disabledFeaturesPending = usePendingRequests('disabledFeatures');

  console.log(appType);

  const disabledFeatures = { ...disabledFeaturesStore };
  for (const req of disabledFeaturesPending) {
    disabledFeatures[req.data.feature] = req.data.disabled;
  }

  const toggleDisabled = (event, feature) => {
    const disabled = !event.target.checked;

    disableFeature(feature, disabled, {
      retries: 2,
      onSuccess() {
        dispatch(setFeatureDisabledAction({ feature, disabled }));
      },
      onError(error, reason, retriesLeft) {
        if (retriesLeft === 0) {
          showGenericErrorNotification('changing feature');
        }
      },
    });
  };

  const filterFeatureFlags = (feature) => {
    if (feature === 'queue') return config.SHOW_STAGE;
    return true;
  };

  return (
    <div className={classes.featureSwitcherWrapper} data-fromsettings={fromSettings ? 1 : 0}>
      <div className={classes.featureSwitcher} data-fromsettings={fromSettings ? 1 : 0}>
        <Typography variant="h6">Features</Typography>
        {features
          .filter(filterFeatureFlags)
          .filter((feature) =>
            featureForType[appType]
              ? featureForType[appType].includes(feature)
              : featureForType['default']
          )
          .map((feature) => (
            <div className="feature" key={feature}>
              <Switch
                checked={!disabledFeatures[feature]}
                onChange={(event) => toggleDisabled(event, feature)}
                texton="ON"
                textoff="OFF"
              />
              <div>{getFeatureName(feature)}</div>
              <div className={classes.description}>{getFeatureDescription(feature)}</div>
            </div>
          ))}
      </div>
    </div>
  );
}
