import { useEffect, useState } from 'react';
import { Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { useSelector } from 'react-redux';
import deepEqual from 'react-fast-compare';

const useStyles = makeStyles(() => ({
  metricContainer: {
    zIndex: 999999999,
    position: 'absolute',
    color: 'white',
    left: '0px',
    top: '0px',
    overflow: 'auto',
    padding: '.5rem',
    backgroundColor: 'rgba(0,0,0,.6)',
    fontFamily: 'monospace',
  },
  videoMetrics: {
    position: 'absolute',
    bottom: '0',
    left: '0',
    color: 'white',
    fontFamily: 'monospace',
    backgroundColor: 'rgba(0,0,0,.4)',

    '& ul': {
      listStyleType: 'none',
      padding: '0',
      margin: '0',
    },
  },
}));

function bytesToSize(bytes, decimals = 2) {
  if (bytes === 0) return '0 Bytes';

  const k = 1024;
  const dm = decimals < 0 ? 0 : decimals;
  const sizes = ['Bytes', 'KB', 'MB', 'GB'];

  const i = Math.floor(Math.log(bytes) / Math.log(k));

  return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
}

const videoUpstreamMetricsKeyStats = {
  videoUpstreamGoogFrameHeight: 'Frame Height',
  videoUpstreamGoogFrameWidth: 'Frame Width',
  videoUpstreamFrameHeight: 'Frame Height',
  videoUpstreamFrameWidth: 'Frame Width',
  videoUpstreamBitrate: 'Bitrate (per second)',
  videoUpstreamPacketsSent: 'Packets Sent',
  videoUpstreamFramesEncodedPerSecond: 'Frame Rate',
};

const videoDownstreamMetricsKeyStats = {
  videoDownstreamGoogFrameHeight: 'Frame Height',
  videoDownstreamGoogFrameWidth: 'Frame Width',
  videoDownstreamFrameHeight: 'Frame Height',
  videoDownstreamFrameWidth: 'Frame Width',
  videoDownstreamBitrate: 'Bitrate (per second)',
  videoDownstreamPacketLossPercent: 'Packet Loss (%)',
  videoDownstreamFramesDecodedPerSecond: 'Frame Rate',
};

export default function Metrics() {
  const classes = useStyles();
  const [metricReport, setMetricReport] = useState(null);
  const [videoMetrics, setVideoMetrics] = useState(null);
  const audioVideo = useSelector((store) => store.chime.audioVideo, deepEqual);

  const getVideoStats = (tileId, keyStatstoShow, metricsData) => {
    const streams = metricsData ? Object.keys(metricsData) : [];
    if (streams.length === 0) {
      return {};
    }

    let stats = {};

    for (const ssrc of streams) {
      for (const [metricName, value] of Object.entries(metricsData[ssrc])) {
        if (keyStatstoShow[metricName]) {
          stats[keyStatstoShow[metricName]] =
            metricName === 'videoDownstreamBitrate' || metricName === 'videoUpstreamBitrate'
              ? bytesToSize(value)
              : value;
        }
      }
    }

    const list = `<ul>
      ${Object.entries(stats)
        .map(([key, value]) => `<li>${key} - ${value}</li>`)
        .join('')}
    </ul>`;

    let metricTileDiv = document.getElementById(`metrics-tile-${tileId}`);
    if (metricTileDiv) {
      metricTileDiv.innerHTML = list;
    } else {
      const div = document.createElement('div');
      div.setAttribute('id', `metrics-tile-${tileId}`);
      div.className = classes.videoMetrics;
      div.innerHTML = list;
      let videoTile = document.getElementById(`video-tile-${tileId}`);
      if (videoTile) videoTile.appendChild(div);
    }
    return stats;
  };

  const metricsObserver = {
    metricsDidReceive: (clientMetricReport) => {
      setMetricReport(clientMetricReport.getObservableMetrics());

      const videoTiles = audioVideo.getAllVideoTiles();
      if (videoTiles.length === 0) {
        return;
      } else {
        const videoMetricReport = clientMetricReport.getObservableVideoMetrics();
        const updatedVideoMetrics = [];
        for (const videoTile of videoTiles) {
          const tileState = videoTile.state();
          if (tileState.paused || tileState.isContent) {
            continue;
          }
          const tileId = videoTile.id();
          if (tileState.localTile) {
            updatedVideoMetrics.push(
              getVideoStats(
                tileId,
                videoUpstreamMetricsKeyStats,
                videoMetricReport[tileState.boundAttendeeId],
                'Upstream'
              )
            );
          } else {
            updatedVideoMetrics.push(
              tileId,
              getVideoStats(
                tileId,
                videoDownstreamMetricsKeyStats,
                videoMetricReport[tileState.boundAttendeeId],
                'Downstream'
              )
            );
          }
        }
        setVideoMetrics(updatedVideoMetrics);
      }
    },
  };

  useEffect(() => {
    audioVideo.addObserver(metricsObserver);
    return () => {
      audioVideo.removeObserver(metricsObserver);
      const divs = document.querySelectorAll('[id]');
      for (let i = 0, len = divs.length; i < len; i++) {
        let div = divs[i];
        if (div.id.indexOf('metrics-tile-') > -1) {
          div.parentNode.removeChild(div);
        }
      }
    };
  }, []);

  const getAvailableUplinkBandwidth = () => {
    let availableUplinkBandwith = 'Unknown';
    if (
      typeof metricReport.availableSendBandwidth === 'number' &&
      !isNaN(metricReport.availableSendBandwidth)
    ) {
      availableUplinkBandwith = String(metricReport.availableSendBandwidth / 1000) + ' Kbps';
    } else if (
      typeof metricReport.availableOutgoingBitrate === 'number' &&
      !isNaN(metricReport.availableOutgoingBitrate)
    ) {
      availableUplinkBandwith = String(metricReport.availableOutgoingBitrate / 1000) + ' Kbps';
    }
    return availableUplinkBandwith;
  };

  const getAvailableDownlinkBandwidth = () => {
    let availableDownlinkBandwith = 'Unknown';
    if (
      typeof metricReport.availableReceiveBandwidth === 'number' &&
      !isNaN(metricReport.availableReceiveBandwidth)
    ) {
      availableDownlinkBandwith = String(metricReport.availableReceiveBandwidth / 1000) + ' Kbps';
    } else if (
      typeof metricReport.availableIncomingBitrate === 'number' &&
      !isNaN(metricReport.availableIncomingBitrate)
    ) {
      availableDownlinkBandwith = String(metricReport.availableIncomingBitrate / 1000) + ' Kbps';
    }
    return availableDownlinkBandwith;
  };

  return (
    <div className={classes.metricContainer}>
      {metricReport && (
        <div>
          <Typography variant="h5">Network statistics</Typography>
          <table>
            <tbody>
              <tr>
                <td>Available Uplink Bandwidth: </td>
                <td>{getAvailableUplinkBandwidth()}</td>
              </tr>
              <tr>
                <td>Available Downlink Bandwidth: </td>
                <td>{getAvailableDownlinkBandwidth()}</td>
              </tr>
              <tr>
                <td>Video Upstream Bitrate: </td>
                <td>{metricReport.videoUpstreamBitrate}</td>
              </tr>
              <tr>
                <td>Audio Packet Loss: </td>
                <td>{metricReport.audioPacketLossPercent} packets</td>
              </tr>
              <tr>
                <td>Audio Speaker Delay: </td>
                <td>{metricReport.audioSpeakerDelayMs}ms</td>
              </tr>
            </tbody>
          </table>
        </div>
      )}

      {!videoMetrics && !metricReport && 'No Metrics Available!'}
    </div>
  );
}
