import { sendLoadTestingRequest } from 'api/routes/session';
import chat from './tests/chat/api';
import meeting from './tests/meeting/api';
import question from './tests/question/api';
import roster from './tests/roster/api';
import grid from './tests/grid/api';
import poll from './tests/poll/api';
import queue from './tests/queue/api';
const types = { chat, meeting, question, roster, grid, poll, queue };

export const makeLoadTestRequest = async ({ type, userIds, payload, randomBroadcastCount }) => {
  await sendLoadTestingRequest(type, userIds, payload, randomBroadcastCount);
};

export const receiveLoadTestRequest = async ({ type, payload }) => {
  const [mainType, subType] = type.split(':');
  await types[mainType]({ subType, payload });
};
