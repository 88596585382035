import { basePost } from 'api/apiDetour';
// import config from 'config';
// const { MEETING_API, SIGNED_API } = config;
const post = async ({ endpoint, data = {}, isSigned = false }) => {
  let resp = await basePost(endpoint, data, 'user', isSigned);
  return resp;
};

/**
 * @param  {string} externalToken The original token passed into our app that will be converted to our own token which is the token stored in the cache
 * @param  {string} accountId
 */

export const authUser = async (externalToken, accountId) => {
  const resp = await post({
    endpoint: 'auth',
    data: { externalToken, accountId },
  });
  return resp;
};

export const testingLogin = async (firstName, lastName, role, email, sessionId, eventId) => {
  const resp = await post({
    endpoint: 'testing',
    data: {
      firstName,
      lastName,
      role,
      email,
      sessionId,
      eventId,
    },
  });
  return resp;
};

export const batchGetUsers = async (userIds) => {
  const resp = await post({ endpoint: 'batchget', data: { userIds } });
  return resp;
};

export const getUser = async (userId, shouldGetUserSession = false) => {
  const resp = await post({ endpoint: 'get', data: { userId, shouldGetUserSession } });
  return resp;
};

export const createExternalTestToken = async (
  firstName,
  lastName,
  role,
  email,
  eventId,
  title,
  isForRecordingUser = false,
  type,
  externalAccountUserId = null
) => {
  const resp = await post({
    endpoint: 'testing',
    data: {
      firstName,
      lastName,
      role,
      email,
      eventId,
      title,
      isForRecordingUser,
      type,
      externalAccountUserId,
    },
  });
  return resp;
};

export const getUsers = async () => {
  const resp = await post({ endpoint: 'list' });
  return resp;
};

export const initializeUserData = async () => {
  const resp = await post({ endpoint: 'initialize-user-data' });
  return resp;
};

export const updateToken = async (updates) => {
  const resp = await post({ endpoint: 'update-token', data: { updates } });
  return resp;
};
