import { Button, Typography, Slider } from '@material-ui/core';
import Backdrop from '@material-ui/core/Backdrop';
import Fade from '@material-ui/core/Fade';
import FormHelperText from '@material-ui/core/FormHelperText';
import InputLabel from '@material-ui/core/InputLabel';
import Modal from '@material-ui/core/Modal';
import Select from '@material-ui/core/Select';
import AddToPhotosIcon from '@material-ui/icons/AddToPhotos';
import GradientIcon from '@material-ui/icons/Gradient';
import PauseCircleFilledIcon from '@material-ui/icons/PauseCircleFilled';
import VideocamIcon from '@material-ui/icons/Videocam';
import { setNextInput, switchInput } from 'api/routes/sessionResources';
import CallMadeIcon from '@material-ui/icons/CallMade';
import CloseIcon from '@material-ui/icons/Close';
import StreamOfflinePanel from 'components/StreamOfflinePanel';
import { has } from 'lodash';
import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { setNextInputAction } from 'store/session-resource/sessionResourceActions';
import { sendWebSocketMessageAction } from 'store/web-socket/webSocketActions';
import { stopStreamAction } from 'store/session/sessionActions';
import useStyles from './style.js';
import PropTypes from 'prop-types';
import { formatSeconds } from 'utils/utilMethods';
import deepEqual from 'react-fast-compare';
import StopIcon from '@material-ui/icons/Stop';
import SendIcon from '@material-ui/icons/Send';
import MoreHorizIcon from '@material-ui/icons/MoreHoriz';

const images = {
  Presenters: '/PresenterTile.png',
  Standby: '/StandbyImage.png',
  default: '/MediaImage.png',
};

const StreamController = ({ setOpenStream, openStream }) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const videos = useSelector((store) => store.event.videos, deepEqual);
  const currentResourceId = useSelector((store) => store.session.currentResourceId);
  const currentInput = useSelector(
    (store) => store.sessionResource.currentInput || { name: '', key: '' },
    deepEqual
  );
  const nextInput = useSelector(
    (store) => store.sessionResource.nextInput || { name: '', key: '' },
    deepEqual
  );
  const status = useSelector((store) => store.session.status);
  const role = useSelector((store) => store.user.role);
  const standby = useSelector((store) => store.event.standby, deepEqual);
  const mediaProgress = useSelector((store) => store.sessionResource.mediaProgress, deepEqual);
  const [open, setOpen] = useState(false);
  const [seconds, setSeconds] = useState(0);
  const [showSeconds, setShowSeconds] = useState(false);
  const [openStopStream, setOpenStopStream] = useState(false);

  if (status !== 'LIVE') return <StreamOfflinePanel status={status} role={role} />;

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleOpenStream = () => {
    setOpenStopStream(true);
  };

  const handleCloseStream = () => {
    setOpenStopStream(false);
  };

  const switchTo = (type, key, name) => {
    switch (type) {
      case 'Presenters':
        switchInput(currentResourceId, false, 'Presenters');
        break;
      case 'loop':
        switchInput(currentResourceId, true, name, key, true);
        break;
      case 'continue':
        switchInput(currentResourceId, true, name, key, false);
        break;
      default:
    }
  };

  const switchNextInput = async (next) => {
    dispatch(setNextInputAction({ nextInput: next }));
    setNextInput(next, currentResourceId);
  };

  const switchToNext = () => {
    if (nextInput.name === 'Presenters') {
      switchTo('Presenters', '', 'Presenters');
    } else {
      switchTo('continue', nextInput.key, nextInput.name);
    }
    switchNextInput({ name: '', key: '' });
  };

  const handleMediaTimeSet = () => {
    if (!isNaN(seconds))
      dispatch(sendWebSocketMessageAction({ type: 'set-media-time', payload: { seconds } }));
  };

  const handleSecondsChange = (event, newValue) => {
    setSeconds(newValue);
  };

  const handleShowSeconds = () => {
    setShowSeconds(!showSeconds);
  };

  const currentImage = images[currentInput.name] || images['default'];

  return (
    <div className={classes.broadcastStreamControls}>
      <div className={classes.imgContainer}>
        <div className={classes.imgTitle}>Active</div>
        <img className={classes.images} src={currentImage} alt="Current Video" />
        {currentInput.name === 'Presenters' ? (
          ''
        ) : (
          <div className={classes.imgOverlay}>
            <Typography style={{ color: "#333" }} variant="caption">Name:</Typography>
            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
              <Typography style={{ color: "#333" }}>{currentInput.name}</Typography>
              {currentImage === images['default'] && (
                <Typography>
                  {formatSeconds(mediaProgress?.currentTime || 0)} /{' '}
                  {formatSeconds(mediaProgress?.endTime || 0)}
                </Typography>
              )}
            </div>
          </div>
        )}
      </div>

      {currentImage === images['default'] && (
        <>
          <div style={{ display: 'flex', justifyContent: 'space-between' }}>
            <Typography>Advanced </Typography>
            <MoreHorizIcon onClick={handleShowSeconds} />
          </div>
          {showSeconds && (
            <div className={classes.timeSetContainer}>
              <Slider
                value={seconds}
                max={mediaProgress?.endTime || 0}
                onChange={handleSecondsChange}
              />
              <Typography style={{ padding: '.5rem' }}>{formatSeconds(seconds)}</Typography>
              <Button color="primary" variant="contained" onClick={handleMediaTimeSet}>
                <SendIcon />
              </Button>
            </div>
          )}
        </>
      )}

      <Button
        disabled={nextInput === ''}
        color="primary"
        className={classes.nextbutton}
        variant="contained"
        onClick={() => switchToNext()}
      >
        <GradientIcon />
        Next
      </Button>

      {nextInput.name !== '' ? (
        <>
          <div className={classes.imgContainer}>
            <div className={classes.imgTitle} data-is-queue="1">
              Queued
            </div>
            <img
              className={classes.images}
              src={nextInput.name === 'Presenters' ? '/PresenterTile.png' : '/MediaImage.png'}
              alt="Next Video"
            />
            {nextInput.name === 'Presenters' ? (
              ''
            ) : (
              <div className={classes.imgOverlay}>
                <Typography variant="caption">Name:</Typography>
                <Typography>{nextInput.name}</Typography>
              </div>
            )}
            <div className={classes.clearButtonOverlay}>
              <Button
                color="secondary"
                variant="contained"
                onClick={() => switchNextInput({ name: '', key: '' })}
              >
                Clear
              </Button>
            </div>
          </div>
        </>
      ) : (
        <div className={classes.selectNextBox}>
          <Button
            onClick={() => switchNextInput({ name: 'Presenters', key: '' })}
            color="primary"
            className={classes.nextbutton}
            type="button"
            variant="contained"
          >
            <VideocamIcon />
            Queue Presenters
          </Button>
          <Button
            onClick={handleOpen}
            color="primary"
            className={classes.nextbutton}
            type="button"
            variant="contained"
          >
            <AddToPhotosIcon />
            Queue Media
          </Button>
        </div>
      )}

      <Button
        color="primary"
        className={classes.nextbutton}
        variant="contained"
        onClick={setOpenStream}
      >
        {openStream ? <CloseIcon /> : <CallMadeIcon />}
        {openStream ? 'Close Stream' : 'Open Stream'}
      </Button>

      <Button
        className={classes.standbyButton}
        variant="contained"
        onClick={() => {
          if (has(standby, 'bucket') && has(standby, 'key')) {
            switchTo('loop', `${standby.key}`, 'Standby');
          } else {
            switchTo('loop', `Standby.mp4`, 'Standby');
          }
        }}
      >
        <PauseCircleFilledIcon />
        Standby
      </Button>

      <Button className={classes.stopButton} variant="contained" onClick={handleOpenStream}>
        <StopIcon />
        Stop Stream
      </Button>

      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        className={classes.modal}
        open={open}
        onClose={handleClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={open}>
          <div className={classes.transitionBackground}>
            <InputLabel shrink id="video-select-placeholder">
              Uploaded Videos
            </InputLabel>
            <Select
              native
              fullWidth
              value={nextInput}
              onChange={(e) => {
                switchNextInput(JSON.parse(e.target.value));
                handleClose();
              }}
            >
              <option value={''} />
              {videos?.map((video) => {
                return (
                  <option
                    key={video.key}
                    value={JSON.stringify({ key: video.key, name: video.name })}
                  >
                    {video.name}
                  </option>
                );
              })}
            </Select>
            <FormHelperText>{`Video will not play until "NEXT" is selected`}</FormHelperText>
          </div>
        </Fade>
      </Modal>

      <Modal
        aria-labelledby="stop-stream-modal"
        aria-describedby="stops-stream"
        className={classes.modal}
        open={openStopStream}
        onClose={handleCloseStream}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={openStopStream}>
          <div className={classes.transitionBackground}>
            <Typography>Are you sure you want to stop the broadcast?</Typography>
            <div style={{ display: 'flex', justifyContent: 'space-between', marginTop: '2rem' }}>
              <Button variant="contained" onClick={handleCloseStream}>
                Cancel
              </Button>

              <Button
                variant="contained"
                className={classes.stop}
                onClick={() => {
                  dispatch(stopStreamAction());
                }}
              >
                Yes
              </Button>
            </div>
          </div>
        </Fade>
      </Modal>
    </div>
  );
};

StreamController.propTypes = {
  setOpenStream: PropTypes.func,
  openStream: PropTypes.bool,
};

export default StreamController;
