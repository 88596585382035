import { createAction as ca } from '@reduxjs/toolkit';

export const errorAction = ca('sessionResource/errorAction');
export const initAction = ca('sessionResource/initAction');
export const resetStateAction = ca('sessionResource/resetStateAction');
export const successAction = ca('sessionResource/successAction');
export const setInputAction = ca('sessionResource/setInputAction');
export const setSessionResource = ca('sessionResource/setSessionResource');
export const setNextInputAction = ca('sessionResource/setNextInputAction');
export const setCurrentInputAction = ca('sessionResource/setCurrentInputAction');
export const setVideoTimeSetAction = ca('sessionResource/setVideoTimeSetAction');
export const setMediaProgressAction = ca('sessionResource/setMediaProgressAction');
