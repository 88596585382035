import { makeStyles } from '@material-ui/core/styles';
import IconButton from '@material-ui/core/IconButton';
import PersonIcon from '@material-ui/icons/Person';
import PhotoSizeSelectLargeIcon from '@material-ui/icons/PhotoSizeSelectLarge';
import PhotoSizeSelectSmallIcon from '@material-ui/icons/PhotoSizeSelectSmall';
import PropTypes from 'prop-types';
import { useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import VideoStatus from 'enums/VideoStatus';
import { setSpeakerAction } from 'store/session/sessionActions';
import { setSelectSpeaker } from 'api/routes/session';
import deepEqual from 'react-fast-compare';
import { showGenericErrorNotification } from 'utils/utilMethods';

export const useStyles = makeStyles((theme) => ({
  videoWrapper: {
    position: 'relative',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: '100%',
    height: '100%',
    // border: '1px solid black',
    backgroundColor: theme.palette.stage.dark,
    overflow: 'hidden',
    borderRadius: '1em',

    '&[data-active="1"]': {
      //outline: '3px solid yellow',
    },

    '& video': {
      height: '100%',
      width: '100%',
      position: 'absolute',
      objectFit: 'cover',
    },

    '&[data-content="1"] video': {
      objectFit: 'contain',
    },

    '& .hoverButtons': {
      display: 'none',
      alignItems: 'center',
      position: 'absolute',
      top: '0.5rem',
      right: '0.5rem',
      color: '#f4f4f4',
    },

    '&:hover .hoverButtons': {
      display: 'flex',
    },
  },
  emptyUser: {
    position: 'absolute',
    width: '8rem',
    height: '8rem',
    background: theme.palette.icon.tertiary.background,
    borderRadius: '50%',
    textAlign: 'center',
    '& > .MuiSvgIcon-root:first-child': {
      fontSize: '7rem',
      color: theme.palette.icon.tertiary.fill,
    },
  },
}));

VideoTile.propTypes = {
  videoTileId: PropTypes.number,
  videoUserId: PropTypes.string,
  isSelectSpeaker: PropTypes.bool,
  children: PropTypes.node,
  isActive: PropTypes.bool,
  isPaused: PropTypes.bool,
  isContent: PropTypes.bool,
};

export default function VideoTile({
  children,
  videoTileId,
  videoUserId,
  isSelectSpeaker,
  isActive,
  // isPaused,
  isContent,
}) {
  const dispatch = useDispatch();
  const classes = useStyles();
  const videoElement = useRef(null);
  const audioVideo = useSelector((store) => store.chime.audioVideo, deepEqual);
  const localTileId = useSelector((store) => store.chime.localTileId);
  const videoStatus = useSelector((store) => store.chime.videoStatus);
  const role = useSelector((store) => store.user.role);
  const userId = useSelector((store) => store.user.userId);
  const contentSharerId = useSelector((store) => store.chime.contentSharerId);

  const isLocal = videoUserId === userId;

  const startStreamVideo = async () => {
    audioVideo.bindVideoElement(localTileId, videoElement.current);
  };

  const stopStreamVideo = () => {
    audioVideo.unbindVideoElement(localTileId);
  };

  useEffect(() => {
    if (!isLocal) return;

    if (videoStatus === VideoStatus.Enabled && localTileId) {
      startStreamVideo();
    } else {
      stopStreamVideo();
    }
  }, [isLocal, localTileId, videoStatus]);

  useEffect(() => {
    if (isLocal) return;

    if (videoTileId === undefined) return;

    if (contentSharerId === userId && !videoElement.current.hasAttribute('muted')) {
      videoElement.current.setAttribute('muted', 'true');
      videoElement.current.muted = true;
    }
    audioVideo.bindVideoElement(videoTileId, videoElement.current);
    return () => {
      audioVideo.unbindVideoElement(videoTileId);
    };
    // eslint-disable-next-line
  }, [isLocal, videoTileId]);

  const toggleSelectSpeaker = () => {
    const newSpeakerId = isSelectSpeaker ? 'none' : videoUserId;
    setSelectSpeaker(newSpeakerId, {
      retries: 2,
      onSuccess() {
        dispatch(setSpeakerAction({ userId: newSpeakerId }));
      },
      onError(error, reason, retriesLeft) {
        if (retriesLeft === 0) {
          showGenericErrorNotification('setting select speaker');
        }
      },
    });
  };

  const canManage = role === 'presenter';
  const SelectSpeakerIconToUse = isSelectSpeaker
    ? PhotoSizeSelectSmallIcon
    : PhotoSizeSelectLargeIcon;

  return (
    <div
      data-testid={'video-tile'}
      id={`video-tile-${isLocal ? localTileId : videoTileId}`}
      className={classes.videoWrapper}
      data-empty={(isLocal ? localTileId : videoTileId) === undefined}
      data-active={isActive ? 1 : 0}
      data-content={isContent ? 1 : 0}
    >
      {/* {isPaused && <div className={classes.emptyUser}>PAUSED</div>} */}
      {(
        isLocal
          ? videoStatus === VideoStatus.Disabled || [null, undefined].includes(localTileId)
          : videoTileId === undefined
      ) ? (
        <div className={classes.emptyUser}>
          <PersonIcon />
        </div>
      ) : (
        <video data-testid="video-tile-video" ref={videoElement} />
      )}

      {canManage && !isContent && (
        <div className="hoverButtons">
          <IconButton
            onClick={toggleSelectSpeaker}
            id={`user-spotlight-button-${videoUserId}`}
            data-selected={isSelectSpeaker ? 1 : 0}
          >
            <SelectSpeakerIconToUse />
          </IconButton>
        </div>
      )}

      {children}
    </div>
  );
}
