import { basePost } from 'api/apiDetour';
const post = async ({ endpoint, data = {}, isSigned = false }) => {
  let resp = await basePost(endpoint, data, 'user-session-data', isSigned);
  return resp;
};

/**
 * @param  {string} attendenceType EXIT|ENTER
 */
export const getSesisonData = async () => {
  let resp = await post({ endpoint: 'get' });
  return resp;
};

export const getUserSessionData = async (userId) => {
  let resp = await post({ endpoint: 'get-user', data: { userId } });
  return resp;
};

export const getMeetingAnalytics = async (sessionId, eventId) => {
  const resp = await post({ endpoint: 'meeting-analytics', data: { sessionId, eventId } });
  return resp;
};
