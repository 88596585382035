import IconButton from '@material-ui/core/IconButton';
import InputBase from '@material-ui/core/InputBase';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import SendIcon from '@material-ui/icons/Send';
import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { askQuestionAction } from 'store/session-question/sessionQuestionActions';
import { questionCharsPerLine } from './Question.style.js';

const maxChars = questionCharsPerLine * 8;
const questionsMax = 50;
const errors = {
  maxQuestions: `A maximum of ${questionsMax} questions can be asked at a time.\nSelect "My Questions" from the filter above and remove a question prior to asking another.`,
  blank: 'Question cannot be blank.',
  maxChars: `Questions cannot have more than ${maxChars} characters.`,
  blocked: 'Asking questions has been temporarily disabled for this user.',
};

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.light,
    padding: '1rem',
    paddingBottom: '0.5rem',
    margin: `0.5rem ${theme.sidePanel.gutter}`,
    ...theme.mixins.borderRadius(),
    [theme.breakpoints.downWithLandscape('mobile')]: {
      margin: 0,
      paddingTop: '0.25rem',
      paddingBottom: '0.25rem',
      display: 'flex',
    },
  },
  input: {
    color: theme.palette.secondary.main,
  },
  buttons: {
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center',
  },
  buttonHighlight: {
    padding: '2px',
    color: theme.palette.primary.main,
  },
  button: {
    padding: '2px',
  },
  hr: {
    margin: '0.5rem 0',
    height: '1px',
    backgroundColor: theme.palette.hr.monochrome,
  },
}));

QuestionInput.propTypes = {
  bypassNextIfScrolled: PropTypes.func.isRequired,
  getUpdates: PropTypes.func.isRequired,
  thisUsersActiveQuestionCount: PropTypes.number,
};

export default function QuestionInput({
  bypassNextIfScrolled,
  getUpdates,
  thisUsersActiveQuestionCount,
}) {
  const dispatch = useDispatch();
  const classes = useStyles();
  const isBlocked = useSelector((store) =>
    (store.session.blockedUsers || []).includes(store.user.userId)
  );
  const [inputText, setInputText] = useState('');
  const [showError, setShowError] = useState('');

  useEffect(() => {
    if (isBlocked) {
      setShowError(errors.blocked);
    } else if (showError === errors.maxQuestions && thisUsersActiveQuestionCount < questionsMax) {
      setShowError('');
    } else if (showError === errors.blocked && !isBlocked) {
      setShowError('');
    }
    //eslint-disable-next-line
  }, [isBlocked, thisUsersActiveQuestionCount]);

  const onClickAskQuestion = async () => {
    let question = inputText.trim();
    if (question.length === 0) {
      setShowError(errors.blank);
      setInputText(question);
    } else if (thisUsersActiveQuestionCount >= questionsMax) {
      setShowError(errors.maxQuestions);
      setInputText(question);
    } else if (question.length > maxChars) {
      setShowError(errors.maxChars);
      setInputText(question);
    } else {
      bypassNextIfScrolled();

      dispatch(askQuestionAction({ question }));

      setInputText('');
      setShowError('');
      getUpdates();
    }
  };

  const inputOnKeyDown = (event) => {
    if (event.keyCode === 13) {
      event.preventDefault();
      onClickAskQuestion();
    }
  };

  const inputOnChange = (event) => {
    setInputText(event.target.value);
    setShowError(inputText.trim().length >= maxChars ? errors.maxChars : '');
  };

  return (
    <div className={classes.root} id="questions-container">
      {showError && (
        <Typography color="error" variant="caption">
          {showError}
        </Typography>
      )}
      {!isBlocked && (
        <>
          <InputBase
            fullWidth
            multiline={true}
            rowsMax={3}
            inputProps={{
              maxLength: maxChars,
            }}
            id="question-input"
            className={classes.input}
            value={inputText}
            placeholder="Enter question..."
            data-testid="themsg"
            onChange={inputOnChange}
            onKeyDown={inputOnKeyDown}
          />
          <div className={classes.hr} />
          <div className={classes.buttons}>
            <IconButton
              data-testid="send"
              className={inputText ? classes.buttonHighlight : classes.button}
              disabled={!!showError}
              onClick={onClickAskQuestion}
              id="send-question"
            >
              <SendIcon size="large" />
            </IconButton>
          </div>
        </>
      )}
    </div>
  );
}
