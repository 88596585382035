import ErrorOutlineIcon from '@material-ui/icons/ErrorOutline';
import FiberSmartRecordIcon from '@material-ui/icons/FiberSmartRecordOutlined';
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined';
import { blockUser, rogueUserStillExists, setUserInGrid } from 'api/routes/session';
import {
  launchrecordingTask,
  launchStreamingTask,
  stoprecordingTask,
  stopStreamingTask,
} from 'api/routes/sessionResources';
import { updateLocalVideoStatus } from 'api/routes/userSession';
import { select, takeEvery } from 'redux-saga/effects';
// import * as aa from 'store/app/appActions';
import * as ca from 'store/chime/chimeActions';
import { updateRosterAttributeAction } from 'store/chime/chimeActions';
import * as sa from 'store/session/sessionActions';
import { call, put } from 'store/utils';
import { showNotificationAction } from 'store/utils/utilActions';
import { getFeatureName } from 'constants/features';

function* askForHelpFlowSaga() {
  const key = 'askForHelpFlowSaga';
  yield put(sa.initAction({ key }));
  try {
    // const { message } = action.payload;e=
    // const { userId, sessionId } = yield select(({ user, session }) => ({
    //   userId: user.userId,
    //   sessionId: session.sessionId,
    // }));
    // TODO add back help stuff
    // yield call(askForHelp, message, userId, sessionId);
    // yield put(
    //   showNotificationAction({
    //     message: 'Help request received!',
    //     type: 'info',
    //     icon: ContactSupportOutlinedIcon,
    //     notiPopoutName: 'Help Request',
    //   })
    // );
    yield put(sa.successAction({ key }));
  } catch (error) {
    yield put(sa.errorAction({ key, error }));
  }
}

function* blockUserFlowSaga(action) {
  const key = 'blockUserFlowSaga';
  yield put(sa.initAction({ key }));
  try {
    const { userId, shouldBlock } = action.payload;
    yield call(blockUser, userId, shouldBlock);
    yield put(sa.successAction({ key }));
  } catch (error) {
    yield put(sa.errorAction({ key, error }));
  }
}

function* updateLocalVideoStatusFlowSaga({ payload }) {
  const key = 'updateLocalVideoStatusFlowSaga';
  yield put(sa.initAction({ key }));
  try {
    const { localVideoStatus } = payload;
    yield call(updateLocalVideoStatus, localVideoStatus);
    yield put(sa.successAction({ key }));
  } catch (error) {
    yield put(sa.errorAction({ key, error }));
  }
}

function* startStreamFlowSaga() {
  const key = 'startStreamFlowSaga';
  yield put(sa.initAction({ key }));
  try {
    const { sessionId } = yield select(({ session }) => ({
      sessionId: session.sessionId,
    }));
    const startData = yield call(launchStreamingTask, sessionId);
    if (startData && startData.error) {
      console.log(startData.error);
      //TODO potentially check for specific errors here like not enough resources
      yield put(
        showNotificationAction({
          message: 'There was an Error when trying to start the Broadcast.',
          type: 'error',
          icon: ErrorOutlineIcon,
        })
      );
    } else {
      yield put(
        sa.setSessionAction({
          ...startData.session,
          usersInSession: startData.session?.usersInSession?.length || 0,
        })
      );
      yield put(sa.setResourceStatusAction(startData.resource.status));
    }
    yield put(sa.successAction({ key }));
  } catch (error) {
    yield put(sa.errorAction({ key, error }));
  }
}

function* stopStreamFlowSaga() {
  const key = 'stopStreamFlowSaga';
  yield put(sa.initAction({ key }));
  try {
    const { sessionId } = yield select(({ session }) => ({
      sessionId: session.sessionId,
    }));
    const stopData = yield call(stopStreamingTask, sessionId);
    yield put(
      sa.setSessionAction({
        ...stopData.session,
        usersInSession: stopData?.session?.usersInSession?.length || 0,
      })
    );
    yield put(sa.successAction({ key }));
  } catch (error) {
    yield put(sa.errorAction({ key, error }));
  }
}

function* toggleRecordingFlowSaga() {
  const key = 'toggleRecordingFlowSaga';
  yield put(sa.initAction({ key }));
  try {
    const { isRecording } = yield select(({ session }) => ({
      isRecording: session.isRecording,
    }));
    if (isRecording === 'LIVE') {
      yield call(stoprecordingTask);
      put(sa.setIsRecordingAction({ isRecording: 'OFFLINE' }));
      put(
        showNotificationAction({
          message: 'Recording saved.',
          type: 'info',
          icon: FiberSmartRecordIcon,
        })
      );
    } else {
      const recordData = yield call(launchrecordingTask);
      if (recordData && recordData.error) {
        console.log(recordData.error);
        //TODO potentially check for specific errors here like not enough resources
        yield put(
          showNotificationAction({
            message: 'There was an Error when trying to start the recording.',
            type: 'error',
            icon: ErrorOutlineIcon,
          })
        );
        put(sa.setIsRecordingAction({ isRecording: 'OFFLINE' }));
      } else {
        put(sa.setIsRecordingAction({ isRecording: 'STARTING' }));
      }
    }
    yield put(sa.successAction({ key }));
  } catch (error) {
    yield put(sa.errorAction({ key, error }));
  }
}

function* setIsRecordingFlowSaga({ payload }) {
  const key = 'setIsRecordingFlowSaga';
  yield put(sa.initAction({ key }));
  const { isRecordingUser, appType } = yield select(({ user, app }) => ({
    isRecordingUser: user.isRecordingUser,
    appType: app.appType,
  }));
  try {
    const { isRecording } = payload;
    if (isRecording === 'STARTING' && !isRecordingUser && appType === 'meeting') {
      // yield put(aa.setShowRecordingModalAction({ showRecordingModal: true }));
      //save incase Robert wants to use
      yield put(
        showNotificationAction({
          message: (
            <>
              {`This meeting is being recorded. If you wish to opt-out of the meeting recording, please disconnect now.`}
            </>
          ),
          type: 'info',
          icon: FiberSmartRecordIcon,
          duration: 20000,
        })
      );
    }
    yield put(sa.successAction({ key }));
  } catch (error) {
    yield put(sa.errorAction({ key, error }));
  }
}

export function* setRaisedHandFlowSaga({ payload }) {
  const key = 'setRaisedHandFlowSaga';
  const { raisedHand } = payload;

  yield put(sa.initAction({ key }));
  try {
    const userId = yield select((state) => state.user.userId);

    yield put(
      updateRosterAttributeAction({
        userId,
        key: 'handIsRaised',
        value: raisedHand,
      })
    );

    yield put(sa.successAction({ key }));
  } catch (error) {
    yield put(sa.errorAction({ key, error }));
  }
}

export function* notifyRaiseHandFlowSaga({ payload }) {
  const key = 'notifyRaiseHandFlowSaga';
  const { handIsRaised, name } = payload;

  yield put(sa.initAction({ key }));
  try {
    if (handIsRaised) {
      const popout = yield select((state) => state.app.popout);
      const appType = yield select((state) => state.app.appType);

      const rosterPanelName = appType === 'broadcast' ? 'Panelists' : 'Participants';

      if (!popout.open && popout.name !== rosterPanelName) {
        yield put(
          showNotificationAction({
            message: `${name || 'Someone'} raised their hand`,
            type: 'warning',
            icon: InfoOutlinedIcon,
            notiPopoutName: rosterPanelName,
          })
        );
      }
    }

    yield put(sa.successAction({ key }));
  } catch (error) {
    yield put(sa.errorAction({ key, error }));
  }
}
export function* eventBridgeMeetingEndedFlowSaga() {
  const key = 'eventBridgeMeetingEndedFlowSaga';
  yield put(sa.initAction({ key }));
  try {
    yield put(
      showNotificationAction({
        message: `Rogue Session`,
        type: 'error',
        icon: InfoOutlinedIcon,
        notiPopoutName: 'Session Ended',
      })
    );
    yield put(ca.leaveRoomAction({ forceLogout: true }));
    yield call(rogueUserStillExists);
    yield put(sa.successAction({ key }));
  } catch (error) {
    yield put(sa.errorAction({ key, error }));
  }
}

export function* removeAttendeeSpeakerFlowSaga({ payload }) {
  const key = 'removeAttendeeSpeaker';
  yield put(sa.initAction({ key }));
  try {
    const { userId } = payload;
    const thisUserId = yield select((state) => state.user.userId);
    const roster = yield select((state) => state.chime.roster);
    const gridUsers = yield select((state) => state.session.gridUsers);
    const inQueue = Object.keys(roster).includes(userId);

    yield put(sa.removeStageAttendeeAction({ userId }));
    if (gridUsers[userId]) {
      yield call(setUserInGrid, userId, false);
      yield put(sa.removeUserFromGridAction({ userId }));
    }
    if (userId === thisUserId && inQueue) yield put(ca.setAttendeeInBroadcastAction(false));
    if (userId === thisUserId && !inQueue) {
      yield put(sa.setQueueQuestionTextAction({ text: '' }));
      yield put(
        showNotificationAction({
          message: 'Your Queue request has been denied.',
          type: 'info',
          icon: ErrorOutlineIcon,
          notiPopoutName: getFeatureName('queue'),
        })
      );
    }

    yield put(sa.successAction({ key }));
  } catch (error) {
    yield put(sa.errorAction({ key, error }));
  }
}

export function* updateStageAttendeeFlowSaga({ payload }) {
  const key = 'updateStageAttendeeFlowSaga';
  yield put(sa.initAction({ key }));
  try {
    const { userId, key, value } = payload;
    const thisUserId = yield select((state) => state.user.userId);

    if (key === 'accepted' && value === true && userId === thisUserId) {
      yield put(ca.setAttendeeInBroadcastAction(true));
    }

    yield put(sa.updateStageAttendeeAttrAction({ userId, key, value }));

    yield put(sa.successAction({ key }));
  } catch (error) {
    yield put(sa.errorAction({ key, error }));
  }
}

export default function* sessionWatcher() {
  yield takeEvery(sa.blockUserAction().type, blockUserFlowSaga);
  yield takeEvery(sa.askForHelpAction().type, askForHelpFlowSaga);
  yield takeEvery(sa.setIsRecordingAction().type, setIsRecordingFlowSaga);
  yield takeEvery(sa.startStreamAction().type, startStreamFlowSaga);
  yield takeEvery(sa.stopStreamAction().type, stopStreamFlowSaga);
  yield takeEvery(sa.updateLocalVideoStatusAction().type, updateLocalVideoStatusFlowSaga);
  yield takeEvery(sa.toggleRecordingAction().type, toggleRecordingFlowSaga);
  yield takeEvery(sa.setIsRecordingAction().type, setIsRecordingFlowSaga);
  yield takeEvery(sa.removeAttendeeSpeaker().type, removeAttendeeSpeakerFlowSaga);
  yield takeEvery(sa.setRaisedHandAction().type, setRaisedHandFlowSaga);
  yield takeEvery(sa.notifyRaiseHandAction().type, notifyRaiseHandFlowSaga);
  yield takeEvery(sa.eventBridgeMeetingEndedAction().type, eventBridgeMeetingEndedFlowSaga);
  yield takeEvery(sa.handleUpdateStageAttendeeAction().type, updateStageAttendeeFlowSaga);
}
