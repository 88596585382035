import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import deepEqual from 'react-fast-compare';
import { formatSeconds } from 'utils/utilMethods';

export const PropTypePoll = PropTypes.shape({
  pollId: PropTypes.string.isRequired,
  eventId: PropTypes.string,
  creatorName: PropTypes.string,
  questionText: PropTypes.string.isRequired,
  answerOptions: PropTypes.object,
  correctAnswer: PropTypes.string,
  startedAt: PropTypes.number,
  endedAt: PropTypes.number,
  isEventPoll: PropTypes.bool,
  resultsShared: PropTypes.bool,
  duration: PropTypes.number,
  displayDuration: PropTypes.bool,
});

export const pollIsStarted = (poll, serverTime) =>
  !!poll.startedAt && (serverTime ? serverTime : Date.now()) >= poll.startedAt;
export const pollIsEnded = (poll, serverTime) =>
  !!poll.endedAt && (serverTime ? serverTime : Date.now()) >= poll.endedAt;
export const pollIsRunning = (poll, serverTime) =>
  pollIsStarted(poll, serverTime) && !pollIsEnded(poll, serverTime);

export const pollIdIsEqual = (pollA, pollB) => {
  return pollA.pollId === pollB.pollId && pollA.eventId === pollB.eventId;
};

export const pollGetTotalAnswers = (poll) => {
  let totalAnswers = 0;
  const options = Object.keys(poll.answerOptions).sort((a, b) =>
    poll.answerOptions[a].order > poll.answerOptions[b].order ? 1 : -1
  );
  for (let option of options) totalAnswers += poll.answerOptions[option].count;
  return totalAnswers;
};

export const pollGetTimeDisplay = (poll) => {
  let timeDisplay = 0;
  if (pollIsRunning(poll)) {
    timeDisplay = poll.endedAt - Date.now();
  } else if (!pollIsEnded(poll)) {
    timeDisplay = poll.duration || 0;
  }
  return formatSeconds(timeDisplay / 1000);
};

export const useMyPollVote = (pollId) =>
  useSelector((state) => state.sessionPolling.myAnswers[pollId] ?? null);

export const useRunningPoll = () =>
  useSelector((store) => {
    const polls = store.sessionPolling.polls;

    for (let poll of polls) {
      if (pollIsRunning(poll)) {
        return poll;
      }
    }

    return null;
  }, deepEqual);
