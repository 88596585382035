import {
  Button,
  IconButton,
  MenuItem,
  Select,
  TextField,
  Tooltip,
  Typography,
} from '@material-ui/core';
import LinearProgress from '@material-ui/core/LinearProgress';
import { makeStyles } from '@material-ui/core/styles';
import FileCopyIcon from '@material-ui/icons/FileCopy';
import { createExternalTestToken } from 'api/routes/user';
import useKeyPress from 'hooks/useKeyPress';
import PropTypes from 'prop-types';
import { useState } from 'react';
import { useCookie } from 'hooks/useCookie';

const USER_NAME_MAX = 32;

const useStyles = makeStyles((theme) => ({
  userName: {
    alignItems: 'center',
    ...theme.mixins.flexHorizontalList('0.5rem'),
  },
}));

JoinMeetingSetup.propTypes = {
  eventId: PropTypes.string.isRequired,
  type: PropTypes.string.isRequired,
  setToken: PropTypes.func.isRequired,
  eventName: PropTypes.string.isRequired,
};

let copyNotifyTimer;

const createBetaToken = async (eventId, title, role, firstName, lastName, type) => {
  const { token } = await createExternalTestToken(
    firstName,
    lastName,
    role,
    `betaUser@mosaiclearning.com`,
    eventId,
    title,
    false,
    type,
    null
  );

  return token;
};

export default function JoinMeetingSetup({ eventName, eventId, setToken, type }) {
  const classes = useStyles();
  const [joining, setJoining] = useState(false);
  const [firstNameCookie, setFirstNameCookie] = useCookie('beta_firstname', '');
  const [lastNameCookie, setLastNameCookie] = useCookie('beta_lastname', '');
  const [roleCookie, setRoleCookie] = useCookie('beta_role', 'presenter');
  const [firstName, setFirstName] = useState(firstNameCookie);
  const [lastName, setLastName] = useState(lastNameCookie);
  const [copiedNotify, setCopiedNotify] = useState(false);
  const [role, setRole] = useState(roleCookie);

  const joinEvent = async () => {
    setFirstNameCookie(firstName, 365);
    setLastNameCookie(lastName, 365);
    setRoleCookie(role, 365);

    setJoining(true);
    const newToken = await createBetaToken(eventId, eventId, role, firstName, lastName, type);
    setJoining(false);

    setToken(newToken);
  };

  const onClickCopy = async (event) => {
    event.preventDefault();

    await navigator.clipboard.writeText(window.location.href);

    if (copyNotifyTimer) clearTimeout(copyNotifyTimer);
    setCopiedNotify(true);
    copyNotifyTimer = setTimeout(() => void setCopiedNotify(false), 2000);
  };

  const onChangeFirstName = (event) => {
    let name = event.currentTarget.value;
    name = name.substr(0, USER_NAME_MAX);
    setFirstName(name);
  };

  const onChangeLastName = (event) => {
    let name = event.currentTarget.value;
    name = name.substr(0, USER_NAME_MAX);
    setLastName(name);
  };

  const meetingIsValid =
    firstName && lastName && role && !joining && eventName && eventName !== 'Invalid!';

  useKeyPress(() => {
    if (meetingIsValid) joinEvent();
  }, 13);

  return (
    <>
      <div>
        <Typography variant="h5" color="primary">
          Join a meeting
        </Typography>
        <Typography variant="body2" color="primary">
          {eventName || 'Loading event name...'}
        </Typography>
        <Typography variant="caption">(Event ID: {eventId})</Typography>
      </div>
      <div style={{ display: 'flex' }}>
        <Tooltip open={copiedNotify} placement="right" title="Copied!">
          <IconButton onClick={onClickCopy} href={window.location.href}>
            <FileCopyIcon />
            <Typography color="primary">Copy join link</Typography>
          </IconButton>
        </Tooltip>
      </div>

      <div className={classes.userName}>
        <TextField
          autoFocus={true}
          label="First name"
          value={firstName}
          placeholder="John"
          onChange={onChangeFirstName}
        />
        <TextField
          label="Last name"
          value={lastName}
          placeholder="Smith"
          onChange={onChangeLastName}
        />
      </div>

      <Select label="Role" value={role} onChange={(event) => void setRole(event.target.value)}>
        <MenuItem value="presenter">Presenter</MenuItem>
        <MenuItem value="attendee">Attendee</MenuItem>
      </Select>

      <Button variant="contained" color="primary" onClick={joinEvent} disabled={!meetingIsValid}>
        {joining ? 'Joining' : 'Join'}
      </Button>
      <LinearProgress style={{ opacity: joining ? 1 : 0 }} />
    </>
  );
}
