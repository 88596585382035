import { useTheme } from '@material-ui/core/styles';
import PropTypes from 'prop-types';

PoweredByImage.propTypes = {
  style: PropTypes.object,
};

export default function PoweredByImage({ style }) {
  const theme = useTheme();

  return <img src={theme.palette.logo} style={style} alt="logo" />;
}
