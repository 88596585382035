import ReconnectingWebSocket from 'reconnecting-websocket';
import config from 'config';
import { select, takeEvery } from 'redux-saga/effects';
import { startMessagingAction } from 'store/chime/chimeActions';
import { put } from 'store/utils';
import * as wsa from './webSocketActions';
import { getTokenCookie, getRefreshTokenCookie } from 'utils/token';

const { WSS } = config;

export function* sendWebSocketMessageFlowSaga(action) {
  const key = 'sendWebSocketMessageFlowSaga';
  yield put(wsa.initAction({ key }));
  try {
    const { type, payload } = action.payload;
    const { socket } = yield select(({ webSocket }) => ({
      socket: webSocket.socket,
    }));
    socket.send(
      JSON.stringify({
        message: 'sendmessage',
        data: JSON.stringify({
          type,
          payload,
          token: getTokenCookie(),
          refreshToken: getRefreshTokenCookie(),
        }),
      })
    );
    yield put(wsa.successAction({ key }));
  } catch (error) {
    yield put(wsa.errorAction({ key, error }));
  }
}

export function* initializeWebSocketChannelFlowSaga() {
  const key = 'initializeWebSocketChannelFlowSaga';
  yield put(wsa.initAction({ key }));
  try {
    const { sessionId, userId, eventId, role, isRecordingUser } = yield select(
      ({ user, event, session }) => ({
        role: user.role,
        userId: user.userId,
        eventId: event.eventId,
        sessionId: session.sessionId,
        isRecordingUser: user.isRecordingUser,
      })
    );

    let uri = `${WSS}?userId=${userId}&sessionId=${sessionId}&eventId=${eventId}&role=${role}`;

    if (isRecordingUser) {
      uri += '&isRecordingUser=1';
    }

    const socket = new ReconnectingWebSocket(uri);

    yield put(wsa.setSocketAction({ socket }));
  } catch (error) {
    yield put(wsa.errorAction({ key, error }));
  }
}

export function* disconnectUserFlowSaga(action) {
  const key = 'disconnectUserFlowSaga';
  yield put(wsa.initAction({ key }));
  try {
    const { code } = action.payload;

    if (code !== 3511) {
      const socket = yield select((state) => state.webSocket.socket);
      socket.reconnect();
    }

    yield put(wsa.successAction({ key }));
  } catch (error) {
    yield put(wsa.errorAction({ key, error }));
  }
}

export default function* webSocketWatcher() {
  yield takeEvery(startMessagingAction().type, initializeWebSocketChannelFlowSaga);
  yield takeEvery(wsa.sendWebSocketMessageAction().type, sendWebSocketMessageFlowSaga);
  yield takeEvery(wsa.disconnectUserAction().type, disconnectUserFlowSaga);
}
