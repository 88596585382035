import { QandAIcon } from 'svg';
import { useSelector } from 'react-redux';
import { getFeatureName } from 'constants/features';
import DefaultIcon from './DefaultIcon';

export default function QuestionAnswerIcon() {
  const unreadQuestions = useSelector((store) => store.sessionQuestion.unreadQuestions);

  return (
    <DefaultIcon
      buttonId="questions-icon"
      name={getFeatureName('questions')}
      IconComp={QandAIcon}
      badgeContent={unreadQuestions}
      hideBadge={unreadQuestions <= 0}
    />
  );
}
