import ChatIcon from '@material-ui/icons/Chat';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import HelpIcon from '@material-ui/icons/Help';
import PanToolIcon from '@material-ui/icons/PanTool';
import PersonAddIcon from '@material-ui/icons/PersonAdd';
import QuestionAnswerIcon from '@material-ui/icons/QuestionAnswer';
import ScreenShareIcon from '@material-ui/icons/ScreenShare';
import StopScreenShareIcon from '@material-ui/icons/StopScreenShare';
import ThumbUpAltIcon from '@material-ui/icons/ThumbUpAlt';
import VideoCallIcon from '@material-ui/icons/VideoCall';
import VideocamIcon from '@material-ui/icons/Videocam';
import VideocamOffIcon from '@material-ui/icons/VideocamOff';
import Timeline from '@material-ui/lab/Timeline';
import TimelineConnector from '@material-ui/lab/TimelineConnector';
import TimelineContent from '@material-ui/lab/TimelineContent';
import TimelineDot from '@material-ui/lab/TimelineDot';
import TimelineItem from '@material-ui/lab/TimelineItem';
import TimelineSeparator from '@material-ui/lab/TimelineSeparator';
import { makeStyles } from '@material-ui/styles';
import moment from 'moment';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { setPopoutAction } from 'store/app/appActions';
import deepEqual from 'react-fast-compare';

const useStyles = makeStyles((theme) => ({
  timeline: {
    overflowY: 'auto',
    marginLeft: '1rem',

    '& .MuiTimelineDot-root': {
      backgroundColor: theme.palette.background.light,
    },
    '& .MuiTimelineDot-root > .MuiSvgIcon-root': {
      fontSize: '1rem',
    },
    '& .MuiTimelineItem-missingOppositeContent:before': {
      padding: 0,
      flex: 0,
    },
  },
  nameTag: {
    fontSize: '1.3rem',
    textAlign: 'center',
    marginBottom: '0.5rem 0',
  },
  dotDefault: {
    '& > .MuiSvgIcon-root': {
      color: theme.palette.primary.main,
    },
  },
  connectorDefault: {
    width: '4px',
    borderRadius: '4px',
  },
  dotVideoInStreamSTART: {
    '& > .MuiSvgIcon-root': {
      color: 'lime',
    },
  },
  dotVideoInStreamSTOP: {
    '& > .MuiSvgIcon-root': {
      color: 'red',
    },
  },
  dotCameraActiveSTART: {
    '& > .MuiSvgIcon-root': {
      color: 'lime',
    },
  },
  dotCameraActiveSTOP: {
    '& > .MuiSvgIcon-root': {
      color: 'red',
    },
  },
  dotContentShareSTART: {
    '& > .MuiSvgIcon-root': {
      color: 'lime',
    },
  },
  dotContentShareSTOP: {
    '& > .MuiSvgIcon-root': {
      color: 'red',
    },
  },
  dotRaisedHands: {
    '& > .MuiSvgIcon-root': {
      color: 'gold',
    },
  },
  dataTable: {
    border: '1px solid',
    borderColor: theme.palette.primary.main,
    margin: '0 1rem',
    backgroundColor: theme.palette.background.main,

    '& th': {
      borderBottom: '1px solid #222',
    },
    '& td:not(:first-child)': {
      textAlign: 'center',
    },
    '& tbody td:first-child': {
      textAlign: 'right',
    },
    '& tbody tr': {
      borderBottom: '1px solid white',
    },
  },
  timelineItem: {
    minHeight: '4.5rem',
  },
  timelineDate: {
    fontSize: '0.9rem',
    fontWeight: 'bold',
    letterSpacing: '1px',
    textShadow: '1px 1px 2px #0008',
  },
  timelineBox: {
    fontSize: '1rem',
    marginLeft: '0.5rem',
    textShadow: '1px 1px 2px #0008',
  },
  emptyTimeline: {
    margin: '1rem auto',
    padding: '0.5rem 1rem',
    fontWeight: 'bold',
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.secondary.main,
    ...theme.mixins.borderRadius(),
    ...theme.mixins.shadow(),
  },
  backButton: {
    cursor: 'pointer',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    marginLeft: theme.sidePanel.gutter,
    marginTop: '0.5rem',
    alignSelf: 'flex-start',
    fontSize: '1rem',
    '& > *:not(:last-child)': {
      marginRight: '0.5rem',
    },
  },
}));

const timelineStructure = {
  attendenceSTART: {
    content: () => 'Entered the room',
    icon: PersonAddIcon,
  },
  attendenceSTOP: {
    content: () => 'Left the room',
    icon: ExitToAppIcon,
  },
  messages: {
    content: () => 'Sent a chat message',
    icon: ChatIcon,
  },
  questionsAsked: {
    content: () => 'Asked a question',
    icon: HelpIcon,
  },
  questionsAnswered: {
    content: () => 'Answered a question',
    icon: QuestionAnswerIcon,
  },
  questionsVoted: {
    content: () => 'Voted on a question',
    icon: ThumbUpAltIcon,
  },
  videoInStreamSTART: {
    content: () => 'Entered the stream',
    icon: VideoCallIcon,
    dotClass: 'dotVideoInStreamSTART',
  },
  videoInStreamSTOP: {
    content: () => 'Exited the stream',
    icon: VideocamOffIcon,
    dotClass: 'dotVideoInStreamSTOP',
  },
  cameraActiveSTART: {
    content: () => 'Turned camera on',
    icon: VideocamIcon,
    dotClass: 'dotCameraActiveSTART',
  },
  cameraActiveSTOP: {
    content: () => 'Turned camera off',
    icon: VideocamOffIcon,
    dotClass: 'dotCameraActiveSTOP',
  },
  contentShareSTART: {
    content: () => 'Started screen sharing',
    icon: ScreenShareIcon,
    dotClass: 'dotContentShareSTART',
  },
  contentShareSTOP: {
    content: () => 'Stopped screen sharing',
    icon: StopScreenShareIcon,
    dotClass: 'dotContentShareSTOP',
  },
  raisedHands: {
    content: () => 'Raised their hand',
    icon: PanToolIcon,
    dotClass: 'dotRaisedHands',
  },
};

const eventSort = (a, b) => a.timestamp - b.timestamp;

export default function UserAnalytics() {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [timelineEvents, setTimelineEvents] = useState([]);
  const analytics = useSelector(
    (store) => store.analytics.analytics[store.chime.currentAnalyticsUserId] || {},
    deepEqual
  );
  const userId = useSelector((store) => store.chime.currentAnalyticsUserId);
  const appType = useSelector((store) => store.app.appType);

  useEffect(() => {
    let key, val, dataKey, content, tlStruct;

    const events = [];
    const counts = {};

    for (key in analytics) {
      if (key === 'user') continue;

      const { history } = analytics[key];
      if (!history) continue;

      for (val of history) {
        let [historyType, timestamp] = val.toString().includes('#') ? val.split('#') : [null, val];
        timestamp = parseInt(timestamp);
        dataKey = historyType ? key + historyType : key;
        tlStruct = timelineStructure[dataKey] || {};
        content = tlStruct.content ? tlStruct.content(historyType) : `${dataKey} happened`;
        events.push({
          uniqueKey: content + timestamp,
          dataKey,
          content,
          timestamp,
          date: moment(timestamp).format('LT'),
          icon: tlStruct.icon,
          iconStyle: tlStruct.iconStyle,
          dotClass: tlStruct.dotClass,
          dotVariant: tlStruct.dotVariant,
          connectorClass: tlStruct.connectorClass,
        });

        ++counts[dataKey];
      }
    }

    events.sort(eventSort);

    setTimelineEvents(events);
  }, [analytics, userId]);

  const user = analytics?.user;

  const onClickBack = async () => {
    dispatch(
      setPopoutAction({
        popout: {
          open: true,
          name: appType === 'broadcast' ? 'Panelists' : 'Participants',
        },
      })
    );
  };

  return (
    <>
      <div className={classes.backButton} onClick={onClickBack}>
        <ChevronLeftIcon size="small" /> Back
      </div>
      {user && (
        <div className={classes.nameTag}>
          {user.firstName} {user.lastName}
        </div>
      )}

      {timelineEvents.length === 0 && <div className={classes.emptyTimeline}>No events yet</div>}

      <Timeline align="left" className={classes.timeline}>
        {timelineEvents.map((event, index) => (
          <TimelineItem key={event.uniqueKey} className={classes.timelineItem}>
            <TimelineSeparator>
              <TimelineDot
                variant={event.dotVariant || 'outlined'}
                className={classes[event.dotClass || 'dotDefault']}
              >
                {event.icon && <event.icon />}
              </TimelineDot>
              {index < timelineEvents.length - 1 && (
                <TimelineConnector
                  className={classes[event.connectorClass || 'connectorDefault']}
                />
              )}
            </TimelineSeparator>
            <TimelineContent>
              <div className={classes.timelineBox}>
                <div className={classes.timelineDate}>{event.date}</div>
                <div>{event.content}</div>
              </div>
            </TimelineContent>
          </TimelineItem>
        ))}
      </Timeline>
    </>
  );
}
