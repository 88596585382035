import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import { makeStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';

const useStyles = makeStyles(() => ({
  root: {},
  content: {},

  dialogActions: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  actions: {},
}));
Base.propTypes = {
  ModalContent: PropTypes.node.isRequired,
  ModalActions: PropTypes.node.isRequired,
  open: PropTypes.bool.isRequired,
  setOpen: PropTypes.func.isRequired,
};

export default function Base(props) {
  const { ModalContent, ModalActions, open, setOpen } = props;
  const classes = useStyles();
  return (
    <Dialog open={open} onClose={() => setOpen(false)} className={classes.root}>
      <DialogContent className={classes.content}>
        <ModalContent />
      </DialogContent>
      <DialogActions className={classes.dialogActions}>
        <div>
          <Button onClick={() => setOpen(false)}>Close</Button>
        </div>
        <div className={classes.actions}>
          <ModalActions />
        </div>
      </DialogActions>
    </Dialog>
  );
}
