import { createReducer } from '@reduxjs/toolkit';
import * as act from 'store/session-polling/sessionPollingActions';
import { buildActionStatus, standardActions } from 'store/utils';
import { merge, findIndex } from 'lodash';
import { removeOneBy } from 'utils/utilMethods';
import { pollIsRunning } from 'utils/polling';

const initialState = {
  actionStatus: buildActionStatus(act),
  focusedPollId: null,
  unseenPoll: false,
  polls: [],
  templates: [],
  myAnswers: {},
};

const sessionPollingReducer = createReducer(initialState, {
  ...standardActions(act),
  [act.resetStateAction]: () => ({
    ...initialState,
  }),
  [act.setMyAnswersAction]: (state, { payload: { myAnswers } }) => {
    state.myAnswers = myAnswers;
  },
  [act.setMyAnswerAction]: (state, { payload: { pollId, answer } }) => {
    state.myAnswers[pollId] = answer;
  },
  [act.setUnseenPollAction]: (state, { payload: { unseenPoll } }) => {
    state.unseenPoll = unseenPoll;
  },
  [act.setPollsAction]: (state, { payload: { polls, serverTime } }) => {
    state.polls = polls;
    for (const poll of polls) {
      if (pollIsRunning(poll, serverTime)) state.unseenPoll = true;
    }
  },
  [act.setTemplatesAction]: (state, { payload: { polls } }) => {
    state.templates = polls;
  },
  [act.addPollAction]: (state, { payload: { poll, serverTime } }) => {
    if (poll.template) state.templates.push(poll);
    else state.polls.push(poll);

    if (pollIsRunning(poll, serverTime)) state.unseenPoll = true;

    //Check if that poll was started and stop other polls
    let idx = findIndex(state.polls, ['pollId', poll.pollId]);
    if (idx > -1) {
      if (pollIsRunning(state.polls[idx], serverTime)) {
        for (let oldPoll of state.polls) {
          if (poll.pollId !== oldPoll.pollId && pollIsRunning(oldPoll, serverTime)) {
            oldPoll.endedAt = Date.now();
          }
        }
      }
    }
  },
  [act.removePollAction]: (state, { payload: { pollId, template } }) => {
    template
      ? removeOneBy(state.templates, 'pollId', pollId)
      : removeOneBy(state.polls, 'pollId', pollId);
  },
  [act.updatePollAction]: (state, { payload: { pollId, data } }) => {
    if (data.template) {
      let idx = findIndex(state.templates, ['pollId', pollId]);
      if (idx > -1) {
        merge(state.templates[idx], data);
        // lodash merge did not take away old answer if one was updated so set new object if its available
        if (Object.keys(data).includes('answerOptions'))
          state.templates[idx].answerOptions = data.answerOptions;
      }
    } else {
      let idx = findIndex(state.polls, ['pollId', pollId]);
      if (idx > -1) {
        merge(state.polls[idx], data);
        // lodash merge did not take away old answer if one was updated so set new object if its available
        if (Object.keys(data).includes('answerOptions'))
          state.polls[idx].answerOptions = data.answerOptions;
      }
    }
  },
  [act.startPollAction]: (state, { payload: { pollId, startedAt, endedAt, serverTime } }) => {
    let idx = findIndex(state.polls, ['pollId', pollId]);
    if (idx > -1) {
      state.polls[idx].startedAt = Number(startedAt) || 0;
      state.polls[idx].endedAt = Number(endedAt) || 0;
    }
    if (pollIsRunning(state.polls[idx], serverTime)) state.unseenPoll = true;

    // All other polls should end.
    for (let oldPoll of state.polls) {
      if (pollId !== oldPoll.pollId && pollIsRunning(oldPoll, serverTime)) {
        oldPoll.endedAt = Date.now();
      }
    }
  },
  [act.endPollAction]: (state, { payload: { pollId, startedAt, endedAt } }) => {
    let idx = findIndex(state.polls, ['pollId', pollId]);
    if (idx > -1) {
      state.polls[idx].startedAt = Number(startedAt) || 0;
      state.polls[idx].endedAt = Number(endedAt) || 0;
    }
  },
  [act.processPollUpdatesAction]: (state, { payload: { answerUpdates } }) => {
    for (let update of answerUpdates) {
      const [pollId, answer, incrementNumber] = update;

      let idx = findIndex(state.polls, ['pollId', pollId]);
      if (idx > -1) {
        const answerData = state.polls[idx].answerOptions[answer];
        if (answerData) {
          answerData.count += incrementNumber;
        }
      }
    }
  },
  [act.setFocusedPollIdAction]: (state, { payload: { focusedPollId } }) => {
    state.focusedPollId = focusedPollId;
  },
  [act.resetFocusedPollIdAction]: (state) => {
    state.focusedPollId = null;
  },
});

export default sessionPollingReducer;
