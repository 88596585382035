import { basePost, promisedPost } from 'api/apiDetour';

const baseEndpoint = 'session';

const post = async ({ endpoint, data = {}, isSigned = false }) => {
  let resp = await basePost(endpoint, data, baseEndpoint, isSigned);
  return resp;
};

export const joinMeeting = async () => {
  const resp = await post({ endpoint: 'join-meeting' });
  return resp;
};

export const getSession = async () => {
  const resp = await post({ endpoint: 'get' });
  return resp;
};

export const getSessionForEvent = async (sessionId) => {
  const resp = await post({ endpoint: 'get-for-event', data: { sessionId } });
  return resp;
};

export const getSessionbyId = async (sessionId, eventId) => {
  const resp = await post({ endpoint: 'get-by-id', data: { sessionId, eventId } });
  return resp;
};

export const createSession = async () => {
  const resp = await post({ endpoint: 'create' });
  return resp;
};

export const endSession = async () => {
  const resp = await post({ endpoint: 'end' });
  return resp;
};

export const removeUser = async () => {
  const resp = await post({ endpoint: 'remove-user' });
  return resp;
};

export const forceUserLogout = async (accountId, eventId, sessionId, userId) => {
  const resp = await post({
    endpoint: 'force-log-out',
    data: { sessionId, eventId, userId, accountId },
  });
  return resp;
};

export const listSessions = async (status) => {
  const resp = await post({ endpoint: 'list', data: { status } });
  return resp;
};

export const blockUser = async (userId, shouldBlock) => {
  const resp = await post({ endpoint: 'block-user', data: { userId, shouldBlock } });
  return resp;
};

export const kickUser = async (userId) => {
  const resp = await post({ endpoint: 'kick-user', data: { userId } });
  return resp;
};

export const stopUserVideo = async (userId) => {
  const resp = await post({ endpoint: 'stop-user-video', data: { userId } });
  return resp;
};

export const muteUser = async (userId) => {
  const resp = await post({ endpoint: 'mute-user', data: { userId } });
  return resp;
};

export const muteAllUsers = (isMuted, options) => {
  options = options ?? {};
  options.data = { isMuted };
  return promisedPost('mute-all', baseEndpoint, 'masterMute', options);
};

export const setUserInGrid = (userId, inGrid, options) => {
  options = options ?? {};
  options.data = { userId, inGrid };
  return promisedPost('set-in-grid', baseEndpoint, 'grid', options);
};

export const setSelectSpeaker = (userId, options) => {
  options = options ?? {};
  options.data = { userId };
  return promisedPost('update-speaker', baseEndpoint, 'selectSpeaker', options);
};

export const removeAllUsersFromGrid = (options) => {
  options = options ?? {};
  options.removeAllUsersFromGrid = true;
  return promisedPost('remove-all-users-grid', baseEndpoint, 'grid', options);
};

export const featureRequestEmail = async (
  content = 'test content',
  user = 'test user',
  type = 'test type',
  files
) => {
  const resp = await post({
    endpoint: 'feature-request-email',
    data: { content, user, type, files },
  });
  return resp;
};

export const sendLoadTestingRequest = async (type, userIds, payload, randomBroadcastCount) => {
  const resp = await post({
    endpoint: 'load-test-request',
    data: { type, userIds, payload, randomBroadcastCount },
  });
  return resp;
};

export const deleteSessions = async (sessions) => {
  const resp = await post({
    endpoint: 'delete-sessions',
    data: { sessions },
  });
  return resp;
};

export const rogueUserStillExists = () => post({ endpoint: 'rogue-user-still-exists' });

export const updateStageAttendee = (userId, key, value) =>
  post({ endpoint: 'update-stage-attendee-attribute', data: { userId, key, value } });

export const requestToJoinStage = (message) =>
  post({ endpoint: 'request-to-join-stage', data: { message } });

export const removeStageAttendee = (userId) =>
  post({ endpoint: 'remove-stage-attendee', data: { userId } });
