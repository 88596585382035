import {
  click,
  openAndCloseSidebar,
  typeInField,
  wait,
  findQuestionElement,
} from 'components/side-panel/components/testing/helpers';
export default async function ({ subType, payload }) {
  let id;
  let found = false;
  switch (subType) {
    case 'createQuestion':
      await openAndCloseSidebar('questions-icon', async () => {
        typeInField('question-input', payload.question);
        await wait(200);
        click('send-question');
      });
      break;
    case 'upvoteQuestion':
      await openAndCloseSidebar('questions-icon', async () => {
        id = `upvote-question-${payload.questionId}`;
        found = await findQuestionElement(id);
        if (found) {
          await click(id);
        } else {
          console.log('Question Not Found');
        }
      });
      break;
    case 'removeQuestionVote':
      await openAndCloseSidebar('questions-icon', async () => {
        id = `unvote-question-${payload.questionId}`;
        found = await findQuestionElement(id);
        if (found) {
          click(id);
        } else {
          console.log('Question Not Found');
        }
      });
      break;
    case 'deleteQuestion':
      await openAndCloseSidebar('questions-icon', async () => {
        id = `delete-question-${payload.questionId}`;
        found = await findQuestionElement(id);
        if (found) {
          click(id);
        } else {
          console.log('Question Not Found');
        }
      });
      break;
    case 'answerQuestion':
      await openAndCloseSidebar('questions-icon', async () => {
        id = `open-answer-new-${payload.questionId}`;
        found = await findQuestionElement(id);
        if (found) {
          click(id);
          await wait(200);
          typeInField(`question-answer-input-${payload.questionId}`, payload.answer);
          await wait(200);
          click(`save-question-answer-${payload.questionId}`);
        } else {
          console.log('Question Not Found');
        }
      });
      break;
    case 'deleteQuestionAnswer':
      await openAndCloseSidebar('questions-icon', async () => {
        id = `question-existing-answer-${payload.questionId}`;
        found = await findQuestionElement(id);
        if (found) {
          click(id);
          await wait(200);
          click(`delete-question-answer-${payload.questionId}`);
        } else {
          console.log('Question Not Found');
        }
      });
      break;
    case 'editQuestionAnswer':
      await openAndCloseSidebar('questions-icon', async () => {
        id = `question-existing-answer-${payload.questionId}`;
        found = await findQuestionElement(id);
        if (found) {
          click(id);
          await wait(200);
          click(`open-edit-question-answer-${payload.questionId}`);
          await wait(200);
          typeInField(`question-answer-input-${payload.questionId}`, payload.answer);
          await wait(200);
          click(`save-question-answer-${payload.questionId}`);
        } else {
          console.log('Question Not Found');
        }
      });
      break;
    default:
      console.log('No Subtype Found For ', subType, ' In Question');
  }
}
