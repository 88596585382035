import { makeStyles } from '@material-ui/core/styles';
import FilterListIcon from '@material-ui/icons/FilterList';
import PropTypes from 'prop-types';
import BaseMenu from './BaseMenu';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center',
    background: theme.palette.background.light,
    padding: `0.25rem ${theme.sidePanel.gutter}`,
    zIndex: '1',
    marginTop: '-1px',
    borderBottom: `1px solid ${theme.palette.hr.main}`,
    ...theme.mixins.shadow(),

    [theme.breakpoints.downWithLandscape('mobile')]: {
      paddingTop: 0,
      paddingBottom: 0,
      fontSize: '0.7rem',
    },
  },
  filterOn: {
    color: theme.palette.primary.main,
  },
}));

export default function QuestionsMenu({ filtered, sorted, setFiltered, setSorted }) {
  const classes = useStyles();
  const sortedMenuItems = ['Recent', 'Oldest', 'Popular'];
  const answeredMenuItems = ['Show All', 'Answered', 'Not Answered', 'My Questions'];

  const filterListClass =
    filtered !== answeredMenuItems[0] || sorted !== sortedMenuItems[0] ? classes.filterOn : '';

  return (
    <div className={classes.root}>
      <FilterListIcon className={filterListClass} />

      <div style={{ flexGrow: 1 }} />

      <BaseMenu selected={filtered} menuItems={answeredMenuItems} itemClick={setFiltered} />
      <BaseMenu selected={sorted} menuItems={sortedMenuItems} itemClick={setSorted} />
    </div>
  );
}

QuestionsMenu.propTypes = {
  sorted: PropTypes.string.isRequired,
  filtered: PropTypes.string.isRequired,
  setSorted: PropTypes.func.isRequired,
  setFiltered: PropTypes.func.isRequired,
};
