import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import DefaultIcon from './DefaultIcon';
import { getFeatureName } from 'constants/features';
import SvgIcon from '@material-ui/core/SvgIcon';
import { ReactComponent as chatIcon } from './presenter-chat.svg';

function PresenterSvgIcon(props) {
  return <SvgIcon {...props} component={chatIcon} viewBox="0 0 512 512"></SvgIcon>;
}

PresentersChatIcon.propTypes = {
  hideIcon: PropTypes.bool.isRequired,
};

export default function PresentersChatIcon({ hideIcon }) {
  const unreadMessages = useSelector((store) => store.sessionMessage.unreadPresenterMessages);

  return (
    <>
      <DefaultIcon
        IconComp={PresenterSvgIcon}
        badgeContent={unreadMessages}
        buttonId="presenters-chat-icon"
        hideBadge={unreadMessages <= 0}
        hideIcon={hideIcon}
        name={getFeatureName('presenterchat')}
      />
    </>
  );
}
