import { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { switchInput, updateTime, setNextInput } from 'api/routes/sessionResources';
import { useSelector, useDispatch } from 'react-redux';
import { has, throttle } from 'lodash';
import {
  setNextInputAction,
  setVideoTimeSetAction,
} from 'store/session-resource/sessionResourceActions';
import deepEqual from 'react-fast-compare';

StreamVideo.propTypes = {
  videoUrl: PropTypes.string.isRequired,
  loop: PropTypes.bool,
  showControls: PropTypes.bool,
};

export default function StreamVideo({ videoUrl, loop, showControls = false }) {
  const playerRef = useRef(null);
  const [videoError, setVideoError] = useState(false);
  const standby = useSelector((store) => store.event.standby, deepEqual);
  const currentResourceId = useSelector((store) => store.session.currentResourceId);
  const videoTimeSet = useSelector((store) => store.sessionResource.videoTimeSet);
  const nextInput = useSelector(
    (store) => store.sessionResource.nextInput || { name: '', key: '' },
    deepEqual
  );
  const dispatch = useDispatch();

  const switchToNextInput = async () => {
    switch (nextInput.name) {
      case 'Presenters':
        await switchInput(currentResourceId, false, 'Presenters');
        break;
      default:
        await switchInput(currentResourceId, true, nextInput.name, nextInput.key, false);
        break;
    }
    setNextInput({ name: '', key: '' }, currentResourceId);
    dispatch(setNextInputAction({ nextInput: { name: '', key: '' } }));
  };

  const switchToStandby = async () => {
    if (has(standby, 'bucket') && has(standby, 'key')) {
      await switchInput(currentResourceId, true, 'Standby', standby.key, true);
    } else {
      await switchInput(currentResourceId, true, 'Standby', `Standby.mp4`, true);
    }
  };

  const videoEnded = async () => {
    if (!loop) {
      if (has(nextInput, 'name') && nextInput.name != '') {
        await switchToNextInput();
      } else {
        await switchToStandby();
      }
    }
  };

  const timeUpdate = async () => {
    try {
      if (playerRef?.current && !loop) {
        await updateTime(
          Math.floor(playerRef.current.currentTime),
          Math.floor(playerRef.current.duration)
        );
      }
    } catch (e) {
      console.log('error updating time', e);
    }
  };

  useEffect(() => {
    if (playerRef?.current) {
      playerRef.current.ontimeupdate = throttle(timeUpdate, 1000);
    }
  }, [videoUrl]);

  useEffect(() => {
    if (playerRef?.current) {
      playerRef.current.addEventListener('ended', videoEnded);
    }
    return () => {
      if (playerRef?.current) {
        playerRef.current.removeEventListener('ended', videoEnded);
      }
    };
  }, [videoUrl, nextInput, loop]);

  useEffect(() => {
    playerRef.current.loop = loop;
  }, [loop]);

  useEffect(() => {
    if (playerRef.current && videoTimeSet !== null && Number.isInteger(videoTimeSet)) {
      try {
        playerRef.current.currentTime = videoTimeSet;
      } catch (error) {
        console.log('error setting time', error);
      } finally {
        dispatch(setVideoTimeSetAction({ videoTimeSet: null }));
      }
    }
  }, [videoTimeSet]);

  return (
    <div style={{ height: '100%' }}>
      {videoError ? (
        'Failed to load Video.'
      ) : (
        <video
          autoPlay
          ref={playerRef}
          src={videoUrl}
          onError={(err) => {
            console.log(err.target.error);
            setVideoError(true);
          }}
          controls={showControls}
          style={{ width: '100%', height: '100%', maxWidth: '100vw', maxHeight: '100vh' }}
        />
      )}
    </div>
  );
}
