import { useSelector } from 'react-redux';
import { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Metrics from 'components/Metrics';
import DeviceSwitcher from 'components/devices/DeviceSwitcher';
import MasterMuteSwitcher from 'components/devices/MasterMuteSwitcher';
import FeatureSwitcher from 'components/FeatureSwitcher';
import ThemeSwitcher from 'components/themes/ThemeSwitcher';
import { Typography, Switch } from '@material-ui/core';
import MeetingControls from 'components/MeetingControls';
const useStyles = makeStyles((theme) => ({
  statsPanel: {
    backgroundColor: theme.palette.background.light,
    margin: theme.sidePanel.gutter,
    padding: '1rem',
    ...theme.mixins.borderRadius(),
    ...theme.mixins.shadow(),
    ...theme.mixins.flexVerticalList(),
    '& > div': {
      width: '100%',
      alignItems: 'center',
      ...theme.mixins.flexHorizontalList('0.33rem'),
      '& > *:last-child': {
        flexGrow: 1,
      },
    },
  },
}));

export default function Settings() {
  const classes = useStyles();
  const role = useSelector((store) => store.user.role);
  const servicesOnly = useSelector((store) => store.app.servicesOnly);
  const isBroadcastApp = useSelector((store) => store.app.appType === 'broadcast');
  const [showMetrics, setShowMetrics] = useState(false);

  const canManage = role === 'admin' || role === 'presenter';

  return (
    <div>
      {canManage && !isBroadcastApp && !servicesOnly && <MasterMuteSwitcher />}
      {!servicesOnly && <DeviceSwitcher qualityPanel={true} />}
      <ThemeSwitcher />
      {canManage && <FeatureSwitcher />}
      {showMetrics && !servicesOnly && <Metrics />}
      {!servicesOnly && (
        <div className={classes.statsPanel}>
          <div>
            <Switch
              checked={showMetrics}
              onChange={(event) => setShowMetrics(event.target.checked)}
              color="primary"
            />
            <Typography>Show Statistics</Typography>
          </div>
        </div>
      )}
      {canManage && <MeetingControls />}
    </div>
  );
}
