import { DefaultActiveSpeakerPolicy, MeetingSessionStatusCode } from 'amazon-chime-sdk-js';
import { getUser } from 'api/routes/user';
import upperFirst from 'lodash/upperFirst';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { addAnalyticUserAction } from 'store/analytics/analyticsActions';
import * as chimeActions from 'store/chime/chimeActions';
import * as utilActions from 'store/utils/utilActions';
import * as eventActions from 'store/event/eventActions';
import { updateLocalVideoStatus } from 'api/routes/userSession';
import VideoStatus from 'enums/VideoStatus';
import deepEqual from 'react-fast-compare';
import { leaveRoomAction } from 'store/chime/chimeActions';
// import { useHistory } from 'react-router-dom';

export default function AudioVideoInitialization() {
  const dispatch = useDispatch();
  const [deviceChangeObserver, setDeviceChangeObserver] = useState(null);
  const hasJoined = useSelector((store) => store.chime.hasJoined);
  const meetingId = useSelector((store) => store.chime.meetingId);
  const isRecordingUser = useSelector((store) => store.user.isRecordingUser);
  // const reloadUrl = useSelector((store) => store.user.reloadUrl);
  const audioVideo = useSelector((store) => store.chime.audioVideo, deepEqual);
  const isBroadcastApp = useSelector((store) => store.app.appType === 'broadcast');
  const role = useSelector((store) => store.user.role);
  const isPresenter = role === 'presenter' || role === 'admin';
  const isBroadcastAttendee = !isPresenter && isBroadcastApp;

  useEffect(() => {
    const observers = {};
    for (const type of ['audioInput', 'audioOutput', 'videoInput']) {
      observers[`${type}sChanged`] = (freshDeviceList) => {
        dispatch(
          chimeActions.deviceChangedAction({
            deviceList: `${type}Devices`,
            deviceType: `current${upperFirst(type)}`,
            freshDeviceList,
          })
        );
      };
    }
    setDeviceChangeObserver(observers);
  }, []);

  useEffect(() => {
    if (audioVideo && deviceChangeObserver)
      audioVideo.addDeviceChangeObserver(deviceChangeObserver);
    return () => {
      if (audioVideo && deviceChangeObserver) {
        audioVideo.removeDeviceChangeObserver(deviceChangeObserver);
      }
    };
  }, [deviceChangeObserver]);

  useEffect(() => {
    if (hasJoined && meetingId) {
      audioVideo.addObserver({
        audioVideoDidStartConnecting: (reconnecting) => {
          console.log('observer: audioVideoDidStartConnecting: Is reconnecting ', reconnecting);
          dispatch(chimeActions.setReconnectAction({ reconnecting: true }));
          dispatch(chimeActions.reconnectingAction());
        },
        audioVideoDidStart: () => {
          console.log('observer: audioVideoDidStart: Audio Video did start');
          dispatch(chimeActions.setReconnectAction({ reconnecting: false }));
          dispatch(chimeActions.stopReconnectingAction());
        },
        // connectionHealthDidChange: (connectionHealthData) => {
        //   console.log('CONNECTION HEALTH DATA', connectionHealthData);
        // },
        eventDidReceive: (name, attributes) => {
          dispatch(eventActions.collectMeetingEventsAction({ name, attributes }));
        },
        videoTileDidUpdate: (tileState) => {
          if (tileState && tileState.boundExternalUserId && tileState.tileId) {
            dispatch(chimeActions.audioVideoTileDidUpdateAction({ tileState }));
          }
        },
        videoTileWasRemoved: (tileId) =>
          void dispatch(chimeActions.audioVideoTileWasRemovedAction({ tileId })),
        audioVideoDidStop: (sessionStatus) => {
          console.warn(
            'observer: audioVideoDidStop: The Chime Session Recieved an Audio Video did stop call. Status Code:',
            sessionStatus.statusCode()
          );
          dispatch(eventActions.sendMeetingEventsAction());

          if (isBroadcastAttendee) {
            dispatch(chimeActions.setAttendeeInBroadcastAction(false));
            return; //They are switching back to the broadcast
          }
          if (sessionStatus.statusCode() === MeetingSessionStatusCode.MeetingEnded) {
            if (isRecordingUser) {
              // TODO get the ability for the recording to stay on past a chime session
              // history.push(reloadUrl);
              dispatch(leaveRoomAction({ shouldEndSession: true }));
              return;
            }
            if (!isBroadcastAttendee) dispatch(leaveRoomAction({ shouldEndSession: false }));
          } else if (sessionStatus.statusCode() === MeetingSessionStatusCode.SignalingBadRequest) {
            dispatch(
              utilActions.showNotificationAction({
                message: 'You were removed from the meeting.',
                type: 'warning',
              })
            );
            dispatch(leaveRoomAction({ shouldEndSession: false }));
          } else if (sessionStatus.statusCode() === MeetingSessionStatusCode.AudioCallEnded) {
            dispatch(leaveRoomAction({ shouldEndSession: false }));
          } else if (sessionStatus.isTerminal()) {
            dispatch(
              utilActions.showNotificationAction({
                message: sessionStatus.toString(),
                type: 'warning',
              })
            );
            dispatch(leaveRoomAction({ shouldEndSession: false }));
          }
        },
        connectionDidSuggestStopVideo: () => {
          console.log('observer: connectionDidSuggestStopVideo: Suggesting to stop video.');
          dispatch(
            utilActions.showNotificationAction({
              message: 'Poor Connection. Suggest Turning Off Video',
              type: 'warning',
            })
          );
        },
        videoNotReceivingEnoughData: (receivingDataMap) => {
          console.log(`observer: videoNotReceivingEnoughData: ${JSON.stringify(receivingDataMap)}`);
          // if (receivingDataMap && receivingDataMap.length) {
          //   console.log('looping through users to stop video');
          //   receivingDataMap.forEach((usr) => {
          //     console.log('Checking if we should stop user: ', usr);
          //     if (usr.receivedAverageBitrateKbps === 0) {
          //       dispatch(chimeActions.pauseRemoteVideoTileAction({ attendeeId: usr.attendeeId }));
          //     }
          //   });
          // }
        },
        videoSendDidBecomeUnavailable: () => {
          // occurs when attendee tries to start video but the maximum video limit of 16 tiles
          // has already been reached by other attendees sharing their video
          console.log(
            `observer: videoSendDidBecomeUnavailable: User cannot start local video. Video send is not available.`
          );
          dispatch(
            utilActions.showNotificationAction({
              message: 'Cannot add video at this time.',
              type: 'error',
            })
          );
          dispatch(chimeActions.toggleVideoStatusAction(VideoStatus.Disabled));
          updateLocalVideoStatus(VideoStatus.Disabled);
        },
        videoAvailabilityDidChange: (availability) => {
          // occurs video availability state has changed such as whether the attendee can start
          // local video or whether remote video is available. See MeetingSessionVideoAvailability for more information.
          console.log(
            `observer: videoAvailabilityDidChange: User ${
              availability.canStartLocalVideo ? 'can' : "can't"
            } start local video`
          );
          console.log(
            `observer: videoAvailabilityDidChange: There ${
              availability.remoteVideoAvailable ? 'is' : "isn't"
            } remote video available`
          );
        },
        // videoSendHealthDidChange: (bitrateKbps, packetsPerSecond) => {
        //   console.log(
        //     `Video send health changed to ${bitrateKbps}kbps and ${packetsPerSecond} packets per second`
        //   );
        // },
        videoSendBandwidthDidChange: (newBandwidthKbps, oldBandwidthKbps) => {
          console.log(
            `observer: videoSendBandwidthDidChange: Video Bandwidth Changed. New Bandwidth is ${newBandwidthKbps}. Old Bandwidth was ${oldBandwidthKbps}`
          );
        },
        connectionDidBecomeGood: () => {
          console.log('observer: connectionDidBecomeGood: Connection did become good');
          dispatch(chimeActions.setPoorConnectionStatusAction({ isPoor: false }));
        },
        connectionDidBecomePoor: () => {
          console.log('observer: connectionDidBecomePoor: Connection did become poor');
          dispatch(chimeActions.setPoorConnectionStatusAction({ isPoor: true }));
        },
        estimatedDownlinkBandwidthLessThanRequired: (estimatedBandwidth, requiredBandwidth) => {
          console.log(
            `observer: estimatedDownlinkBandwidthLessThanRequired: Bandwidth is less than required. Required: ${requiredBandwidth} Estimated: ${estimatedBandwidth}`
          );
        },
      });

      audioVideo.subscribeToActiveSpeakerDetector(
        new DefaultActiveSpeakerPolicy(),
        (/*attendeeIds*/) => {
          //TODO: figure out why this auto scrolls the chat
          /*const active = {};
          for (let id of attendeeIds) {
            active[id] = true;
          }

          dispatch(setActiveSpeakersAction({ active }));

          if (attendeeIds.length) {
            console.log(`${attendeeIds[0]} is the most active speaker`);
          }*/
        }
      );

      audioVideo.realtimeSubscribeToAttendeeIdPresence(
        //eslint-disable-next-line
        async (presentAttendeeId, present, userId) => {
          const volumeFunction = (attendeeId, volume, muted, signalStrength, userId) => {
            if (
              (muted === true || muted === false) &&
              attendeeId &&
              !attendeeId.endsWith('#content')
            ) {
              dispatch(
                chimeActions.updateRosterAttributeAction({
                  userId,
                  key: 'muted',
                  value: muted,
                })
              );
            }
          };

          if (presentAttendeeId.endsWith('#content')) return;
          if (!present) {
            audioVideo.realtimeUnsubscribeFromVolumeIndicator(presentAttendeeId, volumeFunction);

            dispatch(chimeActions.removeRosterAttendeeAction({ userId }));
          } else if (presentAttendeeId) {
            const { user, sessionUser } = await getUser(userId, true);
            dispatch(
              chimeActions.addRosterAttendeeAction({
                userId,
                value: {
                  name: `${user.firstName} ${user.lastName}`,
                  isRecordingUser: Boolean(user.isRecordingUser),
                  handIsRaised: sessionUser.handIsRaised,
                  videoStatus: sessionUser.videoStatus,
                  userId,
                  attendeeId: presentAttendeeId,
                  muted: true,
                  role: sessionUser.role,
                },
              })
            );
            dispatch(addAnalyticUserAction({ user }));
            audioVideo.realtimeSubscribeToVolumeIndicator(presentAttendeeId, volumeFunction);
          }
        }
      );
      audioVideo.realtimeSubscribeToSetCanUnmuteLocalAudio((canUnmute) => {
        dispatch(chimeActions.setCanUnmuteAudioAction({ canUnmute }));
      });
    }
    dispatch(chimeActions.initializeAction());
    return () => {
      if (audioVideo) {
        audioVideo.realtimeUnsubscribeToAttendeeIdPresence();
        audioVideo.realtimeUnsubscribeToSetCanUnmuteLocalAudio();
      }
    };
    //eslint-disable-next-line
  }, [hasJoined]);

  const sendStats = () => {
    dispatch(eventActions.sendMeetingEventsAction());
  };

  useEffect(() => {
    window.addEventListener('beforeunload', sendStats);

    return () => {
      window.removeEventListener('beforeunload', sendStats);
    };
  }, []);

  return null;
}
