import { makeStyles } from '@material-ui/core/styles';
import PoweredByImage from 'components/PoweredByImage';
import PropTypes from 'prop-types';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100vw',
    ...theme.mixins.vhHeight(100),
    padding: '3rem',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',

    '& > *': {
      margin: '0 1rem',
    },

    '& > img': {
      height: '4rem',
    },

    '& > div > *': {
      color: theme.palette.secondary.main,
    },

    '& > div > * > span': {
      color: theme.palette.primary.main,
    },
  },
}));
WithPowerdBy.propTypes = {
  children: PropTypes.node.isRequired,
};

export default function WithPowerdBy({ children }) {
  const classes = useStyles();
  return (
    <div className={classes.root}>
      <div style={{ marginBottom: '40px' }}>{children}</div>
      <PoweredByImage />
    </div>
  );
}
