import { makeStyles } from '@material-ui/core/styles';
import Tab from '@material-ui/core/Tab';
import Tabs from '@material-ui/core/Tabs';
import { getEvent } from 'api/routes/event';
import { getVods } from 'api/routes/vod';
import { getAllPolls, getPollTemplates } from 'api/routes/sessionPolls';
import { setPollsAction, setTemplatesAction } from 'store/session-polling/sessionPollingActions';
import LoadingPage from 'components/LoadingPage';
import SiteHeader from 'components/SiteHeader';
// import Typography from '@material-ui/core/Typography';
import last from 'lodash/last';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { setEventAction, setVodsAction } from 'store/event/eventActions';
import FileUpload from './components/FileUpload';
import FeatureSwitcher from 'components/FeatureSwitcher';
import RecordingsTab from './tabs/recordings';
import MediaTab from './tabs/media';
import PollsTab from './tabs/polls';
import get from 'lodash/get';

export const useStyles = makeStyles((theme) => ({
  wrapper: {
    backgroundColor: theme.palette.background.dark,
    ...theme.mixins.vhMinHeight(100),
  },
  nameChanger: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    '& h5': {
      fontWeight: '800',
    },
  },
}));

const tabSettings = {
  meeting: [
    { name: 'Meeting Features', comp: FeatureSwitcher },
    { name: 'Meeting Recordings', comp: RecordingsTab },
    { name: 'Polling', comp: PollsTab },
  ],
  vod: [{ name: 'Video Manager', comp: RecordingsTab }],
  broadcast: [
    { name: 'Broadcast Features', comp: FeatureSwitcher },
    { name: 'Broadcast Media', comp: MediaTab },
    { name: 'Broadcast Recordings', comp: RecordingsTab },
    { name: 'Polling', comp: PollsTab },
  ],
};

export default function Index() {
  const [loadingEvent, setLoadingEvent] = useState(true);
  const [uploadOpen, setUploadOpen] = useState(false);
  const dispatch = useDispatch();
  const classes = useStyles();
  const settingsType = last(useLocation().pathname.split('/')); // broadcast | vod | meeting
  const [tab, setTab] = useState(0);
  const tabs = tabSettings[settingsType];
  const tabName = get(tabs, `[${tab}].name`, '');
  const TabComponent = get(tabs, `[${tab}].comp`, <></>);
  // const title = useSelector((store) => store.event.title);
  const initTokenLoading = useSelector(
    (store) => store.actionStatus?.initializeTokenFlowSaga?.loading
  );
  const initTokenError = useSelector((store) => store.actionStatus?.initializeTokenFlowSaga?.error);

  useEffect(() => {
    const getEventData = async () => {
      setLoadingEvent(true);

      const { event, error: eventError } = await getEvent();
      if (eventError) throw new Error(eventError.message);
      dispatch(setEventAction({ ...event }));

      const response = await getVods();
      dispatch(
        setVodsAction({
          vods: response.vods,
        })
      );

      const { polls, serverTime } = await getAllPolls();
      dispatch(setPollsAction({ polls, serverTime }));

      const { polls: templates } = await getPollTemplates();
      dispatch(setTemplatesAction({ polls: templates }));

      setLoadingEvent(false);
    };
    getEventData();
  }, []); // eslint-disable-line

  if (initTokenLoading || loadingEvent) return <LoadingPage>Loading settings...</LoadingPage>;
  if (initTokenError) return null;

  const closeUploadModal = () => void setUploadOpen(false);

  return (
    <div className={classes.wrapper}>
      <SiteHeader>
        <Tabs
          value={Number(tab)}
          onChange={(event, newTab) => void setTab(newTab)}
          indicatorColor="primary"
          textColor="secondary"
        >
          {tabs.map(({ name }) => (
            <Tab key={name} label={name} />
          ))}
        </Tabs>
      </SiteHeader>
      {/* <div className={classes.nameChanger}>
        <Typography variant="h5">{title}</Typography>
      </div> */}
      <TabComponent tabName={tabName} setUploadOpen={setUploadOpen} fromSettings={true} />
      <FileUpload
        open={uploadOpen}
        onClose={closeUploadModal}
        isVodUpload={['Video Manager', 'Meeting Recordings', 'Broadcast Recordings'].includes(
          tabName
        )}
      />
    </div>
  );
}
