import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import Collapse from '@material-ui/core/Collapse';
import IconButton from '@material-ui/core/IconButton';
import InputBase from '@material-ui/core/InputBase';
import Typography from '@material-ui/core/Typography';
import CheckIcon from '@material-ui/icons/Check';
import CloseIcon from '@material-ui/icons/Close';
import CreateIcon from '@material-ui/icons/Create';
import DeleteIcon from '@material-ui/icons/Delete';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ThumbUpIcon from '@material-ui/icons/ThumbUp';
import ThumbUpOutlinedIcon from '@material-ui/icons/ThumbUpOutlined';
import clsx from 'clsx';
import PropTypes from 'prop-types';
//import { findIndex } from 'lodash';
import { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { hideQuestion, updateQuestionVotes, answerQuestion } from 'api/routes/sessionQuestion';
import {
  updateQuestionAction,
  setQuestionAnswerAction,
  setQuestionHiddenAction,
} from 'store/session-question/sessionQuestionActions';
import moment from 'moment';
//import BlockUserIcon from './BlockUserIcon';
import useStyles from './Question.style';
import { useLatestPendingRequest } from 'hooks/usePendingRequests';
import { showGenericErrorNotification } from 'utils/utilMethods';

Question.propTypes = {
  answer: PropTypes.string,
  bypassNextIfScrolled: PropTypes.func.isRequired,
  didLocalUserVote: PropTypes.bool,
  getUpdates: PropTypes.func.isRequired,
  hidden: PropTypes.bool,
  question: PropTypes.string,
  questionId: PropTypes.string.isRequired,
  questionUserId: PropTypes.string,
  style: PropTypes.object,
  timeAsked: PropTypes.number.isRequired,
  userName: PropTypes.string,
  voteCount: PropTypes.number,
};
Question.defaultProps = {
  voteCount: 0,
};

export default function Question({
  answer,
  bypassNextIfScrolled,
  didLocalUserVote,
  getUpdates,
  hidden,
  question,
  questionId,
  questionUserId,
  style,
  timeAsked,
  userName,
  voteCount,
}) {
  const dispatch = useDispatch();
  const classes = useStyles();
  const [expanded, setExpanded] = useState(false);
  const [answering, setAnswering] = useState(false);
  const [answerText, setAnswerText] = useState('');
  const [showSpinner, setShowSpinner] = useState({
    show: false,
    type: '',
    isCurrentlyBlocked: null,
  });
  const answerInput = useRef(null);
  const userId = useSelector((store) => store.user.userId);
  const canManage = useSelector((store) => ['presenter', 'admin'].includes(store.user.role));
  const thisUserIsBlocked = useSelector((store) =>
    (store.session.blockedUsers || []).includes(questionUserId)
  );
  //const appType = useSelector((store) => store.app.appType);
  const latestPendingUpvote = useLatestPendingRequest('questionUpvote', 'questionId', questionId);
  const latestPendingAnswerChange = useLatestPendingRequest(
    'questionAnswer',
    'questionId',
    questionId
  );

  if (latestPendingUpvote) {
    if (latestPendingUpvote.data.updateType === 'ADD') {
      // Process of adding
      didLocalUserVote = true;
      ++voteCount;
    } else {
      // Process of deleting
      didLocalUserVote = false;
      --voteCount;
    }
  }

  if (latestPendingAnswerChange) {
    answer = latestPendingAnswerChange.data.answer;
  }

  const isMyQuestion = userId === questionUserId;
  const canAnswer = canManage && !hidden;

  useEffect(() => {
    if (thisUserIsBlocked && showSpinner.show && showSpinner.type === 'block') {
      setShowSpinner({ show: false, type: '' });
    }
    //eslint-disable-next-line
  }, [thisUserIsBlocked]);

  const handleUpvote = () => {
    bypassNextIfScrolled();

    updateQuestionVotes(questionId, !didLocalUserVote ? 'ADD' : 'DELETE', {
      retries: 2,
      onSuccess(response) {
        dispatch(
          updateQuestionAction({
            questionId,
            data: response.data.question,
          })
        );
      },
      onError(error, reason, retriesLeft) {
        if (retriesLeft === 0) {
          showGenericErrorNotification('voting for question');
        }
      },
    });

    getUpdates();
  };

  const unCollapse = () => {
    setExpanded(false);
    setAnswering(false);
  };

  const startEditing = () => {
    if (answering) return;
    setAnswerText(answer);
    setAnswering(true);
  };

  const cancelAnswerEdit = () => {
    setAnswerText('');
    setAnswering(false);
  };

  const saveAnswer = () => {
    bypassNextIfScrolled();

    answerQuestion(questionId, answerText, {
      retries: 2,
      onSuccess(response) {
        dispatch(setQuestionAnswerAction({ questionId, answer: response.data.answer }));
      },
      onError(error, reason, retriesLeft) {
        if (retriesLeft === 0) {
          showGenericErrorNotification('answering question');
        }
      },
    });

    setAnswerText('');
    unCollapse();
    getUpdates();
  };

  const deleteAnswer = () => {
    setAnswerText('');
    saveAnswer();
  };

  const answerEditOnKeyDown = (event) => {
    if (event.keyCode === 13) {
      event.preventDefault();
      saveAnswer();
    }
  };

  const deleteQuestion = () => {
    bypassNextIfScrolled();

    hideQuestion(questionId, {
      retries: 2,
      isMyQuestion,
      onSuccess() {
        dispatch(
          setQuestionHiddenAction({
            questionId,
            hidden: true,
          })
        );
      },
      onError(error, reason, retriesLeft) {
        if (retriesLeft === 0) {
          showGenericErrorNotification('deleting question');
        }
      },
    });

    getUpdates();
    unCollapse();
  };

  const VoteIconToUse = didLocalUserVote && !hidden ? ThumbUpIcon : ThumbUpOutlinedIcon;

  const uncollapsed = (expanded && !!answer) || answering; // eslint-disable-line
  return (
    <div className={classes.wrapper} style={style}>
      <div className={classes.root} data-hidden={hidden ? 1 : 0}>
        <div className={classes.header}>
          <Typography className={classes.userName}>{userName}</Typography>
          <Typography className={classes.time}>{moment(timeAsked).format('hh:mm a')}</Typography>
          <Typography className={classes.voteCount} data-self-voted={didLocalUserVote ? 1 : 0}>
            {voteCount}
          </Typography>
          <IconButton
            aria-label="up vote"
            id={`${didLocalUserVote ? 'un' : 'up'}vote-question-${questionId}`}
            disabled={hidden || !!latestPendingUpvote}
            onClick={handleUpvote}
            name={`${didLocalUserVote ? 'voted' : 'unvoted'}-upvote-question`}
          >
            <VoteIconToUse
              className={classes.voteIcon}
              data-self-voted={didLocalUserVote ? 1 : 0}
            />
          </IconButton>
        </div>

        <p className={classes.question}>{question}</p>

        <div className={classes.buttons}>
          {(isMyQuestion || canManage) && (
            <>
              {showSpinner.show ? (
                <CircularProgress size={10} />
              ) : (
                <div style={{ textAlign: 'left' }}>
                  <IconButton
                    id={`delete-question-${questionId}`}
                    aria-label="delete question"
                    onClick={() => {
                      deleteQuestion();
                    }}
                  >
                    <DeleteIcon className={classes.deleteIcon} />
                  </IconButton>
                  {/*canManage && appType === 'broadcast' && (
                    <BlockUserIcon
                      setShowSpinner={setShowSpinner}
                      questionUserId={questionUserId}
                    />
                  )*/}
                </div>
              )}
            </>
          )}
          {answer && (
            <IconButton
              className={clsx(classes.expand, {
                [classes.expandOpen]: expanded,
              })}
              onClick={() => setExpanded(!expanded)}
              aria-expanded={expanded}
              aria-label="show more"
              ref={answerInput}
              id={`question-existing-answer-${questionId}`}
            >
              Answered
              <ExpandMoreIcon />
            </IconButton>
          )}

          {canAnswer && !answer && (
            <Button
              className={classes.answerButton}
              data-answering={answering ? 1 : 0}
              onClick={startEditing}
              id={`open-answer-new-${questionId}`}
            >
              Answer
            </Button>
          )}
        </div>

        <Collapse in={uncollapsed} timeout="auto" unmountOnExit>
          <ClickAwayListener onClickAway={unCollapse}>
            <div className={classes.answerArea}>
              {answering && (
                <InputBase
                  id={`question-answer-input-${questionId}`}
                  fullWidth
                  multiline={true}
                  rowsMax={3}
                  className={classes.answerInput}
                  value={answerText}
                  data-testid="answertext"
                  onChange={(event) => setAnswerText(event.target.value)}
                  onKeyDown={answerEditOnKeyDown}
                />
              )}

              <div className={classes.answerContainer}>
                {canManage && (
                  <IconButton
                    aria-label="delete answer"
                    id={`delete-question-answer-${questionId}`}
                    onClick={deleteAnswer}
                  >
                    <DeleteIcon className={classes.deleteIcon} />
                  </IconButton>
                )}

                {answering ? (
                  <>
                    <div style={{ flexGrow: 1 }} />

                    <IconButton
                      aria-label="cancel changing answer"
                      className={classes.cancelButton}
                      onClick={cancelAnswerEdit}
                    >
                      <CloseIcon className={classes.cancelIcon} />
                    </IconButton>

                    <IconButton
                      aria-label="save answer"
                      id={`save-question-answer-${questionId}`}
                      onClick={saveAnswer}
                    >
                      <CheckIcon className={classes.saveIcon} />
                    </IconButton>
                  </>
                ) : (
                  <>
                    <p className={classes.answerText}>{answer}</p>

                    <IconButton
                      aria-label="cancel editing answer"
                      className={classes.cancelButton}
                      onClick={unCollapse}
                    >
                      <CloseIcon className={classes.cancelIcon} />
                    </IconButton>

                    {canManage && (
                      <IconButton
                        aria-label="edit answer"
                        id={`open-edit-question-answer-${questionId}`}
                        onClick={startEditing}
                      >
                        <CreateIcon className={classes.editIcon} />
                      </IconButton>
                    )}
                  </>
                )}
              </div>
            </div>
          </ClickAwayListener>
        </Collapse>
      </div>
    </div>
  );
}
