import { createMuiTheme, emphasize, makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme) => ({
  page: {
    padding: '0 2rem',
    width: '60rem',
    maxWidth: '100vw',
    margin: '0 auto',
    marginTop: '1rem',

    '& > h2:first-child': {
      width: '100%',
      margin: 0,
      fontSize: '1.1rem',
      fontWeight: 'bold',
      '&::after': {
        content: '""',
        display: 'block',
        width: '100%',
        height: '0.33rem',
        margin: '0.5rem 0',
        backgroundColor: theme.palette.primary.main,
      },
    },

    '& .MuiFormLabel-root': {
      fontWeight: 'bold',
    },
  },
  textField: {
    '& > div > input': {
      padding: '0.5em 1em',
    },
  },
  infoArea: {
    width: '100%',
    padding: '1rem',
    backgroundColor: theme.palette.background.dark,
    ...theme.mixins.borderRadius(),
    ...theme.mixins.shadow(),
    '& > *': {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      '& > *:first-child': {
        fontWeight: 'bold',
        textDecoration: 'underline',
        fontSize: '110%',
        marginRight: '3rem',
      },
      '& > *:last-child': {},
    },
  },
  fileTableWrapper: {
    marginTop: '1rem',
    width: '100%',
  },
  vodWrapper: {
    marginTop: '1rem',
  },
  standbyControl: {
    width: '100%',
    marginBottom: '3rem',
  },
  modal: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',

    '& > .wrapper': {
      display: 'flex',
      flexDirection: 'column',
      minWidth: '45rem',
      maxWidth: '95vw',
      minHeight: '30rem',
      ...theme.mixins.vhMaxHeight(95),
      backgroundColor: theme.palette.background.modal,
      ...theme.mixins.borderRadius(),
      ...theme.mixins.shadow(),

      '& > .header': {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        height: '4rem',
        borderBottom: '1px solid',
        borderBottomColor: emphasize(theme.palette.background.modal, 0.33),
        marginBottom: '3rem',
        padding: '1rem',

        '& > .title': {
          fontSize: '1.1rem',
          fontWeight: 'bold',
        },
      },

      '& > .body': {
        display: 'flex',
        flexDirection: 'column',
        padding: '1rem 6rem',

        '& > *:not(:first-child)': {
          marginTop: '1rem',
        },
      },

      '& > .footer': {
        display: 'flex',
        justifyContent: 'flex-end',
        flexGrow: 1,
        alignItems: 'flex-end',
        marginTop: '3rem',
        padding: '1rem 6rem',
        '& > *:not(:last-child)': {
          marginRight: '1rem',
        },
      },
    },
  },
  uploadArea: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
    cursor: 'pointer',
    padding: '1rem',
    '& > .MuiSvgIcon-root': {
      fontSize: '12rem',
      color: emphasize(theme.palette.background.modal, 0.3),
    },
  },
  uploadState: {
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'column',
    alignItems: 'center',
    '& > .MuiSvgIcon-root': {
      fontSize: '2rem',
    },
    '& > .fileName': {
      fontSize: 'bold',
      marginBottom: '0.5rem',
    },
    '& > .success': {
      fontWeight: 'bold',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    },
    '& > .MuiLinearProgress-root': {
      width: '60%',
    },
  },
  previewModal: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: '100%',
  },
  previewVideoWrapper: {
    width: '70%',
    background: theme.palette.background.dark,
    border: '1px solid',
    borderColor: theme.palette.background.light,
    overflow: 'hidden',
    padding: '3rem',
    outline: '0',
  },
  editNameWrapper: {
    width: '400px',
    background: theme.palette.background.dark,
    border: '1px solid',
    borderColor: theme.palette.background.light,
    overflow: 'hidden',
    padding: '1rem',
    outline: '0',
  },
  editModalBottom: {
    // margin: '1rem',
    display: 'flex',
    justifyContent: 'space-between',
  },
}));

export const getDataTableTheme = (theme) =>
  createMuiTheme({
    palette: theme.palette,
    overrides: {
      ...theme.overrides,
      MuiPaper: {
        root: {},
      },
      MUIDataTable: {
        paper: {
          backgroundColor: 'transparent',
        },
        responsiveBase: {
          overflow: 'hidden',
        },
      },
      MUIDataTableHeadRow: {
        root: {
          backgroundColor: 'transparent',
        },
      },
      MUIDataTableToolbar: {
        root: {
          padding: 0,
        },
      },
      MUIDataTableSelectCell: {
        headerCell: {
          backgroundColor: 'transparent',
        },
        root: {
          border: 0,
        },
        // checkbox: {
        //   root: {},
        // },
      },
      MUIDataTableBodyCell: {
        root: {
          border: 0,
          fontWeight: 300,
          '&:nth-child(n+3)': {
            textAlign: 'center',
          },
        },
      },
      MUIDataTableHeadCell: {
        root: {
          border: 0,
          fontWeight: 'bold',
          backgroundColor: 'transparent',
          '&:nth-child(n+3) > span': {
            justifyContent: 'center',
          },
        },
        fixedHeader: {
          backgroundColor: 'transparent',
          '&:nth-child(n+3) > div': {
            textAlign: 'center',
          },
        },
        sortActive: {},
      },
      MUIDataTablePagination: {
        tableCellContainer: {
          border: 0,
        },
      },
    },
  });
