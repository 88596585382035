import { List, Tooltip } from '@material-ui/core';
import ChatIcon from 'components/side-menu/components/ChatIcon';
import CopyLinkButton from 'components/side-menu/components/CopyLinkButton';
import ExitButton from 'components/side-menu/components/ExitButton';
import PollIcon from 'components/side-menu/components/PollIcon';
import PresenterDevices from 'components/side-menu/components/PresenterDevices';
import PresentersChatIcon from 'components/side-menu/components/PresentersChatIcon';
import ParticipantsIcon from 'components/side-menu/components/ParticipantsIcon';
import LeaderBoardIcon from 'components/side-menu/components/LeaderBoardIcon';
import QuestionAnswerIcon from 'components/side-menu/components/QuestionAnswerIcon';
import RaiseHand from 'components/side-menu/components/RaisedHand';
import SettingsIcon from 'components/side-menu/components/SettingsIcon';
import StudioIcon from 'components/side-menu/components/StudioIcon';
import TestingIcon from 'components/side-menu/components/TestingIcon';
import TheStageIcon from 'components/side-menu/components/TheStageIcon';
import config from 'config';
import { useSelector } from 'react-redux';
import WarningIcon from '@material-ui/icons/Warning';
import deepEqual from 'react-fast-compare';
import BlockIcon from '@material-ui/icons/Block';
import { ParticipantsIcon as PeopleCountIcon } from 'svg';
import useStyles from './index.style.js';

const { STAGE } = config;

const SideMenu = () => {
  const classes = useStyles();
  const role = useSelector((store) => store.user.role);
  const status = useSelector((store) => store.session.status);
  const connectionIsPoor = useSelector((store) => store.chime.connectionIsPoor);
  const isWebsocketConnected = useSelector((store) => store.session.isWebsocketConnected);
  const usersInSession = useSelector((store) => store.session.usersInSession, deepEqual);
  const isBroadcastApp = useSelector((store) => store.app.appType === 'broadcast');
  const stageAttendees = useSelector((store) => store.session.stageAttendees || {}, deepEqual);
  const userId = useSelector((store) => store.user.userId);
  const attendeeInBroadcast = stageAttendees[userId]?.currentSpeaker === true;
  const isPresenter = role === 'presenter' || role === 'admin';
  const isBroadcastPresenter = isPresenter && isBroadcastApp;
  const isBroadcastAttendee = !isPresenter && isBroadcastApp;
  const isMeetingAttendee = !isPresenter && !isBroadcastApp;
  const servicesOnly = useSelector((store) => store.app.servicesOnly);
  const hasVideoControls = isPresenter || isMeetingAttendee || attendeeInBroadcast;
  const showSidebar = !(isBroadcastAttendee && status !== 'LIVE');
  const appType = useSelector((store) => store.app.appType);

  return (
    showSidebar && (
      <div className={classes.sideMenu}>
        <List className={classes.buttons}>
          <QuestionAnswerIcon />
          <PresentersChatIcon hideIcon={!isPresenter} />
          <ParticipantsIcon hideIcon={isBroadcastAttendee} />
          <ChatIcon hideIcon={false} />
          <PollIcon hideIcon={false} />
          <LeaderBoardIcon hideIcon={!isPresenter || isBroadcastApp} />
          <RaiseHand hideIcon={!isMeetingAttendee || servicesOnly} />
          <StudioIcon hideIcon={!isBroadcastPresenter} />
          <TheStageIcon hideIcon={!config.SHOW_STAGE || appType !== 'broadcast'} />
          <SettingsIcon hideIcon={isBroadcastApp && !isPresenter} />
          <TestingIcon hideIcon={!isPresenter || !['develop', 'beta'].includes(STAGE)} />
          <CopyLinkButton />
        </List>

        {connectionIsPoor && (
          <Tooltip title="Meeting Connection Is Poor" placement="left">
            <div className={classes.warningSignal}>
              <WarningIcon />
            </div>
          </Tooltip>
        )}

        {!isWebsocketConnected && (
          <Tooltip title="Messaging Connection Lost" placement="left">
            <div className={classes.warningSignal}>
              <BlockIcon />
            </div>
          </Tooltip>
        )}

        <Tooltip title="User count" placement="left">
          <div className={classes.userCount}>
            <PeopleCountIcon style={{ height: '.85rem' }} />
            <span>{usersInSession}</span>
          </div>
        </Tooltip>

        <div className={classes.deviceContainer}>
          {hasVideoControls && <PresenterDevices isStageAttendee={attendeeInBroadcast} />}
          <ExitButton />
        </div>
      </div>
    )
  );
};

export default SideMenu;
