import { makeStyles } from '@material-ui/core/styles';
import { requestToJoinStage } from 'api/routes/session';
import { Typography, TextField } from '@material-ui/core';
import { PrimaryButton } from './muiComponents';
import PropTypes from 'prop-types';
import { StageIcon } from 'svg';
import { useSelector, useDispatch } from 'react-redux';
import { setQueueQuestionTextAction } from 'store/session/sessionActions';

AttendeeRequest.propTypes = {
  pendingRequest: PropTypes.bool,
};

const useStyles = makeStyles(() => ({
  messageCaption: {
    display: 'flex',
    justifyContent: 'space-between',
    padding: '0 5px',
  },
  ApprovalContainer: {
    marginTop: '2rem',
    display: 'flex',
    justifyContent: 'center',
  },
}));

export default function AttendeeRequest({ pendingRequest }) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const message = useSelector((store) => store.session.queueQuestionText);

  const requestToJoin = async () => {
    await requestToJoinStage(message);
  };

  const handleMessage = (e) => {
    dispatch(setQueueQuestionTextAction({ text: e.target.value }));
  };

  return (
    <>
      <div style={{ display: 'flex', flexDirection: 'column' }}>
        <StageIcon />
        <Typography variant="h6">Request to speak</Typography>
        <Typography variant="body1">
          {!pendingRequest
            ? 'Ask the presenters of the meeting to be invited onto stage to ask a question.'
            : 'Your request has been sent. You will be notified here of approval status.'}
        </Typography>
        <br />
        <TextField
          multiline
          onChange={handleMessage}
          value={message}
          label="Describe your question."
          variant="outlined"
          disabled={pendingRequest}
          inputProps={{ maxLength: 256 }}
        />
        <div className={classes.messageCaption}>
          <Typography variant="caption">Optional</Typography>
          <Typography variant="caption" color={message.length < 256 ? 'inherit' : 'error'}>
            {message.length} / 256{' '}
          </Typography>
        </div>
        <div className={classes.ApprovalContainer}>
          <PrimaryButton
            id="ask-question"
            onClick={() => requestToJoin()}
            disabled={pendingRequest}
            style={{ width: pendingRequest ? 'inherit' : '100%', padding: '1rem' }}
          >
            {!pendingRequest ? 'Send Request' : 'Approval Pending'}
          </PrimaryButton>
        </div>
      </div>
    </>
  );
}
