import { IconButton, Button, TextField } from '@material-ui/core';
import { useState } from 'react';
import { useDispatch } from 'react-redux';
import CancelIcon from '@material-ui/icons/Cancel';
import { setEventAction } from 'store/event/eventActions';
import { updateVideoName } from 'api/routes/event';
import PropTypes from 'prop-types';

MediaEditModal.propTypes = {
  video: PropTypes.object,
  onClose: PropTypes.func.isRequired,
};

export default function MediaEditModal({ video, onClose }) {
  const dispatch = useDispatch();
  const [currentFileName, setCurrentFileName] = useState('');

  const handleRenameVideo = async () => {
    const updatedEvent = await updateVideoName(currentFileName, video.key);
    dispatch(
      setEventAction({
        eventId: updatedEvent.event.eventId,
        title: updatedEvent.event.title,
        description: updatedEvent.event.description,
        videos: updatedEvent.event.videos,
        standby: updatedEvent.event.standby,
        blockedUsers: updatedEvent.event.blockedUsers,
      })
    );

    onClose();
  };

  return (
    <div className="wrapper">
      <div className="header">
        <div className="title">Edit</div>
        <IconButton onClick={onClose}>
          <CancelIcon />
        </IconButton>
      </div>

      <div className="body">
        <TextField
          style={{ width: '100%' }}
          value={currentFileName}
          placeholder={video.name}
          onChange={(e) => setCurrentFileName(e.target.value)}
          label="Rename Video"
          variant="outlined"
          size="small"
          autoFocus
        />
      </div>
      <div className="footer">
        <Button variant="outlined" color="primary" onClick={onClose}>
          Cancel
        </Button>

        <Button variant="contained" color="primary" onClick={handleRenameVideo}>
          Save
        </Button>
      </div>
    </div>
  );
}
