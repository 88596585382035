import { createQuestion } from 'api/routes/sessionQuestion';
import { select, takeEvery } from 'redux-saga/effects';
import { putBlockedUserAction } from 'store/session/sessionActions';
import { call, put } from 'store/utils';
import { getFeatureName } from 'constants/features';
import {
  initAction,
  successAction,
  errorAction,
  putQuestionAction,
  askQuestionAction,
  deleteQuestionAction,
  addUnreadQuestionAction,
  removeUnreadQuestionAction,
  updateQuestionAction,
  setQuestionHiddenAction,
} from 'store/session-question/sessionQuestionActions';

function* askQuestionFlowSaga(action) {
  const key = 'askQuestionFlowSaga';
  yield put(initAction({ key }));
  try {
    const userId = yield select((state) => state.user.userId);

    const payloadQuestion = action.payload.question;

    const data = yield call(createQuestion, payloadQuestion);
    if (data.error) throw Error(data.error.message);

    if (data.blocked) {
      yield put(putBlockedUserAction({ userId }));
    } else {
      const { question } = data;
      question.voteCount = question.voteCount ?? 0;
      question.didLocalUserVote = question.didLocalUserVote ?? false;

      yield put(putQuestionAction(question));
    }
    yield put(successAction({ key }));
  } catch (error) {
    yield put(errorAction({ key, error }));
  }
}

export function* putQuestionFlowSaga(action) {
  const key = 'putQuestionFlowSaga';
  yield put(initAction({ key }));

  try {
    const { questionId } = action.payload;

    const popout = yield select((state) => state.app.popout);
    if (!popout.open || popout.name !== getFeatureName('questions')) {
      const notiOpen = yield select((state) => state.utils.open);
      if (!notiOpen) {
        yield put(addUnreadQuestionAction({ questionId }));
      }
    }

    yield put(successAction({ key }));
  } catch (error) {
    yield put(errorAction({ key, error }));
  }
}

export function* deleteQuestionFlowSaga(action) {
  const key = 'deleteQuestionFlowSaga';
  yield put(initAction({ key }));

  try {
    const { questionId } = action.payload;

    yield put(removeUnreadQuestionAction({ questionId }));

    yield put(successAction({ key }));
  } catch (error) {
    yield put(errorAction({ key, error }));
  }
}

export function* updateQuestionFlowSaga(action) {
  const key = 'updateQuestionFlowSaga';
  yield put(initAction({ key }));

  try {
    const { questionId, data } = action.payload;

    if (data.hidden) {
      yield put(removeUnreadQuestionAction({ questionId }));
    }

    yield put(successAction({ key }));
  } catch (error) {
    yield put(errorAction({ key, error }));
  }
}

export function* setQuestionHiddenFlowSaga(action) {
  const key = 'setQuestionHiddenFlowSaga';
  yield put(initAction({ key }));

  try {
    const { questionId, hidden } = action.payload;

    if (hidden) {
      yield put(removeUnreadQuestionAction({ questionId }));
    }

    yield put(successAction({ key }));
  } catch (error) {
    yield put(errorAction({ key, error }));
  }
}

export default function* sessionQuestionWatcher() {
  yield takeEvery(askQuestionAction().type, askQuestionFlowSaga);
  yield takeEvery(putQuestionAction().type, putQuestionFlowSaga);
  yield takeEvery(deleteQuestionAction().type, deleteQuestionFlowSaga);
  yield takeEvery(updateQuestionAction().type, updateQuestionFlowSaga);
  yield takeEvery(setQuestionHiddenAction().type, setQuestionHiddenFlowSaga);
}
