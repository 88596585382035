import { ButtonGroup } from '@material-ui/core';
import { makeStyles, emphasize } from '@material-ui/core/styles';
import PropTypes from 'prop-types';

const useStyles = makeStyles((theme) => ({
  buttonStrip: {
    width: '100%',
    display: 'flex',

    '& > *': {
      flexGrow: 1,
      minHeight: '3rem',
      boxShadow: '0 0 transparent',
      textTransform: 'none',
      borderRadius: 0,
      borderStyle: 'solid',
      borderColor: emphasize(theme.palette.background.paper),
      borderTopWidth: 1,
      borderBottomWidth: 1,

      '&:not(:first-child)': {
        borderLeftWidth: 1,
      },
    },
  },
}));

ButtonStrip.propTypes = {
  children: PropTypes.node,
  extraStyles: PropTypes.string,
};

export default function ButtonStrip({ children, extraStyles }) {
  const classes = useStyles();

  return (
    <ButtonGroup
      color="primary"
      className={`${classes.buttonStrip} ${extraStyles ? extraStyles : ''}`}
    >
      {children}
    </ButtonGroup>
  );
}
