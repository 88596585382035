import {
  click,
  openAndCloseSidebar,
  typeInField,
  wait,
} from 'components/side-panel/components/testing/helpers';

export default async function ({ subType, payload }) {
  switch (subType) {
    case 'sendMessage':
      await openAndCloseSidebar('chat-icon', async () => {
        typeInField('chat-input', payload.message);
        await wait(200);
        click('chat-send');
      });
      break;
    case 'sendPresentersMessage':
      await openAndCloseSidebar('presenters-chat-icon', async () => {
        typeInField('chat-input', payload.message);
        await wait(200);
        click('chat-send');
      });
      break;
    default:
      console.log('No Subtype Found For ', subType, ' In Chat');
  }
}
